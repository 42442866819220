import {
	base_url,
} from '../config';
import { secureFetch } from '../../utils/http';
import {
	getAuthUser,
  } from "../../utils/auth";

const endpoints_carr = {
	GET: 'career',
	GETBYID: 'career',
};

export function getQueryParams() {
	return {
		pagination: {
			value: false,
			items: 2,
			page: 1,
		},
		filters: {
			properties: [],
		},
		filtersDefault: {
			properties: {
				codCarr:"codCarr",
				unid: "unidadAcademica.uniId",
				nombCarr:"nombCarr",
			} 
		},
	};
}

export async function getCarrsApi(queryParams=null) {
	const url = `${base_url}/api/${endpoints_carr.GET}`;
	if(queryParams)
		return await secureFetch(url, 'GET', queryParams);
	return await secureFetch(url, 'GET', null);
} 

export async function getCarrByIdApi(id) {
	const url = `${base_url}/api/${endpoints_carr.GETBYID}/${id}`;
	return await secureFetch(url, 'GET', null);
}