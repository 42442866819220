const initialActividadData = {
	planifEnfId: '',
	planifTipId: '',
	planifMatId: '',
	descripcion: ''
};

const initialArrayActividadData = [];

export { initialActividadData, initialArrayActividadData };
