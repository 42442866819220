import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import './notiStyle.scss';
import ErrorIcon from '@mui/icons-material/Error';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export default function NotiWarning(props) {
    
    const {
        open,
        setState,
        titulo,
        mensaje,
    } = props;

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setState(false);
      };

    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center' }}
                className= 'notiWarning' 
            >
                <Alert onClose={() => {handleClose()}} icon={
                    <ErrorIcon
                        style={{ color: '#FEFCFC' }}
                    />
                }>
                     <AlertTitle style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '16px',
                        lineHeight: '20px',
                     }}>{titulo}</AlertTitle>
                        {mensaje}
                </Alert>
            </Snackbar>

        </div>
    );
}
