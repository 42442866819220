const initialUnidadData = {
	id: '',
	descripcion: '',
	icono: '',
	color: '',
	anulado: '',
	codUsuAnula: ''
};

const initialArrayUnidadData = [];

export { initialUnidadData, initialArrayUnidadData };
