import { base_url } from '../config';
import { secureFetch } from '../../utils/http';
import { getAuthUser } from '../../utils/auth';

const endpoints_proyecto_modulo = {
	GET: 'planif/proyecto/modulos',
	GETBYID: 'planif/proyecto/modulos',
	CREATE: 'planif/proyecto/modulos',
	EDIT: 'planif/proyecto/modulos',
	DELETE: 'planif/proyecto/modulos',
};

const endpoint_orden_modulo = {
	EDIT: 'planif/proyecto/modulo/orden',
};

export function getQueryParams() {
	return {
		pagination: {
			value: false,
			items: 2,
			page: 1,
		},
		filters: {
			properties: [],
		},
		filtersDefault: {
			properties: {
				proyecto: 'proyecto',
			},
		},
	};
}

export async function getProyectoModulosApi(queryParams = null) {
	const url = `${base_url}/api/${endpoints_proyecto_modulo.GET}`;
	if (queryParams) return await secureFetch(url, 'GET', queryParams);
	return await secureFetch(url, 'GET', null);
}

export async function getProyectoModuloByIdApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_modulo.GETBYID}/${id}`;
	return await secureFetch(url, 'GET', null);
}

export async function createProyectoModuloApi(data) {
	const url = `${base_url}/api/${endpoints_proyecto_modulo.CREATE}`;
	let dataCreate = {
		...data,
		ordenVisual: +data.ordenVisual,
	};

	console.log(dataCreate);

	return await secureFetch(url, 'POST', dataCreate);
}

export async function editProyectoModuloApi(id, data) {
	let dataEdit = {
		...data,
		ordenVisual: +data.ordenVisual,
	};

	const url = `${base_url}/api/${endpoints_proyecto_modulo.EDIT}/${id}`;
	return await secureFetch(url, 'PUT', dataEdit);
}

export async function editOrdenModulo(data) {
	/* 
    "planifPyId": 434,
    "planifModId": 436,
    "tipo": "S",
    "dato": null */

	const url = `${base_url}/api/${endpoint_orden_modulo.EDIT}`;
	return await secureFetch(url, 'POST', data);
}

export async function deleteProyectoModuloApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_modulo.DELETE}/${id}`;
	return await secureFetch(url, 'DELETE', null);
}
