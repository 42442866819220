import React, { useState, useEffect } from 'react';
import './CardActiTablero.scss';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import { CardActions } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { Bookmark, ExpandSharp } from '@mui/icons-material';
import { ThreePointsMenu } from '../threePointsMenu/ThreePointsMenu';

export default function CardActiTablero(props) {
	const {
		id,
		idModulo,
		handleOnClickEditActividad,
		material,
		nombreActi,
		descriDocente,
		descriTipo,
		tiempoActividad,
		proposito,
		tema,
		detalles,
		dificultad,
		iconoProposito,
		iconoDificultad,
		iconoMaterial,
		handleDeleteActividad,
		showModalConfirmDelete,
		enfasisId,
	} = props;

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [cardTitulo, setCardTitutlo] = useState(descriDocente);
	const open = Boolean(anchorEl);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
		if (expanded != true) {
			setCardTitutlo('');
		} else {
			setCardTitutlo(descriDocente);
		}
	};

	useEffect(() => {
		setCardTitutlo(descriDocente);
	}, [descriDocente]);

	return (
		<div className="cardActContainer">
			<Card className="cardAct">
				<CardActions>
					<CardHeader
						className="cardHeader"
						subheader={
							<Chip
								style={{ backgroundColor: '#fff', color: '#626262' }}
								icon={<AccessTimeIcon style={{ color: '#626262' }} />}
								label={tiempoActividad}
							/>
						}
						title={
							cardTitulo?.length < 10
								? `${cardTitulo}`
								: `${cardTitulo?.substring(0, 13)}...`
						}
						onClick={handleExpandClick}
					/>
					<ThreePointsMenu>
						<MenuItem
							onClick={handleOnClickEditActividad}
							data-actividad_id={id}
							data-enfasis_id={enfasisId}
							data-modulo_id={idModulo}
						>
							Editar
						</MenuItem>
						<MenuItem
							onClick={showModalConfirmDelete}
							data-actividad_id={id}
							data-descri_doc={descriDocente}
						>
							Eliminar
						</MenuItem>
					</ThreePointsMenu>
				</CardActions>
				<Collapse in={expanded} timeout="auto" unmountOnExit>
					<CardContent style={{ width: 'auto', marginTop: '-22px' }}>
						<div>
							<span className="textoHeader">{descriDocente}</span>
							<br />

							<hr></hr>
						</div>
						<div className="textoCard">
							<div style={{ display: 'flex' }}>
								<p style={{ display: 'flex', marginBottom: '2px' }}>
									<FiberManualRecordIcon
										style={{
											fontSize: '13px',
											color: '#B11276',
											marginRight: '10px',
											marginTop: '2px',
										}}
									/>
									{descriTipo}
								</p>
							</div>
						</div>
						<span className="textoResaltado">{nombreActi}</span>
						{tema ? (
							<div style={{ display: 'flex', marginTop: '9px' }}>
								<p
									style={{
										display: 'flex',
										marginBottom: '2px',
										fontFamily: 'Roboto',
										fontSize: '14px',
									}}
								>
									<BookmarkIcon
										style={{
											fontSize: '20px',
											color: '#FFB81C',
											marginRight: '10px',
										}}
									/>
									{tema}
								</p>
							</div>
						) : (
							''
						)}
						<hr></hr>
						<div className="listadoMateriales">
							<div className="listadoItems">
								<Chip
									icon={
										<span
											style={{ color: '#33599F' }}
											className="material-symbols-outlined"
										>
											{iconoProposito != null ? iconoProposito : 'public'}
										</span>
									}
									label={proposito}
									style={{
										backgroundColor: '#FFF',
										color: '#2d2926',
										fontSize: '14px',
									}}
								/>
							</div>
							<div className="listadoItems">
								<Chip
									icon={
										<span
											style={{ color: '#33599F' }}
											className={
												iconoDificultad != null &&
												iconoDificultad === 'star_border'
													? 'material-symbols-outlined'
													: 'material-symbols-rounded'
											}
										>
											{iconoDificultad != null ? iconoDificultad : 'public'}
										</span>
									}
									label={dificultad}
									style={{
										backgroundColor: '#FFF',
										color: '#2d2926',
										fontSize: '14px',
									}}
								/>
							</div>
							<div className="listadoItems">
								<Chip
									icon={
										<span
											style={{ color: '#33599F' }}
											className="material-symbols-rounded"
										>
											{iconoMaterial != null ? iconoMaterial : 'public'}
										</span>
									}
									label={material}
									style={{
										backgroundColor: '#FFF',
										color: '#2d2926',
										fontSize: '14px',
									}}
								/>
							</div>

							{detalles?.map((item, index) => (
								<div className="listadoItems" key={index}>
									<Chip
										style={{
											backgroundColor: '#FFF',
											color: '#2d2926',
											fontSize: '14px',
										}}
										icon={
											<span
												style={{ color: '#33599F' }}
												className="material-symbols-rounded"
											>
												{item.iconoUnidad != null ? item.iconoUnidad : 'public'}
											</span>
										}
										label={item.cantidad + ' ' + item.unidad}
									/>
								</div>
							))}
						</div>
					</CardContent>
				</Collapse>
			</Card>
		</div>
	);
}
