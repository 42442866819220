import {
	base_url,
	api_version,
	client_id,
	client_secret,
	redirect_uri,
	grant_type,
} from './config';
import { secureFetch } from '../utils/http';
import { getRefreshToken, setToken, logout } from '../utils/auth';

export async function getTokenAccess(code) {
	const path_token = 'token';
	let data = {
		code,
		client_id,
		client_secret,
		redirect_uri,
		grant_type,
	};

	const url = `${base_url}/api/${path_token}`;
	return await secureFetch(url, 'POST', data);
}
export async function getTokenRefresh() {
	const path_refresh = 'token/refresh';
	
	let refresh_token = getRefreshToken();


	if (refresh_token) {
		let data = {
			refresh_token,
			client_id,
			client_secret,
		};

		const url = `${base_url}/api/${path_refresh}`;

		return await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(data),
		})
			.then(response => response.json())
			.then(response => {
					const { id_token, refresh_token } = response;
					setToken(id_token, refresh_token);
					return true;
			})
			.catch(err => {
				logout();
				return false;
			});
	}
			
	return false;
}

export async function getUserApi(code) {
	const end_url = 'users';
	const url = `${base_url}/api/${end_url}/${code}`;
	return await secureFetch(url, 'GET', null);
}
