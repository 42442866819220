import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import './accordionAct.scss';

import {
	Menu,
	MenuItem,
	Card,
	CardContent,
	CardHeader,
	Collapse,
	CardActions,
} from '@mui/material';
import { IconButton } from '@mui/material';
import { generateKeyRandom } from '../../utils/helpers';

export default function AccordionAct(props) {
	const ExpandMore = styled(props => {
		const { expand, ...other } = props;
		return <IconButton {...other} />;
	})(({ theme, expand }) => ({
		transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
		margin: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	}));

	const [anchorEl, setAnchorEl] = React.useState(null);
	const openA = Boolean(anchorEl);
	const [expanded, setExpanded] = useState(false);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const {
		descriTipo,
		handleOnClickEditActividad,
		id,
		descriDocente,
		iconoProposito,
		proposito,
		nombreActi,
		iconoDificultad,
		dificultad,
		iconoMaterial,
		material,
		detalles,
		tiempoActividad,
		handleDeleteActividad,
		panel,
		handleChange,
	} = props;

	return (
		<>
			<Card className="Accordion" style={{ borderRadius: '12px' }}>
				<CardActions
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
					className="accordionSummaryModulo"
				>
					<ExpandMore
						expand={expanded}
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more"
					>
						<ExpandMoreIcon style={{ flexDirection: 'row-reverse' }} />
					</ExpandMore>
					<CardHeader
						className="cardTitulo"
						title={
							<p
								style={{
									marginTop: '10px',
									fontFamily: 'Roboto',
									fontWeight: '700',
								}}
							>
								{descriDocente?.length < 25
									? `${descriDocente}`
									: `${descriDocente?.substring(0, 24)}...`}
							</p>
						}
					/>
					<span className="col-auto p-0">
						<Stack direction="row" spacing={1}>
							<Chip
								key={generateKeyRandom()}
								style={{
									fontFamily: 'Montserrat',
									fontStyle: 'normal',
									fontWeight: '500',
									fontSize: '12px',
									lineHeight: '15px',
									height: '15px',
									color: '#2D2926',
									background: 'none',
								}}
								icon={<AccessTimeIcon style={{ color: '#2D2926' }} />}
								label={tiempoActividad}
							/>
						</Stack>
					</span>
					<div className="col-auto p-0">
						<IconButton
							id="basic-button"
							aria-controls={openA ? 'basic-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={openA ? 'true' : undefined}
							onClick={handleClick}
						>
							<MoreVertIcon />
						</IconButton>
						<Menu
							id="basic-menu"
							anchorEl={anchorEl}
							open={openA}
							style={{ zIndex: '0' }}
							anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
							transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'basic-button',
							}}
						>
							<MenuItem
								className="ActDropdownMenuItem"
								onClick={handleOnClickEditActividad}
								data-actividad_id={id}
							>
								Editar
							</MenuItem>
							<MenuItem
								className="ActDropdownMenuItem"
								data-actividad_id={id}
								data-descri_doc={descriDocente}
								onClick={handleDeleteActividad}
							>
								Eliminar
							</MenuItem>
						</Menu>
					</div>
				</CardActions>
				<Collapse
					in={expanded}
					timeout="auto"
					unmountOnExit
					className="ActAccordionDetails"
				>
					<CardContent
						style={{
							fontFamily: 'Roboto',
							fontStyle: 'normal',
							fontWeight: '400',
							lineHeight: '16px',
							color: '#2D2926',
							padding: '20px 0px',
						}}
					>
						<span className="ActTituloIco">&#9679;</span> {descriTipo}
						<p key={generateKeyRandom()} className="ActTituloDeta">
							{nombreActi}
						</p>
						<div className="my-4">
							<p key={generateKeyRandom()} className="ActBitText">
								<span className="ActBitIco material-symbols-rounded">
									{iconoProposito != null ? iconoProposito : 'public'}
								</span>
								{proposito}
							</p>
							<p key={generateKeyRandom()} className="ActBitText">
								<span
									className={
										iconoDificultad != null && iconoDificultad === 'star_border'
											? 'ActBitIco material-symbols-outlined'
											: 'ActBitIco material-symbols-rounded'
									}
								>
									{iconoDificultad != null ? iconoDificultad : 'public'}
								</span>
								{dificultad}
							</p>
							<p key={generateKeyRandom()} className="ActBitText">
								<span className="ActBitIco material-symbols-rounded">
									{iconoMaterial != null ? iconoMaterial : 'public'}
								</span>
								{material}
							</p>

							{detalles.map(item => (
								<p key={generateKeyRandom()} className="ActBitText">
									<span className="ActBitIco material-symbols-rounded">
										{item.iconoUnidad != null ? item.iconoUnidad : 'public'}
									</span>{' '}
									{item.cantidad} {item.unidad}
								</p>
							))}
						</div>
					</CardContent>
				</Collapse>
			</Card>
		</>
	);
}
