import React, { useState, useEffect } from 'react';
import { getAccessToken } from '../../utils/auth';
import { LoginAuth } from '../../components/login/LoginAuth';
import DataTable from 'react-data-table-component';
import {
	Form,
	Button,
	Row,
	Input,
	FormGroup,
	Label,
	Table,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import {
	initialTipoData,
	initialArrayTipoData,
} from '../../api/Tipo/tipoModel';
import {
	getTiposApi,
	getTipoByIdApi,
	createTipoApi,
	editTipoApi,
	deleteTipoApi,
} from '../../api/Tipo/tipo';
import { InputCheckbox } from '../../components/Inputs/InputCheckbox';
import Tooltip from '@mui/material/Tooltip';
import { Container } from '@mui/material';
import Notificacion from '../../components/snackbar/Notificacion';
import Grid from '@mui/material/Grid';
import {
	Chip,
	Box,
	TextField,
	MenuItem,
	Select,
	InputLabel,
	FormControl,
	IconButton
} from '@mui/material';

import {
	getEnfasisApi,
	getEnfasisByIdApi,
	getQueryParams as getQueryParamsEnfasis,
} from '../../api/Enfasis/enfasis';
import '../../scss/abmEstilos.scss';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import Loading from '../../components/loading/Loading';
import SaveIcon from '@mui/icons-material/Save';

export default function Tipo() {
	const [isCreate, setIsCreate] = useState(true);
	const [isAnulado, setIsAnulado] = useState(true);
	const [isDisable, setIsDisable] = useState('');

	const [tipoData, setTipoData] = useState(initialTipoData);

	const [arrayTipoData, setArrayTipoData] = useState(
		initialArrayTipoData
	);

	const handleChangeTipo = event => {
		let texto = event.target.value;
		setIsDisable(texto);
		setTipoData({
			...tipoData,
			[event.target.name]: event.target.value,
		});
	};

	const handleEditTipo = event => {
		let id = event.target.dataset.id;
		let tipo = arrayTipoData.filter(el => el.id == id);
		setIsDisable(tipo)
		if (tipo.length) {
			setTipoData(tipo[0]);
			setIsCreate(false);
			setIdEnfasis(tipo[0].enfasiId);

			if (tipo[0].anulado == 'N') {
				setIsAnulado(true);
			} else {
				setIsAnulado(false);
			}
		}
	};

	const handleSubmitTipo = event => {
		event.preventDefault();
		isCreate ? createTipo() : editTipo();
	};

	useEffect(() => {
		getArrayTipo();
		getEnfasisData();
	}, []);

	const columns = [
		{
			name: 'Énfasis',
			selector: row => row.enfasi.descripcion,
			sortable: true,
			cell: obj =>
				obj.enfasi ? (
					<div className="dataTableText">
						{obj.enfasi.descripcion}
					</div>
				) : (
					''
				),
			maxWidth: 200,
			minWidth: 140,
			width: 200,
			wrap: true,

		},
		{
			name: 'Tipos',
			selector: row => row.descripcion ? ( row.descripcion) : (''),
			cell: row =>
				row.descripcion ? (
					<div className="dataTableText">
						{row.descripcion}
					</div>
				) : (
					''
				),
			sortable: true,
			maxWidth: 200,
			minWidth: 140,
			width: 200,
			wrap: true,
		},
		{
			name: 'Estado',
			selector: row => row.anulado,
			sortable: true,
			cell: obj => {
				return (
					<div className="botonesTabla">
						{obj.anulado == 'N' ? (
							<div className="abmCampoVisible">
								<span className="material-symbols-rounded abmIcoVisi">
									visibility
								</span>
								Habilitado
							</div>
						) : (
							<div className="abmCampoDeshabilitar">
								<span className="material-symbols-rounded  abmIcoVisiOff">
									visibility_off
								</span>
								Deshabilitado
							</div>
						)}
					</div>
				);
			},
			maxWidth: 200,
			minWidth: 140,
			width: 200,
			wrap: true,
		},
		{
			cell: obj => {
				return (
					<div className="botonesTabla">
						<Tooltip title="Editar" placement="top" arrow>
							<IconButton
								data-id={obj.id ? obj.id : ''}
								className="material-symbols-rounded icoAbmEditar"
								onClick={handleEditTipo}
							>
								mode_edit
							</IconButton>
						</Tooltip>
					</div>
				);
			},
			right: true,
			ignoreRowClick: true,
			allowOverflow: true,
		},
	];

	const handleChangeAnulado = event => {
		let anulado = event.target.checked;
		setIsAnulado(anulado);
	};

	const handleClickCancelar = () => {
		setIsDisable('');
		clear();
	};

	const clear = () => {
		setIsDisable('');
		setIsCreate(true);
		setIsAnulado(true);
		setTipoData(initialTipoData);
		setIdEnfasis('');
	};

	const [OpenNoti, setOpenNoti] = React.useState(false);

	const notiConfigIni = {
		titulo: '',
		mensaje: '',
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const [notiConfig, setNotiConfig] = useState(notiConfigIni);

	const _fieldObligatorio = () => {
		return <b style={{ color: 'red' }}>*</b>;
	};

	const [idEnfasis, setIdEnfasis] = useState('');

	const handleOnChangeEnfasis = event => {
		let val = event.target.value;
		setIdEnfasis(val);
	};

	const [enfasisObjeto, setEnfasisObjeto] = useState([]);

	const emptyColumn = [
		{
			name: 'Tipo de Recursos',
			cell: obj => {
				return <span>Sin tipo de recursos creados.</span>;
			},
			left: true,
			ignoreRowClick: true,
			allowOverflow: true,
		},
	];

	const [dataTest, setDataTest] = useState([
		{
			descripcion: 'Sin tipo de recursos creados.',
		},
	]);

	const [openLoad, setOpenLoad] = React.useState(false);

	const handleCloseLoad = () => {
		setOpenLoad(false);
	};

	const handleOpenLoad = () => {
		setOpenLoad(true);
	};

	if (getAccessToken()) {
		return (
			<Container className="contenedor">
				<div className="textoContenido">
					<h1 className="tituloContenido">Tipo de Recursos</h1>
					<p className="parrafoContenido">
						Creá y configurá los tipos que formarán parte del contenido.
					</p>
				</div>

				<div className="textoContenido">
					<h2 className="subtituloContenido">Crear tipo</h2>

					<Box sx={{ flexGrow: 1 }}>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<FormControl className="selectEnfasisAbm">
									<InputLabel>Énfasis {_fieldObligatorio()} </InputLabel>
									<Select
										value={idEnfasis ?? ''}
										label="Énfasis"
										onChange={handleOnChangeEnfasis}
									>
										{enfasisObjeto?.map((item, index) => {
											return (
												<MenuItem
													data-enfasisid={item.id}
													key={index}
													value={item.id}
													id={item.id}
												>
													{item.descripcion}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								<FormControl className="descripAbm">
									<TextField
										className="inputDeshacer"
										id="descripcion"
										name="descripcion"
										label={
											<span style={{ fontFamily: 'Roboto' }}>
												Nombre del tipo {_fieldObligatorio()}
											</span>
										}
										variant="outlined"
										inputProps={{ maxLength: 255 }}
										type="text"
										onChange={handleChangeTipo}
										value={tipoData.descripcion || ''}
									/>
								</FormControl>
							</Grid>
							<Grid className='' item xs={4}>
								<div className="seccionInput">
									<Input
										id="id"
										name="id"
										type="hidden"
										onChange={handleChangeTipo}
										value={tipoData.id || ''}
									/>
									<InputCheckbox
										isAnulado={isAnulado}
										handleChangeAnulado={handleChangeAnulado}
										isDisable={!isDisable}
									></InputCheckbox>
								</div>
								<div className="btnContentAbm">
									<Button
										className="btnCrearAbm"
										disabled={!isDisable || (idEnfasis=='')}
										onClick={handleSubmitTipo}
									>
										{isCreate ?
										<AddIcon  className='btnIcoAbm'/>
										:
										<SaveIcon className='btnIcoAbm'/>
										}
										{isCreate ? 'Crear' : 'Guardar'}
									</Button>
									<Button
										className="btnAbmCancel"
										onClick={handleClickCancelar}
										disabled={isCreate ? (!isDisable || (idEnfasis=='')) : false}
									>
										<CancelIcon  className='btnIcoAbm'/>
										Cancelar
									</Button>
								</div>
							</Grid>
						</Grid>
					</Box>
				</div>

				<h2 className="subtituloContenido listado">Listado de tipos</h2>

				<DataTable
					columns={columns}
					className="dataTableContainer"
					data={arrayTipoData ? arrayTipoData : ''}
					direction="auto"
					fixedHeaderScrollHeight="300px"
					responsive
					subHeaderAlign="right"
					subHeaderWrap
					noDataComponent={
						<DataTable
							columns={emptyColumn}
							className="dataTableContainer"
							data={dataTest ? dataTest : ''}
							direction="auto"
							fixedHeaderScrollHeight="300px"
							responsive
							subHeaderAlign="right"
							subHeaderWrap
						/>
					}
					paginationPerPage={5}
					pagination
					paginationComponentOptions={{
						rowsPerPageText: 'Registros por página:',
						rangeSeparatorText: 'de',
					}}
					paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
				/>

				<Notificacion open={OpenNoti} setState={setOpenNoti} config={notiConfig} />

				<Loading
					bg="bgLoadingWhite"
					open={openLoad}
				/>
			</Container>
		);
	}

	async function getArrayTipo() {
		try {
			handleOpenLoad();
			let tipos = await getTiposApi();
			setArrayTipoData(tipos);
			handleCloseLoad();
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function createTipo() {
		try {
			delete tipoData.id;
			let descripcion = tipoData.descripcion.trim();
			let anulado = isAnulado ? 'N' : 'S';
			let enfasi = idEnfasis;

			let tipo = await createTipoApi({ descripcion, anulado, enfasi });
			setArrayTipoData([...arrayTipoData, tipo]);
			clear();
			notiConfig.estilo = 'notiSuccessFilled';
			notiConfig.mensaje = "Datos creados con éxito";
			setOpenNoti(true);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);

			let msj = await error.json();

			if (error.status == 422) {
				notiConfig.estilo = 'notiDangerFilled';
				notiConfig.mensaje = msj.violations[0].message;
				setOpenNoti(true);
			}
		}
	}

	async function editTipo() {
		try {
			let id = tipoData.id;
			tipoData.anulado = isAnulado ? 'N' : 'S';
			tipoData.descripcion = tipoData.descripcion.trim();

			await editTipoApi(id, tipoData);
			clear();
			setIsCreate(true);
			setTipoData(initialTipoData);
			getArrayTipo();
			notiConfig.estilo = 'notiSuccessFilled';
			notiConfig.mensaje = "Datos modificados con éxito";
			setOpenNoti(true);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);

			let msj = await error.json();

			if (error.status == 422) {
				notiConfig.estilo = 'notiDangerFilled';
				notiConfig.mensaje = msj.violations[0].message;
				setOpenNoti(true);
			}
		}
	}

	async function getEnfasisData() {
		let queryParamsDefault = getQueryParamsEnfasis();

		let { anulado } = queryParamsDefault.filtersDefault.properties;
		let value = 'N';

		const query = { [anulado]: value };
		queryParamsDefault.filters.properties.push(query);

		try {
			const resp = await getEnfasisApi(queryParamsDefault);
			setEnfasisObjeto(resp);
		} catch (error) {
			console.log(await error.json());
		}
	}

	return (
		<>
			<LoginAuth></LoginAuth>
		</>
	);
}
