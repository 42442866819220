import React, { useEffect, useState } from 'react';

import {
	Chip,
	Stack,
	IconButton,
	Tooltip,
	Menu,
	MenuItem,
	Table,
	TableRow,
	TableCell,
	TableContainer,
	Paper,
	Collapse,
	TableBody,
	Card,
	CardHeader,
	CardContent,
	CardActions,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardTemaActividad from '../cardTemaActividad/CardTemaActividad';
import { styled } from '@mui/material/styles';
import { generateKeyRandom } from '../../utils/helpers';
import { ThreePointsMenu } from '../threePointsMenu/ThreePointsMenu';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import './CardTemasProyecto.scss';

export default function CardTemaProyecto(props) {
	const {
		enfasisId,
		idModulo,
		actividades,
		actividadesDetalleMaterials,
		tema,
		temasDetalleMaterials,
		proyecto,
		actividadesDetalle,
		handleOnClickEditActividad,
		filtrador,
		showModalCrearActividad,
		showModalConfirmDelete,
		cambiarOrdenActividad,
		cambiarOrdenTema,
		permitirMover,
		moverHTMLtema,
	} = props;

	const [expanded, setExpanded] = useState(false);
	const [tiempoActividades, setTiempoActividades] = useState('0 min');

	useEffect(() => {
		if (actividadesDetalle.length) {
			setTiempoActividades(actividadesDetalle[0].tiempoModulos);
		}
	}, [actividadesDetalle]);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	let temasFiltrados = proyecto.temas?.filter(el => el.planifModId == null);

	const ExpandMore = styled(props => {
		const { expand, ...other } = props;
		return <IconButton {...other} />;
	})(({ theme, expand }) => ({
		transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
		margin: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	}));

	return (
		<div>
			<Card className="cardTemaContainer">
				<CardActions className="cardActionsContainer">
					<div className="cardChipsTema">
						<div className="cardActionsContainer">
							<ExpandMore
								expand={expanded}
								onClick={handleExpandClick}
								aria-expanded={expanded}
								aria-label="show more"
							>
								<ExpandMoreIcon style={{ flexDirection: 'row-reverse' }} />
							</ExpandMore>
							<CardHeader title="Recursos Compartidos" />
						</div>

						<CardContent style={{ marginRight: '-16px' }}>
							<Tooltip title="Tiempo total" arrow>
								<Chip
									icon={<AccessTimeIcon style={{ color: '#FFF' }} />}
									label={tiempoActividades}
									style={{
										backgroundColor: '#33599F',
										color: '#FFF',
										marginRight: '17px',
									}}
								/>
							</Tooltip>
							<ThreePointsMenu>
								<MenuItem
									onClick={event => {
										showModalCrearActividad();
									}}
								>
									Crear Actividad
								</MenuItem>
							</ThreePointsMenu>
						</CardContent>
					</div>
				</CardActions>
				<Collapse
					in={expanded}
					timeout="auto"
					unmountOnExit
					className="cardTemaHeader"
				>
					<CardContent style={{ marginTop: '-39px' }}>
						<div
							style={{
								fontFamily: 'Roboto',
								fontSize: '15px',
							}}
						>
							<Stack
								direction="row"
								spacing={1}
								style={{
									display: 'flex',
									justifyContent: 'right',
									marginBottom: '35px',
								}}
							>
								{actividadesDetalleMaterials?.map((item, index) => (
									<Tooltip key={index} title={item.material} arrow>
										<Chip
											style={{
												backgroundColor: '#fff',
												color: '#003087',
												margin: '0px 6px',
												marginBottom: '-15px',
												marginTop: '30px',
											}}
											icon={
												<span
													className="material-icons"
													style={{ color: '#003087', fontSize: '18px' }}
												>
													{item.iconoMaterial != null
														? item.iconoMaterial
														: 'public'}
												</span>
											}
											label={
												<span key={item.material}>{item.tiempoMaterial}</span>
											}
										/>
									</Tooltip>
								))}
							</Stack>
						</div>
						<div className="cardTema">
							<TableContainer
								component={Paper}
								className="tablaTemaContainer"
								style={{ marginLeft: '-15px', marginTop: '1px' }}
							>
								<Table size="small" className="temaTable">
									<TableBody style={{ borderTop: '1px solid #ccc' }}>
										{actividades.length != 0 ? (
											actividades?.map(
												(item, index) =>
													item.tema == null && (
														<TableRow
															id={'card_acti' + item.id}
															key={`${item.planifActId}_${index}`}
														>
															<TableCell colSpan={2} align="left">
																{item.descriDocente}
															</TableCell>
															<TableCell align="left">
																{item.descriTipo}
															</TableCell>
															<TableCell align="left">
																<Chip
																	sx={{ backgroundColor: item.colorEnfasis }}
																	label={item.descriEnfasis}
																/>
															</TableCell>
															<TableCell align="left">
																{item.material}
															</TableCell>
															<TableCell align="right">
																<Stack direction="row" spacing={1}>
																	<Tooltip title={item.dificultad} arrow>
																		<span
																			className="material-icons"
																			style={{ color: '#33599F' }}
																		>
																			{item.iconoDificultad != null
																				? item.iconoDificultad
																				: 'public'}
																		</span>
																	</Tooltip>
																	<Tooltip title={item.proposito} arrow>
																		<span
																			className="material-icons"
																			style={{ color: '#33599F' }}
																		>
																			{item.iconoProposito != null
																				? item.iconoProposito
																				: 'public'}
																		</span>
																	</Tooltip>
																</Stack>
															</TableCell>
															<TableCell align="right">
																<Tooltip title="Tiempo total" arrow>
																	<Chip
																		style={{
																			background: 'none',
																			fontFamily: 'Roboto',
																			fontStyle: 'normal',
																			fontWeight: '400',
																			fontSize: '14px',
																			lineHeight: '16px',
																			color: '#2D2926',
																		}}
																		icon={
																			<span
																				className="material-icons"
																				style={{ color: '#33599F' }}
																			>
																				schedule
																			</span>
																		}
																		label={<span>{item.tiempoActividad}</span>}
																	/>
																</Tooltip>
															</TableCell>
															<TableCell align="right" colSpan={4}>
																<div className='btnBoxUpDownTemaAct'>
																	<Tooltip title="Subir Actividad" placement="top">
																		<IconButton className='btnUpDown'
																			sx={{
																				"&.MuiButtonBase-root:hover": { bgcolor: "transparent" }
																			}}

																			data-actividad_id={item.id}
																			data-tipo="S"
																			data-tema="N"
																			onClick={e => {
																				moverHTMLactividad(e);
																				cambiarOrdenActividad(e);
																			}}
																		>
																			<ArrowCircleUpIcon className='btnUpDownIco' />
																		</IconButton>
																	</Tooltip>
																	<Tooltip title="Bajar Actividad" placement="bottom">
																		<IconButton className='btnUpDown'
																			sx={{
																				"&.MuiButtonBase-root:hover": { bgcolor: "transparent" }
																			}}
																			data-actividad_id={item.id}
																			data-tipo="B"
																			data-tema="N"
																			onClick={e => {
																				moverHTMLactividad(e);
																				cambiarOrdenActividad(e);
																			}}
																		>
																			<ArrowCircleDownIcon className='btnUpDownIco' />
																		</IconButton>
																	</Tooltip>

																</div>
															</TableCell>
															<TableCell
																align="right"
																style={{ paddingRight: '6px' }}
															>
																<ThreePointsMenu>
																	<MenuItem
																		data-actividad_id={item.id}
																		data-enfasis_id={null}
																		data-modulo_id={null}
																		onClick={handleOnClickEditActividad}
																	>
																		Editar
																	</MenuItem>
																	<MenuItem
																		onClick={showModalConfirmDelete}
																		data-actividad_id={item.id}
																		data-descri_doc={item.descriDocente}
																	>
																		Eliminar
																	</MenuItem>
																</ThreePointsMenu>
															</TableCell>
														</TableRow>
													)
											)
										) : (
											<TableRow>
												<TableCell colSpan={2} align="left">
													Sin datos creados.
												</TableCell>
											</TableRow>
										)}

										{temasFiltrados?.map(
											(item, index) =>
												item.tema && (
													<CardTemaActividad
														key={`${item.planifModId}_${index}`}
														tema={item.tema}
														idModulo={null}
														archivo={'card'}
														cambiarOrdenTema={cambiarOrdenTema}
														moverHTMLtema={moverHTMLtema}
														proyecto={proyecto}
														actividades={actividades}
														tiempoTema={item.tiempoModulos}
														showModalConfirmDelete={showModalConfirmDelete}
														showModalCrearActividad={showModalCrearActividad}
														temasDetalleMaterials={temasDetalleMaterials}
														handleOnClickEditActividad={
															handleOnClickEditActividad
														}
														moverHTMLactividad={moverHTMLactividad}
														permitirMover={permitirMover}
														cambiarOrdenActividad={cambiarOrdenActividad}
													/>
												)
										)}
										<TableRow></TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</div>
					</CardContent>
				</Collapse>
			</Card>
		</div>
	);

	function moverHTMLactividad(event) {
		let tipo = event.currentTarget.dataset.tipo;
		let actividad_id = event.currentTarget.dataset.actividad_id;
		let inTema = event.currentTarget.dataset.tema;

		let acti = document.querySelector('#card_acti' + actividad_id);
		let abajo = acti.nextElementSibling;
		let arriba = acti.previousElementSibling;

		if (inTema == 'N') {
			if (
				abajo.className ==
				'MuiTableRow-root tableRowTema css-1q1u3t4-MuiTableRow-root'
			) {
				abajo = null;
			} else {
				abajo = acti.nextElementSibling;
			}
		}

		if (permitirMover) {
			if (tipo == 'S' && arriba != null && !(arriba?.classList.contains('tableRowTema'))) {
				arriba.insertAdjacentElement('beforebegin', acti);

				document.querySelector('#card_acti' + actividad_id).style.transition =
					'background .5s';
				document.querySelector('#card_acti' + actividad_id).style.background =
					'#d7d7d7';
				setTimeout(function () {
					document.querySelector('#card_acti' + actividad_id).style.background =
						'#FFF';
				}, 200);
			} else if (tipo == 'B' && abajo != null && !(abajo?.classList.contains('tableRowTema'))) {
				abajo.insertAdjacentElement('afterend', acti);
				document.querySelector('#card_acti' + actividad_id).style.transition =
					'background .5s';
				document.querySelector('#card_acti' + actividad_id).style.background =
					'#d7d7d7';
				setTimeout(function () {
					document.querySelector('#card_acti' + actividad_id).style.background =
						'#FFF';
				}, 200);
			}
		}
	}
}
