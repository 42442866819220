import React from 'react';
import InfoIcon from '@mui/icons-material/Info';

import './ReferenciaInput.scss';

export default function ReferenciaInput(props) {
	const { show, mensaje, color } = props;

	if (show) {
		return (
			<div className="refContainer">
				<InfoIcon
					style={{
						color: color != '' ? color : '#00BCD4',
						marginRight: '10px',
					}}
				/>
				{mensaje === '' ? (
					<p>
						La duración es un porcentaje expresado en horas. Por ejemplo 30
						minutos equivale a 0.5.
					</p>
				) : (
					mensaje
				)}
			</div>
		);
	} else {
		return <div></div>;
	}
}
