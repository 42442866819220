import { getRolesFromLocalStorage } from '../../src/utils/auth';

const routes_role_persona = [{ route: '/', option_name: 'Inicio' ,show_nav:false}];

const routes_role_conte_plan = [
	{ route: '/', option_name: 'Inicio' ,show_nav:false},
	{ route: '/proyectos', option_name: 'Planificar',show_nav:true, parents:"planificar"},
	{ route: '/listadoActividades/:id', option_name: 'Actividad del proyecto' ,show_nav:false, parents:"planificar"},
	// { route: '/tableroProyectos/:id', option_name: 'Actividad del proyecto' ,show_nav:false, parents:"planificar"},
	{ route: '/tableroProyectos', option_name: 'Actividad del proyecto' ,show_nav:false, parents:"planificar"},
	// { route: '/temasProyecto/:id', option_name: 'Actividad del proyecto' ,show_nav:false, parents:"planificar"},
	{ route: '/temasProyecto', option_name: 'Actividad del proyecto' ,show_nav:false, parents:"planificar"},
];

const routes_role_admin_plan = [
	// ...routes_role_conte_plan,
	{ route: '/', option_name: 'Inicio' ,show_nav:false},
	{ route: '/actividad', option_name: 'Actividades',show_nav:false, parents: 'gestor'},
	{ route: '/competencia', option_name: 'Competencias',show_nav:true, parents: 'gestor'},
	{ route: '/dificultad', option_name: 'Dificultades',show_nav:true, parents: 'gestor'},
	{ route: '/interacciones', option_name: 'Interacciones',show_nav:true, parents: 'gestor'},
	{ route: '/proposito', option_name: 'Propósitos',show_nav:true, parents: 'gestor'},
	{ route: '/enfasis', option_name: 'Énfasis',show_nav:true, parents: 'gestor'},
	{ route: '/unidades', option_name: 'Unidades',show_nav:true, parents: 'gestor'},
	{ route: '/material', option_name: 'Materiales',show_nav:true, parents: 'gestor'},
	{ route: '/asociarUnidades', option_name: 'Asociar unidades',show_nav:true, parents: 'gestor'},
	{ route: '/tipo', option_name: 'Tipo de recursos',show_nav:true, parents: 'gestor'},
	{ route: '/recursos', option_name: 'Recursos',show_nav:true, parents: 'gestor'},
	{ route: '/equivalencia', option_name: 'Equivalencias',show_nav:true, parents: 'gestor'},
	{ route: '/crearEditarRecurso/*', option_name: 'Editar',show_nav:false, parents: 'gestor'},
];

const route_role = [
	{
		role_name: 'ROLE_PERSONA',
		routes: routes_role_persona,
	},
	{
		role_name: 'ROLE_CONTE_PLAN',
		routes: routes_role_conte_plan,
	},
	{
		role_name: 'ROLE_ADMIN_PLAN',
		routes: routes_role_admin_plan,
	},

];

const checkRouteRole = () => {
	let roles = getRolesFromLocalStorage();
	let access_routes = [];
	roles.map(role => {
		route_role.map(rr => {
			if (role == rr.role_name) {
				access_routes.push(...rr.routes);
			}
		});
	});

	return [...new Set(access_routes)];
};

export default checkRouteRole;
