import React, { useState, useEffect, createContext, Navigate } from 'react';
import {
	getAccessToken,
	getRefreshToken,
	refreshAccessToken,
	logout,
} from '../utils/auth';
import { getTokenRefresh } from '../api/user';
import jwtDecode from 'jwt-decode';

export const AuthContext = createContext();

export default function AuthProvider(props) {
	const { children } = props;
	const [user, setUser] = useState({
		user: null,
		isLoading: true,
	});

	useEffect(() => {
		checkUserLogin(setUser);
	}, []);

	return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
}

const checkUserLogin = async setUser => {
	const accessToken = getAccessToken();

	try {
		if (!accessToken) {
			const refreshToken = await getTokenRefresh();
			if (!refreshToken) {
				logout();
				setUser({
					user: null,
					isLoading: false,
				});
			} else {
				setUser({
					user: jwtDecode(getAccessToken()),
					isLoading: false,
				});
			}
		} else {
			setUser({
				user: jwtDecode(accessToken),
				isLoading: false,
			});
		}
	} catch (error) {
		logout();
		window.location.href = '/';
	}
};
