import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';



export default function BtnCrear(props) {

    const {
        fn,
        texto,
        enfasisId,
        moduloId
    } = props;

    return (
        <Stack spacing={2} direction="row">
            <Button style={{ 
                        background: '#FFFFFF',
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '16px',
                        color: '#2D2926',
                        padding: '16px',
                        width: '285px',
                        height: '49px',
                        border: '1px solid #E9E9E9',
                        borderRadius: '12px',
                        textTransform: 'capitalize',
                        marginTop: '0.5rem',
                        justifyContent: 'flex-start'
                    }} 
                    startIcon={<AddIcon />}
                    onClick={fn} 
                    data-enfasis_id={enfasisId}
                    data-modulo_id={moduloId}
            >
                {texto}
            </Button>
        </Stack>
    );
}
