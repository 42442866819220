import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import './accordionProy.scss';
import { generateKeyRandom } from '../../utils/helpers';
import { editProyectoApi } from '../../api/Proyecto/proyecto';
import { initialProyectoModuloData } from '../../api/Proyecto/proyectoModel';
import { createProyectoModuloApi } from '../../api/Proyecto/proyectoModulo';
import Notificacion from '../../components/snackbar/Notificacion';

export default function AccordionProy(props) {
	const {
		descripcion,
		setDescripcion,
		detalles,
		detalleMaterials,
		handleDeleteProyecto,
		getProyectosById,
		proyectoData,
		setProyectoData,
		showModalCrearActividad,
		handleOpenModalHacerCopia,
		handleOpenModalEditarProyecto,
	} = props;

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [tiempoProyecto, setTiempoProyecto] = useState('0 min');
	const [tiempoEstado, setTiempoEstado] = useState('G');

	const open = Boolean(anchorEl);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		setTiempoProyecto(
			detalles.length > 0 ? detalles[0].tiempoProyecto : '0 min'
		);
		setTiempoEstado(detalles.length > 0 ? detalles[0].estadoTiempo : 'G');
	});

	const [isEditDescripcion, setIsEditDescripcion] = useState(false);

	const handleChangeProyectoDescripcion = e => {
		let text = e.currentTarget.textContent;
		setIsEditDescripcion(true);
		setDescripcion(text);
		setProyectoData({
			...proyectoData,
			['descripcion']: descripcion,
		});
	};

	const [proyectoModuloData, setProyectoModuloData] = useState(
		initialProyectoModuloData
	);

	const [OpenNotiCrear, setOpenNotiCrear] = useState(false);

	const notiConfigNotiCrear = {
		titulo: '',
		mensaje: 'Datos creados con éxito',
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const handleCreateProyectoModulo = event => {
		createProyectoModulo();
	};

	const [OpenNotiEditMod, setOpenNotiEditMod] = useState(false);

	const notiConfigNotiEditMod = {
		titulo: '',
		mensaje: 'Datos modificados con éxito',
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const iniNotiErrorMod = {
		titulo: '',
		mensaje: 'Warning',
		estilo: 'notiDangerFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const [notiConfigNotiErrorMod, setNotiConfigNotiErrorMod] =
		useState(iniNotiErrorMod);
	const [OpenNotiErrorMod, setOpenNotiErrorMod] = useState(false);

	useEffect(() => {
		editProyectoData();
	}, [descripcion]);

	return (
		<>
			<div className="proyectoHeader">
				<div className="tituloEdit">
					<h2
						contentEditable={true}
						onBlur={handleChangeProyectoDescripcion}
						style={{ fontSize: '16px' }}
						suppressContentEditableWarning={true}
					>
						{descripcion.length > 30
							? descripcion.substring(0, 30) + '...'
							: descripcion}
					</h2>
					<IconButton>
						<EditIcon style={{ color: '#FFF', fontSize: '19px' }} />
					</IconButton>
				</div>
				<div className="proyectoTimer">
					<Stack direction="row" spacing={1} mt={2}>
						{detalleMaterials.map((item, index) => (
							<React.Fragment key={generateKeyRandom() + index}>
								<div className="chipLabel">
									<Chip
										key={generateKeyRandom() + index}
										className="chipTableroMain"
										style={{ backgroundColor: '#003087', color: '#fff' }}
										icon={
											<span
												className="material-icons"
												style={{ color: '#fff' }}
											>
												{item.iconoMaterial != null
													? item.iconoMaterial
													: 'public'}
											</span>
										}
										label={
											<span key={generateKeyRandom() + item.material}>
												{item.tiempoMaterial}
											</span>
										}
									/>
									<label key={generateKeyRandom() + index}>
										{item.material}
									</label>
								</div>
							</React.Fragment>
						))}
						{tiempoEstado == 'V' && (
							<div>
								<Chip
									key={generateKeyRandom()}
									className="chipVerde"
									style={{ color: '#003087', marginRight: '6px' }}
									icon={<AccessTimeIcon style={{ color: '#003087' }} />}
									label={
										<span key={generateKeyRandom()}>{tiempoProyecto}</span>
									}
								/>
								<Tooltip
									placement="top"
									arrow
									title="Duración base completa. ¡Buen trabajo!"
								>
									<CheckCircleIcon style={{ color: '#53D258' }} />
								</Tooltip>
							</div>
						)}

						{tiempoEstado == 'A' && (
							<div>
								<Chip
									key={generateKeyRandom()}
									className="chipAmarillo"
									style={{ color: '#003087', marginRight: '6px' }}
									icon={<AccessTimeIcon className="iconAmarillo" />}
									label={
										<span key={generateKeyRandom()}>{tiempoProyecto}</span>
									}
								/>
								<Tooltip
									placement="top"
									arrow
									title="Alcanzando la duración base requerida. Continua cargando actividades."
								>
									<WarningIcon className="iconAmarillo" />
								</Tooltip>
							</div>
						)}

						{tiempoEstado == 'R' && (
							<div>
								<Chip
									key={generateKeyRandom()}
									className="chipRojo"
									style={{ color: '#003087', marginRight: '6px' }}
									icon={<AccessTimeIcon className="iconRojo" />}
									label={
										<span key={generateKeyRandom()}>{tiempoProyecto}</span>
									}
								/>
								<Tooltip
									placement="top"
									arrow
									title="La duración base del proyecto establecida es de 90:00 hs. Si superaste este valor, puedes modificar el mismo desde la edición del proyecto. "
								>
									<ErrorIcon className="iconRojo" />
								</Tooltip>
							</div>
						)}
						{tiempoEstado == 'G' && (
							<div>
								<Chip
									key={generateKeyRandom()}
									className="chipGeneral"
									style={{
										background: '#FFF',
										color: '#003087',
										marginRight: '8px',
									}}
									icon={<AccessTimeIcon style={{ color: '#003087' }} />}
									label={
										<span key={generateKeyRandom()}>{tiempoProyecto}</span>
									}
								/>
							</div>
						)}
					</Stack>

					<IconButton
						id="basic-button"
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
					>
						<MoreVertIcon style={{ color: '#FFF' }} />
					</IconButton>
					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						style={{ zIndex: '0' }}
						open={open}
						onClose={handleClose}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}
					>
						<MenuItem
							onClick={() => {
								handleOpenModalEditarProyecto();
								handleClose();
							}}
						>
							Editar
						</MenuItem>

						<MenuItem
							onClick={() => {
								handleOpenModalHacerCopia();
								handleClose();
							}}
						>
							Hacer una copia
						</MenuItem>

						<MenuItem
							onClick={() => {
								handleCreateProyectoModulo();
								handleClose();
							}}
						>
							Crear módulo
						</MenuItem>

						<MenuItem
							onClick={() => {
								showModalCrearActividad();
								handleClose();
							}}
						>
							Crear actividad
						</MenuItem>
						<MenuItem
							onClick={handleDeleteProyecto}
							data-proyecto_id={proyectoData.id}
						>
							Eliminar
						</MenuItem>
					</Menu>
				</div>
			</div>

			<Notificacion
				open={OpenNotiCrear}
				setState={setOpenNotiCrear}
				config={notiConfigNotiCrear}
			/>

			<Notificacion
				open={OpenNotiEditMod}
				setState={setOpenNotiEditMod}
				config={notiConfigNotiEditMod}
			/>

			<Notificacion
				open={OpenNotiErrorMod}
				setState={setOpenNotiErrorMod}
				config={notiConfigNotiErrorMod}
			/>
		</>
	);

	async function editProyectoData() {
		try {
			if (isEditDescripcion) {
				let edit = await editProyectoApi(proyectoData.id, { descripcion });
				setOpenNotiEditMod(true);
			}
		} catch (error) {
			let msj = await error.json();
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);

			if (error.status == 422) {
				notiConfigNotiErrorMod.mensaje = msj.violations[0].message;
				setOpenNotiErrorMod(true);
			}
		}
	}

	async function createProyectoModulo() {
		try {
			let { descripcion } = proyectoModuloData;
			let result = await createProyectoModuloApi({
				descripcion: 'Módulo ' + (proyectoData.modulos.length + 1),
				proyecto: proyectoData.id,
			});

			setOpenNotiCrear(true);
			await getProyectosById();
		} catch (error) {
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);
		}
	}
}
