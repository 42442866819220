export const generateKeyRandom = () => {
    return (Math.random() + 1).toString(36).substring(7);
}



export const getExpressionCheckIfValueIsNumber = () => {
    // const regex = /^[0-9\b]+$/;
    // const regex = /^[0-9\b.,]+$/;
    const regex = /^(^[0-9]+$|^$)/;
    return regex; 
}



export const getExpressionCkeckIfValueIsNumAndPoint = () => {
    const regex = /^[0-9]+([.][0-9]+)?$/;
    return regex;
}

export const getExpressionCkeckIfValueIsHour = () => {
    const regex = /[0-9^.]$/;
    return regex;
}   