import 'bootstrap/dist/css/bootstrap.min.css';
// import "./App.css";
import { Route, Routes, Link } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import AuthProvider from './providers/authProvider';
import routes from './config/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
	return (
		<AuthProvider>
			<Routes>
				{routes.map((route, index) => (
					<Route
						key={index}
						path={route.path}
						element={
							<route.layout>
								<route.component />
							</route.layout>
						}
					/>
				))}
			</Routes>
			<ToastContainer />
		</AuthProvider>
	);
}

export default App;
