import React, { useState } from 'react';
import { getAccessToken } from '../../utils/auth';
import { LoginAuth } from '../../components/login/LoginAuth';
// import { Form, Button } from 'reactstrap';
import Notification from '../../components/notification/Notificaction';
import useNotification from '../../hooks/useNotificacion';
import { getUserApi, getTokenRefresh  as getTokenRefreshApi } from '../../api/user';
import { ToastContainer, toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import { Select, MenuItem, InputLabel, Button, FormControl} from '@mui/material';

export default function Example() {
	const [
		isOpenNotification,
		openNotificacion,
		closeNotification,
		isTItle,
		setTitleNotification,
		isContent,
		setContentNotification,
	] = useNotification(false);

	const [age, setAge] = useState(); 
	console.log("🚀 ~ file: Example.js:24 ~ Example ~ age", age)
	if (getAccessToken()) {
		return (
			<>
		<FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Age</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
	value={age}
    label="Age"
    // onChange={handleChange}
  >
    <MenuItem value={10}>Ten</MenuItem>
    <MenuItem value={20}>Twenty</MenuItem>
    <MenuItem value={30}>Thirty</MenuItem>
  </Select>
</FormControl>


				<h1>Simulacro de notification</h1>
				<Button color="primary" onClick={openNotificacion}>
					abrir
				</Button>
				<Button color="primary" onClick={closeNotification}>
					ceerrrar
				</Button>
				<Button color="primary" onClick={changeText}>
					changetitle
				</Button>
				<Button color="primary" onClick={changeContent}>
					changeContent
				</Button>
				<input id="test" type="text"></input>
				<Notification
					isOpen={isOpenNotification}
					title={isTItle}
					content={isContent}
				></Notification>
				<h1>Simulacro de http request</h1>
				<Button color="primary" onClick={getUser}>
					get http
				</Button>
				<Button color="primary" onClick={getTokenRefresh}>
					check token expire
				</Button>
			</>
		);
	}

	async function getUser() {
		try {
			let u = await getUserApi(1628162);
			console.log('🚀 ~ file: Example.js ~ line 53 ~ getUser ~ u', u);
		} catch (error) {
			console.log("🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error", error)
		}
	}

	function changeText() {
		let val = document.getElementById('test').value;
		setTitleNotification(val);
	}
	async function getTokenRefresh() {
		try {
			// jwtDecode(undefined);
			var a ; 
			jwtDecode("casacaacas");
		} catch (error) {
			console.log("🚀 ~ file: Example.js:72 ~ getTokenRefresh ~ error", error)
			
		}
		// let r = await getTokenRefreshApi();
		// console.log("🚀 ~ file: Example.js ~ line 69 ~ getTokenRefresh ~ r", r)
	}
	function changeContent() {
		let val = document.getElementById('test').value;
		setContentNotification(val);
	}

	return (
		<>
			<LoginAuth></LoginAuth>
		</>
	);
}
