import { useState } from 'react';

export function useNotificationMui (initialValue = false) {
	const [isOpenNotification, setIsOpenNotification] = useState(initialValue);
	const [isTItle, setTitle] = useState('title');
	const [isContent, setIsContent] = useState('content');

	const openNotificacion = ( optional  ) => {
		setIsOpenNotification(true);
		const {delay} = optional ?? false
		if(delay){
			setTimeout(closeNotification, +delay);
		}
	};

	const closeNotification = () => {
		setIsOpenNotification(false);
	};

	const setTitleNotification = title => {
		setTitle(title);
	};

	const setContentNotification = title => {
		setIsContent(title);
	};

	return [
		isOpenNotification,
		openNotificacion,
		closeNotification,
		isTItle,
		setTitleNotification,
        isContent,
        setContentNotification
	];
};