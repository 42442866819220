import React, { useState, useEffect } from 'react';
import { Chip, Container } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import {
	getEnfasisApi,
	getQueryParams as getQueryParamsEnfasis,
} from '../../api/Enfasis/enfasis';
import {getActividadApi, getQueryParams as getQueryParamsActividad} from '../../api/Actividad/actividad'
import './Recurso.scss';
import TablaRecursos from '../../components/tableRecursos/TablaRecursos';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Loading from '../../components/loading/Loading';

export default function Recurso() {

	const navigate = useNavigate();
	 
	const [enfasis, setEnfasis] = useState([]);

	useEffect(() => {
		getEnfasisData();
	}, []);

	const [openLoading, setOpenLoading] = React.useState(false);

	const handleCloseLoading = () => {
		setOpenLoading(false);
	};

	const handleOpenLoading = () => {
		setOpenLoading(true);
	};

	return (
		<Container className="contenedor">
			<div>
				<div className="textoContenido">
					<h1 className="tituloContenido">Recursos</h1>
					<p className="parrafoContenido">
						Configurá los datos de los recursos que formaran parte del
						contenido. A continuación vas a poder crear un nuevo recurso como
						asi tambien visualizar todos los recursos creados.
					</p>
				</div>
				<div className="botonesContenido">
					<Chip
						icon={<AddIcon style={{ color: '#003087', fontSize: '16px' }} />}
						label="Nuevo recurso"
						clickable
						onClick={() => {
							navigate('/crearEditarRecurso');
						}}
						style={{
							background: '#CCD6E7',
							height: '28px',
							fontSize: '12px',
							paddingLeft: '6px',
							color: '#003087',
							fontWeight: '500',
						}}
					/>
				</div>
				<div className="recursoContenido">
					<div className="recursoItem recursoHeader">
						<h2>Lista de recursos creados</h2>
					</div>
					{enfasis.map((item, index) => (
						<div key={`${index}_${item.id}`}>
							<div className="recursoItem enfasisHeader">
								<h2>{item.descripcion} </h2>
							</div>
							<div>
								{item.tipos.map((el, i) => (
									<TablaRecursos  key={`${el.id}_${item.id}`} descripcion={el.descripcion} tipoId={el.id} enfasisId={item.id} />
								))}
							</div>
						</div>
					))}
				</div>
			</div>

			<Loading
				bg="bgLoadingWhiteTrans"
				open={openLoading}
				tiempoMensaje={3000}
			/>
		</Container>
	);

	async function getEnfasisData() {
		let queryParamsDefault = getQueryParamsEnfasis();

		let { anulado } = queryParamsDefault.filtersDefault.properties;
		let value = 'N';

		const query = { [anulado]: value };
		queryParamsDefault.filters.properties.push(query);

		try {
			handleOpenLoading();
			const resp = await getEnfasisApi(queryParamsDefault);
			setEnfasis(resp);
			handleCloseLoading();
		} catch (error) {
			console.log(await error.json());
		}
	}
	async function getActividaData() {
		let queryParamsDefault = getQueryParamsActividad();

		let { anulado } = queryParamsDefault.filtersDefault.properties;
		let value = 'N';

		const query = { [anulado]: value };
		queryParamsDefault.filters.properties.push(query);

		try {
			handleOpenLoading();
			const resp = await getActividadApi(queryParamsDefault);
			handleCloseLoading();
		} catch (error) {
			console.log(await error.json());
		}
	}



}
