import React, { useEffect, useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './cardModuloTablero.scss';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CardActiTablero from '../cardActiTablero/CardActiTablero';
import {
	Card,
	CardActions,
	Collapse,
	CardContent,
	CardHeader,
	Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton } from '@mui/material';
import EnfasisTablero from '../enfasisTablero/EnfasisTablero';
import { ThreePointsMenu } from '../threePointsMenu/ThreePointsMenu';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

export default function CardModuloTablero(props) {
	const {
		descripcion,
		detalles,
		fn,
		fnEdit,
		observacion,
		content,
		cambiarOrden,
		showModalCrearActividad,
		enfasis,
		idModulo,
		actividades,
		modulos,
		showModalConfirmDelete,
		detallesEnfasis,
		detalleMaterials,
		detalleEnfasis,
		handleOnClickEditActividad,
		handleDeleteModulo,
		getProyectosById,
		handleDeleteActividad,
		panel,
		permitirMover,
		/* expanded, */
		handleChange,
		showModalModulo,
	} = props;

	const [moduloDescripcion, setModuloDescripcion] = useState('');

	const [isEditModulo, setIsEditModulo] = useState(false);
	const [modulosData, setModulosData] = useState(modulos);
	const [anchorEl, setAnchorEl] = useState(false);
	const openA = Boolean(anchorEl);
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [expanded, setExpanded] = useState(false);

	const ExpandMore = styled(props => {
		const { expand, ...other } = props;
		return <IconButton {...other} />;
	})(({ theme, expand }) => ({
		transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
		margin: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	}));

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<Card
			id={'card' + idModulo}
			className="accordion"
			style={{ borderRadius: '12px', marginBottom: '10px' }}
		>
			<CardActions
				aria-controls="panel1a-content"
				id="panel1a-header"
				className="accordionSummaryModulo"
			>
				<ExpandMore
					expand={expanded}
					onClick={handleExpandClick}
					aria-expanded={expanded}
					aria-label="show more"
				>
					<ExpandMoreIcon style={{ flexDirection: 'row-reverse' }} />
				</ExpandMore>
				<CardHeader
					className="cardTitulo"
					title={<p className="cardDescriModulo">{descripcion}</p>}
				/>
				<div className="cardOpciones">
					<Stack direction="row" spacing={1}>
						{detalles != '' ? (
							<Chip
								className="chipGeneral"
								icon={<AccessTimeIcon style={{ color: '#FFF' }} />}
								label={detalles}
								style={{
									backgroundColor: '#33599F',
									color: '#FFF',
									marginRight: '10px',
								}}
							/>
						) : (
							<Chip
								className="chipTableroMain"
								icon={<AccessTimeIcon style={{ color: '#FFF' }} />}
								label={'0 min'}
								style={{
									backgroundColor: '#A9A9A9',
									color: '#FFF',
								}}
							/>
						)}
					</Stack>

					<div className='btnBoxUpDown'>
						<Tooltip title="Subir Módulo" placement="top">
							<IconButton className='btnUpDown' 
								sx={{
									"&.MuiButtonBase-root:hover": { bgcolor: "transparent"}
								}}

								onClick={e => {
									cambiarOrden(e);
									moverHTMLmodulo(e);
								}}
								data-modulo_id={idModulo}
								data-tipo="S"
							>
								<ArrowCircleUpIcon className='btnUpDownIco'/>
							</IconButton>
						</Tooltip>
						<Tooltip title="Bajar Módulo" placement="bottom">
							<IconButton className='btnUpDown' 
								sx={{
									"&.MuiButtonBase-root:hover": { bgcolor: "transparent"}
								}}
								onClick={e => {
									cambiarOrden(e);
									moverHTMLmodulo(e);
								}}
								data-modulo_id={idModulo}
								data-tipo="B"
							>
								<ArrowCircleDownIcon className='btnUpDownIco'/>
							</IconButton>
						</Tooltip>
						
					</div>

					<div className="cardSeccion">
						<ThreePointsMenu>
							<MenuItem
								data-modulo_id={idModulo}
								onClick={event => {
									showModalCrearActividad(null, idModulo);
								}}
							>
								Crear actividad
							</MenuItem>
							<MenuItem
								className="ActDropdownMenuItem"
								data-modulo_id={idModulo}
								data-nombre_modulo={descripcion}
								data-observacion={observacion}
								onClick={showModalModulo}
							>
								Editar
							</MenuItem>
							<MenuItem
								className="ActDropdownMenuItem"
								data-modulo_id={idModulo}
								data-nombre_modulo={descripcion}
								onClick={showModalConfirmDelete}
							>
								Eliminar
							</MenuItem>
						</ThreePointsMenu>
					</div>
				</div>
			</CardActions>
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				<CardContent>
					<div
						style={{
							display: 'flex',
							justifyContent: 'right',
							marginBottom: '-20px',
							marginTop: '10px',
						}}
					>
						{detalleMaterials?.map((item, index) => (
							<Stack
								direction="row"
								spacing={1}
								style={{
									display: 'flex',
									justifyContent: 'right',
									marginBottom: '35px',
								}}
								key={generateKeyRandom() + index}
							>
								<Tooltip title={item.material} arrow>
									<Chip
										style={{
											background: 'none',
											fontFamily: 'Roboto',
											fontStyle: 'normal',
											fontWeight: '400',
											fontSize: '14px',
											lineHeight: '16px',
											color: '#003087',
										}}
										icon={
											<span
												className="material-icons"
												style={{ color: '#33599F', fontSize: '20px' }}
											>
												{item.iconoMaterial != null
													? item.iconoMaterial
													: 'public'}
											</span>
										}
										label={
											<span key={generateKeyRandom() + item.material}>
												{item.tiempoMaterial}
											</span>
										}
									/>
								</Tooltip>
							</Stack>
						))}
					</div>
					<div className="tableroGeneral">
						{enfasis?.map((el, index) => (
							<EnfasisTablero
								key={index}
								nombreEnfasis={el.descripcion}
								actividades={actividades}
								detallesEnfasis={detallesEnfasis}
								showModalCrearActividad={showModalCrearActividad}
								enfasisId={el.id}
								idModulo={idModulo}
								hr={el.color != null ? el.color : '#a0a0a0'}
								keyRandom={generateKeyRandom}
								handleOnClickEditActividad={handleOnClickEditActividad}
								contentActi={modulos?.map((item, index) => (
									<React.Fragment key={index}>
										{item.planifEnfId == el.id && (
											<CardActiTablero
												key={index}
												enfasisId={item.planifEnfId}
												id={item.id}
												idModulo={idModulo}
												handleOnClickEditActividad={handleOnClickEditActividad}
												descriDocente={item.descriDocente}
												showModalConfirmDelete={showModalConfirmDelete}
												proposito={item.proposito}
												dificultad={item.dificultad}
												nombreActi={item.nombreActividad}
												descriEnfasis={item.descriEnfasis}
												descriTipo={item.descriTipo}
												detalles={item.detalles}
												tema={item.tema}
												material={item.material}
												iconoProposito={item.iconoProposito}
												iconoDificultad={item.iconoDificultad}
												iconoMaterial={item.iconoMaterial}
												iconoTipo={item.iconoTipo}
												iconoEnfasis={item.iconoEnfasis}
												tiempoActividad={item.tiempoActividad}
												handleDeleteActividad={handleDeleteActividad}
											/>
										)}
									</React.Fragment>
								))}
							/>
						))}
					</div>
				</CardContent>
			</Collapse>
		</Card>
	);

	function moverHTMLmodulo(event) {
		let tipo = event.currentTarget.dataset.tipo;
		let modulo_id = event.currentTarget.dataset.modulo_id;

		let modulos = document.querySelector('#modulos');
		let mod = document.querySelector('#card_mod_' + modulo_id);
		let abajo = mod.nextElementSibling;
		let arriba = mod.previousElementSibling;

		if (permitirMover) {
			if (tipo == 'S' && arriba != null) {
				arriba.insertAdjacentElement('beforebegin', mod);

				document.querySelector('#card' + modulo_id).style.transition =
					'background .5s';
				document.querySelector('#card' + modulo_id).style.background =
					'#d7d7d7';
				setTimeout(function () {
					document.querySelector('#card' + modulo_id).style.background = '#FFF';
				}, 200);
			} else if (tipo == 'B' && abajo != null) {
				abajo.insertAdjacentElement('afterend', mod);
				document.querySelector('#card' + modulo_id).style.transition =
					'background .5s';
				document.querySelector('#card' + modulo_id).style.background =
					'#d7d7d7';
				setTimeout(function () {
					document.querySelector('#card' + modulo_id).style.background = '#FFF';
				}, 200);
			}
		}
	}

	function generateKeyRandom() {
		return (Math.random() + 1).toString(36).substring(7);
	}
}
