import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { LoginAuth } from '../../components/login/LoginAuth';
import { getAccessToken } from '../../utils/auth';

export default function SignIn() {
	let navigate = useNavigate();
	if (getAccessToken()) {
		return navigate('/');
	}

	return (
		<>
			<LoginAuth></LoginAuth>
		</>
	);
}
