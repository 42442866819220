import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SaveIcon from '@mui/icons-material/Save';
import InfoIcon from '@mui/icons-material/Info';
import HelpIcon from '@mui/icons-material/Help';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
	Modal,
	Button,
	Box,
	Container,
	IconButton,
	TextField,
	MenuItem,
	Select,
	InputLabel,
	FormControl,
	Chip,
	FormHelperText,
	Tooltip,
	InputAdornment,
	OutlinedInput,
	Autocomplete,
	Grid,
} from '@mui/material';
import { ModuloAutocompleteComponent } from '../../../components/moduloAutocomplete/ModuloAutocompleteComponent';
import {
	getProyectoModulosApi,
	getProyectoModuloByIdApi,
} from '../../../api/Proyecto/proyectoModulo';
import {
	getTemasProyectoByIdApi,
	getQueryParams as getQueryParamsProyecto,
	getProyectoByIdApi,
} from '../../../api/Proyecto/proyecto';
import {
	createProyectoDetalleApi,
	editProyectoDetalleApi,
	getProyectoDetalleByIdApi,
} from '../../../api/Proyecto/proyectoDetalle';
import {
	getEnfasisApi,
	getEnfasisByIdApi,
	getQueryParams as getQueryParamsEnfasis,
} from '../../../api/Enfasis/enfasis';

import {
	getTiposApi,
	getQueryParams as getQueryParamsTipos,
} from '../../../api/Tipo/tipo';

import { getMaterialsApi } from '../../../api/Material/material';

import {
	getPropositoApi,
	getQueryParams as getQueryParamsProposito,
} from '../../../api/Proposito/proposito';

import {
	getDificultadApi,
	getQueryParams as getQueryParamsDificultad,
} from '../../../api/Dificultad/dificultad';
import { editProyectoActividadApi } from '../../../api/Proyecto/proyectoActividad';
import {
	getMaterialsUnidadByIdApi,
	getQueryParams as getQueryParamsMaterialsUnidad,
} from '../../../api/MaterialUnidad/materialUnidad';

import {
	getActividadApi,
	getActividadByIdApi,
	getQueryParams as getQueryParamsActividad,
} from '../../../api/Actividad/actividad';
import {
	getProyectoActividadByIdApi,
	getProyectoActividadByIdViewApi,
} from '../../../api/Proyecto/proyectoActividad';

import './modalEditarActividad.scss';
import { CoPresentOutlined, Help } from '@mui/icons-material';
import Notificacion from '../../../components/snackbar/Notificacion';
import '../../../scss/modal.scss';

export default function NewEditarActividad(props) {
	const { enfasisParent, moduloParent, getProyectosById } = props;

	const navigate = useNavigate();
	const params = useParams();

	const { proyecto, modulos } = props;

	const {
		handleOpenModalEditarActividad,
		openModalEditarActividad,
		setOpenModalEditarActividad,
		handleChangeEditActividad,
	} = props;

	const { idEditActividad, actividades } = props;

	const [editActividadModal, setEditActividadModal] = useState(idEditActividad);

	const [titulo, setTitulo] = useState('');
	const [descripcion, setDescripcion] = useState('');
	const [idModulo, setIdModulo] = useState('');
	const [idEnfasis, setIdEnfasis] = useState('');
	const [idTipoRecurso, setIdTipoRecurso] = useState('');
	const [idRecurso, setIdRecurso] = useState('');
	const [idMaterial, setIdMaterial] = useState('');
	const [idProposito, setIdProposito] = useState('');
	const [idDificultad, setIdDificultad] = useState('');
	const [idActividad, setIdActividad] = useState('');
	const [isDisableBtnEditar, setIsDisableBtnEditar] = useState(true);
	const [idProyecto, setIdProyecto] = useState(params.id);

	const [enfasisObjeto, setEnfasisObjeto] = useState([]);
	const [tipoObjeto, setTipoObjeto] = useState([]);
	const [propositoObjeto, setPropositoObjeto] = useState([]);
	const [dificultadObjeto, setDificultadObjeto] = useState([]);
	const [materialObjeto, setMaterialObjeto] = useState([]);
	const [recursoObjeto, setRecursoObjeto] = useState([]);
	const [moduloObjeto, setModuloObjeto] = useState(modulos ?? []);
	const [unidadMaterial, setUnidadMaterial] = useState([]);
	const [tiempo, setTiempo] = useState();

	const [proyectoData, setProyectoData] = useState(proyecto);

	const [actividad, setActividad] = useState({});

	const [valueTema, setValueTema] = useState('');
	const [inputValueTema, setInputValueTema] = useState('');
	const [temas, setTemas] = useState([]);

	const formHelperText = {
		Palabras: 'Mín 350 - Máx 900',
		Minutos: 'Mín 01:00 - Máx 05:00',
		Páginas: 'Mín 1 - Máx 5',
	};

	useEffect(() => {
		getDificultadData();
		getPropositoData();
		setModuloObjeto(modulos);
		getMaterialesData();
		getEnfasisData();
		getActividadesData();
	}, [proyecto]);

	useEffect(() => {
		if (Object.keys(actividad)?.length) {
			setTitulo(actividad.descriDocente);
			setDescripcion(actividad.observacion);
			setIdEnfasis(actividad.planifEnfId);
			setIdTipoRecurso(actividad.planifTipId);
			setIdRecurso(actividad.detalles[0].planifActId);
			setIdMaterial(actividad.planifMatId);
			setIdProposito(actividad.planifProId);
			setIdDificultad(actividad.planifDifId);
			setIdModulo(actividad.planifModId);
			setUnidadMaterial(actividad.detalles);
			setTiempo(actividad.tiempoActividad);
			setValueTema(actividad.tema);
			setIdProyecto(actividad.proyectoId);
		}
	}, [actividad]);

	useEffect(() => {
		if (idEnfasis) {
			getTiposData(idEnfasis);
		}
	}, [idEnfasis]);

	useEffect(() => {
		getTemasProyectoByIdApiData();
	}, [idModulo, idProyecto]);

	const isFalse = (item) => item==false;
	
	const [unidArray,setUnidArray] = useState([]);

	useEffect(() => {
		if (
			titulo.length > 0 &&
			idEnfasis != '' &&
			idTipoRecurso != '' &&
			idRecurso != '' &&
			idMaterial != '' &&
			idProposito != '' &&
			idDificultad != '' &&
			_checkIfUnidadMaterialHasValue() && 
			unidArray.every(isFalse)
		) {
			handleEnableDisableBtnCrear(false);
		} else {
			handleEnableDisableBtnCrear(true);
		}
	}, [
		titulo,
		idEnfasis,
		idTipoRecurso,
		idRecurso,
		idMaterial,
		idProposito,
		idDificultad,
		unidadMaterial,
		unidArray
	]);

	

	useEffect(() => {
		if (idEditActividad) {
			setEditActividadModal(idEditActividad);
			getActividadData();
		}
	}, [idEditActividad]);

	useEffect(() => {
		if (idTipoRecurso) {
			getActividadesByEnfasisAndRecursoData();
		}
	}, [idTipoRecurso]);

	const handleOpen = () => {
		setOpenModalEditarActividad(true);
	};

	const handleEnableDisableBtnCrear = value => {
		setIsDisableBtnEditar(value);
	};

	const handleClose = () => {
		setOpenModalEditarActividad(false);
		clear();
	};

	const handleOnChangeTitulo = event => {
		let val = event.target.value;

		if(val.length>0){
			setValid(false);
		}
		else {
			setValid(true);
		}

		if (val != ' ') {
			setTitulo(val);
		}
	};

	const handleOnChangeDescri = event => {
		let val = event.target.value;
		setDescripcion(val);
	};

	const handleOnChangeEnfasis = event => {
		let val = event.target.getAttribute('data-enfasisid');
		setIdEnfasis(val);

		getTiposData(val);
	};

	const handleOnChangeTipoRecurso = event => {
		let val = event.target.getAttribute('data-tiporecursoid');
		setIdTipoRecurso(val);
	};

	const handleOnChangeRecurso = event => {
		let val = event.target.getAttribute('data-recursoid');
		setIdRecurso(val);
	};

	const handleOnChangeMaterial = event => {
		let val = event.target.getAttribute('data-materialid');
		setIdMaterial(val);
	};

	const handleOnChangeProposito = event => {
		let val = event.currentTarget.dataset.propositoid;
		setIdProposito(val);
	};

	const handleOnChangeDificultad = event => {
		let val = event.currentTarget.dataset.dificultadid;
		setIdDificultad(val);
	};

	const handleOnChangeModulo = event => {
		let val = event.target.getAttribute('data-moduloid');
		setIdModulo(val);
		setValueTema({ label: '' });
		setInputValueTema('');
	};

	const handleOnChangeMaterialUnidadData = index => e => {
		// let unidad = unidadMaterial[index].unidad;

		const regex = /^[0-9\b]+$/;

		if (e.target.value === '' || regex.test(e.target.value)) {
			let max = +e.target.getAttribute('data-max');
			let min = +e.target.getAttribute('data-min');
			let rige = e.target.getAttribute('data-rige');

			let value = +e.target.value;

			if (rige == 'S') {
				if (value >= min && value <= max) {
					unidadMaterial[index] = {
						...unidadMaterial[index],
						...{
							planifUniId: e.target.getAttribute('data-unidad_id'),
							material: idMaterial,
							cantidad: +e.target.value,
						},
					};

					let copy = structuredClone(unidadMaterial);
					setUnidadMaterial(copy);
					handleChangeUnidArray(index,false);
				} else {
					handleChangeUnidArray(index,true);
				}
			} else {
				unidadMaterial[index] = {
					...unidadMaterial[index],
					...{
						planifUniId: e.target.getAttribute('data-unidad_id'),
						planifMatId: idMaterial,
						cantidad: +e.target.value,
					},
				};
				let copy = structuredClone(unidadMaterial);
				setUnidadMaterial(copy);
			}
		} else {
			e.target.value = '';
		}
	};

	const notiActInit = {
		titulo: '',
		mensaje: '',
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const [notiActConfig, setNotiActConfig] = React.useState(notiActInit);

	const [openNotiAct, setOpenNotiAct] = React.useState(false);

	const [valid, setValid] = useState(false);

	function clear() {
		setIdEnfasis('');
		setIdModulo('');
		setIdTipoRecurso('');
		setTitulo('');
		setDescripcion('');
		handleChangeEditActividad(false);
		setUnidArray([]);
	}

	const _fieldObligatorio = () => {
		return <b style={{ color: '#D32F2F' }}>*</b>;
	};


	const _checkIfUnidadMaterialHasValue = () => {
		let veri = unidadMaterial.filter(um => {
			return (
				um?.cantidad == null || um?.cantidad == undefined || um?.cantidad == ''
			);
		});

		return veri.length == 0 ? true : false;
	};

	const initUnidArray = (size) => {
		let array = [];

		if(size!=0){
			for(let i=0;i<size;i++){
				array.push(false);
			}
		}
		
		setUnidArray(array);
	}

	const handleChangeUnidArray = (index,vbool) => {
		unidArray[index] = vbool;
		let copy = structuredClone(unidArray);
		setUnidArray(copy);
	}

	return (
		<div>
			<Modal
				open={openModalEditarActividad}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box className="modalActividadContainer modalShadow">
					<Container className="modalActividadHeader">
						<h1>Editar actividad</h1>
						<IconButton onClick={handleClose} style={{ margin: 'auto 0px' }}>
							<CloseIcon />
						</IconButton>
					</Container>
					<Container className="headerTiempoTotal">
						<div className="tiempoTotalHeader">
							<h2>Tiempo total</h2>
							<Chip
								icon={<AccessTimeIcon />}
								label={tiempo}
								variant="outlined"
							/>
						</div>
					</Container>
					<Container>
						<TextField
							className="ActiInputModal"
							id="outlined-basic"
							error={valid}
							label={
								<span style={{ fontFamily: 'Roboto' }}>
									Título {_fieldObligatorio()}
								</span>
							}
							variant="outlined"
							inputProps={{ maxLength: 255 }}
							value={titulo}
							onChange={handleOnChangeTitulo}
						/>
					</Container>
					<Container>
						<TextField
							className="DescriInputModal"
							id="outlined-multiline-static"
							label="Descripción"
							inputProps={{ maxLength: 1000 }}
							multiline
							onChange={handleOnChangeDescri}
							value={descripcion ?? ''}
							rows={3}
						/>
					</Container>
					<Container className="seccionInputAcitividad">
						<FormControl className="selectModuloActividad">
							<ModuloAutocompleteComponent
								setIdModulo={setIdModulo}
								setValueTema={setValueTema}
								idProyecto={idProyecto}
								idModulo={idModulo}
							></ModuloAutocompleteComponent>
						</FormControl>
						<FormControl className="selectModuloTema">
							{/* {String(JSON.stringify(valueTema))} "---"
							{String(JSON.stringify(inputValueTema))} */}
							<Autocomplete
								freeSolo
								id="combo-box-demo"
								disableClearable={true}
								value={valueTema}
								options={temas}
								onChange={(event, newValue) => {
									setValueTema(newValue);
								}}
								onInputChange={(event, newInputValue) => {
									setInputValueTema(newInputValue);
								}}
								renderInput={params => {
									return <TextField {...params} label="Temas" />;
								}}
							/>
						</FormControl>
					</Container>
					<Container className="seccionInputAcitividad">
						<FormControl className="selectEnfasisActividad">
							<InputLabel>Énfasis {_fieldObligatorio()} </InputLabel>
							<Select value={idEnfasis ?? ''} label="Énfasis" disabled>
								{enfasisObjeto?.map((item, index) => {
									return (
										<MenuItem
											onClick={handleOnChangeEnfasis}
											data-enfasisid={item.id}
											// key={index}
											key={`${item.id}_${index}`} 
											value={item.id}
											id={item.id}
										>
											{item.descripcion}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
						<FormControl className="selectTipoRecursoActividad">
							<InputLabel>Tipo de recurso {_fieldObligatorio()} </InputLabel>
							<Select
								value={idTipoRecurso ?? ''}
								label="Tipo de recurso"
								disabled
							>
								{tipoObjeto?.map((item, index) => {
									return (
										<MenuItem
											onClick={handleOnChangeTipoRecurso}
											data-tiporecursoid={item.id}
											// key={index}
											key={`${item.id}_${index}`} 
											value={item.id}
											id={item.id}
										>
											{item.descripcion}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Container>
					<Container>
						<FormControl className="selectRecursoActividad">
							<InputLabel>Recurso {_fieldObligatorio()} </InputLabel>
							<Select
								value={idRecurso ?? ''}
								label="Recurso"
								disabled
								onChange={handleOnChangeRecurso}
							>
								{recursoObjeto?.map((item, index) => {
									return (
										<MenuItem
											data-recursoid={item.id}
											// key={index}
											key={`${item.id}_${index}`} 
											id={item.id}
											value={item.id}
										>
											{item.descripcion}
											<Tooltip
												title={item.observacion}
												placement="right"
												arrow
												className="tooltipRecurso"
											>
												<InfoIcon
													style={{
														marginLeft: '10px',
														fontSize: '16px',
														color: '#00BCD4',
													}}
												/>
											</Tooltip>
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Container>
					<Container maxWidth="lg" className="seccionInputMatAcitividad">
						<FormControl className="selectMaterialActividad">
							<InputLabel>Material</InputLabel>
							<Select
								value={idMaterial ?? ''}
								label="Material"
								disabled
							>
								{materialObjeto?.map((item, index) => {
									return (
										<MenuItem
											data-materialid={item.id}
											key={`${item.id}_${index}`} 
											value={item.id}
											id={item.id}
										>
											<span
												className="material-symbols-rounded"
												style={{ margin: '0px 3px', fontSize: '16px' }}
											>
												{item.icono != null ? item.icono : 'Help'}
											</span>
											{item.descripcion}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
						{unidadMaterial?.map((unidad, index) => {
							return (
									<FormControl
										key={`${unidad.unidad.id}_${index}`} 
										id="outlined-weight-helper-text"
										className="selectMinutosActividad"
										style={{ marginLeft: '6px' }}
										error={unidArray[index]}
									>
										<InputLabel htmlFor="component-outlined">
											{`${unidad.unidad}`} {_fieldObligatorio()}
										</InputLabel>
										<OutlinedInput
											id="outlined-adornment-weight"
											data-id={unidad.unidad.id}
											aria-describedby="outlined-weight-helper-text"
											inputProps={{
												'aria-label': 'weight',
												maxLength: 6,
												'data-unidad_id': `${unidad.planifUniId}`,
												'data-max': `${unidad?.limite?.maximo}`,
												'data-min': `${unidad?.limite?.minimo}`,
												'data-rige': `${
													unidad?.limite?.rige == undefined
														? 'N'
														: unidad?.limite?.rige
												}`,
											}}
											label={
												<span style={{ fontFamily: 'Roboto' }}>
													{`${unidad.unidad.descripcion}`}
													{_fieldObligatorio()}
												</span>
											}
											defaultValue={+unidad.cantidad}
											onChange={handleOnChangeMaterialUnidadData(index)}
											startAdornment={
												<InputAdornment position="start">
													<span
														className="material-symbols-rounded"
														style={{ margin: '0px 3px', fontSize: '16px' }}
													>
														{unidad.iconoMaterial != null
															? unidad.iconoMaterial
															: 'Help'}
													</span>
												</InputAdornment>
											}
										/>

										<FormHelperText
											id="outlined-weight-helper-text"
											className="selectMinutosActividad"
										>
											{unidad?.limite?.texto == undefined
												? ''
												: unidad.limite?.texto}
										</FormHelperText>
									</FormControl>
							);
						})}
						<FormControl className="selectPropositoActividad">
							<InputLabel>Propósito {_fieldObligatorio()} </InputLabel>
							<Select value={idProposito} label="Proposito">
								{propositoObjeto?.map((item, index) => {
									return (
										<MenuItem
											onClick={handleOnChangeProposito}
											data-propositoid={item.id}
											key={`${item.id}_${index}`} 
											value={item.id}
											id={item.id}
										>
											{' '}
											<span
												className="material-symbols-rounded"
												style={{ margin: '0px 3px', fontSize: '16px' }}
											>
												{item.icono != null ? item.icono : 'Help'}
											</span>
											{item.descripcion}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
						<FormControl className="selectDificultadActividad">
							<InputLabel>Dificultad {_fieldObligatorio()} </InputLabel>
							<Select value={idDificultad} label="Dificultad">
								{dificultadObjeto?.map((item, index) => {
									return (
										<MenuItem
											onClick={handleOnChangeDificultad}
											data-dificultadid={item.id}
											// key={index}
											key={`${item.id}_${index}`} 
											value={item.id}
											id={item.id}
										>
											<span
												className={
													item.icono != null && item.icono === 'star_border'
														? 'material-symbols-outlined'
														: 'material-symbols-rounded'
												}
												style={{ margin: '0px 3px', fontSize: '16px' }}
											>
												{item.icono != null ? item.icono : 'Help'}
											</span>
											{item.descripcion}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Container>
					<Container className="modalSeccionBotones">
						<Chip
							className="chipBotonCrear"
							icon={<SaveIcon style={{ fontSize: '18px', color: '#003087' }} />}
							label="Guardar"
							disabled={isDisableBtnEditar}
							clickable
							onClick={editProyectoActividad}
						/>
						<Chip
							className="chipBotonCancelar"
							icon={
								<CancelIcon style={{ fontSize: '18px', color: '#003087' }} />
							}
							label="Cancelar"
							variant="outlined"
							clickable
							onClick={handleClose}
						/>
					</Container>
				</Box>
			</Modal>

			<Notificacion
				open={openNotiAct}
				setState={setOpenNotiAct}
				config={notiActConfig}
			/>
		</div>
	);
	/* GETTERS */

	async function getEnfasisData() {
		let queryParamsDefault = getQueryParamsEnfasis();

		let { anulado } = queryParamsDefault.filtersDefault.properties;
		let value = 'N';

		const query = { [anulado]: value };
		queryParamsDefault.filters.properties.push(query);

		try {
			const resp = await getEnfasisApi(queryParamsDefault);
			setEnfasisObjeto(resp);
		} catch (error) {
			console.log(await error.json());
		}
	}

	async function getTiposData(val) {
		try {
			let queryParamsDefault = getQueryParamsTipos();

			let { anulado } = queryParamsDefault.filtersDefault.properties;
			let value = 'N';

			let { enfasi } = queryParamsDefault.filtersDefault.properties;
			let enfasiId = val;

			const query = { [anulado]: value, [enfasi]: enfasiId };
			queryParamsDefault.filters.properties.push(query);

			const resp = await getTiposApi(queryParamsDefault);
			setTipoObjeto(resp);
		} catch (error) {
			console.log(await error.json());
		}
	}

	async function getMaterialesData() {
		try {
			let queryParamsDefault = getQueryParamsMaterialsUnidad();

			let { anulado } = queryParamsDefault.filtersDefault.properties;
			let value = 'N';

			const query = { [anulado]: value };
			queryParamsDefault.filters.properties.push(query);

			const resp = await getMaterialsApi();
			setMaterialObjeto(resp);
		} catch (error) {
			console.log(await error.json());
		}
	}

	async function getPropositoData() {
		try {
			let queryParamsDefault = getQueryParamsProposito();

			let { anulado } = queryParamsDefault.filtersDefault.properties;
			let value = 'N';

			const query = { [anulado]: value };
			queryParamsDefault.filters.properties.push(query);

			const resp = await getPropositoApi(queryParamsDefault);
			setPropositoObjeto(resp);
		} catch (error) {
			console.log(await error.json());
		}
	}

	async function getDificultadData() {
		try {
			let queryParamsDefault = getQueryParamsDificultad();

			let { anulado } = queryParamsDefault.filtersDefault.properties;
			let value = 'N';

			const query = { [anulado]: value };
			queryParamsDefault.filters.properties.push(query);

			const resp = await getDificultadApi(queryParamsDefault);
			setDificultadObjeto(resp);
		} catch (error) {
			console.log(await error.json());
		}
	}

	async function getActividadesData() {
		let queryParamsDefault = getQueryParamsActividad();

		let { anulado } = queryParamsDefault.filtersDefault.properties;
		let value = 'N';

		const query = { [anulado]: value };
		queryParamsDefault.filters.properties.push(query);

		try {
			const resp = await getActividadApi(queryParamsDefault);
			setIdActividad(resp);
		} catch (error) {
			console.log(await error.json());
		}
	}

	async function getActividadData() {
		try {
			const actividad = await getProyectoActividadByIdViewApi(idEditActividad);
			setActividad(actividad);
			initUnidArray(actividad.detalles.length);
		} catch (error) {
			console.log(await error.json());
		}
	}

	async function getActividadByIdData(id) {
		const actividad = await getActividadByIdApi(id);

		setIdTipoRecurso(actividad.tipoId);
	}

	async function getActividadesByEnfasisAndRecursoData() {
		let queryParamsDefault = getQueryParamsActividad();

		let { anulado, tipo_recurso, enfasis } =
			queryParamsDefault.filtersDefault.properties;
		let value = 'N';

		if (idTipoRecurso && idEnfasis) {
			const query = {
				[anulado]: value,
				[tipo_recurso]: idTipoRecurso,
				[enfasis]: +idEnfasis,
			};

			queryParamsDefault.filters.properties.push(query);

			try {
				const resp = await getActividadApi(queryParamsDefault);
				setRecursoObjeto(resp);
			} catch (error) {
				console.log(await error.json());
			}
		}
	}

	async function editProyectoActividad() {
		try {
			const data = {};

			data.planifPyId = +proyecto.id;
			data.planifEnfId = +idEnfasis;
			data.descripcion = titulo;
			data.observacion =
				descripcion != '' && descripcion != null ? descripcion : '';
			data.actividad = +idRecurso;
			data.planifDifId = +idDificultad;
			data.planifProId = +idProposito;
			data.tema = inputValueTema.trim();

			data.planifModId = +idModulo == '' ? null : +idModulo;
			const response_proyecto_actividad = await editProyectoActividadApi(
				idEditActividad,
				data
			);
			console.log(data, 'data');

			const response_proyecto_detalle = await editProyectoDetalleData(
				response_proyecto_actividad
			);
			console.log(response_proyecto_detalle, 'resp');
		} catch (error) {
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);
			let msj = await error.json();
			console.log(msj);

			if (error.status == 422) {
				notiActConfig.mensaje = msj.violations[0].message;
				notiActConfig.estilo = 'notiDangerFilled';
				setOpenNotiAct(true);
			}
		}
	}

	async function editProyectoDetalleData(data) {
		try {
			for await (const element of unidadMaterial) {
				let body = {};
				body.cantidad = +element.cantidad;
				const resp = await editProyectoDetalleApi(
					`actividadProyecto=${element.planifPyActId};unidad=${element.planifUniId};material=${element.planifMatId}`,
					body
				);
			}

			await getProyectosById();
			setOpenModalEditarActividad(false);
			clear();

			notiActConfig.mensaje = 'Datos modificados con éxito';
			notiActConfig.estilo = 'notiSuccessFilled';
			setOpenNotiAct(true);
		} catch (error) {
			let msj = await error.json();

			if (error.status == 422) {
				notiActConfig.mensaje = msj.violations[0].message;
				notiActConfig.estilo = 'notiDangerFilled';
				setOpenNotiAct(true);
			}
		}
	}

	async function getTemasProyectoByIdApiData() {
		if (idProyecto && idModulo) {
			// setValueTema('');
			let queryParamsDefault = getQueryParamsProyecto();

			let { modulo } = queryParamsDefault.filtersDefault.properties;

			let moduloid = idModulo;

			const query = { [modulo]: moduloid };
			queryParamsDefault.filters.properties.push(query);

			const resp = await getTemasProyectoByIdApi(
				+idProyecto,
				queryParamsDefault
			);

			const mutateTema = resp.map(el => {
				return { label: el.tema };
			});

			console.log(mutateTema)
			
			setTemas(mutateTema);

		} else if (idProyecto) {
			const resp = await getTemasProyectoByIdApi(+idProyecto);

			const mutateTema = resp.map(el => {
				return { label: el.tema };
			});
			setTemas(mutateTema);
		}
	}
}
