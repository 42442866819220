import { base_url } from '../config';
import { secureFetch } from '../../utils/http';
import { getAuthUser } from '../../utils/auth';

const endpoints_proyecto_interaccion = {
	GET: 'planif/interacciones',
	GETBYID: 'planif/interacciones',
	CREATE: 'planif/interacciones',
	EDIT: 'planif/interacciones',
};

export async function getInteraccionApi() {
	const url = `${base_url}/api/${endpoints_proyecto_interaccion.GET}`;
	return await secureFetch(url, 'GET', null);
}

export async function getInteraccionByIdApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_interaccion.GETBYID}/${id}`;
	return await secureFetch(url, 'GET', null);
}

export async function createInteraccionApi(data) {
	const url = `${base_url}/api/${endpoints_proyecto_interaccion.CREATE}`;
	let dataCreate = {
        ...data,
	};

	return await secureFetch(url, 'POST', dataCreate);

}

export async function editInteraccionApi(id, data) {
    let user = getAuthUser();
	
	let dataEdit = {
		...data,
        descripcion: data.descripcion,
        anulado: data.anulado || '',
        color: data.color || '',
        icono: data.icono || '',
	};


	if(dataEdit.anulado == 'S'){
		dataEdit.codUsuAnula= user.username;
	}

	const url = `${base_url}/api/${endpoints_proyecto_interaccion.EDIT}/${id}`;
	return await secureFetch(url, 'PUT', dataEdit);
}
