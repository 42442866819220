import React from 'react';
import { Label, FormGroup } from 'reactstrap';

export function InputCheckbox(props) {
	const { isAnulado, handleChangeAnulado, isDisable } = props;

	return (
		<>
			<FormGroup className="seccionCheck" row>
				<div className="seccionCheck">
					<Label>Estado</Label>
					<span>{isAnulado ? ' Habilitado' : ' Deshabilitado'}</span>
				</div>
			</FormGroup>
			<input
				type="checkbox"
				className="toggle"
				onChange={handleChangeAnulado}
				checked={isAnulado}
				disabled={isDisable}
			></input>
		</>
	);
}
