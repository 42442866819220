import { base_url } from '../config';
import { secureFetch } from '../../utils/http';
import { getAuthUser } from '../../utils/auth';

const endpoints_proyecto = {
	GET: 'planif/proyectos',
	GETBYID: 'planif/proyectos',
	CREATE: 'planif/proyectos',
	EDIT: 'planif/proyectos',
	DELETE: 'planif/proyectos',
	COPY: 'planif/proyecto/copy',
	DOWNLOAD: 'planif/proyecto/:id/export/:format',
	GETTTEMASBYID: 'planif/proyecto/:id/temas',
};
let user = getAuthUser();
export function getQueryParams  () {
	return {
		pagination: {
			value: false,
			items: 2,
			page: 1,
		},
		filters: {
			properties: [],
		},
		filtersDefault: {
			properties: {
				username: 'persona.username',
				modulo: 'modulo'
			},
		},
	};
};

export async function getProyectosApi() {
	let user = getAuthUser();
	let value = user.username;

	let queryParamsDefault = getQueryParams();
	let { username } = queryParamsDefault.filtersDefault.properties;
	const query = { [username]: value };
	queryParamsDefault.filters.properties.push(query);

	const url = `${base_url}/api/${endpoints_proyecto.GET}`;
	return await secureFetch(url, 'GET', queryParamsDefault);
}

export async function getProyectoByIdApi(id, queryParams = null) {
	if (!queryParams) {
		queryParams = getQueryParams();
	}

	const url = `${base_url}/api/${endpoints_proyecto.GETBYID}/${id}`;
	return await secureFetch(url, 'GET', queryParams);
}

export async function createProyectoApi(data) {
	let user = getAuthUser();

	const url = `${base_url}/api/${endpoints_proyecto.CREATE}`;

	let dataCreate = {
		...data,
		persona: user.username,
	};

	delete dataCreate.codigoId;
	delete dataCreate.codigoTipo;

	return await secureFetch(url, 'POST', dataCreate);
}

export async function editProyectoApi(id, data) {
	let dataEdit = {
		...data,
	};
	const url = `${base_url}/api/${endpoints_proyecto.EDIT}/${id}`;
	return await secureFetch(url, 'PUT', dataEdit);
}

export async function copyProyectoApi(data) {
	let dataEdit = {
		...data,
	};
	const url = `${base_url}/api/${endpoints_proyecto.COPY}`;
	return await secureFetch(url, 'POST', dataEdit);
}

export async function deleteProyectoApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto.DELETE}/${id}`;
	return await secureFetch(url, 'DELETE', null);
}


export async function getDownloadProyectoByIdApi(id, format,queryParams = null) {
	if (!queryParams) {
		queryParams = getQueryParams();
	}

	let url = `${base_url}/api/${endpoints_proyecto.DOWNLOAD.replace(":id",id).replace(":format",format)}`;

	return await secureFetch(url, 'GET', queryParams);
}

export async function getTemasProyectoByIdApi(id, queryParams = null) {
	if (!queryParams) {
		queryParams = getQueryParams();
	}

	let url = `${base_url}/api/${endpoints_proyecto.GETTTEMASBYID.replace(":id",id)}`;
	return await secureFetch(url, 'GET', queryParams);
}