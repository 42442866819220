import { base_url } from '../config';
import { secureFetch } from '../../utils/http';
import { getAuthUser } from '../../utils/auth';

const endpoints_proyecto_proposito = {
	GET: 'planif/propositos',
	GETBYID: 'planif/propositos',
	CREATE: 'planif/propositos',
	EDIT: 'planif/propositos',
	DELETE: 'planif/propositos',
};

export function getQueryParams() {
	return {
		pagination: {
			value: false,
			items: 2,
			page: 1,
		},
		filters: {
			properties: [],
		},
		filtersDefault: {
			properties: {
				anulado:"anulado",
				order: ["descripcion"],
			} 
		},
	};
}


export async function getPropositoApi(queryParams=null) {
	const url = `${base_url}/api/${endpoints_proyecto_proposito.GET}`;
	if(queryParams)
		return await secureFetch(url, 'GET', queryParams);
	return await secureFetch(url, 'GET', null);
}

export async function getPropositoByIdApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_proposito.GETBYID}/${id}`;
	return await secureFetch(url, 'GET', null);
}

export async function createPropositoApi(data) {
	const url = `${base_url}/api/${endpoints_proyecto_proposito.CREATE}`;
	let dataCreate = {
        ...data,
	};

	console.log(dataCreate);
	
	return await secureFetch(url, 'POST', dataCreate);

}

export async function editPropositoApi(id, data) {
	let user = getAuthUser();
	let dataEdit = {
		...data,
        descripcion: data.descripcion,
        anulado: data.anulado || '',
        color: data.color || '',
        icono: data.icono || ''
	};

	if(dataEdit.anulado == 'S'){
		
		dataEdit.codUsuAnula= user.username;
	}

	const url = `${base_url}/api/${endpoints_proyecto_proposito.EDIT}/${id}`;
	return await secureFetch(url, 'PUT', dataEdit);
}

export async function deleteEnfasisApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_proposito.DELETE}/${id}`;
	return await secureFetch(url, 'DELETE',null);
}
