const initialPropositoData = {
	id: '',
	descripcion: '',
	icono: '',
	color: '',
	anulado: '',
	anuladoFec: '',
	codUsuAnula: ''
};

const initialArrayPropositoData = [];

export { initialPropositoData, initialArrayPropositoData };
