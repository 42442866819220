import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import './notiStyle.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, CircularProgress } from '@mui/material';

export default function NotificacionLoad(props) {
	const { open, setState, config, icono, customButton } = props;

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setState(false);
	};

	const renderSwitch = param => {
		switch (param) {
			case 'notiLoader':
				return (
					<div>
						<CircularProgress
							style={{
								color: '#FFF',
								width: '20px',
								height: '20px',
								marginRight: '10px',
							}}
						/>
					</div>
				);
			case 'notiLoaderButton':
				return (
					<div>
						{customButton}
						<IconButton
							size="small"
							aria-label="close"
							color="inherit"
							onClick={handleClose}
						>
							<CloseIcon fontSize="small" />
						</IconButton>
					</div>
				);
			default:
				return (
					<div>
						<IconButton
							size="small"
							aria-label="close"
							color="inherit"
							onClick={handleClose}
						>
							<CloseIcon fontSize="small" />
						</IconButton>
					</div>
				);
		}
	};

	return (
		<div>
			<Snackbar
				anchorOrigin={{
					vertical: config.vertical,
					horizontal: config.horizontal,
				}}
				open={open}
				autoHideDuration={config.tiempo}
				onClose={handleClose}
				message={
					<div style={{marginRight: '30px'}}>
						{icono} {config.titulo}
					</div>
				}
				className={config.estilo}
				action={renderSwitch(config.tipo)}
			/>
		</div>
	);
}
