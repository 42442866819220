import { useState } from 'react';

const useNotification = (initialValue = false) => {
	const [isOpenNotification, setIsOpenNotification] = useState(initialValue);
	const [isTItle, setTitle] = useState('title');
	const [isContent, setIsContent] = useState('content');

	const openNotificacion = () => {
		setIsOpenNotification(true);
		setTimeout(closeNotification, 3000);
	};

	const closeNotification = () => {
		setIsOpenNotification(false);
	};

	const setTitleNotification = title => {
		setTitle(title);
	};

	const setContentNotification = title => {
		setIsContent(title);
	};

	return [
		isOpenNotification,
		openNotificacion,
		closeNotification,
		isTItle,
		setTitleNotification,
        isContent,
        setContentNotification
	];
};

export default useNotification;
