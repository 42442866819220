import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import './EnfasisTablero.scss';

export default function EnfasisTablero(props) {
	const [tiempoEnfasis, setTiempoEnfasis] = useState('0 min');
	const {
		nombreEnfasis,
		enfasisId,
		idModulo,
		detallesEnfasis,
		contentActi,
		func,
		hr,
		actividades,
		toggle,
		showModalCrearActividad,
		keyRandom,
	} = props;

	useEffect(() => {
		setTiempoEnfasis(
			detallesEnfasis.filter(el => el.planifEnfId == enfasisId).length > 0
				? detallesEnfasis.filter(el => el.planifEnfId == enfasisId)[0]?.tiempo
				: '0 min'
		);
	});

	return (
		<>
			<div className="tableroSeccion">
				<div className="tableroHeaderEnfasis">
					<h2>{nombreEnfasis}</h2>
					<Stack className="tableroTimerEnfasis" direction="row" spacing={1}>
						<Chip
							key={keyRandom}
							style={{ backgroundColor: '#FFF', color: '#6683B7' }}
							icon={<AccessTimeIcon style={{ color: '#6683B7' }} />}
							label={tiempoEnfasis}
						/>
					</Stack>
				</div>
				<hr
					className="lineaHrApren"
					style={{
						color: hr,
						border: '2px solid ' + hr + '',
						backgroundColor: hr,
						background: hr,
					}}
				></hr>
				<div className="tableroContenido">
					{contentActi ? contentActi : ''}
					{idModulo != '' ? (
						<div
							className="botonEditarActividad"
							onClick={() => {
								showModalCrearActividad(enfasisId, idModulo);
							}}
							data-modulo_id={idModulo}
							data-enfasis_id={enfasisId}
						>
							<Stack direction="row" spacing={1}>
								<Chip
									className="botonEditarChip"
									icon={<AddIcon />}
									label="Crear actividad"
									style={{ backgroundColor: '#FFF', fontSize: '14px' }}
								/>
							</Stack>
						</div>
					) : (
						<div
							className="botonEditarActividad"
							data-enfasis_id={enfasisId}
							onClick={showModalCrearActividad}
						>
							<Stack direction="row" spacing={1}>
								<Chip
									className="botonEditarChip"
									icon={<AddIcon />}
									label="Crear actividad"
									style={{ backgroundColor: '#FFF', fontSize: '14px' }}
								/>
							</Stack>
						</div>
					)}
				</div>
			</div>
		</>
	);
}
