import React, { useState } from 'react';
import useAuth from '../hooks/useAuth';
import { LoginAuth } from '../components/login/LoginAuth';
import NavbarGeneral from '../components/header/Nav';
import Container from '@mui/material/Container';

export default function LayoutBasic(props) {
	const { children } = props;
	const { user, isLoading } = useAuth();

	if (!user && !isLoading) {
		return (
			<>
				<LoginAuth />
			</>
		);
	}

	if (user && !isLoading) {
		return (
			<>
				<React.Fragment>
					<NavbarGeneral></NavbarGeneral>
					<Container maxWidth="lg">
						<div>
							{children}
						</div>
					</Container>
				</React.Fragment>
			</>
		);
	}
	return null;
}
