import { base_url } from '../config';
import { secureFetch } from '../../utils/http';
import { getAuthUser } from '../../utils/auth';

const endpoints_proyecto_enfasis = {
	GET: 'planif/enfasis',
	GETBYID: 'planif/enfasis',
	CREATE: 'planif/enfasis',
	EDIT: 'planif/enfasis',
	DELETE: 'planif/enfasis',
};

export function getQueryParams() {
	return {
		pagination: {
			value: false,
			items: 2,
			page: 1,
		},
		filters: {
			properties: [],
		},
		filtersDefault: {
			properties: {
				anulado:"anulado",
				descripcion:"descripcion",
				order: ["descripcion"],
			} 
		},
	};
}

export async function getEnfasisApi(queryParams=null) {
	const url = `${base_url}/api/${endpoints_proyecto_enfasis.GET}`;
	if(queryParams)
		return await secureFetch(url, 'GET', queryParams);
	return await secureFetch(url, 'GET', null);
	
}

export async function getEnfasisByIdApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_enfasis.GETBYID}/${id}`;
	return await secureFetch(url, 'GET', null);
}

export async function createEnfasisApi(data) {
	const url = `${base_url}/api/${endpoints_proyecto_enfasis.CREATE}`;
	let dataCreate = {
		...data,
	};

	console.log(dataCreate);

	return await secureFetch(url, 'POST', dataCreate);
}

export async function editEnfasisApi(id, data) {
	let user = getAuthUser();
	let dataEdit = {
		...data,
		descripcion: data.descripcion,
		anulado: data.anulado || '',
		color: data.color || '',
		icono: data.icono || '',
	};

	if(dataEdit.anulado == 'S'){
		
		dataEdit.codUsuAnula= user.username;
	}

	const url = `${base_url}/api/${endpoints_proyecto_enfasis.EDIT}/${id}`;
	return await secureFetch(url, 'PUT', dataEdit);
}

export async function deleteEnfasisApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_enfasis.DELETE}/${id}`;
	return await secureFetch(url, 'DELETE', null);
}
