import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
	getActividadApi,
	deleteActividadApi,
	getQueryParams as getQueryParamsActividad,
} from '../../api/Actividad/actividad';
import { generateKeyRandom } from '../../utils/helpers';
import {
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';
import Notificacion from '../../components/snackbar/Notificacion';
import './tableRecursos.scss';
import Loading from '../../components/loading/Loading';
import { ThreePointsMenu } from '../threePointsMenu/ThreePointsMenu';

const Accordion = styled(props => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled(props => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function TablaRecursos(props) {
	const [openLoading, setOpenLoading] = React.useState(false);

	const handleCloseLoading = () => {
		setOpenLoading(false);
	};

	const handleOpenLoading = () => {
		setOpenLoading(true);
	};

	const { descripcion, tipoId, enfasisId } = props;

	const [recursos, setRecursos] = useState([]);

	const navigate = useNavigate();

	const [expanded, setExpanded] = useState('');
	const [anchorEl, setAnchorEl] = React.useState([]);
	const open = Boolean(anchorEl);

	const notiActInit = {
		titulo: '',
		mensaje: '',
		estilo: 'notiSuccessFilled',
		tiempo: 1500,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const [notiActConfig, setNotiActConfig] = useState(notiActInit);

	const [openNotiAct, setOpenNotiAct] = useState(false);

	const handleOnClickEditRecurso = event => {
		const recurso_id = event.currentTarget.dataset.recurso_id;
		navigate(`/crearEditarRecurso/${recurso_id}`, { replace: true });
		event.preventDefault();
		event.stopPropagation();
	};

	const handleOnClickDeleteRecurso = event => {
		const recurso_id = event.currentTarget.dataset.recurso_id;
		const enfasis_id = event.currentTarget.dataset.enfasis_id;
		deleteActividadApiData(event, recurso_id, enfasisId);
		event.preventDefault();
		event.stopPropagation();
	};

	const handleChange = panel => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const handleOnClickViewRecursos = (event, expanded) => {
		if (expanded) {
			let enfasis = event.currentTarget.dataset.enfasis_id;
			let tipo = event.currentTarget.dataset.tipo_id;
			getRecursosByEnfasisAndTipoData(enfasis, tipo);
		}
	};

	return (
		<>
			<Loading
				bg="bgLoadingWhiteTrans"
				open={openLoading}
				tiempoMensaje={3000}
			/>

			<Accordion
				className="recursoTablaAcc"
				onChange={handleOnClickViewRecursos}
			>
				<AccordionSummary
					aria-controls="panel1d-content"
					id="panel1d-header"
					className="accordionHeaderRecurso"
					data-tipo_id={tipoId}
					data-enfasis_id={enfasisId}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						<p style={{ marginTop: '14px', fontFamily: 'Roboto' }}>
							{descripcion}
						</p>
					</div>
				</AccordionSummary>
				{recursos && recursos.length > 0 ? (
					<AccordionDetails style={{ padding: '0px' }}>
						<TableContainer style={{ zIndex: '-10' }}>
							<Table
								size="small"
								style={{ width: '100%' }}
								sx={{ minWidth: 650 }}
								aria-label="simple table"
							>
								<TableHead
									className="tablaRecursoHeader"
									style={{ height: '20px' }}
								>
									<TableRow>
										<TableCell style={{ width: 230 }} align="center">
											RECURSO
										</TableCell>
										<TableCell
											style={{ width: 100, textAlign: 'center' }}
											align="left"
										>
											ESTADO
										</TableCell>
										<TableCell align="left">INTERACCIÓN</TableCell>
										<TableCell align="left">MATERIAL</TableCell>
										<TableCell style={{ width: 100 }} align="center">
											UNIDAD
										</TableCell>
										<TableCell style={{ width: 100 }} align="center">
											MÍNIMO
										</TableCell>
										<TableCell style={{ width: 100 }} align="center">
											MÁXIMO
										</TableCell>
										<TableCell style={{ width: 100 }} align="center">
											VALIDACIÓN
										</TableCell>
										<TableCell style={{ width: 100 }} align="right"></TableCell>
									</TableRow>
								</TableHead>
								<TableBody className="tablaRecursoContenido">
									{recursos &&
										recursos?.map((el, i) => {
											return el.material?.unidads.map((u, j) => {
												return (
													<TableRow
														key={/* el.id + '_' + j.id */ generateKeyRandom()}
													>
														{j == 0 && (
															<>
																<TableCell
																	className="fuenteTablaRecurso"
																	align="left"
																	rowSpan={
																		el.material?.unidads.length > 1
																			? el.material?.unidads.length
																			: ''
																	}
																	style={{ width: 100 }}
																>
																	<p style={{ fontSize: '13px' }}>
																		{el?.descripcion ?? ''}
																	</p>
																</TableCell>
																<TableCell
																	className="fuenteTablaRecurso"
																	rowSpan={
																		el.material?.unidads.length > 1
																			? el.material?.unidads.length
																			: ''
																	}
																>
																	{el.anulado == 'N' ? (
																		<div
																			className="campoVisible"
																			style={{ marginTop: '-6px' }}
																		>
																			<span className="material-symbols-rounded visible">
																				visibility
																			</span>
																			Habilitado
																		</div>
																	) : (
																		<div className="campoDeshabilitar">
																			<span className="material-symbols-rounded  deshabilitar">
																				visibility_off
																			</span>
																			Deshabilitado
																		</div>
																	)}
																</TableCell>
																<TableCell
																	className="fuenteTablaRecurso"
																	rowSpan={
																		el.material?.unidads.length > 1
																			? el.material?.unidads.length
																			: ''
																	}
																>
																	<p style={{ fontSIze: '15px' }}>
																		{el.interaccion &&
																		el.interaccion.descripcion != null
																			? el.interaccion.descripcion
																			: ''}
																	</p>
																</TableCell>
																<TableCell
																	align="center"
																	className="fuenteTablaRecurso"
																	rowSpan={
																		el.material?.unidads.length > 1
																			? el.material?.unidads.length
																			: ''
																	}
																>
																	<span
																		className="material-icons"
																		style={{ fontSize: '17px' }}
																	>
																		{el.material?.icono != null
																			? el.material?.icono
																			: 'public'}
																	</span>
																	<br></br>
																	<p style={{ fontSize: '12px' }}>
																		{el.material.descripcion}
																	</p>
																</TableCell>
															</>
														)}
														<TableCell
															className="fuenteTablaRecurso"
															align="center"
															key={generateKeyRandom()}
														>
															<span
																className="material-icons"
																style={{ fontSize: '17px' }}
															>
																{u.unidad?.icono != null
																	? u.unidad?.icono
																	: 'public'}
															</span>
															<br></br>
															<p style={{ fontSize: '12px' }}>
																{u.unidad.descripcion}
															</p>
														</TableCell>
														<TableCell
															style={{ width: 100 }}
															align="center"
															key={generateKeyRandom()}
															className="fuenteTablaRecurso"
														>
															{u?.limites?.minimo}
														</TableCell>
														<TableCell
															style={{ width: 100 }}
															align="center"
															className="fuenteTablaRecurso"
															key={
																u.limites?.planifActId +
																generateKeyRandom() +
																'_' +
																u.limites?.planifUniId
															}
														>
															{u?.limites?.maximo}
														</TableCell>
														<TableCell
															style={{ width: 100 }}
															align="center"
															className="fuenteTablaRecurso"
															key={
																u.limites?.planifActId +
																generateKeyRandom() +
																'_' +
																u.limites?.planifUniId
															}
														>
															{u?.limites?.rige == 'N' ? (
																<span
																	className="material-icons"
																	style={{
																		color: '#358638',
																		color: '#B1281D',
																		position: 'absolute',
																		marginTop: '-20px',
																	}}
																>
																	block
																</span>
															) : (
																<span
																	className="material-icons"
																	style={{
																		color: '#358638',
																		position: 'absolute',
																		marginTop: '-20px',
																	}}
																>
																	check_circle
																</span>
															)}
														</TableCell>
														<TableCell
															align="right"
															className="fuenteTablaRecurso"
															key={generateKeyRandom()}
														>
															{j == 0 && (
																<ThreePointsMenu>
																	<MenuItem
																			className="ActDropdownMenuItem"
																			data-recurso_id={el.id}
																			onClick={handleOnClickEditRecurso}
																		>
																			Editar
																		</MenuItem>
																		<MenuItem
																			data-recurso_id={el.id}
																			data-enfasis_id={enfasisId}
																			onClick={handleOnClickDeleteRecurso}
																			className="ActDropdownMenuItem"
																		>
																			Eliminar
																		</MenuItem>
																</ThreePointsMenu>
															)}
														</TableCell>
													</TableRow>
												);
											});
										})}
								</TableBody>
							</Table>
						</TableContainer>
					</AccordionDetails>
				) : (
					''
				)}
			</Accordion>
			<Notificacion
				open={openNotiAct}
				setState={setOpenNotiAct}
				config={notiActConfig}
			/>
		</>
	);

	async function getRecursosByEnfasisAndTipoData(enfasis_id, tipo_recurso_id) {
		let queryParamsDefault = getQueryParamsActividad();

		let { anulado, tipo_recurso, enfasis } =
			queryParamsDefault.filtersDefault.properties;
		let value = 'N';

		const query = {
			[anulado]: value,
			[tipo_recurso]: tipo_recurso_id,
			[enfasis]: enfasis_id,
		};

		queryParamsDefault.filters.properties.push(query);

		try {
			handleOpenLoading();
			const resp = await getActividadApi(queryParamsDefault);

			resp.map(e => {
				let unidads = e.material.unidads;
				let limites = e.limites;

				let unidaLimites = unidads.map(el => {
					limites.forEach(l => {
						if (el.unidad.id == l.planifUniId) {
							el.limites = l;
							return el;
						} else {
							return el;
						}
					});
				});
				return unidaLimites;
			});

			setRecursos(resp);
			handleCloseLoading();
		} catch (error) {
			console.log(
				'🚀 ~ file: TablaRecursos.js:271 ~ getRecursosByEnfasisAndTipoData ~ error:',
				error
			);
			// console.log(await error.json());
		}
	}

	async function deleteActividadApiData(event, recurso_id, enfasis_id) {
		const resp = await deleteActividadApi(recurso_id);

		if (resp.status === 'true') {
			notiActConfig.mensaje = resp.message;
			notiActConfig.estilo = 'notiSuccessFilled';
			setOpenNotiAct(true);

			const filterRows = recursos.filter(el => {
				return el.id != recurso_id;
			});

			setRecursos(filterRows);
		} else {
			notiActConfig.mensaje = resp.message;
			notiActConfig.tiempo = 4000;
			notiActConfig.estilo = 'notiDangerFilled';
			setOpenNotiAct(true);
		}
	}
}
