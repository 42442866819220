import { base_url } from '../config';
import { secureFetch } from '../../utils/http';
import { getAuthUser } from '../../utils/auth';

const endpoints_material = {
	GET: 'planif/materials',
	GETBYID: 'planif/materials',
	CREATE: 'planif/materials',
	EDIT: 'planif/materials',
	DELETE: 'planif/unidads',
};

export function getQueryParams() {
	return {
		pagination: {
			value: false,
			items: 2,
			page: 1,
		},
		filters: {
			properties: [],
		},
		filtersDefault: {
			properties: {
				anulado: 'anulado',
				descripcion: 'descripcion',
				order: 'order[descripcion]',
			},
		},
	};
}

export async function getMaterialsApi(queryParams = null) {
	const url = `${base_url}/api/${endpoints_material.GET}`;
	if (queryParams) {
		return await secureFetch(url, 'GET', queryParams);
	}
	return await secureFetch(url, 'GET', null);
}

export async function getMaterialsByIdApi(id) {
	const url = `${base_url}/api/${endpoints_material.GETBYID}/${id}`;
	return await secureFetch(url, 'GET', null);
}

export async function createMaterialsApi(data) {
	const url = `${base_url}/api/${endpoints_material.CREATE}`;
	return await secureFetch(url, 'POST', data);
}

export async function editMaterialsApi(id, data) {
	let user = getAuthUser();

	let dataEdit = {
		...data,
	};

	if (dataEdit.anulado == 'S') {
		dataEdit.codUsuAnula = user.username;
	}

	const url = `${base_url}/api/${endpoints_material.EDIT}/${id}`;
	return await secureFetch(url, 'PUT', dataEdit);
}

export async function deleteMaterialsApi(id, data) {
	let user = getAuthUser();

	const dataEdit = {
		codUsuAnula: user.username,
		...data,
	};
	const url = `${base_url}/api/${endpoints_material.EDIT}/${id}`;
	return await secureFetch(url, 'PUT', dataEdit);
}
