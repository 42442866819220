import React from 'react';
import { IconButton, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export function ThreePointsMenu(props) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openA = Boolean(anchorEl);
	let { children } = props;

	const handleCloseThreePointsMenu = () => {
		setAnchorEl(null);
	};

	const handleClickThreePointsMenu = event => {
		setAnchorEl(event.currentTarget);
		event.stopPropagation();
		event.preventDefault();
	};

	return (
		<>
			<IconButton
				id="basic-button"
				aria-controls={openA ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={openA ? 'true' : undefined}
				onClick={handleClickThreePointsMenu}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={openA}
				style={{ zIndex: '0' }}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				onClick={handleCloseThreePointsMenu}
				onClose={handleCloseThreePointsMenu}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{children}
			</Menu>
		</>
	);
}
