import React, { useState, useEffect } from 'react';
import { getAccessToken } from '../../utils/auth';
import { LoginAuth } from '../../components/login/LoginAuth';
import {
	Form,
	Button,
	Row,
	Container,
	Input,
	FormGroup,
	Label,
	Table,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import {
	initialProyectoData,
	initialProyectosData,
} from '../../api/Proyecto/proyectoModel';
import {
	getProyectosApi,
	editProyectoApi,
	createProyectoApi,
	deleteProyectoApi,
} from '../../api/Proyecto/proyecto';

export default function Proyecto() {
	const [id, setId] = useState('');

	const [idMaterial, setIdMaterial] = useState('');
	const [idUnidad, setUnidad] = useState('');

    const [idDeleteProyecto, setDeleteProyecto] = useState(false);

	const [isCreate, setIsCreate] = useState(true);

	const [proyectoData, setProyectoData] = useState(initialProyectoData);

	const [proyectosData, setProyectosData] = useState(initialProyectosData);

	useEffect(()=>{
        if(idDeleteProyecto){
            getProyectos();
        }

    },[idDeleteProyecto])
    
    const handleChangeProyecto = event => {
		setProyectoData({
			...proyectoData,
			[event.target.name]: event.target.value,
		});
	};

	const handleEditProyecto = event => {
		let id = window.event.target.dataset.id;
		let proyecto = proyectosData.filter(el => el.id == id);
		if (proyecto.length) {
			setProyectoData(proyecto[0]);
			setIsCreate(false);
		}
	};

	const handleDeleteProyecto = event => {
		let id = window.event.target.dataset.id;
        setDeleteProyecto(false);
        deleteProyecto(id);
	};

	const handleSubmitProyecto = event => {
		event.preventDefault();
		isCreate ? createProyecto() : editProyecto();
	};

	if (getAccessToken()) {
		return (
			<>
				<h1>ABM proyecto</h1>
				<ToastContainer></ToastContainer>
				<Container>
					<Row>
						<Button color="primary" onClick={getProyectos}>
							get proyecto
						</Button>
					</Row>
					<Table bordered>
						<thead>
							<tr>
								<th>#</th>
								<th>codigoId</th>
								<th>codigoTipo</th>
								<th>descripcion</th>
								<th>observacion</th>
								<th>codUsu</th>
								<th>fecha</th>
							</tr>
						</thead>
						<tbody>
							{proyectosData.map((proyecto, i) => {
								return (
									<tr key={i}>
										<td>{proyecto.id}</td>
										<td>{proyecto.codigoId}</td>
										<td>{proyecto.codigoTipo}</td>
										<td>
											{proyecto.descripcion.length > 100
												? proyecto.descripcion.substr(0, 100)
												: proyecto.descripcion}
										</td>
										<td>
											{proyecto.observacion?.length > 100
												? proyecto.observacion.substr(0, 100)
												: proyecto.observacion}
										</td>
										<td>{proyecto.codUsu}</td>
										<td>{proyecto.fecha}</td>
										<td>
											<Button
												color="primary"
												size="sm"
												data-id={proyecto.id}
												onClick={handleEditProyecto}
											>
												edit
											</Button>
											<Button
												color="warning"
												size="sm"
												data-id={proyecto.id}
												onClick={handleDeleteProyecto}
											>
												delete
											</Button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Container>

				{/* <Container>
					<Row>
						<div>
							<Input
								id="id"
								name="id"
								onChange={handleChangeMaterial}
								value={id}
							/>
						</div>
						<Button color="primary" onClick={getUnidadById}>
							get unidad by id
						</Button>
					</Row>
				</Container>
                        */}
				<Container>
					Create {idDeleteProyecto ? "true": "false"}
					<Row>
						<Form onSubmit={handleSubmitProyecto}>
							<FormGroup>
								<Label for="codigoId">codigoId</Label>
								<Input
									id="codigoId"
									name="codigoId"
									type="hidden"
									onChange={handleChangeProyecto}
									value={proyectoData?.codigoId || ''}
								/>
								<Input
									id="codigoId"
									name="codigoId"
									onChange={handleChangeProyecto}
									value={proyectoData?.codigoId || ''}
								/>

								<Label for="codigoTipo">codigoTipo</Label>
								<Input
									id="codigoTipo"
									name="codigoTipo"
									onChange={handleChangeProyecto}
									value={proyectoData?.codigoTipo || ''}
								/>
								<Label for="descripcion">descripcion</Label>
								<Input
									id="descripcion"
									name="descripcion"
									onChange={handleChangeProyecto}
									value={proyectoData?.descripcion || ''}
								/>
								<Label for="observacion">observacion</Label>
								<Input
									id="observacion"
									name="observacion"
									onChange={handleChangeProyecto}
									value={proyectoData?.observacion || ''}
								/>
								<Label for="codUsu">codUsu</Label>
								<Input
									id="codUsu"
									name="codUsu"
									onChange={handleChangeProyecto}
									value={proyectoData?.codUsu || ''}
								/>
							</FormGroup>
							<Button color="primary" type="submit">
								{isCreate ? 'create' : 'edit'}
							</Button>
						</Form>
					</Row>
				</Container>
			</>
		);
	}

	async function getProyectos() {
		try {
			let proyectos = await getProyectosApi();
			setProyectosData(proyectos);
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function createProyecto() {
		try {
			console.log(
				'🚀 ~ file: Proyecto.js:201 ~ createProyecto ~ proyectoData',
				proyectoData
			);
			delete proyectoData.id;
			let proyecto = await createProyectoApi(proyectoData);
			setProyectosData([...proyectosData, proyecto]);
		} catch (error) {
			console.log(await error.json());
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);
		}
	}

	async function deleteProyecto(id) {
		try {
			
			let proyecto = await deleteProyectoApi(id);
            setDeleteProyecto(true)
		} catch (error) {
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);
			console.log(await error.json());
		}
	}

	async function editProyecto() {
		try {
			console.log('🚀 ~ file: Proyecto.js:237 ~ editMaterial ~ editMaterial');
			let id = proyectoData.id;
			let edit = await editProyectoApi(id, proyectoData);
			setIsCreate(true);
			setProyectoData(initialProyectoData);
			getProyectos();
		} catch (error) {
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);
			console.log(await error?.json());
		}
	}

	// async function createMaterialsUnidad() {
	// 	console.log(
	// 		'TODO file: Material.js:240 ~ createMaterialsUnidad, valor de descripcion??'
	// 	);
	// 	try {
	// 		let data = {
	// 			descripcion: 'Extension',
	// 			material: idMaterial,
	// 			unidad: idUnidad,
	// 		};

	// 		await createMaterialsUnidadApi(data);
	// 		getMaterials();
	// 	} catch (error) {
	// 		if (error.status == 422) {
	// 			let message = await error.json();
	// 			toast.info(message.detail);
	// 		}
	// 	}
	// }

	return (
		<>
			<LoginAuth></LoginAuth>
		</>
	);
}
