import {
	base_url,
} from '../config';
import { secureFetch } from '../../utils/http';
import {
	getAuthUser,
  } from "../../utils/auth";

const endpoints_unidad = {
	GET: 'unit/academic',
};
export function getQueryParams() {
	return {
		pagination: {
			value: false,
			items: 2,
			page: 1,
		},
		filters: {
			properties: [],
		},
		filtersDefault: {
			properties: {
				uniId:"uniId",
				nombUni:"nombUni",
				nombUniAl:"nombUniAl",
				isUnidAcad:"isUnidAcad"
			} 
		},
	};
}


export async function getUnidsApi(queryParams=null) {
	const url = `${base_url}/api/${endpoints_unidad.GET}`;
	
	if(queryParams)
		return await secureFetch(url, 'GET', queryParams);
	return await secureFetch(url, 'GET', null);
}

