import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { createProyectoApi } from '../../../api/Proyecto/proyecto';
import {
	initialProyectoData,
	initialProyectosData,
} from '../../../api/Proyecto/proyectoModel';
import UnidSelect from '../../unidSelect/UnidSelect';
import { Select, MenuItem, InputLabel } from '@mui/material';
import { SettingsSystemDaydream } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import NotiWarning from '../../../components/snackbar/NotiWarning';
import {
	TextField,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Modal,
	Button,
	Box,
	Container,
	IconButton,
	Chip,
	Stack,
} from '@mui/material';

import { NotificationMui } from '../../notification/NotificactionMui';
import { useNotificationMui } from '../../../hooks/useNotificacionMui';
import { NotificationMui as CreateNotificationMui } from '../../../components/notification/NotificactionMui';

import './CrearProyecto.scss';

import { create } from '@mui/material/styles/createTransitions';
import Typography from '@mui/material/Typography';
import '../../../scss/modal.scss';

export default function CrearProyecto(props) {
	let { getProyectos, handleSuccessCreateProyecto } = props;

	const [duracion, setDuracion] = useState('');
	const [unidSelected, setUnidSelected] = useState(0);
	const [carrSelected, setCarrSelected] = useState(0);
	const [mateId, setMateId] = useState(0);

	const [idProyecto, setIdProyecto] = useState();
	const [radioBoton, setRadioBoton] = useState();

	const [modal, setModal] = useState(false);
	const [proyectoData, setProyectoData] = useState(initialProyectoData);
	const [duracionRegex, setDuracionRegex] = useState(false);
	const [valid, setValid] = useState(true);

	const [
		isOpenNotification,
		openNotificacion,
		closeNotification,
		isTitle,
		setTitleNotification,
		isContent,
		setContentNotification,
	] = useNotificationMui(false);

	useEffect(() => {
		if (modal) {
			setDuracion('');
			setUnidSelected(0);
			setCarrSelected(0);
			setMateId(0);
			setProyectoData(initialProyectoData);
		}
	}, [modal]);

	const [notiCrearOpen, notiCrearSetOpen] = React.useState(false);
	const [codigoMateria, setCodigoMateria] = React.useState('');

	const notiCrearClick = () => {
		notiCrearSetOpen(true);
	};

	const handleShowModalCrearProyecto = () => {
		setModal(!modal);
		setCodigoMateria('');
	};

	const handleChangeProyecto = event => {
		setValid(event.target.value != '');

		if (event.target.value != ' ') {
			setProyectoData({
				...proyectoData,
				[event.target.name]: event.target.value,
			});
		}
	};

	const handleChangeDescriProyecto = event => {
		setProyectoData({
			...proyectoData,
			[event.target.name]: event.target.value,
		});
	};

	const handleSubmitProyecto = event => {
		let val = createProyecto();
	};

	const handleChangeRadioButton = event => {
		let value = event.target.value;
		setRadioBoton(value);
	};

	const handleOnChangeDuracion = event => {
		let duracion = event.target.value;
		setDuracion(duracion);
	};

	return (
		<>
			<div>
				<Chip
					icon={<AddIcon style={{ color: '#003087', fontSize: '16px' }} />}
					label="Nuevo proyecto"
					clickable
					onClick={handleShowModalCrearProyecto}
					style={{
						background: '#CCD6E7',
						height: '28px',
						fontSize: '12px',
						paddingLeft: '6px',
						color: '#003087',
						fontWeight: '500',
						marginLeft: '11px',
					}}
				/>
				<NotiWarning
					open={notiCrearOpen}
					setState={notiCrearSetOpen}
					mensaje="Observación y duración son datos obligatorios"
				/>
				<Modal open={modal} onClose={handleShowModalCrearProyecto}>
					<Box className="modalActividadContainer modalShadow">
						<Container className="modalActividadHeader">
							<h1>Nuevo proyecto</h1>
							<IconButton
								onClick={handleShowModalCrearProyecto}
								style={{ margin: 'auto 0px' }}
							>
								<CloseIcon />
							</IconButton>
						</Container>
						<Container className="w-auto">
							<Typography
								sx={{ mb: 3 }}
								style={{
									fontFamily: 'Roboto',
									fontStyle: 'normal',
									fontWeight: '400',
									fontSize: '16px',
									lineHeight: '19px',
									color: '#000000',
								}}
							>
								Configurá los datos del nuevo proyecto.
							</Typography>
						</Container>
						<Container>
							<TextField
								className="inputCrearProy"
								id="descripcion"
								name="descripcion"
								onChange={handleChangeProyecto}
								error={!valid}
								value={proyectoData?.descripcion || ''}
								inputProps={{ maxLength: 225 }}
								label={
									<>
										Título <b style={{ color: 'red' }}>*</b>
									</>
								}
								variant="outlined"
							/>
						</Container>
						<Container>
							<TextField
								className="textAreaCrearProy"
								id="observacion"
								name="observacion"
								onChange={handleChangeDescriProyecto}
								value={proyectoData?.observacion || ''}
								label="Descripción"
								variant="outlined"
								multiline
								inputProps={{ maxLength: 1000 }}
								rows={3}
							/>
						</Container>
						<Container>
							<UnidSelect
								duracion={duracion}
								unidSelected={unidSelected}
								carrSelected={carrSelected}
								setDuracion={setDuracion}
								setUnidSelected={setUnidSelected}
								setCarrSelected={setCarrSelected}
								setMateId={setMateId}
								codigoMateria={codigoMateria}
								setCodigoMateria={setCodigoMateria}
								duracionRegex={duracionRegex}
								setDuracionRegex={setDuracionRegex}
							></UnidSelect>
						</Container>

						<Container className="" item xs={12} style={{margin: '38px 0px 38px 0px',}}>
							<div className="btnContentAbm">
								
								<Button
									className="btnCrearAbm"
									disabled={
										!duracionRegex ||
										duracion == '' ||
										proyectoData.descripcion == ''
											? true
											: false
									}
									onClick={handleSubmitProyecto}
									style={{ textTransform: 'capitalize' }}
								>
									<AddIcon className="btnIcoAbm" />
									Crear
								</Button>

								<Button
									className="btnAbmCancel"
									onClick={handleShowModalCrearProyecto}
									style={{ textTransform: 'capitalize' }}
								>
									<CancelIcon className="btnIcoAbm" />
									Cancelar
								</Button>
							</div>
						</Container>
					</Box>
				</Modal>
			</div>
		</>
	);

	async function createProyecto() {
		try {
			if (+unidSelected != 0) {
				proyectoData.unidad = +unidSelected;
			}

			if (carrSelected != 0) {
				proyectoData.codCarr = carrSelected;
			}

			if (mateId != 0) {
				proyectoData.asignatura = mateId;
			}

			proyectoData.hsCurTotal = duracion;

			if (proyectoData.descripcion == '' || proyectoData.hsCurTotal == '') {
				notiCrearClick();
				return false;
			}

			let proyecto = await createProyectoApi(proyectoData);
			getProyectos();
			handleShowModalCrearProyecto();
			handleSuccessCreateProyecto(proyecto.id);
			console.log(
				'🚀 ~ file: CrearProyecto.js:194 ~ createProyecto ~ proyecto',
				proyecto
			);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);
			console.log(await error.json());
		}
	}
}
