import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import SignIn from "../pages/Auth/SignIn";
import useAuth from "../hooks/useAuth";
import Home from "../pages/Home";
import {LoginAuth} from "../components/login/LoginAuth"
import Auth from "../pages/Auth/Auth"
// import Auth from "../pages/Auth/AuthCustom"

export default function LayoutBasic(props) {
  const { children } = props;
  const { user, isLoading } = useAuth();
  
  if (!user && !isLoading) {
    return (
      <>
       <Auth />
      </>
    );
  }

  if (user && !isLoading) {
    return (
      <>
        {children}
      </>
    );
  }
  return null;
}
