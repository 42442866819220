import React, { useState, useEffect } from 'react';
import { getAccessToken } from '../../utils/auth';
import { LoginAuth } from '../../components/login/LoginAuth';
import {
	Form,
	Button,
	Row,
	Input,
	FormGroup,
	Label,
	Table,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import {
	getMaterialsApi,
	createMaterialsApi,
	editMaterialsApi,
} from '../../api/Material/material';
import { InputCheckbox } from '../../components/Inputs/InputCheckbox';
import {
	initialMaterialData,
	initialMaterialsData,
} from '../../api/Material/materialModel';
import SelectUnidad from '../../components/unidad/SelectUnidad';
import { getUnidadesApi } from '../../api/Unidad/unidad';
import { createMaterialsUnidadApi } from '../../api/MaterialUnidad/materialUnidad';
import './Material.scss';
import Tooltip from '@mui/material/Tooltip';
import { Container } from '@mui/material';
import Notificacion from '../../components/snackbar/Notificacion';
import { 
	Box, 
	FormControl,
	TextField,
	IconButton
} from '@mui/material';
import Grid from '@mui/material/Grid';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import '../../scss/abmEstilos.scss';
import Loading from '../../components/loading/Loading';
import SaveIcon from '@mui/icons-material/Save';

export default function Material() {
	const [id, setId] = useState('');

	const [idMaterial, setIdMaterial] = useState('');
	const [idUnidad, setUnidad] = useState('');

	const [isAnulado, setIsAnulado] = useState(true);
	const [isDisable, setIsDisable] = useState('');

	const [isCreate, setIsCreate] = useState(true);
	const [unidades, setUnidades] = useState([]);

	const [materialData, setMaterialData] = useState(initialMaterialData);
	const [materialsData, setMaterialsData] = useState(initialMaterialsData);

	useEffect(() => {
		getUnidadesApi().then(unidades => {
			setUnidades(unidades);
		});
	}, []);

	const handleChangeMaterial = event => {
		let texto = event.target.value;
		setIsDisable(texto);
		setMaterialData({
			...materialData,
			[event.target.name]: event.target.value,
		});
	};

	const handleEditMaterial = event => {
		let id = event.target.dataset.id;
		let material = materialsData.filter(el => el.id == id);
		setIsDisable(material);

		if (material.length) {
			setMaterialData(material[0]);
			setIsCreate(false);

			if (material[0].anulado == 'N') {
				setIsAnulado(true);
			} else {
				setIsAnulado(false);
			}
		}
	};

	const handleButtonAsociarMaterialUnidad = event => {
		event.preventDefault();
		createMaterialsUnidad();
	};

	const handleSubmitMaterial = event => {
		event.preventDefault();
		isCreate ? createMaterial() : editMaterial();
	};

	const handleOnChangeSelectUnidad = event => {
		let index = event.target.selectedIndex;
		let materialId = event.target.id;
		let el = event.target.childNodes[index];
		let id = el.getAttribute('id');
		setUnidad(id);
		setIdMaterial(materialId);
	};

	useEffect(() => {
		getMaterials();
	}, []);

	const columns = [
		{
			name: 'Material',
			selector: row => row.descripcion ? ( row.descripcion) : (''),
			cell: row =>
				row.descripcion ? (
					<div className="dataTableText">
						{row.descripcion}
					</div>
				) : (
					''
				),
			sortable: true,
			maxWidth: 200,
			minWidth: 140,
			width: 200,
			wrap: true,
		},
		{
			name: 'Estado',
			selector: row => row.anulado,
			sortable: true,
			cell: obj => {
				return (
					<div className="botonesTabla">
						{obj.anulado == 'N' ? (
							<div className="abmCampoVisible">
								<span className="material-symbols-rounded abmIcoVisi">
									visibility
								</span>
								Habilitado
							</div>
						) : (
							<div className="abmCampoDeshabilitar">
								<span className="material-symbols-rounded  abmIcoVisiOff">
									visibility_off
								</span>
								Deshabilitado
							</div>
						)}
					</div>
				);
			},
			maxWidth: 200,
			minWidth: 140,
			width: 200,
			wrap: true,
		},
		{
			cell: obj => {
				return (
					<div className="botonesTabla">
						<Tooltip title="Editar" placement="top" arrow>
							<IconButton
								data-id={obj.id ? obj.id : ''}
								className="material-symbols-rounded icoAbmEditar"
								onClick={handleEditMaterial}
							>
								mode_edit
							</IconButton>
						</Tooltip>
					</div>
				);
			},
			right: true,
			ignoreRowClick: true,
			allowOverflow: true,
		},
	];

	const handleChangeAnulado = event => {
		let anulado = event.target.checked;
		setIsAnulado(anulado);
	};

	const handleClickCancelar = () => {
		setIsDisable('');
		clear();
	};

	const clear = () => {
		setIsDisable('');
		setIsCreate(true);
		setIsAnulado(true);
		setMaterialData(initialMaterialData);
	};

	const [OpenNoti, setOpenNoti] = React.useState(false);

	const notiConfigIni = {
		titulo: '',
		mensaje: '',
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const [notiConfig,setNotiConfig] = useState(notiConfigIni);

	const _fieldObligatorio = () => {
		return <b style={{ color: 'red' }}>*</b>;
	};

	const emptyColumn = [
		{
			name: 'Materiales',
			cell: obj => {
				return <span>Sin materiales creados.</span>;
			},
			left: true,
			ignoreRowClick: true,
			allowOverflow: true,
		},
	];

	const [dataTest, setDataTest] = useState([
		{
			descripcion: 'Sin materiales creados.',
		},
	]);

	const [openLoad, setOpenLoad] = React.useState(false);

	const handleCloseLoad = () => {
		setOpenLoad(false);
	};

	const handleOpenLoad = () => {
		setOpenLoad(true);
	};

	if (getAccessToken()) {
		return (
			<Container className="contenedor">
				<div className="textoContenido">
					<h1 className="tituloContenido">Materiales</h1>
					<p className="parrafoContenido">
						Creá y configurá los materiales que formarán parte del contenido.
					</p>
				</div>

				<div className="textoContenido">
					<h2 className="subtituloContenido">Crear material</h2>

					<Box sx={{ flexGrow: 1 }}>
						<Grid container spacing={2}>
							<Grid item xs={8}>
								<FormControl className="descripAbm">
									<TextField
										id="descripcion"
										name="descripcion"
										label={
											<span style={{ fontFamily: 'Roboto' }}>
												Nombre de material {_fieldObligatorio()}
											</span>
										}
										variant="outlined"
										inputProps={{ maxLength: 255 }}
										type="text"
										onChange={handleChangeMaterial}
										value={materialData.descripcion || ''}
									/>
								</FormControl>
							</Grid>
							<Grid className='' item xs={4}>
								<div className="seccionInput">
									<InputCheckbox
										isAnulado={isAnulado}
										handleChangeAnulado={handleChangeAnulado}
										isDisable={!isDisable}
									></InputCheckbox>
								</div>
								<div className="btnContentAbm">
									<Button
										className="btnCrearAbm"
										disabled={!isDisable}
										onClick={handleSubmitMaterial}
									>
										{isCreate ?
										<AddIcon  className='btnIcoAbm'/>
										:
										<SaveIcon className='btnIcoAbm'/>
										}
										{isCreate ? 'Crear' : 'Guardar'}
									</Button>
									<Button
										className="btnAbmCancel"
										onClick={handleClickCancelar}
										disabled={isCreate ? !isDisable : false}
									>
										<CancelIcon  className='btnIcoAbm'/>
										Cancelar
									</Button>
								</div>
							</Grid>
						</Grid>
					</Box>
				</div>

				<h2 className="subtituloContenido listado">Listado de materiales</h2>

				<DataTable
					columns={columns}
					data={materialsData ? materialsData : ''}
					direction="auto"
					className="dataTableContainer"
					fixedHeaderScrollHeight="300px"
					responsive
					subHeaderAlign="right"
					subHeaderWrap
					noDataComponent={
						<DataTable
							columns={emptyColumn}
							className="dataTableContainer"
							data={dataTest ? dataTest : ''}
							direction="auto"
							fixedHeaderScrollHeight="300px"
							responsive
							subHeaderAlign="right"
							subHeaderWrap
						/>
					}
					paginationPerPage={5}
					pagination
					paginationComponentOptions={{
						rowsPerPageText: 'Registros por página:',
						rangeSeparatorText: 'de',
					}}
					paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
				/>

				<Notificacion open={OpenNoti} setState={setOpenNoti} config={notiConfig} />

				<Loading
					bg="bgLoadingWhite"
					open={openLoad}
				/>
			</Container>
		);
	}

	async function getMaterials() {
		try {
			handleOpenLoad();
			let materiales = await getMaterialsApi();
			setMaterialsData(materiales);
			handleCloseLoad();
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function getUnidadById() {
		// try {
		// 	let u = await getUnidadByIdApi(id);
		// 	console.log('🚀 ~ file: Unidad.js ~ line 77 ~ getUnidadById ~ u', u);
		// } catch (error) {
		// 	console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		// }
	}

	async function createMaterial() {
		try {
			delete materialData.id;
			let descripcion = materialData.descripcion.trim();
			let anulado = isAnulado ? 'N' : 'S';

			let material = await createMaterialsApi({ descripcion, anulado });
			setMaterialsData([...materialsData, material]);
			clear();
			notiConfig.estilo = 'notiSuccessFilled';
			notiConfig.mensaje = "Datos creados con éxito";
			setOpenNoti(true);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);

			let msj = await error.json();

			if (error.status == 422) {
				notiConfig.estilo = 'notiDangerFilled';
				notiConfig.mensaje = msj.violations[0].message;
				setOpenNoti(true);
			}
		}
	}

	async function editMaterial() {
		try {
			let id = materialData.id;
			materialData.anulado = isAnulado ? 'N' : 'S';
			materialData.descripcion = materialData.descripcion.trim();
			await editMaterialsApi(id, materialData);
			getMaterials();
			clear();
			notiConfig.estilo = 'notiSuccessFilled';
			notiConfig.mensaje = "Datos modificados con éxito";
			setOpenNoti(true);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);
			
			let msj = await error.json();

			if (error.status == 422) {
				notiConfig.estilo = 'notiDangerFilled';
				notiConfig.mensaje = msj.violations[0].message;
				setOpenNoti(true);
			}
		}
	}

	async function createMaterialsUnidad() {
		try {
			let data = {
				descripcion: 'Extension',
				material: idMaterial,
				unidad: idUnidad,
			};

			await createMaterialsUnidadApi(data);
			getMaterials();
		} catch (error) {
			if (error.status == 422) {
				let message = await error.json();
				toast.info(message.detail);
			}
		}
	}

	return (
		<>
			<LoginAuth></LoginAuth>
		</>
	);
}
