import { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import loadingPng from '../../img/Loading.png';
import './loading.scss';

export default function Loading(props) {
    const {
        titulo,
        texto,
        bg,
        open,
        tiempoMensaje = 0
    } = props;

    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        setInterval(() => {
            setShowMessage(true);
            
        }, tiempoMensaje); 
    }, [Loading]);

    return (
        <div>
            <Backdrop
                sx={{ zIndex: 2000 }}
                open={open}
                style={{ marginTop: '85px' }}
                className={bg}
            >

                <div className='loadingBody'>
                    <img className='loading' src={loadingPng} alt="Logo" />

                    {showMessage &&

                        <>
                            <h3 className='loadTitle'>{titulo}</h3>
                            <p className='loadText'>{texto}</p>
                        </>
                    }

                </div>
            </Backdrop>
        </div>
    );
}