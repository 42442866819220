import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuDrawer from './MenuDrawer';
import Logo from '../../img/fullLogoUCC.png';
import LogoWhite from '../../img/logo-ucc.svg';
import PerfilWhite from '../../img/perfilwhite.svg';
import LogoPerfil from '../../img/perfil.png';
import Link from '@mui/material/Link';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { getItemLocalStorage, logout } from '../../utils/auth';
import { FULL_NAME } from '../../api/constants';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import HelpIcon from '@mui/icons-material/Help';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { app_enviroment } from '../../api/config';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './nav.scss';

export default function NavbarGeneral() {
	const [fullname, setFullname] = useState('');

	const cerrarSesion = () => {
		logout();
		window.location.href = '/login';
	};

	useEffect(() => {
		const fullname = getItemLocalStorage(FULL_NAME);
		setFullname(fullname);
	}, []);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const openUserMenu = Boolean(anchorEl);

	const handleUserMenuClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleUserMenuClose = () => {
		setAnchorEl(null);
	};

	return (
		<div className="header">
			<Box sx={{ flexGrow: 1 }}>
				<AppBar
					position="fixed"
					style={
						app_enviroment == 'develop'
							? { background: '#a52a2a' }
							: { background: '#FFF' }
					}
				>
					<Toolbar style={{ padding: '0 10px' }}>
						<MenuDrawer color={app_enviroment == 'develop' ? '#FFF' : '#000'} />
						<Typography
							component="div"
							sx={{ flexGrow: 1 }}
							style={{ display: 'flex' }}
						>
							<Link href="/">
								{app_enviroment == 'develop' ? (
									<img
										className="logoHeader"
										src={LogoWhite}
										style={{ color: '#FFF' }}
									/>
								) : (
									<img
										className="logoHeader"
										src={Logo}
										style={{ color: '#FFF' }}
									/>
								)}
							</Link>
							{app_enviroment == 'develop' && (
								<Typography
									variant="h6"
									style={{
										color: '#FFF',
										marginTop: '25px',
										marginLeft: '25px',
									}}
								>
									ENTORNO DE PRUEBA
								</Typography>
							)}
						</Typography>
						<Typography
							variant="h6"
							style={
								app_enviroment == 'develop'
									? { color: '#FFF' }
									: { color: '#000' }
							}
						>
							{fullname}
						</Typography>
						<Link style={{ margin: '30px 10px' }}>
							{app_enviroment == 'develop' ? (
								<img
									className="logoHeader"
									src={PerfilWhite}
									style={{ color: '#FFF', width: '80%' }}
								/>
							) : (
								<img
									className="logoHeader"
									src={LogoPerfil}
									style={{ color: '#FFF', width: '100%', height: '60%' }}
								/>
							)}
						</Link>

						<IconButton
							id="basic-button"
							aria-controls={openUserMenu ? 'basic-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={openUserMenu ? 'true' : undefined}
							onClick={handleUserMenuClick}
						>
							<KeyboardArrowDownIcon
								style={
									app_enviroment == 'develop'
										? { color: '#FFF' }
										: { color: '#000' }
								}
							/>
						</IconButton>
						<Menu
							id="basic-menu"
							anchorEl={anchorEl}
							open={openUserMenu}
							onClose={handleUserMenuClose}
							MenuListProps={{
								'aria-labelledby': 'basic-button',
							}}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							disableScrollLock={true}
							PaperProps={{
								style: {
									padding: '8px 0px',
									background: '#FFFFFF',
									boxShadow:
										'0px 0px 2px rgba(0, 0, 0, 0.1), 0px 3px 3px rgba(0, 0, 0, 0.05)',
									borderRadius: '16px',
								},
							}}
						>
							{/* <MenuItem className="ProyDropdownMenuItem">
								<PersonIcon style={{ fontSize: '23px', marginRight: '10px' }} />
								Perfil
							</MenuItem>
							<MenuItem className="ProyDropdownMenuItem">
								<HelpIcon style={{ fontSize: '23px', marginRight: '10px' }} />
								Ayuda
							</MenuItem> */}
							<MenuItem className="ProyDropdownMenuItem" onClick={cerrarSesion}>
								<LogoutIcon style={{ fontSize: '23px', marginRight: '10px' }} />
								Cerrar Sesión
							</MenuItem>
						</Menu>
					</Toolbar>
				</AppBar>
			</Box>
			<Box component="main" sx={{ p: 6 }}></Box>
		</div>
	);
}
