import { base_url } from '../config';
import { secureFetch } from '../../utils/http';
import { getAuthUser } from '../../utils/auth';

const endpoints_proyecto_actividad = {
	GET: 'planif/proyecto/actividads',
	GETBYID: 'planif/proyecto/actividads',
	CREATE: 'planif/proyecto/actividads',
	EDIT: 'planif/proyecto/actividads',
	DELETE: 'planif/proyecto/actividads',
	GETBYIDVIEW: 'planif/view/proyecto/actividads',
};

const endpoint_orden_actividad = {
	EDIT: 'planif/proyecto/actividad/orden',
};
const endpoint_orden_tema = {
	EDIT: 'planif/proyecto/tema/orden',
};

export async function getProyectosActividadApi() {
	const url = `${base_url}/api/${endpoints_proyecto_actividad.GET}`;
	return await secureFetch(url, 'GET', null);
}

export async function getProyectoActividadByIdApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_actividad.GETBYID}/${id}`;
	return await secureFetch(url, 'GET', null);
}
export async function getProyectoActividadByIdViewApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_actividad.GETBYIDVIEW}/${id}`;
	return await secureFetch(url, 'GET', null);
}

export async function createProyectoActividadApi(data) {
	const url = `${base_url}/api/${endpoints_proyecto_actividad.CREATE}`;
	let dataCreate = data;
	return await secureFetch(url, 'POST', dataCreate);
}

export async function editProyectoActividadApi(id, data) {
	let user = getAuthUser();

	let dataEdit = {
		...data,
		planifPyId: +data.planifPyId,
		planifModId: data.planifModId,
		planifActId: +data.planifActId,
		planifProId: +data.planifProId,
		planifDifId: +data.planifDifId,
		planifConId: +data.planifConId,
		ordenVisual: +data.ordenVisual,
	};

	const url = `${base_url}/api/${endpoints_proyecto_actividad.EDIT}/${id}`;
	return await secureFetch(url, 'PUT', dataEdit);
}

export async function editOrdenTema(data) {
	const url = `${base_url}/api/${endpoint_orden_tema.EDIT}`;
	return await secureFetch(url, 'POST', data);
}

export async function deleteProyectoActividadApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_actividad.DELETE}/${id}`;
	return await secureFetch(url, 'DELETE', null);
}

export async function editOrdenActividad(data) {
	const url = `${base_url}/api/${endpoint_orden_actividad.EDIT}`;
	return await secureFetch(url, 'POST', data);
}
