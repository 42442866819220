import React, { useState, useEffect } from 'react';
import { getAccessToken } from '../../utils/auth';
import { LoginAuth } from '../../components/login/LoginAuth';
import {
	Form,
	Button,
	Row,
	Container,
	Input,
	FormGroup,
	Label,
	Table,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import {
	initialActividadData,
	initialArrayActividadData,
} from '../../api/Actividad/actividadModel';
import {
	getActividadApi,
	getActividadByIdApi,
	createActividadApi,
	editActividadApi,
} from '../../api/Actividad/actividad';

export default function Actividad() {
	const [isCreate, setIsCreate] = useState(true);

	const [actividadData, setActividadData] = useState(initialActividadData);

	const [arrayActidadData, setArrayActividadData] = useState(
		initialArrayActividadData
	);

	const handleChangeActividad = event => {
		setActividadData({
			...actividadData,
			[event.target.name]: event.target.value,
		});
	};

	const handleEditActividad = event => {
		let id = event.target.dataset.id;
		let actividad = arrayActidadData.filter(el => el.id == id);
		if (actividad.length) {
			setActividadData(actividad[0]);
			setIsCreate(false);
		}
	};

	const handleSubmitActividad = event => {
		event.preventDefault();
		isCreate ? createActividad() : editActividad();
	};

	if (getAccessToken()) {
		return (
			<>
				<h1>ABM actividad</h1>
				<ToastContainer></ToastContainer>
				<Container>
					<Row>
						<Button color="primary" onClick={getArrayActividad}>
							get actividades
						</Button>
					</Row>
					<Table bordered>
						<thead>
							<tr>
								<th>#</th>
								<th>planifEnfId</th>
								<th>planifTipId</th>
								<th>planifMatId</th>
								<th>descripcion</th>
								<th>observacion</th>
								<th>anulado</th>
								<th>anuladoFec</th>
								<th>codUsuAnula</th>
							</tr>
						</thead>
						<tbody>
							{arrayActidadData.map((actividad, i) => {
								return (
									<tr key={i}>
										<td>{actividad.id}</td>
										<td>{actividad.planifEnfId}</td>
										<td>{actividad.planifTipId}</td>
										<td>{actividad.planifMatId}</td>
										<td>{actividad.descripcion}</td>
										<td>{actividad.observacion}</td>
										<td>{actividad.anulado}</td>
										<td>{actividad.anuladoFec}</td>
										<td>{actividad.codUsuAnula}</td>
										<td>
											<Button
												color="primary"
												size="sm"
												data-id={actividad.id}
												onClick={handleEditActividad}
											>
												edit
											</Button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Container>

				<Container>
					Create
					<Row>
						<Form onSubmit={handleSubmitActividad}>
							<FormGroup>
								<Input
									id="id"
									name="id"
									type="hidden"
									onChange={handleChangeActividad}
									value={actividadData?.id || ''}
								/>
								<Label for="planifEnfId">planifEnfId</Label>
								<Input
									id="planifEnfId"
									name="planifEnfId"
									onChange={handleChangeActividad}
									value={actividadData?.planifEnfId || ''}
								/>

								<Label for="planifTipId">planifTipId</Label>
								<Input
									id="planifTipId"
									name="planifTipId"
									onChange={handleChangeActividad}
									value={actividadData?.planifTipId || ''}
								/>
								<Label for="planifMatId">planifMatId</Label>
								<Input
									id="planifMatId"
									name="planifMatId"
									onChange={handleChangeActividad}
									value={actividadData?.planifMatId || ''}
								/>
								<Label for="descripcion">descripcion</Label>
								<Input
									id="descripcion"
									name="descripcion"
									onChange={handleChangeActividad}
									value={actividadData?.descripcion || ''}
								/>
								<Label for="observacion">observacion</Label>
								<Input
									id="observacion"
									name="observacion"
									onChange={handleChangeActividad}
									value={actividadData?.observacion || ''}
								/>
							</FormGroup>
							<Button color="primary" type="submit">
								{isCreate ? 'create' : 'edit'}
							</Button>
						</Form>
					</Row>
				</Container>
			</>
		);
	}

	async function getArrayActividad() {
		try {
			let actividades = await getActividadApi();
			setArrayActividadData(actividades);
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function createActividad() {
		try {
			delete actividadData.id;
			let actividad = await createActividadApi(actividadData);
			setArrayActividadData([...arrayActidadData, actividad]);
		} catch (error) {
			console.log(await error.json());
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);
		}
	}

	async function editActividad() {
		try {
			console.log('🚀 ~ file: Proyecto.js:237 ~ editMaterial ~ editMaterial');
			let id = actividadData.id;
			let edit = await editActividadApi(id, actividadData);
			setIsCreate(true);
			setActividadData(initialActividadData);
			getArrayActividad();
		} catch (error) {
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);
			console.log(await error?.json());
		}
	}

	return (
		<>
			<LoginAuth></LoginAuth>
		</>
	);
}
