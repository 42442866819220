import React, { useEffect, useState } from 'react';
import { getAccessToken } from '../../utils/auth';
import { LoginAuth } from '../../components/login/LoginAuth';
import '../Unidad/Unidad.scss';
import { Form, Button, Row, Input, Label, FormGroup } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { ToastContainer, toast } from 'react-toastify';
import {
	getUnidadesApi,
	getUnidadByIdApi,
	createUnidadApi,
	editUnidadApi,
} from '../../api/Unidad/unidad';
import {
	initialUnidadData,
	initialArrayUnidadData,
} from '../../api/Unidad/unidadModel';
import { InputCheckbox } from '../../components/Inputs/InputCheckbox';
import '../../../src/App.css';
import Tooltip from '@mui/material/Tooltip';
import { Container } from '@mui/material';
import Notificacion from '../../components/snackbar/Notificacion';
import { 
	Box, 
	FormControl,
	TextField,
	IconButton
} from '@mui/material';
import Grid from '@mui/material/Grid';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import '../../scss/abmEstilos.scss';
import Loading from '../../components/loading/Loading';
import SaveIcon from '@mui/icons-material/Save';

export default function Unidad() {
	const [isAnulado, setIsAnulado] = useState(true);
	const [isCreate, setIsCreate] = useState(true);
	const [isDisable, setIsDisable] = useState('');

	const [arrayUnidadData, setArrayUnidadData] = useState(
		initialArrayUnidadData
	);
	const [unidadData, setUnidadData] = useState(initialUnidadData);

	useEffect(() => {
		getUnidades();
	}, []);

	const handleClickCancelar = () => {
		setIsDisable('');
		clear();
	};

	const clear = () => {
		setIsDisable('');
		setIsCreate(true);
		setIsAnulado(true);
		setUnidadData(initialUnidadData);
	};

	const handleChangeAnulado = event => {
		let anulado = event.target.checked;
		setIsAnulado(anulado);
	};
	const handleEditUnidad = event => {
		let id = event.target.dataset.id;
		let unidad = arrayUnidadData.filter(el => el.id == id);
		setIsDisable(unidad);

		if (unidad.length) {
			setUnidadData(unidad[0]);
			setIsCreate(false);

			if (unidad[0].anulado == 'N') {
				setIsAnulado(true);
			} else {
				setIsAnulado(false);
			}
		}
	};

	const handleChangeUnidad = event => {
		let texto = event.target.value;
		setIsDisable(texto);

		setUnidadData({
			...unidadData,
			[event.target.name]: event.target.value,
		});
	};

	const handleSubmitUnidad = event => {
		event.preventDefault();
		isCreate ? createUnidad() : editUnidad();
	};

	const columns = [
		{
			name: 'Unidades',
			selector: row => row.descripcion ? ( row.descripcion) : (''),
			cell: row =>
				row.descripcion ? (
					<div className="dataTableText">
						{row.descripcion}
					</div>
				) : (
					''
				),
			sortable: true,
			maxWidth: 200,
			minWidth: 140,
			width: 200,
			wrap: true,
		},
		{
			name: 'Estado',
			selector: row => row.anulado,
			sortable: true,
			cell: obj => {
				return (
					<div className="botonesTabla">
						{obj.anulado == 'N' ? (
							<div className="abmCampoVisible">
								<span className="material-symbols-rounded abmIcoVisi">
									visibility
								</span>
								Habilitado
							</div>
						) : (
							<div className="abmCampoDeshabilitar">
								<span className="material-symbols-rounded  abmIcoVisiOff">
									visibility_off
								</span>
								Deshabilitado
							</div>
						)}
					</div>
				);
			},
			maxWidth: 200,
			minWidth: 140,
			width: 200,
			wrap: true,
		},
		{
			cell: obj => {
				return (
					<div className="botonesTabla">
						<Tooltip title="Editar" placement="top" arrow>
							<IconButton
								data-id={obj.id ? obj.id : ''}
								className="material-symbols-rounded icoAbmEditar"
								onClick={handleEditUnidad}
							>
								mode_edit
							</IconButton>
						</Tooltip>
					</div>
				);
			},
			right: true,
			ignoreRowClick: true,
			allowOverflow: true,
		},
	];
  
	const [OpenNoti, setOpenNoti] = React.useState(false);

	const notiConfigIni = {
		titulo: '',
		mensaje: '',
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const [notiConfig,setNotiConfig] = useState(notiConfigIni);

	const _fieldObligatorio = () => {
		return <b style={{ color: 'red' }}>*</b>;
	};

	const emptyColumn = [
		{
			name: 'Unidades',
			cell: obj => {
				return <span>Sin unidades creadas.</span>;
			},
			left: true,
			ignoreRowClick: true,
			allowOverflow: true,
		},
	];

	const [dataTest, setDataTest] = useState([
		{
			descripcion: 'Sin unidades creadas.',
		},
	]);

	const [openLoad, setOpenLoad] = React.useState(false);

	const handleCloseLoad = () => {
		setOpenLoad(false);
	};

	const handleOpenLoad = () => {
		setOpenLoad(true);
	};

	if (getAccessToken()) {
		return (
			<Container className="contenedor">
				<div className="textoContenido">
					<h1 className="tituloContenido">Unidades</h1>
					<p className="parrafoContenido">Creá y configurá las unidades.</p>
				</div>

				<div className="textoContenido">
					<h2 className="subtituloContenido">Crear unidad</h2>

					<Box sx={{ flexGrow: 1 }}>
						<Grid container spacing={2}>
							<Grid item xs={8}>
								<FormControl className="descripAbm">
									<TextField
										id="descripcion"
										name="descripcion"
										label={
											<span style={{ fontFamily: 'Roboto' }}>
												Nombre de la competencia {_fieldObligatorio()}
											</span>
										}
										variant="outlined"
										inputProps={{ maxLength: 255 }}
										type="text"
										onChange={handleChangeUnidad}
										value={unidadData?.descripcion || ''}
									/>
								</FormControl>
							</Grid>
							<Grid className='' item xs={4}>
								<div className="seccionInput">
									<InputCheckbox
										isAnulado={isAnulado}
										handleChangeAnulado={handleChangeAnulado}
										isDisable={!isDisable}
									></InputCheckbox>
								</div>
								<div className="btnContentAbm">
									<Button
										className="btnCrearAbm"
										disabled={!isDisable}
										onClick={handleSubmitUnidad}
									>
										{isCreate ?
										<AddIcon  className='btnIcoAbm'/>
										:
										<SaveIcon className='btnIcoAbm'/>
										}
										{isCreate ? 'Crear' : 'Guardar'}
									</Button>
									<Button
										className="btnAbmCancel"
										onClick={handleClickCancelar}
										disabled={isCreate ? !isDisable : false}
									>
										<CancelIcon  className='btnIcoAbm'/>
										Cancelar
									</Button>
								</div>
							</Grid>
						</Grid>
					</Box>
				</div>

				<h2 className="subtituloContenido listado">Listado de unidades</h2>

				<DataTable
					className="dataTableContainer"
					columns={columns}
					data={arrayUnidadData ? arrayUnidadData : ''}
					direction="auto"
					fixedHeaderScrollHeight="300px"
					responsive
					subHeaderAlign="right"
					subHeaderWrap
					noDataComponent={
						<DataTable
							columns={emptyColumn}
							className="dataTableContainer"
							data={dataTest ? dataTest : ''}
							direction="auto"
							fixedHeaderScrollHeight="300px"
							responsive
							subHeaderAlign="right"
							subHeaderWrap
						/>
					}
					paginationPerPage={5}
					pagination
					paginationComponentOptions={{
						rowsPerPageText: 'Registros por página:',
						rangeSeparatorText: 'de',
					}}
					paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
				/>

				<Notificacion open={OpenNoti} setState={setOpenNoti} config={notiConfig} />

				<Loading
					bg="bgLoadingWhite"
					open={openLoad}
				/>
			</Container>
		);
	}

	async function getUnidades() {
		try {
			handleOpenLoad();
			let u = await getUnidadesApi();
			setArrayUnidadData(u);
			handleCloseLoad();
		} catch (error) {
			console.log(error);
		}
	}

	async function createUnidad() {
		try {
			delete unidadData.id;
			let descripcion = unidadData.descripcion.trim();
			let anulado = isAnulado ? 'N' : 'S';
			let unidad = await createUnidadApi({ descripcion, anulado });
			setArrayUnidadData([...arrayUnidadData, unidad]);
			clear();
			notiConfig.estilo = 'notiSuccessFilled';
			notiConfig.mensaje = "Datos creados con éxito";
			setOpenNoti(true);

		} catch (error) {
			console.log(error);

			let msj = await error.json();

			if (error.status == 422) {
				notiConfig.estilo = 'notiDangerFilled';
				notiConfig.mensaje = msj.violations[0].message;
				setOpenNoti(true);
			}
		}
	}

	async function editUnidad() {
		try {
			let id = unidadData.id;
			unidadData.anulado = isAnulado ? 'N' : 'S';
			unidadData.descripcion = unidadData.descripcion.trim();
			await editUnidadApi(id, unidadData);
			clear();
			getUnidades();
			notiConfig.estilo = 'notiSuccessFilled';
			notiConfig.mensaje = "Datos modificados con éxito";
			setOpenNoti(true);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);
			
			let msj = await error.json();

			if (error.status == 422) {
				notiConfig.estilo = 'notiDangerFilled';
				notiConfig.mensaje = msj.violations[0].message;
				setOpenNoti(true);
			}
		}
	}

	return (
		<>
			<LoginAuth></LoginAuth>
		</>
	);
}
