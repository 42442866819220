import {
	Box,
	Container,
	IconButton,
	Modal,
	TextField,
	Typography,
	Stack,
	Chip,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import React, { useState } from 'react';
import '../../../scss/modal.scss';

export default function ModalEditarModulo(props) {
	const {
		modal,
		showModalModulo,
		descripcion,
		observacion,
		onChangeTitulo,
		onChangeObservacion,
		editModuloData,
		valid,
	} = props;

	return (
		<>
			<Modal open={modal} onClose={showModalModulo}>
				<Box className="modalActividadContainer modalShadow">
					<Container className="modalActividadHeader">
						<h1>Editar módulo</h1>
						<IconButton
							onClick={showModalModulo}
							style={{ margin: 'auto', marginLeft: '780px' }}
						>
							<CloseIcon />
						</IconButton>
					</Container>
					<Container>
						<Typography
							sx={{ mb: 3 }}
							style={{
								fontFamily: 'Roboto',
								fontStyle: 'normal',
								fontWeight: '400',
								fontSize: '16px',
								lineHeight: '19px',
								color: '#000000',
							}}
						>
							Configurá los datos del módulo.
						</Typography>
					</Container>
					<Container>
						<TextField
							className="inputCrearProy"
							id="nombre"
							name="nombre"
							error={!valid}
							defaultValue={descripcion ?? ''}
							inputProps={{ maxLength: 225 }}
							label={
								<>
									Título <b style={{ color: 'red' }}>*</b>
								</>
							}
							variant="outlined"
							onChange={onChangeTitulo}
						/>
					</Container>
					<Container>
						<TextField
							className="textAreaCrearProy"
							id="descripcion"
							name="descripcion"
							defaultValue={observacion ?? ''}
							variant="outlined"
							label="Descripción"
							multiline
							inputProps={{ maxLength: 1000 }}
							rows={3}
							onChange={onChangeObservacion}
						/>
					</Container>
					<Container>
						<Stack
							direction="row"
							spacing={1}
							style={{
								display: 'flex',
								justifyContent: 'end',
								margin: '38px 0px 38px 0px',
							}}
						>
							<Chip
								icon={
									<SaveIcon style={{ color: '#003087', fontSize: '14px' }} />
								}
								label="Guardar"
								clickable
								style={{
									background: '#CCD6E7',
									height: '34px',
									fontSize: '12px',
									paddingLeft: '6px',
									marginRight: '15px',
								}}
								onClick={() => {
									editModuloData();
								}}
							/>

							<Chip
								icon={
									<CancelIcon style={{ color: '#003087', fontSize: '14px' }} />
								}
								clickable
								label="Cancelar"
								variant="outlined"
								onClick={showModalModulo}
								style={{
									height: '34px',
									fontSize: '12px',
									paddingLeft: '6px',
								}}
							/>
						</Stack>
					</Container>
				</Box>
			</Modal>
		</>
	);
}
