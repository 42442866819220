import React, { useState, useEffect } from 'react';
import { getAccessToken } from '../../utils/auth';
import { LoginAuth } from '../../components/login/LoginAuth';
import {
	getMaterialsApi,
	getMaterialsByIdApi,
	getQueryParams as getQueryParamsMaterial,
} from '../../api/Material/material';

import {
	initialEquivalenciaData,
	initialArrayEquivalenciaData,
} from '../../api/Equivalencia/equivalenciaModel';
import Notificacion from '../../components/snackbar/Notificacion';

import {
	getQueryParams as getQueryParamsEquiv,
	getEquivalenciaApi,
	createEquivalenciaApi,
	editEquivalenciaApi,
} from '../../api/Equivalencia/equivalencia';
import { Container } from '@mui/material';
import CardEquivalencia from '../../components/cardEquivalencia/CardEquivalencia';

import './Equivalencia.scss';
import ContenidoEquivalencia from '../../components/contenidoEquivalencia/ContenidoEquivalencia';
import { getDificultadApi } from '../../api/Dificultad/dificultad';
import { getPropositoApi } from '../../api/Proposito/proposito';
import Loading from '../../components/loading/Loading';

export default function Equivalencia() {
	const [isCreate, setIsCreate] = useState(true);
	const [isAnulado, setIsAnulado] = useState(true);
	const [isDisable, setIsDisable] = useState('');
	const [materialData, setMaterialData] = useState([]);
	const [dificultadData, setDificultadData] = useState([]);
	const [propositoData, setPropositoData] = useState([]);
	const [materialDeta, setMaterialDeta] = useState([materialData[0]]);
	const [materialId, setMaterialId] = useState();

	const notiActInit = {
		titulo: '',
		mensaje: '',
		estilo: 'notiSuccessFilled',
		tiempo: 1500,
		vertical: 'bottom',
		horizontal: 'center',
	};
	const notiActInitError = {
		titulo: 'Existen cambios sin guardar',
		mensaje: 'Validar formato (00.00).',
		estilo: 'notiDangerFilled',
		tiempo: null,
		vertical: 'top',
		horizontal: 'center',
	};
	const [notiActConfig, setNotiActConfig] = useState(notiActInit);
	const [notiActConfigError, setNotiActConfigError] =
		useState(notiActInitError);
	const [openNotiAct, setOpenNotiAct] = useState(false);
	const [openNotiActError, setOpenNotiActError] = useState(false);

	const [OpenNotiEditMod, setOpenNotiEditMod] = useState(false);

	const notiConfigNotiEditMod = {
		titulo: '',
		mensaje: 'Dato modificado con éxito',
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const [equivalenciaData, setEquivalenciaData] = useState(
		initialEquivalenciaData
	);

	const [arrayEquivalenciaData, setArrayEquivalenciaData] = useState(
		initialArrayEquivalenciaData
	);
	const [show, setShow] = useState(false);

	const handleChangeEquivalencia = event => {
		let texto = event.target.value;
		setIsDisable(texto);
		setEquivalenciaData({
			...equivalenciaData,
			[event.target.name]: event.target.value,
		});
	};

	const handleEditEquivalencia = event => {
		let id = event.target.dataset.id;
		let equivalencia = arrayEquivalenciaData.filter(el => el.id == id);
		setIsDisable(equivalencia);
		if (equivalencia.length) {
			setEquivalenciaData(equivalencia[0]);
			setIsCreate(false);

			if (equivalencia[0].anulado == 'N') {
				setIsAnulado(true);
			} else {
				setIsAnulado(false);
			}
		}
	};

	const handleSubmitEquivalencia = event => {
		event.preventDefault();
		isCreate ? createEquivalencia() : editEquivalencia();
	};

	useEffect(() => {
		getMaterials();
		getArrayEquivalencia();
		getDificultad();
		getProposito();
	}, []);

	const clear = () => {
		setIsDisable('');
		setIsCreate(true);
		setIsAnulado(true);
		setEquivalenciaData(initialEquivalenciaData);
	};

	const [unidadId, setUnidadId] = useState();
	const [activo, setActivo] = useState(false);

	const handleClickMaterialId = event => {
		
		setMaterialId(event.currentTarget.dataset.id);
		setUnidadId(event.currentTarget.dataset.unidad);
		setActivo(event.currentTarget.dataset.key);
		let matId = event.currentTarget.dataset.id;
		getMaterialById(matId);

		if (document.querySelector('.redprueba') != undefined) {
			const elm = document.querySelectorAll('table > tbody > tr > th > p');
			elm.forEach(element => {
				element.classList.remove('redprueba');
			});
			setShow(false);
		} else {
			setShow(false);
		}

		getArrayEquivalencia(matId, unidadId);
	};

	const [openLoad, setOpenLoad] = React.useState(false);

	const handleCloseLoad = () => {
		setOpenLoad(false);
	};

	const handleOpenLoad = () => {
		setOpenLoad(true);
	};

	function handleOnFocusDuracion() {
		setShow(true);
	}

	if (getAccessToken()) {
		return (
			<Container>
				<Notificacion
					open={openNotiActError}
					setState={setOpenNotiActError}
					config={notiActConfigError}
				/>
				<Notificacion
					open={openNotiAct}
					setState={setOpenNotiAct}
					config={notiActConfig}
				/>
				<Notificacion
					open={OpenNotiEditMod}
					setState={setOpenNotiEditMod}
					config={notiConfigNotiEditMod}
				/>
				<div className="equivalenciaHeader">
					<h1 className="equivalenciaHeaderTitle">Equivalencias</h1>
					<p className="equivalenciaHeaderTxt">
						El sistema de equivalencias se centra en dos aspectos clave: el
						propósito y la dificultad. Para determinar la duración en horas de
						cada actividad, es necesario completar cada material con los tiempos
						estimados según su unidad de medida asociada. <br /> Completa las
						tablas de equivalencias correspondientes a cada material o accede a
						esta información seleccionando del material correspondiente.
					</p>
				</div>

				<div className="textoContenidoEquivalencia">
					<div className="listadoEquivalencias">
						<h2>Materiales</h2>
						{materialData?.length > 0 &&
							materialData?.map((item, index) => (
								<div
									key={index}
									style={{ marginBottom: '16px'}}
								>
									<CardEquivalencia
										idKey={index}
										nombreMaterial={item.descripcion}
										iconMaterial={item.icono}
										id={item.id}
										unidadId={item.unidads?.map((el, index) => {
											return <div key={index}>{el.id}</div>;
										})}
										funcion={handleClickMaterialId}
										activo={activo}
									/>
								</div>
							))}
					</div>
					<div className="contenidoEquivalencia">
						<h2>
							Completá las equivalencias correspondientes para cada unidad del
							material seleccionado
						</h2>
						<ContenidoEquivalencia
							equivalencia={arrayEquivalenciaData}
							idMaterial={materialId}
							materialData={materialDeta}
							dificultad={dificultadData}
							setShow={setShow}
							show={show}
							proposito={propositoData}
							editEquivalencia={editEquivalencia}
							createEquivalencia={createEquivalencia}
						/>
					</div>
				</div>

				<Loading bg="bgLoadingWhite" open={openLoad} />
			</Container>
		);
	}

	async function getArrayEquivalencia(matId, unidadId) {
		try {
			handleOpenLoad();
			let queryParamsDefault = getQueryParamsEquiv();

			let { material, unidad } = queryParamsDefault.filtersDefault.properties;
			let value = matId;
			let valUni = unidadId;

			const query = { [material]: value, [unidad]: valUni };
			queryParamsDefault.filters.properties.push(query);

			let equivalencia = await getEquivalenciaApi(queryParamsDefault);
			setArrayEquivalenciaData(equivalencia);
			handleCloseLoad();
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function createEquivalencia(
		matId,
		propoId,
		dificId,
		uniId,
		fact,
		nombreMat
	) {
		try {
			delete equivalenciaData.id;

			let equivalencia = await createEquivalenciaApi({
				material: +matId,
				proposito: +propoId,
				dificultad: +dificId,
				unidad: +uniId,
				factor: +fact,
				funcion: nombreMat == 'Minutos' ? 'M' : 'R',
			});
			setArrayEquivalenciaData([...arrayEquivalenciaData, equivalencia]);
			clear();
			setOpenNotiEditMod(true);
		} catch (error) {
			console.log(await error.json());
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);
		}
	}

	async function editEquivalencia(factor, id) {
		try {
			await editEquivalenciaApi(id, { factor: +factor });
			clear();
			setIsCreate(true);
			setEquivalenciaData(initialEquivalenciaData);
			getArrayEquivalencia();
			setOpenNotiEditMod(true);
		} catch (error) {
			notiActConfig.mensaje = 'Error en el dato ingresado.';
			notiActConfig.tiempo = 4000;
			notiActConfig.estilo = 'notiDangerFilled';
			setOpenNotiAct(true);
		}
	}

	async function getMaterials() {
		try {
			let queryParams = getQueryParamsMaterial();

			let { anulado, order } = queryParams.filtersDefault.properties;
			let value = 'N';
			let valueOrder = 'ASC';

			const query = { [anulado]: value, [order]: valueOrder };
			queryParams.filters.properties.push(query);

			const materiales = await getMaterialsApi(queryParams);
			setMaterialData(materiales);
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function getMaterialById(id) {
		try {
			let materialData = await getMaterialsByIdApi(id);
			setMaterialDeta(materialData);
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function getDificultad() {
		try {
			let dificultades = await getDificultadApi();
			dificultades = dificultades.filter(d => d.anulado != 'S');
			setDificultadData(dificultades);
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function getProposito() {
		try {
			let proposito = await getPropositoApi();
			proposito = proposito.filter(p => p.anulado != 'S');
			setPropositoData(proposito);
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	return (
		<>
			<LoginAuth></LoginAuth>
		</>
	);
}
