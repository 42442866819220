import { base_url } from '../config';
import { secureFetch } from '../../utils/http';
import { getAuthUser } from '../../utils/auth';
const endpoints_proyecto_actividad = {
	GET: 'planif/actividads',
	GETBYID: 'planif/actividads',
	CREATE: 'planif/actividads',
	EDIT: 'planif/actividads',
	DELETE: 'planif/actividads',
	GETCHECK: 'planif/actividad/:id/edit'
};

let user = getAuthUser();

export function getQueryParams() {
	return {
		pagination: {
			value: false,
			items: 2,
			page: 1,
		},
		filters: {
			properties: [],
		},
		filtersDefault: {
			properties: {
				anulado: 'anulado',
				tipo_recurso: 'tipo',
				enfasis: 'enfasi',
			},
		},
	};
}

export async function getActividadApi(queryParams = null) {
	const url = `${base_url}/api/${endpoints_proyecto_actividad.GET}`;
	if (queryParams) return await secureFetch(url, 'GET', queryParams);
	return await secureFetch(url, 'GET', null);
}

export async function getActividadByIdApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_actividad.GETBYID}/${id}`;
	return await secureFetch(url, 'GET', null);
}

export async function createActividadApi(data) {
	const url = `${base_url}/api/${endpoints_proyecto_actividad.CREATE}`;
	let dataCreate = {
		...data,
		enfasi: data.enfasi,
		tipo: data.tipo,
		material: data.material,
		descripcion: data.descripcion,
		observacion: data.observacion,
	};

	if(dataCreate.anulado == 'S'){
		
		dataCreate.codUsuAnula= user.username;
	}


	return await secureFetch(url, 'POST', dataCreate);
}

export async function editActividadApi(id, data) {
	// planifEnfId: +data.planifEnfId,
	// planifTipId: +data.planifTipId,
	// planifMatId: +data.planifMatId,
	let dataEdit = {
		...data
	};

	const url = `${base_url}/api/${endpoints_proyecto_actividad.EDIT}/${id}`;
	return await secureFetch(url, 'PUT', dataEdit);
}

export async function deleteActividadApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_actividad.DELETE}/${id}`;
	return await secureFetch(url, 'DELETE', null);
}

export async function getCheckActividadApi(id) {
	let url_check = endpoints_proyecto_actividad.GETCHECK.replace(':id',id)
	const url = `${base_url}/api/${url_check}`;
	return await secureFetch(url, 'GET', null);
}
