import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNotificationMui } from '../../hooks/useNotificacionMui';
import { NotificationMui as DeleteNotificationMui } from '../../components/notification/NotificactionMui';
import {
	Tooltip,
	InputLabel,
	FormControl,
	Select,
	Chip,
	MenuItem,
	IconButton,
	TextField,
	Container,
	Button,
	Grid,
	Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { getUnidadesApi } from '../../api/Unidad/unidad';
import { getMaterialsApi } from '../../api/Material/material';

import './AsociarUnidades.scss';
import {
	getMaterialsUnidadApi,
	deleteMaterialsUnidadApi,
	editMaterialsUnidadApi,
	createMaterialsUnidadApi,
	getCheckActividadApi,
} from '../../api/MaterialUnidad/materialUnidad';
import Notificacion from '../../components/snackbar/Notificacion';
import ModalCustomConfirmacion from '../../components/modal/modalCustomConfirmacion/modalCustomConfirmacion';
import WarningIcon from '@mui/icons-material/Warning';
import '../../scss/abmEstilos.scss';
import Loading from '../../components/loading/Loading';

export default function AsociarUnidades() {

	const [materialsData, setMaterialsData] = useState();
	const [arrayUnidadData, setArrayUnidadData] = useState();
	const [materialUnidadData, setMaterialUnidadData] = useState();
	const [materialAsoc, setMaterialAsoc] = useState();
	const [tituloAsoc, setTituloAsoc] = useState('');
	const [unidadAsoc, setUnidadAsoc] = useState();
	const [notiCrearOpen, notiCrearSetOpen] = useState(false);

	const [idMaterialSelected, setIdMaterialSelected] = useState('');
	const [idUnidadSelected, setIdUnidadSelected] = useState('');

	const [idDeleteMaterial, setIdDeleteMaterial] = useState();
	const [idDeleteUnidad, setIdDeleteUnidad] = useState();

	const [isCreate, setIsCreate] = useState(true);

	const handleOnChangeMaterial = async event => {
		setMaterialAsoc();
		let id = event.target.value;
		setMaterialAsoc(id);
		setIdMaterialSelected(id);
	};

	const handleOnChangeUnidad = async event => {
		setUnidadAsoc();
		let id = event.target.value;
		setUnidadAsoc(id);
		setIdUnidadSelected(id);
	};

	const handleOnChangeTitulo = event => {
		let titulo = event.target.value;
		setTituloAsoc(titulo);
	};

	const handleEditMaterialUnidad = event => {
		let id = event.target.dataset.id;
		let matId = event.target.getAttribute('data-mat');
		let uniId = event.target.getAttribute('data-unid');
		let desc = event.target.getAttribute('data-desc');
		setTituloAsoc(desc);
		setIdMaterialSelected(matId);
		setIdUnidadSelected(uniId);
		setIsCreate(false);
		setDisableSelect(true);
	};

	const handleDeleteMaterialUnidad = event => {
		let matId = event.target.getAttribute('data-material_id');
		let uniId = event.target.getAttribute('data-unidad_id');
		let dataDesc = event.target.getAttribute('data-desc');
		setIdDeleteMaterial(matId);
		setIdDeleteUnidad(uniId);
		configuracion.descripcion =
			'Si eliminas "' + dataDesc + '" se perderan todos los datos cargados';
		showModalConfirm();
	};

	function clear() {
		setTituloAsoc('');
		setIdMaterialSelected('');
		setIdUnidadSelected('');
		setIsCreate(true);
		setDisableSelect(false);
	}

	useEffect(() => {
		getMaterials();
		setIdMaterialSelected('');
		getUnidades();
		getMaterialUnidList();
	}, []);

	const columns = [
		{
			name: 'Material',
			selector: row => row.material.descripcion ? ( row.material.descripcion) : (''),
			cell: row =>
				row.material.descripcion ? (
					<div className="dataTableText">
						<span className="material-symbols-rounded dataTableIco" style={{fontSize: '18px'}}>
							{row.material.icono ? row.material.icono : 'public'}
						</span>
						{row.material.descripcion}
					</div>
				) : (
					''
				),
			sortable: true,
			maxWidth: 200,
			minWidth: 140,
			width: 200,
			wrap: true,
		},
		{
			name: 'Unidad',
			selector: row => row.unidad.descripcion ? ( row.unidad.descripcion) : (''),
			cell: row =>
				row.unidad.descripcion ? (
					<div className="dataTableText">
						<span
							className="material-symbols-rounded dataTableIco"
							style={{ fontSize: '16px' }}
						>
							{row.unidad.icono}
						</span>
						{row.unidad.descripcion}
					</div>
				) : (
					''
				),
			sortable: true,
			maxWidth: 200,
			minWidth: 140,
			width: 200,
			wrap: true,
		},
		{
			name: 'Título',
			selector: row => row.descripcion ? ( row.descripcion) : (''),
			cell: row =>
				row.descripcion ? (
					<div
						className="dataTableText"
						style={{
							textAlign: 'left',
						}}
					>
						{row.descripcion}
					</div>
				) : (
					''
				),
			sortable: true,
			maxWidth: 300,
			minWidth: 340,
			width: 300,
			wrap: true,
		},
		{
			cell: (row, index, column, id) => {
				return (
					<div className="botonesTabla">
						<Tooltip title="Editar" placement="top" arrow>
							<IconButton
								data-mat={row.material.id}
								data-unid={row.unidad.id}
								data-desc={row.descripcion}
								data-id={index}
								className="material-symbols-rounded icoAbmEditar"
								onClick={handleEditMaterialUnidad}
							>
								mode_edit
							</IconButton>
						</Tooltip>
						<Tooltip title="Eliminar" placement="top" arrow>
							<IconButton
								className="material-symbols-rounded icoAbmEditar"
								onClick={handleDeleteMaterialUnidad}
								data-material_id={row.material.id}
								data-unidad_id={row.unidad.id}
								data-desc={row.descripcion}
							>
								delete
							</IconButton>
						</Tooltip>
					</div>
				);
			},
			right: true,
			ignoreRowClick: true,
			allowOverflow: true,
			sortable: false,
			maxWidth: 100,
			minWidth: 100,
			width: 100,
		},
	];

	const [disableSelect, setDisableSelect] = useState(false);

	const [OpenNoti, setOpenNoti] = React.useState(false);

	const notiConfigIni = {
		titulo: '',
		mensaje: '',
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const [notiConfig, setNotiConfig] = useState(notiConfigIni);

	const [openModalConfirm, setOpenModalConfirm] = useState(false);
	const [confirmacion, setConfirmacion] = useState();

	const showModalConfirm = () => {
		setOpenModalConfirm(true);
	};

	const closeModalConfirm = val => {
		setOpenModalConfirm(false);
		setConfirmacion(val);
	};

	const modalCustomConf = {
		titulo: 'Eliminar',
		descripcion:
			'Si eliminas el siguiente material se perderan todos los datos.',
	};

	const [configuracion, setConfiguracion] = useState(modalCustomConf);

	const _fieldObligatorio = () => {
		return <b style={{ color: 'red' }}>*</b>;
	};

	const emptyColumn = [
		{
			name: 'Unidades Asociadas',
			cell: obj => {
				return <span>Sin unidades asociadas creadas.</span>;
			},
			left: true,
			ignoreRowClick: true,
			allowOverflow: true,
		},
	];

	const [dataTest, setDataTest] = useState([
		{
			descripcion: 'Sin unidades asociadas creadas.',
		},
	]);

	const [openLoad, setOpenLoad] = React.useState(false);

	const handleCloseLoad = () => {
		setOpenLoad(false);
	};

	const handleOpenLoad = () => {
		setOpenLoad(true);
	};

	return (
		<Container className="contenedor">
			<div className="textoContenido">
				<h1 className="tituloContenido">Asociar unidades</h1>
				<p className="parrafoContenido">
					Configurá los datos de los materiales que formaran parte del
					contenido.
				</p>
			</div>

			<div className="textoContenido">
				<h2 className="subtituloContenido">Unidades</h2>
				<Box sx={{ flexGrow: 1 }}>
					<Grid container spacing={2}>
						<Grid item xs={4}>
							<FormControl className="selectEnfasisAbm">
								<InputLabel>Material {_fieldObligatorio()} </InputLabel>
								<Select
									id="material"
									variant="outlined"
									label="Material"
									value={idMaterialSelected ? idMaterialSelected : ''}
									onChange={handleOnChangeMaterial}
									disabled={disableSelect ? disableSelect : false}
								>
									{materialsData?.map(
										(item, index) =>
											item.anulado != 'S' && (
												<MenuItem value={item.id} id={item.id} key={index}>
													{item.descripcion}
												</MenuItem>
											)
									)}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={4}>
							<FormControl className="selectEnfasisAbm">
								<InputLabel>Unidad {_fieldObligatorio()} </InputLabel>
								<Select
									id="Unidad"
									label="Unidad"
									variant="outlined"
									value={idUnidadSelected ? idUnidadSelected : ''}
									onChange={handleOnChangeUnidad}
									disabled={disableSelect ? disableSelect : false}
								>
									{arrayUnidadData?.map(
										(item, index) =>
											item.anulado != 'S' && (
												<MenuItem value={item.id} id={item.id} key={index}>
													{item.descripcion}
												</MenuItem>
											)
									)}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={4}>
							<FormControl className="descripAbm">
								<TextField
									id="Titulo"
									label={
										<span style={{ fontFamily: 'Roboto' }}>
											Título {_fieldObligatorio()}
										</span>
									}
									variant="outlined"
									value={tituloAsoc ? tituloAsoc : ''}
									onChange={handleOnChangeTitulo}
									inputProps={{ maxLength: 255 }}
									type="text"
								/>
							</FormControl>
						</Grid>
						<Grid className="" item xs={12}>
							<div className="btnContentAbm">
								{isCreate ? (
									<Button
										className="btnCrearAbm"
										disabled={
											tituloAsoc != '' &&
											idUnidadSelected != '' &&
											idMaterialSelected != ''
												? false
												: true
										}
										onClick={createMaterialUnidadAsoc}
										style={{ textTransform: 'capitalize' }}
									>
										<AddIcon className="btnIcoAbm" />
										Crear
									</Button>
								) : (
									<Button
										className="btnCrearAbm"
										disabled={
											tituloAsoc != '' &&
											idUnidadSelected != '' &&
											idMaterialSelected != ''
												? false
												: true
										}
										onClick={editMaterialUnidadAsoc}
										style={{ textTransform: 'capitalize' }}
									>
										<SaveIcon className='btnIcoAbm'/>
										Guardar
									</Button>
								)}

								<Button
									className="btnAbmCancel"
									onClick={clear}
									disabled={
										isCreate ?
										(isCreate &&
										tituloAsoc != '' &&
										idUnidadSelected != '' &&
										idMaterialSelected != ''
											? false
											: true)
										:
										false
									}
									style={{ textTransform: 'capitalize' }}
								>
									<CancelIcon className="btnIcoAbm" />
									Cancelar
								</Button>
							</div>
						</Grid>
					</Grid>
				</Box>
			</div>

			<h2 className="subtituloContenido listado">
				Listado de unidades asociados
			</h2>

			<DataTable
				columns={columns}
				className="dataTableContainer"
				data={materialUnidadData ? materialUnidadData : ''}
				direction="auto"
				fixedHeaderScrollHeight="300px"
				responsive
				subHeaderAlign="right"
				subHeaderWrap
				noDataComponent={
					<DataTable
						columns={emptyColumn}
						className="dataTableContainer"
						data={dataTest ? dataTest : ''}
						direction="auto"
						fixedHeaderScrollHeight="300px"
						responsive
						subHeaderAlign="right"
						subHeaderWrap
					/>
				}
				paginationPerPage={5}
					pagination
					paginationComponentOptions={{
						rowsPerPageText: 'Registros por página:',
						rangeSeparatorText: 'de',
					}}
				paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
			/>

			<Notificacion
				open={OpenNoti}
				setState={setOpenNoti}
				config={notiConfig}
			/>

			<ModalCustomConfirmacion
				openModalConfirm={openModalConfirm}
				onClose={closeModalConfirm}
				configs={configuracion}
				icon={
					<WarningIcon
						className="confirmIcon"
						style={{ fontSize: '20px', color: '#626262' }}
					/>
				}
				content=""
				contentButton={
					<div style={{ marginTop: '-40px' }}>
						<Button
							className="buttonEliminarModal"
							onClick={() => {
								deleteMaterialUnidadAsoc(idDeleteMaterial, idDeleteUnidad);
							}}
						>
							Continuar
						</Button>
						<Button
							outlined="true"
							className="buttonEliminarModalOutline"
							onClick={closeModalConfirm}
						>
							Cancelar
						</Button>
					</div>
				}
			/>

			<Loading
				bg="bgLoadingWhite"
				open={openLoad}
			/>
		</Container>
	);

	async function getMaterials() {
		try {
			let materiales = await getMaterialsApi();
			setMaterialsData(materiales);
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function getUnidades() {
		try {
			let u = await getUnidadesApi();
			setArrayUnidadData(u);
		} catch (error) {
			console.log(error);
		}
	}

	async function getMaterialUnidList() {
		try {
			handleOpenLoad();
			let u = await getMaterialsUnidadApi();
			setMaterialUnidadData(u);
			handleCloseLoad();
		} catch (error) {
			console.log(error);
		}
	}

	async function createMaterialUnidadAsoc() {
		try {
			const chkAsocMatUni = await getCheckActividadApiData();
			
			if (chkAsocMatUni) {
				
				await createMaterialsUnidadApi({
					descripcion: (tituloAsoc.trim()),
					material: materialAsoc,
					unidad: unidadAsoc,
				});

				await getMaterialUnidList();
				notiConfig.estilo = 'notiSuccessFilled';
				notiConfig.mensaje = 'Datos creados con éxito';
				setOpenNoti(true);
				clear();
				
			}else{
				notiConfig.estilo = 'notiDangerFilled';
				notiConfig.mensaje = "No es posible asociar una una nueva unidad a un material, ya que se encuentra en un recurso";
				setOpenNoti(true);
			}
		} catch (err) {
			let msj = await err.json();
			notiConfig.estilo = 'notiDangerFilled';
			notiConfig.titulo = '';
			notiConfig.mensaje = msj.violations[0].message;
			setOpenNoti(true);
			console.log(err);
		}
	}

	async function getCheckActividadApiData() {
		try {
			const resp = await getCheckActividadApi(idMaterialSelected);

			if (resp.status == 'true') {
				return true;
			} else {
				return false;
			}
		} catch (err) {
			let msj = await err.json();
			notiConfig.estilo = 'notiDangerFilled';
			notiConfig.mensaje = msj.violations[0].message;
			setOpenNoti(true);
		}
	}

	async function deleteMaterialUnidadAsoc(idMate, idUni) {
		try {
			let idMat = await deleteMaterialsUnidadApi(idMate, idUni);
			setTituloAsoc('');
			setIdMaterialSelected('');
			setIdUnidadSelected('');
			setIsCreate(true);

			if (idMat.status == 'true') {
				await getMaterialUnidList();
				closeModalConfirm();
				notiConfig.estilo = 'notiSuccessFilled';
				notiConfig.titulo = 'Eliminación exitosa';
				notiConfig.mensaje = idMat.message;
				setOpenNoti(true);
			} else {
				closeModalConfirm();
				notiConfig.estilo = 'notiDangerFilled';
				notiConfig.titulo = '';
				notiConfig.mensaje = idMat.message;
				setOpenNoti(true);
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function editMaterialUnidadAsoc() {
		try {
			let idMat = idMaterialSelected;
			let idUni = idUnidadSelected;
			let body = {};
			body.descripcion = tituloAsoc.trim();
			await editMaterialsUnidadApi(`material=${idMat};unidad=${idUni}`, body);
			await getMaterialUnidList();
			notiConfig.estilo = 'notiSuccessFilled';
			notiConfig.titulo = '';
			notiConfig.mensaje = 'Datos modificados con éxito';
			setOpenNoti(true);
			clear();
		} catch (error) {
			console.log(error);

			let msj = await error.json();

			if (error.status == 422) {
				notiConfig.titulo = '';
				notiConfig.estilo = 'notiDangerFilled';
				notiConfig.mensaje = msj.violations[0].message;
				setOpenNoti(true);
			}
		}
	}
}
