import React,{useState} from "react";
import { getAccessToken } from "../utils/auth";
import { LoginAuth } from "../components/login/LoginAuth";
import {
  Form,
	Button,
	Input,
	Toast,
	FormGroup,
	Label,
	Row,
	Container,
} from 'reactstrap';
import { ProfileInfo } from "../components/profileInfo/ProfileInfo";

export default function Profile() {

  if (getAccessToken()) {
    return (
      <>
        <ProfileInfo />
      </>
    );
  }


 
  
  return (
    <>
      <LoginAuth></LoginAuth>
    </>
  );
 
}