import React, { useState, useEffect } from 'react';
import './CrearEditarRecurso.scss';
import DataTable from 'react-data-table-component';
import { useNavigate, useParams } from 'react-router-dom';
import {
	IconButton,
	Button,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	ToolTip,
	Chip,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { InputCheckbox } from '../../components/Inputs/InputCheckbox';
import { InputObligatorio } from '../../components/Inputs/InputObligatorio';

import {
	getEnfasisApi,
	getQueryParams as getQueryParamsEnfasis,
} from '../../api/Enfasis/enfasis';
import {
	getTiposApi,
	getQueryParams as getQueryParamsTipos,
} from '../../api/Tipo/tipo';
import {
	getMaterialsApi,
	getMaterialsByIdApi,
} from '../../api/Material/material';
import {
	createActividadApi,
	getActividadByIdApi,
	editActividadApi,
	getCheckActividadApi,
} from '../../api/Actividad/actividad';
import {
	createActividadLimiteApi,
	editActividadLimiteApi,
	deleteActividadLimiteApi,
} from '../../api/Admin/ActividadLimite';
import Notificacion from '../../components/snackbar/Notificacion';
import { getInteraccionApi } from '../../api/Interaccion/interaccion';
import { getExpressionCheckIfValueIsNumber } from '../../utils/helpers';
import Loading from '../../components/loading/Loading';

export default function CrearEditarRecurso() {
	const navigate = useNavigate();

	const params = useParams();
	const id = params['*'];

	const [idActividad, setIdActividad] = useState(id);
	const [actividadEdit, setActividadEdit] = useState({});
	const [isEdit, setIsEdit] = useState(false);

	const [isAnulado, setIsAnulado] = useState(true);
	const [isDisable, setIsDisable] = useState(false);

	const [enfasisData, setEnfasisData] = useState();
	const [enfasisSelect, setEnfasisSelect] = useState();
	const [tipoData, setTipoData] = useState();
	const [tipoSelect, setTipoSelect] = useState();
	const [nombreRecurso, setNombreRecurso] = useState();
	const [obseRecurso, setObseRecurso] = useState();
	const [interaccionData, setInteraccionData] = useState();
	const [interaccionSelect, setInteraccionSelect] = useState();
	const [materialData, setMaterialData] = useState();
	const [materialSelect, setMaterialSelect] = useState();

	const [materialSelectEdit, setMaterialSelectEdit] = useState();

	const [proyectosData, setProyectoData] = useState([]);

	const [materialUnidadData, setMaterialUnidadData] = useState([]);
	const [materialUnidadDataEdit, setMaterialUnidadDataEdit] = useState([]);
	const [isDisableBtnCrear, setIsDisableBtnCrear] = useState(true);

	const [dataTest, setDataTest] = useState([
		{
			descripcion: 'Sin proyectos creados',
		},
	]);

	const regex = getExpressionCheckIfValueIsNumber();

	const [openNotification, setOpenNotification] = useState(false);
	const [isEditGlobal, setIsEditGlobal] = useState(true);

	const [configNotification, setNotification] = useState({
		titulo: '',
		mensaje: 'Datos creados con éxito',
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	});

	useEffect(() => {
		if (enfasisSelect) {
			getTiposData();
		}
	}, [enfasisSelect]);

	useEffect(() => {
		if (idActividad) {
			getActividadByIdApiData();
			setIsEdit(true);
			getCheckActividadApiData(idActividad);
		}
	}, [idActividad]);

	useEffect(() => {
		if (Object.keys(actividadEdit).length) {
			setEnfasisSelect(actividadEdit.enfasiId);
			setTipoSelect(actividadEdit.tipoId);
			setNombreRecurso(actividadEdit.descripcion);
			setObseRecurso(actividadEdit.observacion);
			setInteraccionSelect(actividadEdit.interaccionId);
			setMaterialSelect(actividadEdit.materialId);
			setIsAnulado(actividadEdit.anulado == 'S' ? false : true);
			_mutateDataEdit(actividadEdit);
		}
	}, [actividadEdit]);

	useEffect(() => {
		if (materialSelect && !isEdit && !isEditSelectMaterial) {
			getMaterialsByIdData();
		}
	}, [materialSelect]);

	const handleChangeEnfasis = event => {
		setEnfasisSelect(event.target.value);
	};
	const handleChangeTipo = event => {
		setTipoSelect(event.target.value);
	};
	const handleChangeRecurso = event => {
		setNombreRecurso(event.target.value);
	};
	const handleChangeObsRecurso = event => {
		setObseRecurso(event.target.value);
	};
	const handleChangeInteraccion = event => {
		setInteraccionSelect(event.target.value);
	};

	const handleChangeMaterial = event => {
		if (isEdit) {
			setIsEditSelectMaterial(true);
			setMaterialSelect(event.target.value);
		} else {
			setMaterialSelect(event.target.value);
		}
	};

	const [isEditSelectMaterial, setIsEditSelectMaterial] = useState(false);

	useEffect(() => {
		if (isEditSelectMaterial) {
			getMaterialsByIdData();
		}
	}, [materialSelect, isEditSelectMaterial]);

	const handleChangeMinimo = index => event => {
		if (regex.test(event.target.value)) {
			if (isEdit && !isEditSelectMaterial) {
				materialUnidadDataEdit[index].unidad.minimo = event.target.value;
				let copy = structuredClone(materialUnidadDataEdit);
				setMaterialUnidadDataEdit(copy);
			} else {
				materialUnidadData[index].unidad.minimo = event.target.value;
				let copy = structuredClone(materialUnidadData);
				setMaterialUnidadData(copy);
			}
		}
	};

	const handleChangeMaximo = index => event => {
		if (regex.test(event.target.value)) {
			if (isEdit && !isEditSelectMaterial) {
				materialUnidadDataEdit[index].unidad.maximo = event.target.value;
				let copy = structuredClone(materialUnidadDataEdit);
				setMaterialUnidadDataEdit(copy);
			} else {
				materialUnidadData[index].unidad.maximo = event.target.value;
				let copy = structuredClone(materialUnidadData);
				setMaterialUnidadData(copy);
			}
		}
	};

	const handleChangeRigeUnidad = index => event => {
		if (isEdit && !isEditSelectMaterial) {
			materialUnidadDataEdit[index].unidad.anulado =
				event.target.checked == true ? 'S' : 'N';
			let copy = structuredClone(materialUnidadDataEdit);
			setMaterialUnidadDataEdit(copy);
		} else {
			materialUnidadData[index].unidad.anulado =
				event.target.checked == true ? 'S' : 'N';
			let copy = structuredClone(materialUnidadData);
			setMaterialUnidadData(copy);
		}
	};

	const handleChangeAnulado = event => {
		let anulado = event.target.checked;
		setIsAnulado(anulado);
	};

	let columnsA = [
		{
			name: 'Material',
			cell: (obj, index, column) => {
				return (
					<div style={{ paddingTop: '13px' }}>
						<span
							className="material-icons"
							style={{ fontSize: '18px', position: 'absolute' }}
						>
							{obj?.parent?.icono}
						</span>
						<p style={{ marginLeft: '20px' }}>{obj?.parent?.descripcion}</p>
					</div>
				);
			},
		},
		{
			name: 'Unidad',
			cell: obj => {
				return (
					<div style={{ paddingTop: '13px' }}>
						<span
							className="material-icons"
							style={{ fontSize: '18px', position: 'absolute' }}
						>
							{obj.unidad.icono}
						</span>
						<p style={{ marginLeft: '20px' }}>{obj.unidad.descripcion}</p>
					</div>
				);
			},
		},
		{
			name: 'Título',
			cell: obj => {
				return obj?.descripcion;
			},
		},
		{
			name: 'Mínimo',
			cell: (obj, index) => {
				return (
					<TextField
						inputProps={{
							maxLength: 6,
							'data-unidad_id': `${obj.unidad.id}`,
							'data-material_id': `${
								materialSelect
							}`,
						}}
						onChange={handleChangeMinimo(index)}
						id="minimo"
						value={obj.unidad?.minimo ?? ''}
						// disabled={!isEditGlobal ? true : false}
					/>
				);
			},
		},
		{
			name: 'Máximo',
			cell: (obj, index) => {
				return (
					<TextField
						className="inputRecursoUni"
						inputProps={{
							maxLength: 6,
							'data-unidad_id': `${obj.unidad.id}`,
							'data-material_id': `${
								materialSelect
							}`,
						}}
						onChange={handleChangeMaximo(index)}
						id="maximo"
						value={obj.unidad?.maximo ?? ''}
						// disabled={!isEditGlobal ? true : false}
					/>
				);
			},
		},
		{
			name: 'Validar límites',
			align: 'center',
			cell: (obj, index) => {
				return (
					<input
						type="checkbox"
						className="toggle"
						style={{ marginLeft: '35px' }}
						onChange={handleChangeRigeUnidad(index)}
						checked={obj.unidad.anulado == 'S' ? true : false}
						disabled={!isEditGlobal ? true : false}
					></input>
				);
			},
			sortable: true,
			maxWidth: '155px',
			wrap: true,
		},
	];

	const emptyColumn = [
		{
			name: '',
			cell: obj => {
				return <span>Sin material cargado</span>;
			},
			left: true,
			ignoreRowClick: true,
			allowOverflow: true,
		},
	];
	const _checkLimitesHasValue = () => {
		if (materialUnidadData.length > 0) {
			let result = materialUnidadData.filter(el => {
				if (
					el.unidad.hasOwnProperty('minimo') &&
					el.unidad.hasOwnProperty('maximo')
				) {
					return el.unidad?.minimo == '' || el.unidad?.maximo == '';
				} else {
					return [1];
				}
			});
			return result.length == 0 ? true : false;
		}
	};

	useEffect(() => {
		getArrayEnfasis();
		// getTiposData();
		getArrayInteraccion();
		getMaterialesData();
	}, []);

	useEffect(() => {
		// _checkLimitesHasValue()
		if (
			nombreRecurso?.length > 0 &&
			enfasisSelect != '' &&
			tipoSelect != '' &&
			materialSelect != '' &&
			interaccionSelect != ''
		) {
			setIsDisableBtnCrear(false);
		} else {
			setIsDisableBtnCrear(true);
		}
	}, [
		enfasisSelect,
		tipoSelect,
		materialSelect,
		nombreRecurso,
		interaccionSelect,
		materialUnidadData,
	]);

	const getDisabledValue = () => {
		// return !isEditGlobal ? true : isDisableBtnCrear ? true : false;
		if (isEdit) {
			if (isEditGlobal) {
				if (isEditSelectMaterial && isDisableBtnCrear) {
					return true;
				}
				return false;
			}
		} else {
			if (isDisableBtnCrear) {
				return true;
			} else {
				return false;
			}
		}
	};

	const goToRecursos = () => {
		setTimeout(() => {
			navigate('/recursos', { replace: true });
		}, 1500);
	};


	const [openLoading, setOpenLoading] = React.useState(false);

	const handleCloseLoading = () => {
		setOpenLoading(false);
	};

	const handleOpenLoading = () => {
		setOpenLoading(true);
	};

	return (
		<>

			<Loading
				bg="bgLoadingWhiteTrans"
				open={openLoading}
				tiempoMensaje={3000}
			/>
			<div className="contenedor">
				<div className="textoContenido">
					<div className="seccionVolver">
						<IconButton href="/recursos">
							<ArrowBackIcon />
						</IconButton>
						<h1 className="tituloContenido">
							{isEdit ? 'Editar ' : 'Nuevo '}recurso
						</h1>
					</div>
					<p className="parrafoContenido" style={{ marginLeft: '50px' }}>
						{isEdit ? 'Edita' : 'Crea'} los recursos para los planes de
						estudios. Los mismos permitiran establecer los límites de cada
						material.
					</p>
				</div>

				<div className="textoContenido">
					<h2 className="subtituloContenido">Configurá los datos</h2>
					<div className="aa">
						<div className="seccionRecurso">
							<FormControl className="selectEnfasisRecurso">
								<InputLabel id="demo-simple-select-label">
									Énfasis
									<InputObligatorio />
								</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={enfasisSelect ?? ''}
									label="Énfasis"
									disabled={!isEditGlobal ? true : false}
									onChange={handleChangeEnfasis}
								>
									{enfasisData?.map((item, index) => (
										<MenuItem key={index} value={item.id}>
											{item.descripcion}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl className="selectTipoRecurso">
								<InputLabel id="demo-simple-select-label">
									Tipo de recurso
									<InputObligatorio />
								</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={tipoSelect ?? ''}
									label="Tipo de recurso"
									onChange={handleChangeTipo}
									disabled={!isEditGlobal ? true : false}
								>
									{tipoData?.map((item, index) => (
										<MenuItem key={index} value={item.id}>
											{item.descripcion}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<div className="inputDeshacer">
								<TextField
									className="inputRecurso"
									id="descripcion"
									label={
										<span style={{ fontFamily: 'Roboto' }}>
											Título <InputObligatorio />
										</span>
									}
									disabled={!isEditGlobal ? true : false}
									value={nombreRecurso ?? ''}
									onChange={handleChangeRecurso}
								/>
							</div>
						</div>
						<div className="seccionRecurso">
							<TextField
								fullWidth
								id="outlined-multiline-static"
								label="Información del recurso"
								multiline
								rows={3}
								value={obseRecurso ?? ''}
								onChange={handleChangeObsRecurso}
								// disabled={!isEditGlobal ? true : false}
							/>
						</div>
						<div className="seccionRecurso">
							<FormControl className="selectEnfasisRecurso">
								<InputLabel id="demo-simple-select-label">
									Interacción
									<InputObligatorio />
								</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={interaccionSelect ?? ''}
									label="Interacción"
									onChange={handleChangeInteraccion}
									disabled={!isEditGlobal ? true : false}
								>
									{interaccionData?.map((item, index) => (
										<MenuItem key={index} value={item.id}>
											{item.descripcion}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl className="selectTipoRecurso">
								<InputLabel id="demo-simple-select-label">
									Material
									<InputObligatorio />
								</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={
										// !isEdit ? materialSelect || '' : materialSelectEdit || ''
										materialSelect || ''
									}
									label="Material"
									disabled={!isEditGlobal ? true : false}
									onChange={handleChangeMaterial}
								>
									{materialData?.map((item, index) => (
										<MenuItem key={index} value={item.id}>
											{item.descripcion}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<div className="estadoRecurso">
								<InputCheckbox
									handleChangeAnulado={handleChangeAnulado}
									isAnulado={isAnulado}
									// isDisable={!isEditGlobal ? true : false}
								/>
							</div>
							<div className="estadoRecurso"></div>
						</div>
						<div className="seccionBoton"></div>
					</div>
				</div>
				<h2 className="subtituloContenido listado">
					Establecé los límites de cada unidad
				</h2>

				<DataTable
					columns={columnsA}
					data={
						isEdit && !isEditSelectMaterial
							? materialUnidadDataEdit
							: materialUnidadData ?? ''
					}
					noDataComponent={
						<DataTable
							columns={emptyColumn}
							className="dataTableContainer"
							data={dataTest ? dataTest : ''}
							direction="auto"
							fixedHeaderScrollHeight="300px"
							responsive
							subHeaderAlign="right"
							subHeaderWrap
						/>
					}
				/>

				<div className="seccionBotonesRecurso">
					<Chip
						className="chipGuardar"
						label={!isEdit ? 'Crear' : 'Guardar'}
						clickable
						disabled={getDisabledValue()}
						onClick={!isEdit ? createRecursoData : editActividadApiData}
						// onClick={ editActividadApiData}
						icon={<AddIcon style={{ color: '#003087' }} />}
					/>
					<Chip
						className="chipCancelar"
						variant="outlined"
						clickable
						label="Cancelar"
						onClick={goToRecursos}
						icon={<CancelIcon style={{ color: '#003087' }} />}
					/>
				</div>
			</div>

			<Notificacion
				open={openNotification}
				setState={setOpenNotification}
				config={configNotification}
			/>
		</>
	);

	async function getArrayEnfasis() {
		let queryParamsDefault = getQueryParamsEnfasis();

		let { anulado } = queryParamsDefault.filtersDefault.properties;
		let value = 'N';

		const query = { [anulado]: value };
		queryParamsDefault.filters.properties.push(query);
		try {
			let enfasis = await getEnfasisApi(queryParamsDefault);
			setEnfasisData(enfasis);
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function getArrayInteraccion() {
		try {
			let contactos = await getInteraccionApi();
			setInteraccionData(contactos);
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function getMaterialesData() {
		try {
			const resp = await getMaterialsApi();
			setMaterialData(resp);
		} catch (error) {
			console.log(await error.json());
		}
	}

	async function createRecursoData() {
		try {
			handleOpenLoading();
			let anulado = isAnulado ? 'N' : 'S';

			const data = {
				enfasi: enfasisSelect,
				tipo: tipoSelect,
				material: materialSelect,
				descripcion: nombreRecurso,
				interaccion: interaccionSelect,
				observacion: obseRecurso ?? '',
				anulado: anulado,
			};

			const resp = await createActividadApi(data);
			createActividadLimites(resp.id);
			handleCloseLoading();
		} catch (error) {
			let msj = await error.json();

			if (error.status == 422) {
				configNotification.estilo = 'notiDangerFilled';
				configNotification.mensaje = msj.violations[0].message;
				setNotification(configNotification);
				return setOpenNotification(true);
			}
		}
	}

	async function createActividadLimites(id) {
		try {
			for await (const element of materialUnidadData) {
				let body = {};

				body.planifActId = +id;
				body.planifMatId = +materialSelect;
				body.planifUniId = +element.unidad.id;
				body.minimo = +element.unidad.minimo;
				body.maximo = +element.unidad.maximo;
				body.rige = element.unidad.anulado;

				const response = await createActividadLimiteApi(body);
				configNotification.estilo = 'notiSuccessFilled';
				configNotification.mensaje = 'Datos creados con éxito';
				setNotification(configNotification);
				setOpenNotification(true);

				setTimeout(() => {
					navigate('/recursos', { replace: true });
				}, 3000);
			}
		} catch (error) {
			let msj = await error.json();

			if (error.status == 422) {
				configNotification.estilo = 'notiDangerFilled';
				setNotification(configNotification);
				configNotification.mensaje = msj.violations[0].message;
				setOpenNotification(true);
			}
		}
	}

	function _mutateDataEdit(data) {
		if (data) {
			let unidad = {
				id: data.material.id,
				descripcion: data.material.descripcion,
				icono: data.material.icono,
				color: data.material.color,
				anulado: data.material.anulado,
				anuladoFec: data.material.anuladoFec,
				codUsuAnula: data.material.codUsuAnula,
			};

			data.material.unidads[0].parent = unidad;

			data.material.unidads.forEach((un, index) => {
				data.limites.forEach(li => {
					if (un.unidad.id == li.planifUniId) {
						data.material.unidads[index].unidad = {
							...un.unidad,
							minimo: li.minimo,
							maximo: li.maximo,
							anulado: li.rige,
						};
					}
				});
			});

			// console.log(data.material.unidads);
			setMaterialUnidadDataEdit(data.material.unidads);
		}
	}

	function _mutateData(data) {
		let unidad = {
			id: data.id,
			descripcion: data.descripcion,
			icono: data.icono,
			color: data.color,
			anulado: data.anulado,
			anuladoFec: data.anuladoFec,
			codUsuAnula: data.codUsuAnula,
		};

		data.unidads[0].parent = unidad;
		let copy = structuredClone(data.unidads);
		setMaterialUnidadData(copy);
	}

	async function getMaterialsByIdData() {
		try {
			const resp = await getMaterialsByIdApi(materialSelect);
			_mutateData(resp);
		} catch (error) {
			console.log(await error.json());
		}
	}

	/*Edit actividad*/
	async function getActividadByIdApiData() {
		const response = await getActividadByIdApi(idActividad);
		setActividadEdit(response);
	}

	async function editActividadApiData() {
		handleOpenLoading();
		let anulado = isAnulado ? 'N' : 'S';

		const data = {
			enfasi: enfasisSelect,
			tipo: tipoSelect,
			material: materialSelect,
			descripcion: nombreRecurso,
			interaccion: interaccionSelect,
			observacion: obseRecurso ?? '',
			anulado: anulado,
		};

		const response = await editActividadApi(id, data);
		editActividadLimiteApiData();
		handleCloseLoading();
	}

	async function editActividadLimiteApiData() {
		try {
			const arrayData =
				isEdit && !isEditSelectMaterial
					? materialUnidadDataEdit
					: materialUnidadData;

			if (isEditSelectMaterial) {
				await deleteActividadLimiteApiData(materialUnidadDataEdit);
				createActividadLimites(idActividad);
			} else {
				for await (const element of arrayData) {
					let body = {};
					body.minimo = +element.unidad.minimo;
					body.maximo = +element.unidad.maximo;
					body.rige = element.unidad.anulado;

					const path = `planifActId=${id};planifMatId=${materialSelect};planifUniId=${element.unidad.id}`;
					const response = await editActividadLimiteApi(path, body);

					configNotification.estilo = 'notiSuccessFilled';
					configNotification.mensaje = 'Datos modificados con éxito';
					setNotification(configNotification);
					setOpenNotification(true);

					setTimeout(() => {
						navigate('/recursos', { replace: true });
					}, 3000);
				}
			}
		} catch (error) {
			
			let msj = await error.json();

			if (error.status == 422) {
				configNotification.estilo = 'notiDangerFilled';
				setNotification(configNotification);
				configNotification.mensaje = msj.violations[0].message;
				setOpenNotification(true);
			}
		}
	}

	async function deleteActividadLimiteApiData() {
		try {
			let planifMatId = materialUnidadDataEdit[0].parent.id;
			for await (const element of materialUnidadDataEdit) {
				const path = `planifActId=${id};planifMatId=${planifMatId};planifUniId=${element.unidad.id}`;
				const responseDeleteActividadLimite = await deleteActividadLimiteApi(
					path
				);
			}
		} catch (error) {
			let msj = await error.json();

			if (error.status == 422) {
				configNotification.estilo = 'notiDangerFilled';
				setNotification(configNotification);
				configNotification.mensaje = msj.violations[0].message;
				setOpenNotification(true);
			}
		}
	}
	async function getCheckActividadApiData() {
		try {
			const responseCheck = await getCheckActividadApi(idActividad);
			setIsEditGlobal(responseCheck.isEdit);
		} catch (error) {
			let msj = await error.json();

			if (error.status == 422) {
				configNotification.estilo = 'notiDangerFilled';
				setNotification(configNotification);
				configNotification.mensaje = msj.violations[0].message;
				setOpenNotification(true);
			}
		}
	}

	async function getTiposData() {
		try {
			let queryParamsDefault = getQueryParamsTipos();

			let { anulado } = queryParamsDefault.filtersDefault.properties;
			let value = 'N';

			let { enfasi } = queryParamsDefault.filtersDefault.properties;
			let enfasiId = enfasisSelect;

			const query = { [anulado]: value, [enfasi]: enfasiId };
			queryParamsDefault.filters.properties.push(query);

			const resp = await getTiposApi(queryParamsDefault);
			setTipoData(resp);
		} catch (error) {
			console.log(await error.json());
		}
	}
}
