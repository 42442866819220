import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import './menuDrawer.scss';
import Navegador from '../navegador/Navegador';
import MailIcon from '@mui/icons-material/Mail';
import {
	Chip,
	Box,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Divider,
} from '@mui/material';

export default function MenuDrawer(props) {
	const [state, setState] = React.useState({
		left: false,
	});

	const { color } = props;

	const toggleDrawer = (anchor, open) => event => {
		if (event.key === 'Tab' || event.key === 'Shift') {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	return (
		<div>
			<IconButton
				aria-label="delete"
				onClick={toggleDrawer('left', true)}
				style={{
					color: '#000',
					fontSize: '18px',
					marginRight: '0 20px',
				}}
			>
				<MenuIcon style={{ color: color }} />
			</IconButton>
			<Drawer
				anchor="left"
				open={state['left']}
				onClose={toggleDrawer('left', false)}
				className="MenuDrawer"
				BackdropProps={{ invisible: true }}
				disableScrollLock={true}
			>
				<Navegador />
			</Drawer>
		</div>
	);
}
