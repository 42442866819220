import './listadoActividades.scss';
import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { getProyectoByIdApi } from '../../api/Proyecto/proyecto';
import {
	getEnfasisApi,
	getQueryParams as getQueryParamsEnfasis,
} from '../../api/Enfasis/enfasis';
import { NotificationMui as DeleteNotificationMui } from '../../components/notification/NotificactionMui';
import { useNotificationMui } from '../../hooks/useNotificacionMui';
import { deleteProyectoModuloApi } from '../../api/Proyecto/proyectoModulo';
import { deleteProyectoActividadApi } from '../../api/Proyecto/proyectoActividad';
import { deleteProyectoApi } from '../../api/Proyecto/proyecto';
import ListIcon from '@mui/icons-material/List';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AccordionAct from '../../components/accordion/AccordionAct';
import AccordionContAct from '../../components/accordion/AccordionContAct';
import AccordionEnf from '../../components/accordion/AccordionEnfasis';
import AccordionMod from '../../components/accordion/AccordionMod';
import Container from '@mui/material/Container';
import AccordionProy from '../../components/accordion/AccordionProy';
import CrearModuloAuto from '../../components/crearModuloAuto/CrearModuloAuto';
import InfoProyectoDeta from '../../components/InfoProyectoDeta/InfoProyectoDeta';
import { editProyectoModuloApi } from '../../api/Proyecto/proyectoModulo';
import ModalHacerCopia from '../../components/modal/modalHacerCopia/ModalHacerCopia';
import ModalEditarProyecto from '../../components/modal/modalEditarProyecto/ModalEditarProyecto';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { getCarrByIdApi } from '../../api/Carrera/carr';
import ModalCrearActividad from '../../components/newModalActividad/ModalCrearActividad';
import Notificacion from '../../components/snackbar/Notificacion';
import WarningIcon from '@mui/icons-material/Warning';
import ModalCustomConfirmacion from '../../components/modal/modalCustomConfirmacion/modalCustomConfirmacion';
import ModalEditarActividad from '../../components/modal/modalEditarActividad/ModalEditarActividad';
import Loading from '../../components/loading/Loading';

export default function ListadoActividades() {
	const navigate = useNavigate();
	const params = useParams();
	const { id } = params;

	const [actividades, setActividades] = useState([]);

	const [
		isOpenNotification,
		openNotificacion,
		closeNotification,
		isTItle,
		setTitleNotification,
		isContent,
		setContentNotification,
	] = useNotificationMui(false);

	const [isOpenModalCrearActividad, setIsOpenModalCrearActividad] =
		useState(false);

	const [idDeleteModulo, setIdDeleteModulo] = useState(-1);
	const [idDeleteActividad, setIdDeleteActividad] = useState(-1);
	const [idDeleteProyecto, setIdDeleteProyecto] = useState(-1);

	const [isMessageNotification, setIsMessageNotification] = useState(false);

	const [modulo, setModulo] = useState({});
	const [isEditModulo, setIsEditModulo] = useState(false);

	const [editActividad, setEditActividad] = useState(0);
	const [detalles, setDetalles] = useState([]);
	const [modulos, setModulos] = useState([]);
	const [proyectoData, setProyectoData] = useState({});
	const [descripcion, setDescripcion] = useState('');
	const [detalleMaterials, setDetalleMaterials] = useState([]);
	const [actividadesDetalleMaterials, setActividadesDetalleMaterials] =
		useState([]);
	const [actividadesDetalleEnfasis, setActividadesDetalleEnfasis] = useState(
		[]
	);
	const [actividadesDetalle, setActividadesDetalle] = useState([]);

	const [enfasisId, setEnfasisId] = useState(false);
	const [moduloId, setModuloId] = useState(false);
	const [enfasis, setEnfasis] = useState([]);

	const [openModalEditarActividad, setOpenModalEditarActividad] =
		useState(false);

	const handleChangeModuloIdParent = moduloid => {
		setModuloId(moduloid);
	};

	const handleOnClickEditActividad = event => {
		let actividad_id = event.target.dataset.actividad_id;
		handleChangeEditActividad(actividad_id);
		setOpenModalEditarActividad(!isOpenModalCrearActividad);
	};

	const handleChangeEnfasisId = enfasisid => {
		setEnfasisId(enfasisid);
	};

	const handleChangeEditActividad = actividad_id => {
		setEditActividad(actividad_id);
	};

	const [openModalEditarProyecto, setOpenModalEditarProyecto] = useState(false);

	const handleOpenModalEditarProyecto = () => {
		setOpenModalEditarProyecto(true);
	};

	const [observacion, setObservacion] = useState('');
	const [hsCurTotal, setHsCurTotal] = useState('');
	const [asignatura, setAsignatura] = useState(null);
	const [unidad, setUnidad] = useState(null);
	const [carrera, setCarrera] = useState(null);
	const [carreraId, setCarreraId] = useState(null);

	useEffect(() => {
		getProyectosById();
		getEnfasisData();
		getCarrById();
	}, []);

	useEffect(() => {
		getCarrById();
	}, [carreraId]);

	const showModalCrearActividad = (enfasis_id = null, modulo_id = null) => {
		if (typeof modulo_id == 'number') {
			setModuloId(modulo_id);
		}

		if (typeof enfasis_id == 'number') {
			setEnfasisId(enfasis_id);
		}

		setIsOpenModalCrearActividad(!isOpenModalCrearActividad);
	};

	const [openModalConfirmActividad, setOpenModalConfirmActividad] =
		useState(false);

	const [mensajeEliminacion, setMensajeEliminacion] = useState('');
	const [confirmacion, setConfirmacion] = useState();

	const configuracionActividad = {
		titulo: 'Eliminar',
		descripcion: mensajeEliminacion,
	};

	const showModalConfirmDelete = event => {
		setOpenModalConfirmActividad(true);
		let id = event.target.getAttribute('data-actividad_id');
		let actiNomb = event.target.getAttribute('data-descri_doc');

		let idMod = event.target.getAttribute('data-modulo_id');
		let moduNomb = event.target.getAttribute('data-nombre_modulo');
		let idProy = event.target.getAttribute('data-proyecto_id');

		if (id != null) {
			setIdDeleteActividad(id);
			setMensajeEliminacion(
				'Si eliminas "' + actiNomb + '" se perderan todos los datos cargados.'
			);
		} else {
			setIdDeleteActividad('');
		}

		if (idMod != null) {
			setIdDeleteModulo(idMod);
			setMensajeEliminacion(
				'Si eliminas "' + moduNomb + '" se perderan todos los datos cargados.'
			);
		} else {
			setIdDeleteModulo('');
		}

		if (idProy != null) {
			setIdDeleteProyecto(idProy);
			setMensajeEliminacion(
				'Si eliminas "' +
					descripcion +
					'" se perderan todos los datos cargados.'
			);
		} else {
			setIdDeleteProyecto('');
		}
	};

	const closeModalConfirmActividad = val => {
		setOpenModalConfirmActividad(false);
		setConfirmacion(val);
	};

	const [openModalHacerCopia, setOpenModalHacerCopia] = useState(false);

	const handleOpenModalHacerCopia = () => {
		setOpenModalHacerCopia(true);
	};

	const handleChangeModuloDescripcion = e => {
		let text = e.currentTarget.textContent;

		if (text != modulo.descripcion) {
			console.log('Entro');
			setIsEditModulo(true);

			let modulo = modulos.filter(el => {
				return e.currentTarget.id == el.id;
			})[0];

			modulo = { ...modulo, ['descripcion']: text };

			setModulo(modulo);
		}

		e.currentTarget.scrollLeft = 0;
	};

	const [OpenNotiEditMod, setOpenNotiEditMod] = useState(false);

	const notiConfigNotiEditMod = {
		titulo: '',
		mensaje: 'Datos modificados con éxito',
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const iniNotiErrorMod = {
		titulo: '',
		mensaje: 'Warning',
		estilo: 'notiDangerFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const [notiConfigNotiErrorMod, setNotiConfigNotiErrorMod] =
		useState(iniNotiErrorMod);
	const [OpenNotiErrorMod, setOpenNotiErrorMod] = useState(false);

	useEffect(() => {
		editModuloData();
	}, [isEditModulo]);

	const [expandedAct, setExpandedAct] = React.useState(false);

	const handleChangeExpandedAct = panel => (event, isExpanded) => {
		setExpandedAct(isExpanded ? panel : false);
	};

	const [expandedEnf, setExpandedEnf] = React.useState(false);

	const handleChangeExpandedEnf = panel => (event, isExpanded) => {
		setExpandedEnf(isExpanded ? panel : false);
	};

	const [expandedMod, setExpandedMod] = React.useState(false);

	const handleChangeExpandedMod = panel => (event, isExpanded) => {
		setExpandedMod(isExpanded ? panel : false);
	};

	const [openLoading, setOpenLoading] = React.useState(false);

	const handleCloseLoading = () => {
		setOpenLoading(false);
	};

	const handleOpenLoading = () => {
		setOpenLoading(true);
	};

	return (
		<>
			<Container className="contenedor">
				<ModalCustomConfirmacion
					openModalConfirm={openModalConfirmActividad}
					onClose={closeModalConfirmActividad}
					configs={configuracionActividad}
					icon={
						<WarningIcon
							className="confirmIcon"
							style={{ fontSize: '20px', color: '#626262' }}
						/>
					}
					content=""
					contentButton={
						<div style={{ marginTop: '-40px' }}>
							<Button
								className="buttonEliminarModal"
								onClick={
									idDeleteModulo > 0
										? deleteModuloData
										: idDeleteActividad > 0
										? deleteActividadData
										: deleteProyectoData
								}
							>
								Continuar
							</Button>
							<Button
								outlined="true"
								className="buttonEliminarModalOutline"
								onClick={closeModalConfirmActividad}
							>
								Cancelar
							</Button>
						</div>
					}
				/>
				<div className="textoContenidoTablero">
					<div className="seccionVolver">
						<IconButton href="/proyectos">
							<ArrowBackIcon />
						</IconButton>
						<h1 className="tituloContenido">Nuevo proyecto</h1>
					</div>
					<div className="tituloBotones">
						<Link to={`../temasProyecto/${proyectoData.id}`}>
							<IconButton>
								<AccountTreeIcon />
							</IconButton>
						</Link>
						<Link to={`../tableroProyectos/${proyectoData.id}`}>
							<IconButton style={{ marginLeft: '4px' }}>
								<ViewColumnIcon />
							</IconButton>
						</Link>
						{/* <Link>
							<IconButton>
								<ListIcon style={{ color: '#003087' }} />
							</IconButton>
						</Link> */}
					</div>
				</div>

				<DeleteNotificationMui
					isOpen={isOpenNotification}
					title={isTItle}
					content={isContent}
				>
					{!isMessageNotification && (
						<>
							<Button onClick={closeNotification}>Cancelar</Button>
							<Button
								onClick={
									idDeleteModulo > 0
										? deleteModuloData
										: idDeleteActividad > 0
										? deleteActividadData
										: deleteProyectoData
								}
								autoFocus
							>
								Eliminar
							</Button>
						</>
					)}
				</DeleteNotificationMui>

				<ModalCrearActividad
					isOpen={isOpenModalCrearActividad}
					proyecto={proyectoData}
					moduloParent={moduloId}
					enfasisParent={enfasisId}
					showModalCrearActividad={showModalCrearActividad}
					setIsOpenModalCrearActividad={setIsOpenModalCrearActividad}
					getProyectosById={getProyectosById}
					handleChangeEnfasisId={handleChangeEnfasisId}
					handleChangeModuloIdParent={handleChangeModuloIdParent}
				/>

				<ModalHacerCopia
					open={openModalHacerCopia}
					setOpen={setOpenModalHacerCopia}
					proyectoId={id}
				/>

				<ModalEditarProyecto
					open={openModalEditarProyecto}
					setOpen={setOpenModalEditarProyecto}
					getProyectosById={getProyectosById}
				/>

				<ModalEditarActividad
					actividades={actividades}
					proyecto={proyectoData}
					enfasisId={enfasisId}
					modulos={modulos}
					openModalEditarActividad={openModalEditarActividad}
					idEditActividad={editActividad}
					getProyectosById={getProyectosById}
					handleOpenModalEditarActividad={handleOnClickEditActividad}
					setOpenModalEditarActividad={setOpenModalEditarActividad}
					handleChangeEditActividad={handleChangeEditActividad}
				/>

				<div className="">
					<AccordionProy
						proyectoData={proyectoData}
						showModalCrearActividad={showModalCrearActividad}
						descripcion={descripcion}
						setDescripcion={setDescripcion}
						detalles={detalles}
						detalleMaterials={detalleMaterials}
						handleDeleteProyecto={showModalConfirmDelete}
						getProyectosById={getProyectosById}
						setProyectoData={setProyectoData}
						handleOpenModalHacerCopia={handleOpenModalHacerCopia}
						handleOpenModalEditarProyecto={handleOpenModalEditarProyecto}
					/>
					<InfoProyectoDeta
						observacion={observacion}
						hsCurTotal={hsCurTotal}
						asignatura={asignatura}
						detalles={detalles}
						unidad={unidad}
						carrera={carrera}
					/>
					<AccordionContAct
						detalleMaterials={actividadesDetalleMaterials}
						detalle={actividadesDetalle}
						actividades={actividades}
						panel={128}
						expanded={expandedMod}
						handleChange={handleChangeExpandedMod}
						content={enfasis.map((el, index) => (
							<AccordionEnf
								key={index}
								descriEnfasis={el.descripcion}
								color={el.color != null ? el.color : '#a0a0a0'}
								detalleEnfasis={actividadesDetalleEnfasis}
								enfasiId={el.id}
								tiempo={actividadesDetalleEnfasis.tiempo}
								showModalCrearActividad={showModalCrearActividad}
								handleDeleteActividad={showModalConfirmDelete}
								panel={el.id}
								expanded={expandedEnf}
								handleChange={handleChangeExpandedEnf}
								content={actividades.map((item, index) => (
									<React.Fragment key={index}>
										<>
											{item.planifEnfId == el.id ? (
												<AccordionAct
													id={item.id}
													handleOnClickEditActividad={
														handleOnClickEditActividad
													}
													handleDeleteActividad={showModalConfirmDelete}
													descriDocente={item.descriDocente}
													proposito={item.proposito}
													nombreActi={item.nombreActividad}
													dificultad={item.dificultad}
													descriTipo={item.descriTipo}
													detalles={item.detalles}
													material={item.material}
													iconoProposito={item.iconoProposito}
													iconoDificultad={item.iconoDificultad}
													iconoMaterial={item.iconoMaterial}
													iconoTipo={item.iconoTipo}
													iconoEnfasis={item.iconoEnfasis}
													tiempoActividad={item.tiempoActividad}
													panel={item.id}
													expanded={expandedAct}
													handleChange={handleChangeExpandedAct}
												/>
											) : (
												''
											)}
										</>
									</React.Fragment>
								))}
							/>
						))}
					/>

					{modulos.map((item, index) => (
						<React.Fragment key={index}>
							<div key={index}>
								<AccordionMod
									showModalCrearActividad={showModalCrearActividad}
									descripcion={item.descripcion}
									detalles={item.detalles?.map((item, index) => (
										<span key={generateKeyRandom()}>{item.tiempoModulos}</span>
									))}
									detalleMaterials={item.detalleMaterials}
									moduloId={item.id}
									fnEdit={handleChangeModuloDescripcion}
									handleDeleteModulo={showModalConfirmDelete}
									panel={item.id}
									expanded={expandedMod}
									handleChange={handleChangeExpandedMod}
									content={enfasis.map((el, index) => (
										<AccordionEnf
											key={index}
											descriEnfasis={el.descripcion}
											showModalCrearActividad={showModalCrearActividad}
											color={el.color != null ? el.color : '#a0a0a0'}
											detalleEnfasis={item.detalleEnfasis}
											enfasiId={el.id}
											moduloId={item.id}
											panel={el.id}
											expanded={expandedEnf}
											handleChange={handleChangeExpandedEnf}
											content={item.actividades.map((item, index) => (
												<React.Fragment key={index}>
													<>
														{item.planifEnfId == el.id ? (
															<AccordionAct
																id={item.id}
																handleOnClickEditActividad={
																	handleOnClickEditActividad
																}
																handleDeleteActividad={showModalConfirmDelete}
																descriDocente={item.descriDocente}
																proposito={item.proposito}
																dificultad={item.dificultad}
																descriTipo={item.descriTipo}
																nombreActi={item.nombreActividad}
																detalles={item.detalles}
																material={item.material}
																iconoProposito={item.iconoProposito}
																iconoDificultad={item.iconoDificultad}
																iconoMaterial={item.iconoMaterial}
																iconoTipo={item.iconoTipo}
																iconoEnfasis={item.iconoEnfasis}
																tiempoActividad={item.tiempoActividad}
																panel={item.id}
																expanded={expandedAct}
																handleChange={handleChangeExpandedAct}
															/>
														) : (
															''
														)}
													</>
												</React.Fragment>
											))}
										/>
									))}
								/>
							</div>
						</React.Fragment>
					))}

					<CrearModuloAuto
						getProyectosById={getProyectosById}
						proyecto={proyectoData}
						typeButton="chip"
					/>
				</div>

				<Notificacion
					open={OpenNotiEditMod}
					setState={setOpenNotiEditMod}
					config={notiConfigNotiEditMod}
				/>

				<Notificacion
					open={OpenNotiErrorMod}
					setState={setOpenNotiErrorMod}
					config={notiConfigNotiErrorMod}
				/>
			</Container>

			<Loading
				bg="bgLoadingWhiteTrans"
				open={openLoading}
				tiempoMensaje={3000}
			/>
		</>
	);

	async function getProyectosById() {
		try {
			handleOpenLoading();
			let proyecto = await getProyectoByIdApi(id);

			setActividades(proyecto.actividades);
			setModulos(proyecto.modulos);
			setDetalles(proyecto.detalles);
			setDetalleMaterials(proyecto.detalleMaterials);
			setActividadesDetalleMaterials(proyecto.actividadesDetalleMaterials);
			setActividadesDetalleEnfasis(proyecto.actividadesDetalleEnfasis);
			setActividadesDetalle(proyecto.actividadesDetalle);
			setDescripcion(proyecto.descripcion);
			setObservacion(proyecto.observacion);
			setHsCurTotal(proyecto.hsCurTotal);
			setAsignatura(proyecto.asignatura);
			setUnidad(proyecto.unidad);
			setCarreraId(proyecto.carreraId);
			setProyectoData(proyecto);
			handleCloseLoading();
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	function generateKeyRandom() {
		return (Math.random() + 1).toString(36).substring(7);
	}

	async function getEnfasisData() {
		let queryParamsDefault = getQueryParamsEnfasis();

		let { anulado } = queryParamsDefault.filtersDefault.properties;
		let value = 'N';

		const query = { [anulado]: value };
		queryParamsDefault.filters.properties.push(query);

		try {
			const resp = await getEnfasisApi(queryParamsDefault);
			setEnfasis(resp);
		} catch (error) {
			console.log(await error.json());
		}
	}

	async function deleteModuloData() {
		try {
			let deleteModulo = await deleteProyectoModuloApi(idDeleteModulo);
			console.log(
				'🚀 ~ file: TableroProyectos.js:506 ~ deleteModuloData ~ deleteModulo',
				deleteModulo
			);

			if (deleteModulo.status) {
				getProyectosById();
				closeNotification();
			} else {
				setIsMessageNotification(true);
				setTitleNotification('Informacion');
				setContentNotification(deleteModulo.message);
				openNotificacion({ delay: 3000 });
			}

			setIdDeleteModulo(-1);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);
			console.log(await error?.json());
		}
	}

	async function deleteActividadData() {
		try {
			let deleteActividad = await deleteProyectoActividadApi(idDeleteActividad);

			if (deleteActividad?.status || deleteActividad) {
				getProyectosById();
				closeNotification();
			} else {
				setIsMessageNotification(true);
				setTitleNotification('Informacion');
				setContentNotification(deleteActividad.message);
				openNotificacion({ delay: 3000 });
			}

			setIdDeleteActividad(-1);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);
			console.log(await error?.json());
		}
	}

	async function deleteProyectoData() {
		try {
			let deleteProyecto = await deleteProyectoApi(idDeleteProyecto);

			if (deleteProyecto.status) {
				setIsMessageNotification(true);
				setTitleNotification('Informacion');
				setContentNotification(deleteProyecto.message);
				openNotificacion({ delay: 3000 });
				setTimeout(() => {
					navigate('/proyectos', { replace: true });
				}, 2000);
			} else {
				setIsMessageNotification(true);
				setTitleNotification('Informacion');
				setContentNotification(deleteProyecto.message);
				openNotificacion({ delay: 3000 });
			}
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function editModuloData() {
		try {
			if (isEditModulo) {
				let edit = await editProyectoModuloApi(modulo.id, {
					descripcion: modulo.descripcion,
				});

				notiConfigNotiEditMod['mensaje'] = 'Hola mundo!';

				setOpenNotiEditMod(true);
				getProyectosById();
				setIsEditModulo(false);
			}
		} catch (error) {
			let msj = await error.json();
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);

			if (error.status == 422) {
				notiConfigNotiErrorMod.mensaje = msj.violations[0].message;
				setOpenNotiErrorMod(true);
			}
		}
	}

	async function getCarrById() {
		try {
			if (carreraId != null) {
				let carrById = await getCarrByIdApi(carreraId);
				setCarrera(carrById);
			} else {
				setCarrera(null);
			}
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 123 ~ getUser ~ error', error);
		}
	}
}
