import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { getAccessToken } from '../../utils/auth';
import { LoginAuth } from '../../components/login/LoginAuth';
import DataTable from 'react-data-table-component';
import { Form, Button, Row, Input, FormGroup, Label, Table } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { InputCheckbox } from '../../components/Inputs/InputCheckbox';
import {
	initialEnfasisData,
	initialArrayEnfasisData,
} from '../../api/Enfasis/enfasisModel';
import {
	getEnfasisApi,
	getEnfasisByIdApi,
	createEnfasisApi,
	editEnfasisApi,
	deleteEnfasisApi,
} from '../../api/Enfasis/enfasis';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import Notificacion from '../../components/snackbar/Notificacion';
import { 
	Box, 
	FormControl,
	TextField,
	IconButton
} from '@mui/material';
import Grid from '@mui/material/Grid';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import '../../scss/abmEstilos.scss';
import Loading from '../../components/loading/Loading';
import SaveIcon from '@mui/icons-material/Save';

export default function Enfasis() {
	const [isCreate, setIsCreate] = useState(true);
	const [isAnulado, setIsAnulado] = useState(true);
	const [isDisable, setIsDisable] = useState('');

	const [enfasisData, setEnfasisData] = useState(initialEnfasisData);

	const [arrayEnfasisData, setArrayEnfasisData] = useState(
		initialArrayEnfasisData
	);

	const handleChangeEnfasis = event => {
		let texto = event.target.value;
		setIsDisable(texto);
		setEnfasisData({
			...enfasisData,
			[event.target.name]: event.target.value,
		});
	};

	const handleEditEnfasis = event => {
		let id = event.target.dataset.id;
		let enfasis = arrayEnfasisData.filter(el => el.id == id);
		setIsDisable(enfasis);
		if (enfasis.length) {
			setEnfasisData(enfasis[0]);
			setIsCreate(false);

			if (enfasis[0].anulado == 'N') {
				setIsAnulado(true);
			} else {
				setIsAnulado(false);
			}
		}
	};

	const handleSubmitEnfasis = event => {
		event.preventDefault();
		isCreate ? createEnfasis() : editEnfasis();
	};

	useEffect(() => {
		getArrayEnfasis();
	}, []);

	const columns = [
		{
			name: 'Énfasis',
			selector: row => row.descripcion ? ( row.descripcion) : (''),
			cell: row =>
				row.descripcion ? (
					<div className="dataTableText">
						{row.descripcion}
					</div>
				) : (
					''
				),
			sortable: true,
			maxWidth: 200,
			minWidth: 140,
			width: 200,
			wrap: true,
		},
		{
			name: 'Estado',
			selector: row => row.anulado,
			sortable: true,
			cell: obj => {
				return (
					<div className="botonesTabla">
						{obj.anulado == 'N' ? (
							<div className="abmCampoVisible">
								<span className="material-symbols-rounded abmIcoVisi">
									visibility
								</span>
								Habilitado
							</div>
						) : (
							<div className="abmCampoDeshabilitar">
								<span className="material-symbols-rounded  abmIcoVisiOff">
									visibility_off
								</span>
								Deshabilitado
							</div>
						)}
					</div>
				);
			},
			maxWidth: 200,
			minWidth: 140,
			width: 200,
			wrap: true,
		},
		{
			cell: obj => {
				return (
					<div className="botonesTabla">
						<Tooltip title="Editar" placement="top" arrow>
							<IconButton
								data-id={obj.id ? obj.id : ''}
								className="material-symbols-rounded icoAbmEditar"
								onClick={handleEditEnfasis}
							>
								mode_edit
							</IconButton>
						</Tooltip>
					</div>
				);
			},
			right: true,
			ignoreRowClick: true,
			allowOverflow: true,
		},
	];

	const handleChangeAnulado = event => {
		let anulado = event.target.checked;
		setIsAnulado(anulado);
	};

	const handleClickCancelar = () => {
		setIsDisable('');
		clear();
	};

	const clear = () => {
		setIsDisable('');
		setIsCreate(true);
		setIsAnulado(true);
		setEnfasisData(initialEnfasisData);
	};

	const [OpenNoti, setOpenNoti] = React.useState(false);

	const notiConfigIni = {
		titulo: '',
		mensaje: '',
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const [notiConfig,setNotiConfig] = useState(notiConfigIni);

	const _fieldObligatorio = () => {
		return <b style={{ color: 'red' }}>*</b>;
	};

	const emptyColumn = [
		{
			name: 'Énfasis',
			cell: obj => {
				return <span>Sin énfasis creados.</span>;
			},
			left: true,
			ignoreRowClick: true,
			allowOverflow: true,
		},
	];

	const [dataTest, setDataTest] = useState([
		{
			descripcion: 'Sin énfasis creados.',
		},
	]);

	const [openLoad, setOpenLoad] = React.useState(false);

	const handleCloseLoad = () => {
		setOpenLoad(false);
	};

	const handleOpenLoad = () => {
		setOpenLoad(true);
	};

	if (getAccessToken()) {
		return (
			<Container className="contenedor">
				<div className="textoContenido">
					<h1 className="tituloContenido">Énfasis</h1>
					<p className="parrafoContenido">
						Creá y configurá los énfasis que formarán parte del contenido.
					</p>
				</div>

				<div className="textoContenido">
					<h2 className="subtituloContenido">Crear énfasis</h2>

					<Box sx={{ flexGrow: 1 }}>
						<Grid container spacing={2}>
							<Grid item xs={8}>
								<FormControl className="descripAbm">
									<TextField
										id="descripcion"
										name="descripcion"
										label={
											<span style={{ fontFamily: 'Roboto' }}>
												Nombre de la competencia {_fieldObligatorio()}
											</span>
										}
										variant="outlined"
										inputProps={{ maxLength: 255 }}
										type="text"
										onChange={handleChangeEnfasis}
										value={enfasisData.descripcion || ''}
									/>
								</FormControl>
							</Grid>
							<Grid className='' item xs={4}>
								<div className="seccionInput">
									<InputCheckbox
										isAnulado={isAnulado}
										handleChangeAnulado={handleChangeAnulado}
										isDisable={!isDisable}
									></InputCheckbox>
								</div>
								<div className="btnContentAbm">
									<Button
										className="btnCrearAbm"
										disabled={!isDisable}
										onClick={handleSubmitEnfasis}
									>
										{isCreate ?
										<AddIcon  className='btnIcoAbm'/>
										:
										<SaveIcon className='btnIcoAbm'/>
										}
										{isCreate ? 'Crear' : 'Guardar'}
									</Button>
									<Button
										className="btnAbmCancel"
										onClick={handleClickCancelar}
										disabled={isCreate ? !isDisable : false}
									>
										<CancelIcon  className='btnIcoAbm'/>
										Cancelar
									</Button>
								</div>
							</Grid>
						</Grid>
					</Box>
				</div>

				<h2 className="subtituloContenido listado">Listado de énfasis</h2>

				<DataTable
					columns={columns}
					className="dataTableContainer"
					data={arrayEnfasisData ? arrayEnfasisData : ''}
					direction="auto"
					fixedHeaderScrollHeight="300px"
					responsive
					subHeaderAlign="right"
					subHeaderWrap
					noDataComponent={
						<DataTable
							columns={emptyColumn}
							className="dataTableContainer"
							data={dataTest ? dataTest : ''}
							direction="auto"
							fixedHeaderScrollHeight="300px"
							responsive
							subHeaderAlign="right"
							subHeaderWrap
						/>
					}
					paginationPerPage={5}
					pagination
					paginationComponentOptions={{
						rowsPerPageText: 'Registros por página:',
						rangeSeparatorText: 'de',
					}}
					paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
				/>

				<Notificacion open={OpenNoti} setState={setOpenNoti} config={notiConfig} />

				<Loading
					bg="bgLoadingWhite"
					open={openLoad}
				/>
			</Container>
		);
	}

	async function getArrayEnfasis() {
		try {
			handleOpenLoad();
			let enfasis = await getEnfasisApi();
			setArrayEnfasisData(enfasis);
			handleCloseLoad();
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function createEnfasis() {
		try {
			delete enfasisData.id;
			let descripcion = enfasisData.descripcion.trim();
			let anulado = isAnulado ? 'N' : 'S';
			let enfasis = await createEnfasisApi({
				descripcion,
				anulado,
			});
			setArrayEnfasisData([...arrayEnfasisData, enfasis]);
			clear();
			notiConfig.estilo = 'notiSuccessFilled';
			notiConfig.mensaje = "Datos creados con éxito";
			setOpenNoti(true);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);

			let msj = await error.json();

			if (error.status == 422) {
				notiConfig.estilo = 'notiDangerFilled';
				notiConfig.mensaje = msj.violations[0].message;
				setOpenNoti(true);
			}
		}
	}

	async function editEnfasis() {
		try {
			let id = enfasisData.id;
			enfasisData.anulado = isAnulado ? 'N' : 'S';
			enfasisData.descripcion = enfasisData.descripcion.trim();
			await editEnfasisApi(id, enfasisData);
			clear();
			setIsCreate(true);
			setEnfasisData(initialEnfasisData);
			getArrayEnfasis();
			notiConfig.estilo = 'notiSuccessFilled';
			notiConfig.mensaje = "Datos modificados con éxito";
			setOpenNoti(true);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);
			
			let msj = await error.json();

			if (error.status == 422) {
				notiConfig.estilo = 'notiDangerFilled';
				notiConfig.mensaje = msj.violations[0].message;
				setOpenNoti(true);
			}
		}
	}

	return (
		<>
			<LoginAuth></LoginAuth>
		</>
	);
}
