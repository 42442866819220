import React, { useState } from 'react';
import './navegador.scss';
import checkRouteRole from '../../config/rolesRoutes';
import { getRolesFromLocalStorage } from '../../utils/auth';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useParams, Link, useNavigate } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useLocation } from 'react-router-dom';

export default function Navegador({}) {
	const navigate = useNavigate();
	const params = useParams();
	const { id } = params;
	const idRecur = params['*'];

	let routes = checkRouteRole();
	const [isVisible, setIsVisible] = useState(true);

	let routesA = routes.filter(ruta => {
		return ruta.parents == 'planificar';
	});

	let routesB = routes.filter(ruta => {
		return ruta.parents == 'gestor';
	});

	let role = getRolesFromLocalStorage();

	const [open, setOpen] = React.useState(true);

	const handleClick = () => {
		setOpen(!open);
	};

	const location = useLocation();

	return (
		<>
			<Box sx={{ width: 256 }} role="presentation">
				<List>
					{routesA.map(route => {
						return (
							route.show_nav && (
								<ListItem disablePadding key={route.route}>
									<ListItemButton
										className={
											route.route === location.pathname ||
											location.pathname === `/temasProyecto/${id}` ||
											location.pathname === `/tableroProyectos/${id}`
												? 'rutaActual'
												: ''
										}
										to={`${route.route}`}
									>
										<ListItemIcon>
											<AssignmentIcon
												style={{ color: 'black', fontSize: '20px' }}
											/>
										</ListItemIcon>
										<ListItemText primary={route.option_name} />
									</ListItemButton>
								</ListItem>
							)
						);
					})}
				</List>

				{role.includes('ROLE_ADMIN_PLAN') ? (
					<List>
						<ListItemButton onClick={handleClick}>
							<ListItemIcon>
								<AdminPanelSettingsIcon
									style={{ color: 'black', fontSize: '20px' }}
								/>
							</ListItemIcon>
							<ListItemText primary="Administrar" />
							{open ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{routesB.map(route => {
									return (
										route.show_nav &&
										(location.pathname == `/crearEditarRecurso/${idRecur}` ||
										location.pathname == '/crearEditarRecurso' ? (
											<ListItemButton
												className={
													route.route == `/recursos` ? 'rutaActual' : ''
												}
												key={route.route}
												to={`${route.route}`}
												sx={{ pl: 9 }}
											>
												<ListItemText primary={route.option_name} />
											</ListItemButton>
										) : (
											<ListItemButton
												className={
													route.route == location.pathname ||
													route.route == `temasProyecto/${id}`
														? 'rutaActual'
														: ''
												}
												key={route.route}
												to={`${route.route}`}
												sx={{ pl: 9 }}
											>
												<ListItemText primary={route.option_name} />
											</ListItemButton>
										))
									);
								})}
							</List>
						</Collapse>
					</List>
				) : (
					''
				)}
			</Box>
		</>
	);
}
