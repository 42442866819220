import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import './accordionWhite.scss';

export default function AccordionContAct(props) {
	const [tiempoActividades, setTiempoActividades] = useState('0 min');

	const AccordionSummary = styled(props => (
		<MuiAccordionSummary
			expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
			{...props}
		/>
	))(({ theme }) => ({
		flexDirection: 'row-reverse',
		borderRadius: '12px 12px 0px 0px;',
		padding: '0px 16px',
		gap: '8px',
		height: '64px',
		borderBottom: '1px solid #CACACA',
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(180deg)',
		},
		'& .MuiAccordionSummary-content': {
			marginLeft: theme.spacing(1),
		},
	}));

	const {
		content,
		detalleMaterials,
		detalle,
		actividades,
		panel,
		expanded,
		handleChange,
	} = props;

	useEffect(() => {
		setTiempoActividades(
			detalle.length > 0 ? detalle[0].tiempoModulos : '0 min'
		);
	});

	const headerStyleFill = 'summaryHeader';
	const headerStyleEmpty = 'summaryHeaderEmpt';

	return (
		<div>
			<Accordion className="my-3">
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
					className={actividades != '' ? headerStyleFill : headerStyleEmpty}
				>
					<div className="row w-100 justify-content-between align-items-center">
						<div className="col-auto">
							<div
								style={{
									fontFamily: 'Montserrat',
									fontStyle: 'normal',
									fontWeight: '700',
									fontSize: '16px',
									lineHeight: '20px',
									color: '#003087',
								}}
							>
								Recursos Compartidos
							</div>
						</div>

						<div className="col-auto p-0">
							<Stack direction="row" spacing={1}>
								{detalleMaterials.map((item, index) => (
									<React.Fragment key={generateKeyRandom() + index}>
										<div className="chipLabelListado">
											<Chip
												key={generateKeyRandom() + index}
												className="chipListadoMain"
												style={{
													background: 'none',
													color: '#2D2926',
												}}
												icon={
													<span
														className="material-icons"
														style={{ color: '#33599F' }}
													>
														{item.iconoMaterial != null
															? item.iconoMaterial
															: 'public'}
													</span>
												}
												label={
													<span key={generateKeyRandom() + item.material}>
														{item.tiempoMaterial}
													</span>
												}
											/>
											<label
												className="labelChipListado"
												key={generateKeyRandom() + index}
											>
												{item.material}
											</label>
										</div>
									</React.Fragment>
								))}
								<Chip
									key={generateKeyRandom()}
									style={{
										color: '#FEFCFC',
										fontFamily: 'Montserrat',
										background: '#003087',
										opacity: '0.76',
										marginTop: '3px',
									}}
									icon={<AccessTimeIcon style={{ color: '#FEFCFC' }} />}
									label={tiempoActividades}
								/>
							</Stack>
						</div>
					</div>
				</AccordionSummary>
				<AccordionDetails>{content}</AccordionDetails>
			</Accordion>
		</div>
	);

	function generateKeyRandom() {
		return (Math.random() + 1).toString(36).substring(7);
	}
}
