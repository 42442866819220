import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Stack, Chip } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import BookIcon from '@mui/icons-material/Book';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import './InfoProyectoDeta.scss';

const Accordion = styled(props => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled(props => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(180deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function InfoProyectoDeta(props) {
	const [expanded, setExpanded] = React.useState();

	const handleChange = panel => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const [color, setColor] = useState('003087');
	const [tiempoBase, setTiempoBase] = useState('0 min');

	const { observacion, asignatura, unidad, carrera, detalles } = props;

	useEffect(() => {
		if (unidad != null) {
			setColor(unidad.color ? unidad.color : '#003087');
		}
	}, [unidad]);

	useEffect(() => {
		setTiempoBase(
			detalles?.length > 0 ? detalles[0].tiempoProyectoBase : '0 min'
		);
	});

	const CustomWidthTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))({
		[`& .${tooltipClasses.tooltip}`]: {
			maxWidth: 500,
		},
	});

	return (
		<>
			<Accordion className="accSummaryInfoProy">
				<AccordionSummary
					aria-controls="panel1d-content"
					id="panel1d-header"
					expandIcon={<ExpandMoreIcon />}
					className="accSummaryInfoProy"
				>
					<div className="cardTitulo">
						<div className="cardTextContent">
							<CircleIcon
								sx={{ color: color }}
								style={{
									fontSize: '12px',
									marginTop: '15px',
									marginRight: '10px',
									marginLeft: '5px',
								}}
							/>
							<CustomWidthTooltip
								title={unidad != null ? unidad.nombUni : ''}
								placement="bottom"
								arrow
							>
								<p className="tituloInfoProy">
									{unidad != null
										? unidad.nombUniAl
										: 'Sin unidad académica asignada'}
									&nbsp;
								</p>
							</CustomWidthTooltip>

							<CustomWidthTooltip
								title={carrera != null ? carrera.nombCarr : ''}
								placement="bottom"
								arrow
							>
								<p className="tituloInfoProy">
									{carrera != null ? ' - ' + carrera.nombCarrA : ''}
								</p>
							</CustomWidthTooltip>
						</div>

						<div className="cardOpciones">
							<Stack direction="row" spacing={1}>
								{asignatura != null ? (
									<>
										<div className="chipLabelModulo">
											<Chip
												style={{
													fontFamily: 'Roboto',
													fontStyle: 'normal',
													fontWeight: '400',
													fontSize: '14px',
													lineHeight: '16px',
													background: '#F2F4F9',
													color: '#2D2926',
												}}
												icon={
													<BookmarksIcon
														style={{ color: '#33599F', fontSize: '16px' }}
													/>
												}
												label={<span>{asignatura.codMat}</span>}
											/>
											<label>Código</label>
										</div>
										<div className="chipLabelModulo">
											<CustomWidthTooltip
												title={asignatura.nombMate}
												placement="bottom"
												arrow
											>
												<Chip
													style={{
														fontFamily: 'Roboto',
														fontStyle: 'normal',
														fontWeight: '400',
														fontSize: '14px',
														background: '#F2F4F9',
														lineHeight: '16px',
														color: '#2D2926',
													}}
													icon={
														<BookIcon
															style={{ color: '#33599F', fontSize: '16px' }}
														/>
													}
													label={<span>{asignatura.nombMatA}</span>}
												/>
											</CustomWidthTooltip>
											<label>Asignatura</label>
										</div>
									</>
								) : (
									''
								)}
								<div className="chipLabelEquiv">
									<Chip
										className="chipDuracionBase"
										style={
											tiempoBase > 10
												? {
														fontFamily: 'Roboto',
														fontStyle: 'normal',
														fontWeight: '400',
														fontSize: '14px',
														background: '#F2F4F9',
														color: '#2D2926',
														width: '150px',
												  }
												: {
														fontFamily: 'Roboto',
														fontStyle: 'normal',
														fontWeight: '400',
														fontSize: '14px',
														background: '#F2F4F9',
														color: '#2D2926',
														marginLeft: '20px',
														width: 'auto',
												  }
										}
										icon={
											<AccessTimeFilledIcon
												style={{ color: '#33599F', fontSize: '16px' }}
											/>
										}
										label={<span>{tiempoBase}</span>}
									/>
									<label>Duración base</label>
								</div>
							</Stack>
						</div>
					</div>
				</AccordionSummary>
				<AccordionDetails>
					<div className="infoDetalle">
						<h2>Descripción</h2>
						<p>{observacion}</p>
					</div>
				</AccordionDetails>
			</Accordion>
		</>
	);
}
