import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { getAccessToken } from '../../utils/auth';
import { LoginAuth } from '../../components/login/LoginAuth';
import {
	Form,
	Button,
	Row,
	Container,
	Input,
	FormGroup,
	Label,
	Table,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import {
	initialProyectoDetalleData,
	initialProyectoDetallesData,
} from '../../api/Proyecto/proyectoModel';
import {
	getProyectoDetallesApi,
	getProyectoDetalleByIdApi,
	createProyectoDetalleApi,
	editProyectoDetalleApi,
    deleteProyectoDetalleApi
} from '../../api/Proyecto/proyectoDetalle';

export default function ProyectoDetalle() {
	const [id, setId] = useState('');

	const [idMaterial, setIdMaterial] = useState('');
	const [idUnidad, setUnidad] = useState('');

	const [idDeleteProyectoDetalle, setDeleteProyectoDetalle] =
		useState(false);

	const [isCreate, setIsCreate] = useState(true);

	const [proyectoDetalleData, setProyectoDetalleData] = useState(
		initialProyectoDetalleData
	);

	const [proyectoDetallesData, setProyectoDetallesData] = useState(
        initialProyectoDetallesData,

	);

	useEffect(() => {
		if (idDeleteProyectoDetalle) {
			getProyectoDetalles();
		}
	}, [idDeleteProyectoDetalle]);

	const handleChangeProyectoDetalle = event => {
		console.log(event.target.name);
		setProyectoDetalleData({
			...proyectoDetalleData,
			[event.target.name]: event.target.value,
		});
	};

	const handleEditProyectoDetalle = event => {
		let id = window.event.target.dataset.id;
		
		let proyecto = proyectoDetallesData.filter(el => {
			return el.id == id
		})
		

		if (proyecto.length) {
			setProyectoDetalleData(proyecto[0]);
			setIsCreate(false);
		}
	};

	const handleDeleteProyectoDetalle = event => {
		let id = window.event.target.dataset.id;
		setDeleteProyectoDetalle(false);
		deleteProyectoActividad(id);
	};

	const handleSubmitProyecto = event => {
		event.preventDefault();
		isCreate ? createProyectoDetalle() : editProyectoDetalle();
	};

	if (getAccessToken()) {
		return (
			<>
				<h1>ABM proyecto detalle</h1>
				<ToastContainer></ToastContainer>
				<Container>
					<Row>
						<Button color="primary" onClick={getProyectoDetalles}>
							get proyecto detalle
						</Button>
					</Row>
					<Table bordered>
						<thead>
							<tr>
								<th>#</th>
                                <th>planifUniId</th>
                                <th>cantidad</th>
							</tr>
						</thead>
						<tbody>
							{proyectoDetallesData.map((proyecto, i) => {
								return (
									<tr key={proyecto.id}>
										<td>{proyecto.id}</td>
										<td>{proyecto.planifUniId}</td>
										<td>{proyecto.cantidad}</td>
										<td>
											<Button
												color="primary"
												size="sm"
												data-id={proyecto.id}
												onClick={handleEditProyectoDetalle}
											>
												edit
											</Button>
											<Button
												color="warning"
												size="sm"
												data-id={proyecto.id}
												onClick={handleDeleteProyectoDetalle}
											>
												delete
											</Button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Container>

				{/* <Container>
					<Row>
						<div>
							<Input
								id="id"
								name="id"
								onChange={handleChangeMaterial}
								value={id}
							/>
						</div>
						<Button color="primary" onClick={getUnidadById}>
							get unidad by id
						</Button>
					</Row>
				</Container>
                        */}
				<Container>
					Create
					<Row>
						<Form onSubmit={handleSubmitProyecto}>
							<FormGroup>
								<Input
									id="id"
									name="id"
									type="hidden"
									onChange={handleChangeProyectoDetalle}
									value={proyectoDetalleData?.id || ''}
								/>
								<Label for="planifUniId">planifUniId</Label>
								<Input
									id="planifUniId"
									name="planifUniId"
									onChange={handleChangeProyectoDetalle}
									value={proyectoDetalleData?.planifUniId || ''}
								/>
								<Label for="cantidad">cantidad</Label>
								<Input
									id="cantidad"
									name="cantidad"
									onChange={handleChangeProyectoDetalle}
									value={proyectoDetalleData?.cantidad || ''}
								/>
							</FormGroup>
							<Button color="primary" type="submit">
								{isCreate ? 'create' : 'edit'}
							</Button>
						</Form>
					</Row>
				</Container>
			</>
		);
	}

	async function getProyectoDetalles() {
		try {
			let proyectos = await getProyectoDetallesApi();
			setProyectoDetallesData(proyectos);
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function createProyectoDetalle() {
		try {
			delete proyectoDetalleData.id;
			let proyecto = await createProyectoDetalleApi(proyectoDetalleData);
			setProyectoDetallesData([...proyectoDetallesData, proyecto]);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);
			console.log(await error.json());
		}
	}

	async function deleteProyectoActividad(id) {
		try {
			await deleteProyectoDetalleApi(id);
			setDeleteProyectoDetalle(true);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);
			console.log(await error.json());
		}
	}

	async function editProyectoDetalle() {
		try {
			let id = proyectoDetalleData.id;
			await editProyectoDetalleApi(id, proyectoDetalleData);
			setIsCreate(true);
			setProyectoDetalleData(initialProyectoDetalleData);
			getProyectoDetalles();
		} catch (error) {
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);
			console.log(await error?.json());
		}
	}

	return (
		<>
			<LoginAuth></LoginAuth>
		</>
	);
}
