import React from 'react';
import {
    Toast,
	ToastHeader,
	ToastBody
} from 'reactstrap';

function Notification({isOpen,title,content}) {

	return (
		<>
			<div className="p-3 my-2 rounded bg-docs-transparent-grid">
				<Toast isOpen={isOpen} >
					<ToastHeader>{title}</ToastHeader>
					<ToastBody>
						{content}
					</ToastBody>
				</Toast>
			</div>
		</>
	);


}

export default Notification;
