import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
	Modal,
	Button,
	Box,
	Container,
	IconButton,
	TextField,
	MenuItem,
	Select,
	InputLabel,
	FormControl,
	Chip,
	OutlinedInput,
	FormHelperText,
	Tooltip,
	Autocomplete,
} from '@mui/material';

import {
	getProyectoModulosApi,
	getQueryParams as getQueryParamsModulos,
} from '../../api/Proyecto/proyectoModulo';
import {
	getTemasProyectoByIdApi,
	getQueryParams as getQueryParamsProyecto,
	getProyectoByIdApi,
} from '../../api/Proyecto/proyecto';

import {
	getEnfasisApi,
	getEnfasisByIdApi,
	getQueryParams as getQueryParamsEnfasis,
} from '../../api/Enfasis/enfasis';

import {
	getTiposApi,
	getQueryParams as getQueryParamsTipos,
} from '../../api/Tipo/tipo';

import {
	getMaterialsApi,
	getMaterialsByIdApi,
} from '../../api/Material/material';

import {
	getPropositoApi,
	getQueryParams as getQueryParamsProposito,
} from '../../api/Proposito/proposito';

import {
	getDificultadApi,
	getQueryParams as getQueryParamsDificultad,
} from '../../api/Dificultad/dificultad';

import {
	getMaterialsUnidadByIdApi,
	getQueryParams as getQueryParamsMaterialsUnidad,
} from '../../api/MaterialUnidad/materialUnidad';

import {
	getActividadApi,
	getQueryParams as getQueryParamsActividad,
} from '../../api/Actividad/actividad';
import { createProyectoModuloApi } from '../../api/Proyecto/proyectoModulo';
import {
	createProyectoActividadApi,
	editProyectoActividadApi,
} from '../../api/Proyecto/proyectoActividad';
import {
	createProyectoDetalleApi,
	editProyectoDetalleApi,
	getProyectoDetalleByIdApi,
} from '../../api/Proyecto/proyectoDetalle';
import { getExpressionCheckIfValueIsNumber } from '../../utils/helpers';
import './modalCrearActividad.scss';
import {
	CollectionsOutlined,
	ConstructionOutlined,
	RampLeft,
} from '@mui/icons-material';
import Notificacion from '../../components/snackbar/Notificacion';
import { isDisabled } from 'bootstrap/js/src/util';
import Loading from '../../components/loading/Loading';
import { ModuloAutocompleteComponent } from '../../components/moduloAutocomplete/ModuloAutocompleteComponent';
import '../../scss/abmEstilos.scss';
import '../../scss/modal.scss';

export default function ModalCrearActividad(props) {
	const { enfasisParent, moduloParent, getProyectosById } = props;
	const { setIsOpenModalCrearActividad, isOpen, showModalCrearActividad } =
		props;
	const { handleChangeEnfasisId, handleChangeModuloIdParent } = props;

	const navigate = useNavigate();
	const params = useParams();

	const { proyecto } = props;

	const [isDisableBtnCrear, setIsDisableBtnCrear] = useState(true);

	const [titulo, setTitulo] = useState('');
	const [descripcion, setDescripcion] = useState('');
	const [idModulo, setIdModulo] = useState('');
	const [idEnfasis, setIdEnfasis] = useState('');
	const [idTipoRecurso, setIdTipoRecurso] = useState('');
	const [idRecurso, setIdRecurso] = useState('');
	const [idMaterial, setIdMaterial] = useState('');
	const [idProposito, setIdProposito] = useState('');
	const [idDificultad, setIdDificultad] = useState('');
	const [idActividad, setIdActividad] = useState('');
	const [idProyecto, setIdProyecto] = useState(params.id);

	const [enfasisObjeto, setEnfasisObjeto] = useState([]);
	const [tipoObjeto, setTipoObjeto] = useState([]);
	const [propositoObjeto, setPropositoObjeto] = useState([]);
	const [dificultadObjeto, setDificultadObjeto] = useState([]);
	const [materialObjeto, setMaterialObjeto] = useState([]);
	const [recursoObjeto, setRecursoObjeto] = useState([]);
	const [moduloObjeto, setModuloObjeto] = useState([]);
	const [unidadMaterial, setUnidadMaterial] = useState([]);

	const [materialString, setMaterialString] = useState('');
	const [limites, setLimites] = useState([]);

	const notiActInit = {
		titulo: '',
		mensaje: '',
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const [notiActConfig, setNotiActConfig] = useState(notiActInit);

	const [openNotiAct, setOpenNotiAct] = useState(false);

	const [temas, setTemas] = useState([]);
	const [valueTema, setValueTema] = useState('');
	// const [inputValue, setInputValueTema] = useState('');
	const [inputValueTema, setInputValueTema] = useState('');

	useEffect(() => {
		getDificultadData();
		getPropositoData();
		getProyectoModulo();
		getEnfasisData();
		getActividadesData();
		getTemasProyectoByIdApiData();

		return () => {
			clear();
		};
	}, [proyecto, idProyecto]);

	useEffect(() => {
		if (enfasisParent) {
			setIdEnfasis(enfasisParent);
			getTiposData(enfasisParent);
			setMaterialString('');
			getTemasProyectoByIdApiData();
		}
	}, [enfasisParent]);

	useEffect(() => {
		if (moduloParent) {
			setIdModulo(moduloParent);
		}
	}, [moduloParent]);

	useEffect(() => {
		if (typeof idModulo != 'undefined') {
			getTemasProyectoByIdApiData();
		}
		else {
			setIdModulo('');
			getTemasProyectoByIdApiData();
		}
	}, [idModulo]);

	useEffect(() => {
		if (!isOpen) {
			clear();
		}
	}, [isOpen]);

	useEffect(() => {
		if (idMaterial) {
			getMaterialByIdData();
		}
	}, [idMaterial, idTipoRecurso]);

	useEffect(() => {
		if (idTipoRecurso) {
			getActividadesByEnfasisAndRecursoData();
		}
	}, [idTipoRecurso]);

	useEffect(() => {
		if (idProyecto) {
			getProyectoModulo();
			getTemasProyectoByIdApiData();
		}
	}, [proyecto.id]);

	const handleOpen = () => {
		setIsOpenModalCrearActividad(true);
	};

	const handleEnableDisableBtnCrear = value => {
		setIsDisableBtnCrear(value);
	};

	const isFalse = (item) => item==false;
	
	const [unidArray,setUnidArray] = useState([]);

	useEffect(() => {
		if (
			titulo.length > 0 &&
			idEnfasis != '' &&
			idTipoRecurso != '' &&
			idRecurso != '' &&
			idMaterial != '' &&
			idProposito != '' &&
			idDificultad != '' &&
			_checkIfUnidadMaterialHasValue() && 
			unidArray.every(isFalse)
		) {
			handleEnableDisableBtnCrear(false);
		} else {
			handleEnableDisableBtnCrear(true);
		}
	}, [
		titulo,
		idEnfasis,
		idTipoRecurso,
		idRecurso,
		idMaterial,
		idProposito,
		idDificultad,
		unidadMaterial,
		unidArray
	]);

	const _checkIfUnidadMaterialHasValue = () => {
		let veri = unidadMaterial.filter(um => {
			return (
				um?.unidad?.cantidad == null ||
				um?.unidad?.cantidad == undefined ||
				um?.unidad?.cantidad == ''
			);
		});

		return veri.length == 0 ? true : false;
	};

	const handleClose = () => {
		clear();
		setIsOpenModalCrearActividad(false);
	};

	const handleOnChangeTitulo = event => {
		let val = event.target.value;

		if(val.length>0){
			setEmptyTitulo(false);
		}
		else {
			setEmptyTitulo(true);
		}

		setTitulo(val);
	};

	const handleOnChangeDescri = event => {
		let val = event.target.value;
		setDescripcion(val);
	};

	const handleOnChangeEnfasis = event => {
		let val = event.target.value;
		setIdEnfasis(val);
		getTiposData(val);
		setIdMaterial('');
		setIdRecurso('');
		setMaterialString('');
		setMaterialObjeto([]);
		setUnidadMaterial([]);
		setRecursoObjeto([]);
		setTipoObjeto([]);
		setIdTipoRecurso('');
		setEmptyEnfasis(false);
		setUnidArray([]);
	};

	const handleOnChangeTipoRecurso = event => {
		let tipo_recurso = event.target.value;
		setIdTipoRecurso(tipo_recurso);
		setMaterialString('');
		setUnidadMaterial([]);
		setIdMaterial('');
		setIdRecurso('');
		setEmptyTipoRec(false);
		setUnidArray([]);
	};

	const handleOnChangeRecurso = event => {
		let recursoId = event.target.value;
		setIdRecurso(recursoId);
		let material = recursoObjeto.filter(el => el.id == recursoId);
		setIdMaterial(material[0].materialId);
		setEmptyRecur(false);
		setUnidArray([]);
	};

	const handleOnChangeProposito = event => {
		let val = event.target.getAttribute('data-propositoid');
		setIdProposito(val);
		setEmptyProp(false);
	};

	const handleOnChangeDificultad = event => {
		let val = event.target.getAttribute('data-dificultadid');
		setIdDificultad(val);
		setEmptyDific(false);
	};

	const handleOnChangeModulo = event => {
		let val = event.target.getAttribute('data-moduloid');
		setIdModulo(val);
		getProyectoModulo();
		setValueTema({ label: '' });
		setInputValueTema('');
	};

	function clear() {
		setIdRecurso('');
		setMaterialString('');
		setIdMaterial('');
		setIdEnfasis('');
		setIdModulo('');
		setIdTipoRecurso('');
		setTitulo('');
		setDescripcion('');
		setIdProposito('');
		setIdDificultad('');
		setUnidadMaterial([]);
		handleChangeEnfasisId(false);
		handleChangeModuloIdParent(false);
		setTemas([]);
		setValueTema('');
		setEmptyTitulo(false);
		setEmptyEnfasis(false);
		setEmptyTipoRec(false);
		setEmptyProp(false);
		setEmptyDific(false);
		setEmptyRecur(false);
		setUnidArray([]);
	}

	const handleCreateProyectoModulo = event => {
		event.preventDefault();
		createProyectoActividad();
	};

	const initUnidArray = (size) => {
		let array = [];

		if(size!=0){
			for(let i=0;i<size;i++){
				array.push(false);
			}
		}
		
		setUnidArray(array);
	}

	const handleChangeUnidArray = (index,vbool) => {
		unidArray[index] = vbool;
		let copy = structuredClone(unidArray);
		setUnidArray(copy);
	}

	const handleOnChangeMaterialUnidadData = index => e => {

		const regex = getExpressionCheckIfValueIsNumber();

		if (e.target.value === '' || regex.test(e.target.value)) {
			let max = +e.target.getAttribute('data-max');
			let min = +e.target.getAttribute('data-min');
			let rige = e.target.getAttribute('data-rige');
			let unidad = unidadMaterial[index].unidad;
			let value = +e.target.value;

			if (rige == 'S') {
				if (value >= min && value <= max) {
					unidadMaterial[index].unidad = {
						...unidad,
						...{
							planifUniId: e.target.getAttribute('data-unidad_id'),
							material: idMaterial,
							cantidad: +e.target.value,
						},
					};

					let copy = structuredClone(unidadMaterial);
					setUnidadMaterial(copy);
					handleChangeUnidArray(index,false);
				} else {
					handleChangeUnidArray(index,true);
				}
			} else {
				unidadMaterial[index].unidad = {
					...unidad,
					...{
						planifUniId: e.target.getAttribute('data-unidad_id'),
						material: idMaterial,
						cantidad: +e.target.value,
					},
				};
				let copy = structuredClone(unidadMaterial);
				setUnidadMaterial(copy);
			}
		} else {
			e.target.value = '';
		}
	};

	const _fieldObligatorio = () => {
		return <b style={{ color: '#D32F2F' }}> *</b>;
	};

	const [openLoad, setOpenLoad] = React.useState(false);

	const handleCloseLoad = () => {
		setOpenLoad(false);
	};
	const handleOpenLoad = () => {
		setOpenLoad(true);
	};

	const [emptytitulo, setEmptyTitulo] = React.useState(false);
	const [emptyEnfasis, setEmptyEnfasis] = React.useState(false);
	const [emptyTipoRec, setEmptyTipoRec] = React.useState(false);
	const [emptyRecur, setEmptyRecur] = React.useState(false);
	const [emptyProp, setEmptyProp] = React.useState(false);
	const [emptyDific, setEmptyDific] = React.useState(false);

	return (
		<div>
			<Modal
				open={isOpen}
				onClose={showModalCrearActividad}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box className="modalActividadContainer modalShadow">
					<Container className="modalActividadHeader">
						<h1>
							Crear actividad
						</h1>
						<IconButton onClick={handleClose} style={{ margin: 'auto 0px' }}>
							<CloseIcon />
						</IconButton>
					</Container>
					<Container>
						<TextField
							className="ActiInputModal"
							id="outlined-basic"
							label={
								<span style={{ fontFamily: 'Roboto' }}>
									Título {_fieldObligatorio()}
								</span>
							}
							variant="outlined"
							value={titulo}
							inputProps={{ maxLength: 255 }}
							type="text"
							onChange={handleOnChangeTitulo}
							onBlur={()=>{titulo==''? setEmptyTitulo(true) : setEmptyTitulo(false)}}
							error={emptytitulo}
						/>
					</Container>
					<Container>
						<TextField
							className="DescriInputModal"
							id="outlined-multiline-static"
							label="Descripción"
							multiline
							onChange={handleOnChangeDescri}
							inputProps={{ maxLength: 1000 }}
							value={descripcion}
							rows={3}
						/>
					</Container>
					<Container className="seccionInputAcitividad">
						<FormControl className="selectModuloActividad">
							<ModuloAutocompleteComponent
								setIdModulo={setIdModulo}
								setValueTema={setValueTema}
								idProyecto={proyecto.id}
								idModulo={idModulo}
							></ModuloAutocompleteComponent>
						</FormControl>
						<FormControl className="selectModuloTema">
							<Autocomplete
								freeSolo
								id="combo-box-demo"
								disableClearable={true}
								value={valueTema}
								options={temas}
								onChange={(event, newValue) => {
									setValueTema(newValue);
								}}
								onInputChange={(event, newInputValue) => {
									setInputValueTema(newInputValue);
								}}
								renderInput={params => {
									return <TextField {...params} label="Temas" />;
								}}
							/>
						</FormControl>
					</Container>
					<Container className="seccionInputAcitividad">
						<FormControl className="selectEnfasisActividad">
							<InputLabel className={emptyEnfasis? 'textRed' : ''}>Énfasis {_fieldObligatorio()} </InputLabel>
							<Select
								value={idEnfasis ?? ''}
								label="Énfasis"
								onChange={handleOnChangeEnfasis}
								onBlur={()=>{idEnfasis == ''? setEmptyEnfasis(true):setEmptyEnfasis(false)}}
								error={emptyEnfasis}
							>
								{enfasisObjeto?.map((item, index) => {
									return (
										<MenuItem
											data-enfasisid={item.id}
											key={index}
											value={item.id}
											id={item.id}
											onBlur={()=>{idEnfasis == ''? setEmptyEnfasis(true):setEmptyEnfasis(false)}}
										>
											{item.descripcion}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
						<FormControl className="selectTipoRecursoActividad" disabled={idEnfasis==''}>
							<InputLabel className={emptyTipoRec? 'textRed' : ''}>Tipo de recurso {_fieldObligatorio()} </InputLabel>
							<Select
								value={idTipoRecurso ?? ''}
								label="Tipo de recurso"
								onChange={handleOnChangeTipoRecurso}
								onBlur={()=>{idTipoRecurso == ''? setEmptyTipoRec(true):setEmptyTipoRec(false)}}
								error={emptyTipoRec}
							>
								{tipoObjeto?.map((item, index) => {
									return (
										<MenuItem
											data-tiporecursoid={item.id}
											key={index}
											value={item.id}
											id={item.id}
											onBlur={()=>{idTipoRecurso == ''? setEmptyTipoRec(true):setEmptyTipoRec(false)}}
										>
											{item.descripcion}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Container>
					<Container>
						<FormControl className="selectRecursoActividad" disabled={idTipoRecurso==''}>
							<InputLabel className={emptyRecur? 'textRed' : ''}>Recurso {_fieldObligatorio()} </InputLabel>
							<Select
								value={idRecurso ?? ''}
								label="Recurso"
								onChange={handleOnChangeRecurso}
								onBlur={()=>{idRecurso == ''? setEmptyRecur(true):setEmptyRecur(false)}}
								error={emptyRecur}
							>
								{recursoObjeto?.map((item, index) => {
									return (
										<MenuItem
											data-recursoid={item.id}
											key={index}
											id={item.id}
											value={item.id}
											onBlur={()=>{idRecurso == ''? setEmptyRecur(true):setEmptyRecur(false)}}
										>
											{item.descripcion}

											<Tooltip
												className="tooltipRecurso"
												title={item.observacion ?? 'Sin información cargada'}
												placement="right"
												arrow
											>
												<InfoIcon
													style={{
														marginLeft: '10px',
														fontSize: '16px',
														color: '#00BCD4',
													}}
												/>
											</Tooltip>
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Container>

					<div className='containerProps'>
						<FormControl className="selectMaterialActividad">
							<TextField
								id="outlined-basic"
								label={
									<span style={{ fontFamily: 'Roboto' }}>
										Material {_fieldObligatorio()}
									</span>
								}
								variant="outlined"
								disabled
								value={materialString}
								type="text"
							/>
						</FormControl>

						{unidadMaterial?.map((unidad, index) => {
							return (
								<FormControl
									key={`unidadMaterial_${index}`}
									className="selectMinutosActividad"
								>
									<TextField
										id="outlined-adornment-weight"
										data-id={unidad.unidad.id}
										label={
											<span style={{ fontFamily: 'Roboto' }}>
												{`${unidad.unidad.descripcion}`}
												{_fieldObligatorio()}
											</span>
										}
										aria-describedby="outlined-weight-helper-text"
										inputProps={{
											'aria-label': 'weight',
											maxLength: 6,
											'data-unidad_id': `${unidad.unidad.id}`,
											'data-max': `${unidad.maximo}`,
											'data-min': `${unidad.minimo}`,
											'data-rige': `${
												unidad.rige == undefined ? 'N' : unidad.rige
											}`,
										}}
										onChange={handleOnChangeMaterialUnidadData(index)}
										type="text"
										error={unidArray[index]}
									/>
									<FormHelperText
										id="outlined-weight-helper-text"
										className={`underLabelActividad ${
											unidad?.rige == 'S' ? 'obligatorio' : ''
										}`}
									>
										{unidad?.texto == undefined ? '' : unidad.texto}
									</FormHelperText>
								</FormControl>
							);
						})}

						<FormControl className="selectPropositoActividad">
							<InputLabel className={emptyProp? 'textRed' : ''}>Propósito {_fieldObligatorio()} </InputLabel>
							<Select value={idProposito} 
								label="Proposito"
								onBlur={()=>{idProposito == ''? setEmptyProp(true):setEmptyProp(false)}}
								error={emptyProp}
							>
								{propositoObjeto?.map((item, index) => {
									return (
										<MenuItem
											onClick={handleOnChangeProposito}
											data-propositoid={item.id}
											key={index}
											value={item.id}
											id={item.id}
											onBlur={()=>{idProposito == ''? setEmptyProp(true):setEmptyProp(false)}}
										>
											{item.descripcion}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
						<FormControl className="selectDificultadActividad">
							<InputLabel className={emptyDific? 'textRed' : ''}>Dificultad {_fieldObligatorio()}</InputLabel>
							<Select value={idDificultad} 
								label="Dificultad"
								onBlur={()=>{idDificultad == ''? setEmptyDific(true):setEmptyDific(false)}}
								error={emptyDific}
							>
								{dificultadObjeto?.map((item, index) => {
									return (
										<MenuItem
											onClick={handleOnChangeDificultad}
											data-dificultadid={item.id}
											key={index}
											value={item.id}
											id={item.id}
											onBlur={()=>{idDificultad == ''? setEmptyDific(true):setEmptyDific(false)}}
										>
											{item.descripcion}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</div>

					<Container className="modalSeccionBotones">
						<Button
							className="btnCrearAbm"
							disabled={isDisableBtnCrear}
							onClick={handleCreateProyectoModulo}
						>
							<AddIcon  className='btnIcoAbm'/>							
							Crear
						</Button>
						<Button
							className="btnAbmCancel"
							onClick={handleClose}
						>
							<CancelIcon  className='btnIcoAbm'/>
							Cancelar
						</Button>
					</Container>
				</Box>
			</Modal>

			<Notificacion
				open={openNotiAct}
				setState={setOpenNotiAct}
				config={notiActConfig}
			/>
		</div>
	);

	/* GETTERS */

	async function getProyectoModulo() {
		try {
			let queryParamsDefault = getQueryParamsModulos();

			let { proyecto } = queryParamsDefault.filtersDefault.properties;
			let value = idProyecto;

			const query = { [proyecto]: value };
			queryParamsDefault.filters.properties.push(query);

			const resp = await getProyectoModulosApi(queryParamsDefault);
			setModuloObjeto(resp);
		} catch (err) {
			console.log(await err.json());
		}
	}

	async function getEnfasisData() {
		let queryParamsDefault = getQueryParamsEnfasis();

		let { anulado } = queryParamsDefault.filtersDefault.properties;
		let value = 'N';

		const query = { [anulado]: value };
		queryParamsDefault.filters.properties.push(query);

		try {
			const resp = await getEnfasisApi(queryParamsDefault);
			setEnfasisObjeto(resp);
		} catch (error) {
			console.log(await error.json());
		}
	}

	async function getTiposData(val) {
		try {
			let queryParamsDefault = getQueryParamsTipos();

			let { anulado } = queryParamsDefault.filtersDefault.properties;
			let value = 'N';

			let { enfasi } = queryParamsDefault.filtersDefault.properties;
			let enfasiId = val;

			const query = { [anulado]: value, [enfasi]: enfasiId };
			queryParamsDefault.filters.properties.push(query);

			const resp = await getTiposApi(queryParamsDefault);
			setTipoObjeto(resp);
		} catch (error) {
			console.log(await error.json());
		}
	}

	async function getMaterialByIdData() {
		try {
			if (idMaterial) {
				const resp = await getMaterialsByIdApi(idMaterial);
				setMaterialString(resp.descripcion);
				_mergeUnidadMaterialLimites(resp.id, resp.unidads);
			}
		} catch (error) {
			console.log(await error.json());
		}
	}

	function _mergeUnidadMaterialLimites(materia_id, unidades) {
		let u = unidades.map(uni => {
			let lim = limites.filter(limite => {
				return (
					limite.planifUniId == uni.unidad.id &&
					limite.planifMatId == materia_id &&
					limite.planifActId == idRecurso
				);
			});

			return { ...uni, ...lim[0] };
		});

		setUnidadMaterial(u);

		initUnidArray(u.length);
	}

	async function getPropositoData() {
		try {
			let queryParamsDefault = getQueryParamsProposito();

			let { anulado } = queryParamsDefault.filtersDefault.properties;
			let value = 'N';

			const query = { [anulado]: value };
			queryParamsDefault.filters.properties.push(query);

			const resp = await getPropositoApi(queryParamsDefault);
			setPropositoObjeto(resp);
		} catch (error) {
			console.log(await error.json());
		}
	}

	async function getDificultadData() {
		try {
			let queryParamsDefault = getQueryParamsDificultad();

			let { anulado } = queryParamsDefault.filtersDefault.properties;
			let value = 'N';

			const query = { [anulado]: value };
			queryParamsDefault.filters.properties.push(query);

			const resp = await getDificultadApi(queryParamsDefault);
			setDificultadObjeto(resp);
		} catch (error) {
			console.log(await error.json());
		}
	}

	async function getActividadesData() {
		let queryParamsDefault = getQueryParamsActividad();

		let { anulado } = queryParamsDefault.filtersDefault.properties;
		let value = 'N';

		const query = { [anulado]: value };
		queryParamsDefault.filters.properties.push(query);

		try {
			const resp = await getActividadApi(queryParamsDefault);
			setIdActividad(resp);
		} catch (error) {
			console.log(await error.json());
		}
	}

	async function getActividadesByEnfasisAndRecursoData() {
		let queryParamsDefault = getQueryParamsActividad();

		let { anulado, tipo_recurso, enfasis } =
			queryParamsDefault.filtersDefault.properties;
		let value = 'N';

		if (idTipoRecurso && idEnfasis) {
			const query = {
				[anulado]: value,
				[tipo_recurso]: idTipoRecurso,
				[enfasis]: +idEnfasis,
			};

			queryParamsDefault.filters.properties.push(query);

			try {
				const resp = await getActividadApi(queryParamsDefault);

				setRecursoObjeto(resp);

				let limites = [];

				resp.map(el => {
					limites.push(...el.limites);
				});

				setLimites(limites);
			} catch (error) {
				console.log(await error.json());
			}
		}
	}

	async function getTemasProyectoByIdApiData() {
		if (proyecto.id && idModulo) {
			let queryParamsDefault = getQueryParamsProyecto();

			let { modulo } = queryParamsDefault.filtersDefault.properties;

			let moduloid = idModulo;

			const query = { [modulo]: moduloid };
			queryParamsDefault.filters.properties.push(query);

			const resp = await getTemasProyectoByIdApi(
				+proyecto.id,
				queryParamsDefault
			);

			const mutateTema = resp.map(el => {
				return { label: el.tema };
			});

			setTemas(mutateTema);
		} else if (proyecto.id) {
			const resp = await getTemasProyectoByIdApi(+proyecto.id);

			const mutateTema = resp.map(el => {
				return { label: el.tema };
			});
			setTemas(mutateTema);
		}
	}

	async function createProyectoActividad() {
		try {
			const data = {};
			data.planifPyId = +proyecto.id;
			data.planifEnfId = +idEnfasis;
			data.descripcion = titulo;
			data.observacion = descripcion != '' ? descripcion : '';
			data.actividad = +idRecurso;
			data.planifDifId = +idDificultad;
			data.planifProId = +idProposito;
			data.tema = inputValueTema.trim();

			data.planifModId = +idModulo == '' ? null : +idModulo;

			const response_proyecto_actividad = await createProyectoActividadApi(
				data
			);

			const response_proyecto_detalle = await createProyectoDetalleData(
				response_proyecto_actividad
			);
		} catch (error) {
			if ([400, 422].includes(error.status)) {
				let msj = await error.json();
				notiActConfig.estilo = 'notiDangerFilled';

				if (error.status == 422) {
					notiActConfig.mensaje = msj?.violations[0].message;
				} else {
					notiActConfig.mensaje = msj.errors[0].message;
				}
				setOpenNotiAct(true);
			}
		}
	}

	async function createProyectoDetalleData(data) {
		try {
			for await (const element of unidadMaterial) {
				let body = {};
				body.actividadProyecto = +data.id; //id del recurso
				body.unidad = +element.unidad.planifUniId;
				body.material = +element.unidad.material;
				body.cantidad = +element.unidad.cantidad;
				const resp = await createProyectoDetalleApi(body);
			}

			await getProyectosById();
			showModalCrearActividad();

			notiActConfig.mensaje = 'Datos creados con éxito';
			notiActConfig.estilo = 'notiSuccessFilled';

			setTimeout(function () {
				setOpenNotiAct(true);
			}, 1300);
		} catch (error) {
			if ([400, 422].includes(error.status)) {
				let msj = await error.json();

				notiActConfig.estilo = 'notiDangerFilled';

				if (error.status == 422) {
					notiActConfig.mensaje = msj?.violations[0].message;
				} else {
					notiActConfig.mensaje = msj.errors[0].message;
				}
				setOpenNotiAct(true);
			}
		}
	}
}
