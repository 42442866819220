import react, { useState, useEffect } from 'react';
import { Card, CardContent } from '@mui/material';
import LinearProgress, {
	linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import './CardEquivalencia.scss';

export default function CardEquivalencia(props) {
	const {
		iconMaterial,
		nombreMaterial,
		cantCarga,
		id,
		funcion,
		unidadId,
		activo,
		idKey,
	} = props;

	const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
		width: '120px',
		marginTop: '12px',
		height: 10,
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor:
				theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
			backgroundColor: theme.palette.mode === 'light' ? '#B1281D' : '#308fe8',
		},
	}));

	useEffect(() => {
		document
			.querySelector(' div.listadoEquivalencias > div:nth-child(2) > div')
			.click();
	}, []);

	return (
		<Card
			className={
				idKey != activo ? 'cardEquivalenciaContainer' : 'cardEquivActiva'
			}
			data-id={id}
			data-unidad={unidadId}
			onClick={funcion}
			data-key={idKey}
		>
			<CardContent className="cardEquivalenciaTitulo">
				<div className="text-center">
					<span
						className="material-symbols-outlined"
					>
						{iconMaterial != null ? iconMaterial : 'public'}
					</span>

					<p>
						{nombreMaterial}
					</p>
				</div>
			</CardContent>
		</Card>
	);
}
