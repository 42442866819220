export const base_url = process.env.REACT_APP_API_URL;
export const api_version = process.env.REACT_APP_API_VERSION;

export const login_auth_url = process.env.REACT_APP_LOGIN_AUTH_URL;

export const app_enviroment = process.env.REACT_APP_ENV;

export const client_id = process.env.REACT_APP_API_CLIENT_ID;
export const client_secret = process.env.REACT_APP_API_CLIENT_SECRET;
export const redirect_uri = process.env.REACT_APP_API_REDIRECT_URI;
export const grant_type = process.env.REACT_APP_API_GRANT_TYPE;
