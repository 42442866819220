import React, { useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/Circle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

import {
	Chip,
	Stack,
	IconButton,
	Tooltip,
	Menu,
	MenuItem,
	Table,
	TableRow,
	TableCell,
	TableContainer,
	Paper,
	Collapse,
	TableBody,
	CardHeader,
	CardActions,
	Card,
	CardContent,
} from '@mui/material';
import CardTemaActividad from '../cardTemaActividad/CardTemaActividad';

import './ModTemaProyecto.scss';
import { ThreePointsMenu } from '../threePointsMenu/ThreePointsMenu';

export default function ModTemaProyecto(props) {
	const {
		idModulo,
		descripcion,
		detalles,
		detalleMaterials,
		actividades,
		temasDetalleMaterials,
		proyecto,
		showModalCrearActividad,
		handleOnClickEditActividad,
		showModalConfirmDelete,
		cambiarOrden,
		showModalModulo,
		observacion,
		permitirMover,
		cambiarOrdenActividad,
		moverHTMLtema,
		cambiarOrdenTema,
	} = props;

	const [expanded, setExpanded] = useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const ExpandMore = styled(props => {
		const { expand, ...other } = props;
		return <IconButton {...other} />;
	})(({ theme, expand }) => ({
		transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
		margin: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	}));

	let temasFiltrados = proyecto.temas?.filter(el => el.planifModId == idModulo);

	return (
		<Card className="cardTemaContainer" id={'card' + idModulo}>
			<CardActions className="cardActionsContainerTema">
				<div className="cardChipsTema">
					<div className="cardActionsContainer">
						<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
						>
							<ExpandMoreIcon style={{ flexDirection: 'row-reverse' }} />
						</ExpandMore>
						<CardHeader title={descripcion} />
					</div>

					<div className='cardTemaTools'>
						<CardContent>
							<Tooltip title="Tiempo total" arrow>
								<Chip
									icon={<AccessTimeIcon style={{ color: '#FFF' }} />}
									label={detalles != '' ? detalles : '0 min'}
									style={{ backgroundColor: '#33599F', color: '#FFF' }}
								/>
							</Tooltip>
						</CardContent>

						<div className='btnBoxUpDownTema'>
							<Tooltip title="Subir Módulo" placement="top">
								<IconButton className='btnUpDown'
									sx={{
										"&.MuiButtonBase-root:hover": { bgcolor: "transparent" }
									}}

									onClick={e => {
										cambiarOrden(e);
										moverHTMLmodulo(e);
									}}
									data-modulo_id={idModulo}
									data-tipo="S"
								>
									<ArrowCircleUpIcon className='btnUpDownIco' />
								</IconButton>
							</Tooltip>
							<Tooltip title="Bajar Módulo" placement="bottom">
								<IconButton className='btnUpDown'
									sx={{
										"&.MuiButtonBase-root:hover": { bgcolor: "transparent" }
									}}
									onClick={e => {
										cambiarOrden(e);
										moverHTMLmodulo(e);
									}}
									data-modulo_id={idModulo}
									data-tipo="B"
								>
									<ArrowCircleDownIcon className='btnUpDownIco' />
								</IconButton>
							</Tooltip>

						</div>
					</div>
				</div>

				<ThreePointsMenu>
					<MenuItem
						data-modulo_id={idModulo}
						onClick={() => {
							showModalCrearActividad(null, idModulo);
						}}
					>
						Crear actividad
					</MenuItem>
					<MenuItem
						className="ActDropdownMenuItem"
						data-modulo_id={idModulo}
						data-nombre_modulo={descripcion}
						data-observacion={observacion}
						onClick={showModalModulo}
					>
						Editar
					</MenuItem>
					<MenuItem
						className="ActDropdownMenuItem"
						data-modulo_id={idModulo}
						data-nombre_modulo={descripcion}
						onClick={showModalConfirmDelete}
					>
						Eliminar
					</MenuItem>
				</ThreePointsMenu>
			</CardActions>
			<Collapse
				in={expanded}
				timeout="auto"
				unmountOnExit
				className="cardTemaHeader"
			>
				<CardContent style={{ marginTop: '-32px' }}>
					<div
						style={{
							fontFamily: 'Roboto',
							fontSize: '15px',
						}}
					>
						<Stack
							direction="row"
							spacing={1}
							style={{
								display: 'flex',
								justifyContent: 'right',
								marginBottom: '35px',
							}}
						>
							{detalleMaterials.map((item, index) => (
								<Tooltip key={index} title={item.material} arrow>
									<Chip
										key={index}
										style={{
											backgroundColor: '#fff',
											color: '#003087',
											margin: '0px 6px',
											marginBottom: '-10px',
											marginTop: '20px',
										}}
										icon={
											<span
												className="material-icons"
												style={{ color: '#003087', fontSize: '18px' }}
											>
												{item.iconoMaterial != null
													? item.iconoMaterial
													: 'public'}
											</span>
										}
										label={
											<span key={item.material}>{item.tiempoMaterial}</span>
										}
									/>
								</Tooltip>
							))}
						</Stack>
					</div>
					<div className="cardTema">
						<TableContainer
							className="tablaTemaContainer"
							component={Paper}
							style={{ marginLeft: '-15px' }}
						>
							<Table size="small">
								<TableBody style={{ borderTop: '1px solid #ccc' }}>
									<TableRow style={{ position: 'none' }}>
										<TableCell style={{ display: 'none' }}></TableCell>
									</TableRow>
									{actividades.length != 0 ? (
										actividades
											.sort((a, b) => a.ordenActividad - b.ordenActividad)
											.map(
												(item, index) =>
													item.tema == null && (
														<TableRow
															id={'card_acti' + item.id}
															key={`${item.planifActId}_${index}`}
														>
															<TableCell align="left">
																{item.descriDocente}
															</TableCell>
															<TableCell align="left">
																{item.descriTipo}
															</TableCell>
															<TableCell align="left">
																<Chip
																	label={item.descriEnfasis}
																	sx={{ backgroundColor: item.colorEnfasis }}
																/>
															</TableCell>
															<TableCell align="left">
																{item.material}
															</TableCell>
															<TableCell align="right">
																<Stack direction="row" spacing={1}>
																	<Tooltip title={item.dificultad} arrow>
																		<span
																			className="material-icons"
																			style={{ color: '#33599F' }}
																		>
																			{item.iconoDificultad != null
																				? item.iconoDificultad
																				: 'public'}
																		</span>
																	</Tooltip>
																	<Tooltip title={item.proposito} arrow>
																		<span
																			className="material-icons"
																			style={{ color: '#33599F' }}
																		>
																			{item.iconoProposito != null
																				? item.iconoProposito
																				: 'public'}
																		</span>
																	</Tooltip>
																</Stack>
															</TableCell>
															<TableCell align="right" colSpan={2}>
																<Tooltip title="Tiempo total" arrow>
																	<Chip
																		style={{
																			background: 'none',
																			fontFamily: 'Roboto',
																			fontStyle: 'normal',
																			fontWeight: '400',
																			fontSize: '14px',
																			lineHeight: '16px',
																			color: '#2D2926',
																		}}
																		icon={
																			<span
																				className="material-icons"
																				style={{ color: '#33599F' }}
																			>
																				schedule
																			</span>
																		}
																		label={
																			<span key={index}>
																				{item.tiempoActividad}
																			</span>
																		}
																	/>
																</Tooltip>
															</TableCell>
															<TableCell align="right" colSpan={4}>
																<div className='btnBoxUpDownTemaAct'>
																	<Tooltip title="Subir Actividad" placement="top">
																		<IconButton className='btnUpDown'
																			sx={{
																				"&.MuiButtonBase-root:hover": { bgcolor: "transparent" }
																			}}

																			data-actividad_id={item.id}
																			data-tipo="S"
																			data-tema="N"
																			onClick={e => {
																				moverHTMLactividad(e);
																				cambiarOrdenActividad(e);
																			}}
																		>
																			<ArrowCircleUpIcon className='btnUpDownIco' />
																		</IconButton>
																	</Tooltip>
																	<Tooltip title="Bajar Actividad" placement="bottom">
																		<IconButton className='btnUpDown'
																			sx={{
																				"&.MuiButtonBase-root:hover": { bgcolor: "transparent" }
																			}}
																			data-actividad_id={item.id}
																			data-tipo="B"
																			data-tema="N"
																			onClick={e => {
																				moverHTMLactividad(e);
																				cambiarOrdenActividad(e);
																			}}
																		>
																			<ArrowCircleDownIcon className='btnUpDownIco' />
																		</IconButton>
																	</Tooltip>

																</div>
															</TableCell>
															<TableCell colSpan={2} 
																align="right"
																style={{ paddingRight: '6px' }}
															>
																<ThreePointsMenu>
																	{/* <MenuItem
																		data-actividad_id={item.id}
																		data-tipo="S"
																		data-tema="N"
																		onClick={e => {
																			moverHTMLactividad(e);
																			cambiarOrdenActividad(e);
																		}}
																	>
																		Subir
																	</MenuItem>
																	<MenuItem
																		data-actividad_id={item.id}
																		data-tipo="B"
																		data-tema="N"
																		onClick={e => {
																			moverHTMLactividad(e);
																			cambiarOrdenActividad(e);
																		}}
																	>
																		Abajo
																	</MenuItem> */}
																	<MenuItem
																		data-actividad_id={item.id}
																		data-enfasis_id={null}
																		data-modulo_id={null}
																		onClick={handleOnClickEditActividad}
																	>
																		Editar
																	</MenuItem>
																	<MenuItem
																		onClick={showModalConfirmDelete}
																		data-actividad_id={item.id}
																		data-descri_doc={item.descriDocente}
																	>
																		Eliminar
																	</MenuItem>
																</ThreePointsMenu>
															</TableCell>
														</TableRow>
													)
											)
									) : (
										<TableRow>
											<TableCell colSpan={2} align="left">
												Sin datos creados.
											</TableCell>
										</TableRow>
									)}
									{temasFiltrados?.map(
										(item, index) =>
											item.tema && (
												<React.Fragment key={`${item.planifModId}_${index}`}>
													<CardTemaActividad
														tiempoTema={item.tiempoModulos}
														archivo={'modulo'}
														tema={item.tema}
														idModulo={idModulo}
														actividades={actividades}
														showModalConfirmDelete={showModalConfirmDelete}
														moverHTMLtema={moverHTMLtema}
														cambiarOrdenTema={cambiarOrdenTema}
														handleOnClickEditActividad={
															handleOnClickEditActividad
														}
														moverHTMLactividad={moverHTMLactividad}
														permitirMover={permitirMover}
														cambiarOrdenActividad={cambiarOrdenActividad}
														showModalCrearActividad={showModalCrearActividad}
														temasDetalleMaterials={temasDetalleMaterials}
													/>
												</React.Fragment>
											)
									)}
									<TableRow style={{ position: 'none' }}>
										<TableCell style={{ display: 'none' }}></TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				</CardContent>
			</Collapse>
		</Card>
	);

	function moverHTMLactividad(event) {
		let tipo = event.currentTarget.dataset.tipo;
		let actividad_id = event.currentTarget.dataset.actividad_id;
		let inTema = event.currentTarget.dataset.tema;

		let acti = document.querySelector('#card_acti' + actividad_id);
		let abajo = acti.nextElementSibling;
		let arriba = acti.previousElementSibling;

		if (inTema == 'N') {
			if (
				abajo.className ==
				'MuiTableRow-root tableRowTema css-1q1u3t4-MuiTableRow-root'
			) {
				abajo = null;
			} else {
				abajo = acti.nextElementSibling;
			}
		}

		if (permitirMover) {
			if (tipo == 'S' && arriba != null && !(arriba?.classList.contains('tableRowTema'))) {
				arriba.insertAdjacentElement('beforebegin', acti);

				document.querySelector('#card_acti' + actividad_id).style.transition =
					'background .5s';
				document.querySelector('#card_acti' + actividad_id).style.background =
					'#d7d7d7';
				setTimeout(function () {
					document.querySelector('#card_acti' + actividad_id).style.background =
						'#FFF';
				}, 200);
			} else if (tipo == 'B' && abajo != null && !(abajo?.classList.contains('tableRowTema'))) {
				abajo.insertAdjacentElement('afterend', acti);
				document.querySelector('#card_acti' + actividad_id).style.transition =
					'background .5s';
				document.querySelector('#card_acti' + actividad_id).style.background =
					'#d7d7d7';
				setTimeout(function () {
					document.querySelector('#card_acti' + actividad_id).style.background =
						'#FFF';
				}, 200);
			}
		}
	}

	function moverHTMLmodulo(event) {
		let tipo = event.currentTarget.dataset.tipo;
		let modulo_id = event.currentTarget.dataset.modulo_id;

		let modulos = document.querySelector('#modulos');
		let mod = document.querySelector('#card_mod_' + modulo_id);
		let abajo = mod.nextElementSibling;
		let arriba = mod.previousElementSibling;

		//COMO

		if (permitirMover) {
			if (tipo == 'S' && arriba != null) {
				arriba.insertAdjacentElement('beforebegin', mod);

				document.querySelector('#card' + modulo_id).style.transition =
					'background .5s';
				document.querySelector('#card' + modulo_id).style.background =
					'#d7d7d7';
				setTimeout(function () {
					document.querySelector('#card' + modulo_id).style.background = '#FFF';
				}, 200);
			} else if (tipo == 'B' && abajo != null) {
				abajo.insertAdjacentElement('afterend', mod);
				document.querySelector('#card' + modulo_id).style.transition =
					'background .5s';
				document.querySelector('#card' + modulo_id).style.background =
					'#d7d7d7';
				setTimeout(function () {
					document.querySelector('#card' + modulo_id).style.background = '#FFF';
				}, 200);
			}
		}
	}
}
