const initialEnfasisData = {
	codigoId: '',
	codigoTipo: '',
	descripcion: '',
	observacion: '',
	codUsu: '',
};

const initialArrayEnfasisData = [];


export {
	initialEnfasisData,
    initialArrayEnfasisData
};
