const initialInteraccionData = {
	descripcion:'',
	icono:'',
	color:'',
	anulado:'',
	anuladoFec:'',
	codUsuAnula:''
};

const initialArrayInteraccionData = [];

export { initialInteraccionData, initialArrayInteraccionData };
