import { base_url } from '../config';
import { secureFetch } from '../../utils/http';
import { getAuthUser } from '../../utils/auth';

const endpoints_proyecto_actividadlimite = {
	GET: 'planif/actividad/limites',
	GETBYID: 'planif/actividad/limites',
	CREATE: 'planif/actividad/limites',
	EDIT: 'planif/actividad/limites',
	DELETE: 'planif/actividad/limites',
};

export async function getActividadLimiteApi() {
	const url = `${base_url}/api/${endpoints_proyecto_actividadlimite.GET}`;
	return await secureFetch(url, 'GET', null);
}

export async function getActividadLimiteByIdApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_actividadlimite.GETBYID}/${id}`;
	return await secureFetch(url, 'GET', null);
}

export async function createActividadLimiteApi(data) {
	const user = getAuthUser(); 

	if(data.rige == 'S'){
		data.codUsuRige = user.username;
	}

	const url = `${base_url}/api/${endpoints_proyecto_actividadlimite.CREATE}`;
	
	let dataCreate = {
		...data,
	};

	return await secureFetch(url, 'POST', dataCreate);
}
// 
export async function editActividadLimiteApi(id, data) {
	let dataEdit = {
		...data,
		planifMatId: data.planifMatId,
		planifUniId: data.planifUniId,
		minimo: data.minimo,
		maximo: data.maximo
	};

	const url = `${base_url}/api/${endpoints_proyecto_actividadlimite.EDIT}/${id}`;
	return await secureFetch(url, 'PUT', dataEdit);
}

export async function deleteActividadLimiteApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_actividadlimite.DELETE}/${id}`;
	return await secureFetch(url, 'DELETE', null);
}
