import React, { useState, useEffect } from 'react';
import { TextField, FormControl, Autocomplete } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Loading from '../../components/loading/Loading';
import {
	getProyectoModulosApi,
	createProyectoModuloApi,
	getQueryParams as getQueryParamsModulos,
} from '../../api/Proyecto/proyectoModulo';
import { generateKeyRandom } from '../../utils/helpers';
import './moduloAutocompleteComponent.scss';
import Divider from '@mui/material/Divider';

export function ModuloAutocompleteComponent(props) {
	const filter = createFilterOptions();
	let { idProyecto, setIdModulo, idModulo, setValueTema } = props;

	const [modulos, setModulos] = useState([]);
	const [valueModulo, setValueModulo] = useState('');
	const [inputValueModulo, setInputValueModulo] = useState('');
	const [inputNewValueModulo, setInputNewValueModulo] = useState('');
	const [inputValueModuloParent, setInputValueModuloParent] = useState();

	useEffect(() => {
		getProyectoModuloData();
	}, []);
	

	useEffect(() => {
		getProyectoModuloData();
	}, [idProyecto]);

	useEffect(() => {
		if (idModulo != null) {
			setInputValueModuloParent(idModulo);
		}
	}, [idModulo]);

	useEffect(() => {
		let val = modulos.filter(el => el.id == idModulo);

		if (val.length > 0) {
			setValueModulo(val[0].label);
			setIdModulo(idModulo);
		}
	}, [idModulo, modulos]);

	useEffect(() => {
		if (inputValueModuloParent != undefined) {
			setInputValueModuloParent(idModulo);
		}
	}, [inputValueModuloParent]);

	useEffect(() => {
		if (valueModulo != '' && valueModulo != null) {
			setValueModulo(valueModulo);
		}
	}, [valueModulo]);

	const handleChange = (event, newValue) => {
		if (typeof newValue === 'string') {
			// setValueModulo({
			// 	label: newValue,
			// });

			setValueModulo(newValue);
		} else if (newValue && newValue.inputValue) {
			// Create a new value from the user input
			// setValueModulo({
			// 	label: newValue.inputValue,
			// });
			createProyectoModulo(newValue.inputValue);
		} else {
			setValueModulo(newValue ?? null);
			setIdModulo(newValue?.id);
			setValueTema('');
		}
	};

	return (
		<>
			<Autocomplete
				//freeSolo
				id="combo-box-demo"
				value={valueModulo}
				options={modulos}
				noOptionsText={'Sin módulos creados'}
				selectOnFocus
				clearOnBlur
				handleHomeEndKeys
				onChange={handleChange}
				renderInput={params => {
					return <TextField {...params} label="Sin módulo asignado" />;
				}}
				filterOptions={(options, params) => {
					const filtered = filter(options, params);

					const { inputValue } = params;
					// Suggest the creation of a new value

					const isExisting = options.some(option => {
						return inputValue === option.label;
					});

					if (inputValue !== '' && !isExisting) {
						filtered.push({
							inputValue,
							label: 
							<div className='filterCrearModuloContent'>
								<p className="filterCrearModulo"> {inputValue} | <span>Crear</span></p>
							</div>
							
						});
					}

					return filtered;
				}}
				getOptionLabel={option => {
					// Value selected with enter, right from the input
					if (typeof option === 'string') {
						return option;
					}
					// Add "xxx" option created dynamically
					if (option.inputValue) {
						return option.inputValue;
					}
					// Regular option
					return option.label;
				}}
				renderOption={(props, option) => {
					return (
						<li
							data-id={option.id}
							{...props}
							key={option.id ?? generateKeyRandom()}
						>
							{option.label}{' '}
						</li>
					);
				}}
			/>
		</>
	);

	async function getProyectoModuloData() {
		try {
			let queryParamsDefault = getQueryParamsModulos();

			let { proyecto } = queryParamsDefault.filtersDefault.properties;
			let value = idProyecto;

			if(typeof value != 'undefined'){
				
				const query = { [proyecto]: value };

				
				queryParamsDefault.filters.properties.push(query);
				
				const resp = await getProyectoModulosApi(queryParamsDefault);
				
				const mutateTema = resp.map(el => {
					return { label: el.descripcion, id: el.id };
				});
				setModulos(mutateTema);
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function createProyectoModulo(newValue) {
		try {
			if (typeof newValue != undefined || newValue != null) {
				let result = await createProyectoModuloApi({
					descripcion: newValue,
					proyecto: idProyecto,
				});
				setValueModulo(result.descripcion);
				setInputValueModuloParent(result.id);
				setIdModulo(result.id);

				let modulosCopy = structuredClone(modulos);
				modulosCopy.push({ label: result.descripcion, id: result.id });
				setModulos(modulosCopy);
			}
		} catch (error) {
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);
		}
	}
}
