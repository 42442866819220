const initialMaterialData = {
	id: '',
	descripcion: '',
	color: '',
	icono: '',
};

const initialMaterialsData = [];


export {initialMaterialData,initialMaterialsData}