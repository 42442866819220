import {
	base_url,
} from '../config';
import { secureFetch } from '../../utils/http';
import {
	getAuthUser,
  } from "../../utils/auth";

const endpoints_unidad = {
	GET: 'planif/unidads',
	GETBYID: 'planif/unidads',
	CREATE: 'planif/unidads',
	EDIT: 'planif/unidads',
	DELETE: 'planif/unidads',
};

export async function getUnidadesApi() {
	const url = `${base_url}/api/${endpoints_unidad.GET}`;
	return await secureFetch(url, 'GET', null);
}

export async function getUnidadByIdApi(id) {
	const url = `${base_url}/api/${endpoints_unidad.GETBYID}/${id}`;
	return await secureFetch(url, 'GET', null);
}

export async function createUnidadApi(data) {
	const url = `${base_url}/api/${endpoints_unidad.CREATE}`;
	return await secureFetch(url, 'POST', data);
}

export async function editUnidadApi(id, data) {
	let user = getAuthUser();
	let dataEdit = {
		...data,
        descripcion: data.descripcion,
        anulado: data.anulado || '',
        color: data.color || '',
        icono: data.icono || ''
	
	};

	if(dataEdit.anulado == 'S'){
		
		dataEdit.codUsuAnula= user.username;
	}
	
	const url = `${base_url}/api/${endpoints_unidad.EDIT}/${id}`;
	return await secureFetch(url, 'PUT', dataEdit);

}

export async function deleteUnidadApi(id) {
	const url = `${base_url}/api/${endpoints_unidad.DELETE}/${id}`;
	return await secureFetch(url, 'DELETE', null);

}