import { Routes, Route, useNavigate, Link, NavLink } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { getAccessToken } from '../../utils/auth';
import { LoginAuth } from '../../components/login/LoginAuth';
import DataTable from 'react-data-table-component';
import ModalCustomConfirmacion from '../../components/modal/modalCustomConfirmacion/modalCustomConfirmacion';
// import { Button } from 'reactstrap';
import './listadoProyectos.scss';
import '../../App.css';
import CrearProyecto from '../../components/unidad/crearProyecto/CrearProyecto';
import {
	initialProyectosData,
	initialProyectoData,
} from '../../api/Proyecto/proyectoModel';
import WarningIcon from '@mui/icons-material/Warning';
import {
	getProyectosApi,
	deleteProyectoApi,
	getDownloadProyectoByIdApi,
} from '../../api/Proyecto/proyecto';
import { useNotificationMui } from '../../hooks/useNotificacionMui';
import { useNotificationMui as useSuccessCreateProyecto } from '../../hooks/useNotificacionMui';
import { NotificationMui as DeleteNotificationMui } from '../../components/notification/NotificactionMui';
import { NotificationMui as SuccessCreateProyecto } from '../../components/notification/NotificactionMui';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import DownloadIcon from '@mui/icons-material/Download';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Chip from '@mui/material/Chip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ModalHacerCopia from '../../components/modal/modalHacerCopia/ModalHacerCopia';
import { Container } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Notificacion from '../../components/snackbar/Notificacion';
import NotificacionLoad from '../../components/snackbar/NotificacionLoad';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Loading from '../../components/loading/Loading';

export default function ListadoProyectos() {
	useEffect(() => {
		getProyectos();
	}, []);

	const [
		isOpenNotification,
		openNotificacion,
		closeNotification,
		isTItle,
		setTitleNotification,
		isContent,
		setContentNotification,
	] = useNotificationMui(false);

	const [
		isOpenNotificationSuccessCreateProyecto,
		openNotificacionSuccessCreateProyecto,
		closeNotificationSuccessCreateProyecto,
		isTItleSuccessCreateProyecto,
		setTitleNotificationSuccessCreateProyecto,
		isContentSuccessCreateProyecto,
		setContentNotificationSuccessCreateProyecto,
	] = useSuccessCreateProyecto(false);

	const [proyectosData, setProyectosData] = useState(initialProyectosData);
	const [isCreate, setIsCreate] = useState(true);
	const [idDeleteProyecto, setIdDeleteProyecto] = useState(-1);
	const [idNewProyecto, setIdNewProyecto] = useState(-1);
	const [accion, setAccion] = useState(1);
	const [proyectoId, setProyectoId] = useState(null);
	const [typeFile, setTypeFile] = useState('pdf');

	const [dataTest, setDataTest] = useState([
		{
			descripcion: 'Sin proyectos creados',
		},
	]);

	let navigate = useNavigate();

	async function handleDeleteProyecto(event) {
		try {
			let id = event.currentTarget.dataset.id;

			setTitleNotification('Eliminación proyecto');
			setContentNotification('¿Esta seguro que desea eliminar el proyecto?');
			openNotificacion();
			setIdDeleteProyecto(id);
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	const handleNavigateToCargarDatos = () => {
		if (accion) {
			// navigate(`/temasProyecto/${idNewProyecto}`, { state:{id:idNewProyecto},replace: true });
			navigate(`/temasProyecto`, { state:{id:idNewProyecto},replace: true });
		} else {
			closeNotificationSuccessCreateProyecto();
		}
	};

	const handleSuccessCreateProyecto = id => {
		setTitleNotificationSuccessCreateProyecto('Proyecto creado');
		setContentNotificationSuccessCreateProyecto(
			'¿Qué es lo que querés hacer primero?'
		);
		openNotificacionSuccessCreateProyecto();
		setIdNewProyecto(id);
	};

	
	const handleSuccessCreateProyectoAfter = event => {
		let accion = event.target.value;

		if (accion == 'navigateToCargarDatos') {
			setAccion(1);
		} else {
			setAccion(0);
		}
	};

	const handleDownloadProyecto = async event => {
		if (typeFile != '') {
			let response = await getDownloadProyectoByIdApi(proyectoId, typeFile);
			let basePdf = 'data:application/octet-stream;base64,';
			let baseXls =
				'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
			var base = typeFile == 'pdf' ? basePdf : baseXls;

			var linkSource = base + response.binario;
			var downloadLink = document.createElement('a');
			var fileName = response.filename;

			downloadLink.href = linkSource;
			downloadLink.download = nombreArchivo + '.' + typeFile;
			downloadLink.click();
			setOpenNotifDescLoad(true);
			setOpenModalDescarga(false);
			setTimeout(() => {
				setOpenNotifDescarga(true);
			}, 1200);
		}
	};

	const handleOnChangeTypeFileDownloadProyecto = async event => {
		let type = event.target.value;
		if (type != '' && ['pdf', 'xlsx'].includes(type)) {
			setTypeFile(type);
		}
		return false;
	};

	const columns = [
		{
			name: 'Nombre del Proyecto',
			selector: row => (row.descripcion ? row.descripcion : ''),
			sortable: true,
			maxWidth: 200,
			minWidth: 140,
			width: 200,
			wrap: true,
		},
		{
			cell: obj => {
				return (
					<div className="botonesTabla">
						<Tooltip title="Detalle" placement="top" arrow>
							<Link
								data-id={obj.id ? obj.id : ''}
								to={`../temasProyecto`}
								state={{ id: obj.id }}
							>
								<ListAltIcon className="Icono-activo" />
							</Link>
						</Tooltip>

						<Tooltip title="Hacer una copia" placement="top" arrow>
							<Link data-id={obj.id} onClick={handleOpenModalHacerCopia}>
								<FileCopyIcon className="Icono-activo" />
							</Link>
						</Tooltip>
						<Tooltip title="Descargar" placement="top" arrow>
							<Link
								data-id={obj.id}
								data-nombreproyecto={obj.descripcion}
								onClick={showModalDescarga}
							>
								<DownloadIcon className="Icono-activo" />
							</Link>
						</Tooltip>

						<Tooltip title="Eliminar" placement="top" arrow>
							<Link
								data-id={obj.id}
								onClick={showModalConfirm}
								data-nombreproyecto={obj.descripcion}
							>
								<DeleteIcon className="Icono-activo" />
							</Link>
						</Tooltip>
					</div>
				);
			},
			right: true,
			ignoreRowClick: true,
			allowOverflow: true,
		},
	];

	const handleProyectoDelete = event => {
		try {
			deleteProyectoData(idDeleteProyecto);
			setTimeout(function () {
				setOpenNotifDelete(true);
			}, 1400);
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	};

	const emptyColumn = [
		{
			name: 'Nombre del Proyecto',
			cell: obj => {
				return <span>Sin proyectos creados</span>;
			},
			left: true,
			ignoreRowClick: true,
			allowOverflow: true,
		},
	];

	const [openModalHacerCopia, setOpenModalHacerCopia] = useState(false);

	const handleOpenModalHacerCopia = event => {
		let id = event.currentTarget.dataset.id;
		setProyectoId(id);
		setOpenModalHacerCopia(true);
	};

	const configuracion = {
		titulo: 'Proyecto creado',
		descripcion: '¿Que es lo que querés hacer primero?',
	};
	const [openNotifDescarga, setOpenNotifDescarga] = useState(false);

	const notiDescarga = {
		titulo: '',
		mensaje: 'Descarga exitosa',
		estilo: 'notiSuccessFilled',
		tiempo: 2000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const [openModalConfirm, setOpenModalConfirm] = useState(false);
	const [confirmacion, setConfirmacion] = useState();
	const [nombreDesc, setNombreDesc] = useState('');
	const [descargaNomb, setDescargaNomb] = useState('');
	const showModalConfirm = event => {
		setOpenModalConfirm(true);
		let id = event.currentTarget.dataset.id;
		let nombre = event.currentTarget.dataset.nombreproyecto;
		setIdDeleteProyecto(id);
		setNombreDesc(
			'Si eliminas "' + nombre + '" se perderan todos los datos cargados.'
		);
	};

	const closeModalConfirm = val => {
		setOpenModalConfirm(false);
		setConfirmacion(val);
	};

	const configuracionProy = {
		titulo: 'Eliminar',
		descripcion: nombreDesc,
	};

	const [openModalDescarga, setOpenModalDescarga] = useState(false);
	const [nombreArchivo, setNombreArchivo] = useState('');

	const showModalDescarga = event => {
		let id = event.currentTarget.dataset.id;
		let nombre = event.currentTarget.dataset.nombreproyecto;
		setNombreArchivo(nombre);
		setDescargaNomb('Descargando ' + nombre);
		setProyectoId(id);
		setOpenModalDescarga(true);
	};

	const configsDescarga = {
		titulo: 'Descargar proyecto',
		descripcion: '',
	};

	const closeModalDescarga = val => {
		setOpenModalDescarga(false);
	};

	const [openNotifLoad, setOpenNotifLoad] = useState(false);

	const configLoader = {
		titulo: descargaNomb,
		estilo: '',
		tiempo: 900,
		tipo: 'notiLoader',
		vertical: 'bottom',
		horizontal: 'center',
	};
	const configLoaderButton = {
		titulo: 'Descarga Exitosa',
		estilo: 'notiSuccessFilled',
		tiempo: 1400,
		tipo: 'notiLoaderButton',
		vertical: 'bottom',
		horizontal: 'center',
	};

	const showLoad = () => {
		setOpenNotifLoad(true);
	};

	const [openNotifDelete, setOpenNotifDelete] = useState(false);
	const [openNotifDescLoad, setOpenNotifDescLoad] = useState(false);

	const notiDelete = {
		titulo: 'Eliminación exitosa',
		mensaje: 'Se guardaron los cambios realizados',
		estilo: 'notiSuccessFilled',
		tiempo: 2000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const [openLoading, setOpenLoading] = React.useState(false);

	const handleCloseLoading = () => {
		setOpenLoading(false);
	};

	const handleOpenLoading = () => {
		setOpenLoading(true);
	};

	const numeros = [5, 10, 15, 20, 50];
	if (getAccessToken()) {
		return (
			<Container className="contenedor">
				<NotificacionLoad
					open={openNotifDescarga}
					setState={setOpenNotifDescarga}
					config={configLoaderButton}
					icono={<CheckCircleIcon style={{ marginRight: '4px' }} />}
				/>
				<NotificacionLoad
					open={openNotifDescLoad}
					setState={setOpenNotifDescLoad}
					config={configLoader}
				/>

				<Notificacion
					open={openNotifDelete}
					setState={setOpenNotifDelete}
					config={notiDelete}
				/>

				<ModalCustomConfirmacion
					openModalConfirm={openModalDescarga}
					onClose={closeModalDescarga}
					configs={configsDescarga}
					icon={
						<CheckCircleIcon
							className="confirmIcon"
							style={{ fontSize: '20px', color: '#53D258' }}
						/>
					}
					content={
						<FormControl className="contentDescarga">
							<p>Selecciona el formato</p>
							<RadioGroup
								aria-labelledby="demo-radio-buttons-group-label"
								defaultValue="pdf"
								name="radio-buttons-group"
								onChange={handleOnChangeTypeFileDownloadProyecto}
							>
								<FormControlLabel
									value="pdf"
									control={<Radio />}
									label="PDF(.pdf)"
								/>
								<FormControlLabel
									value="xlsx"
									control={<Radio />}
									label="Microsoft Excel (.xlsx)"
								/>
							</RadioGroup>
						</FormControl>
					}
					contentButton={
						<div style={{ marginTop: '-40px' }}>
							<Button
								className="buttonEliminarModal"
								onClick={() => {
									handleDownloadProyecto();
								}}
							>
								Continuar
							</Button>
							<Button
								outlined="true"
								className="buttonEliminarModalOutline"
								onClick={closeModalDescarga}
							>
								Cancelar
							</Button>
						</div>
					}
				/>

				<ModalCustomConfirmacion
					openModalConfirm={openModalConfirm}
					onClose={closeModalConfirm}
					configs={configuracionProy}
					icon={
						<WarningIcon
							className="confirmIcon"
							style={{ fontSize: '20px', color: '#626262' }}
						/>
					}
					content=""
					contentButton={
						<div style={{ marginTop: '-40px' }}>
							<Button
								className="buttonEliminarModal"
								onClick={handleProyectoDelete}
							>
								Continuar
							</Button>
							<Button
								outlined="true"
								className="buttonEliminarModalOutline"
								onClick={closeModalConfirm}
							>
								Cancelar
							</Button>
						</div>
					}
				/>

				<NotificacionLoad
					open={openNotifLoad}
					setState={setOpenNotifLoad}
					config={configLoader}
					icono={<CheckCircleIcon />}
					customButton={
						<Button color="inherit" size="small">
							Abrir archivo
						</Button>
					}
				/>

				<ModalHacerCopia
					open={openModalHacerCopia}
					setOpen={setOpenModalHacerCopia}
					proyectoId={proyectoId}
				/>

				<Loading
					bg="bgLoadingWhiteTrans"
					open={openLoading}
					tiempoMensaje={3000}
				/>

				<div>
					<div className="textoContenido">
						<h1 className="tituloContenido">Planificador de proyectos</h1>
						<p className="parrafoContenido">
							Configurá el plan de estudio de todas las actividades.
						</p>
					</div>
					<div className="botonesContenido">
						<CrearProyecto
							getProyectos={getProyectos}
							handleSuccessCreateProyecto={handleSuccessCreateProyecto}
						/>
					</div>

					<DataTable
						columns={columns}
						className="dataTableContainer"
						paginationComponentOptions={{
							rowsPerPageText: 'Registros por página:',
							rangeSeparatorText: 'de',
						}}
						paginationRowsPerPageOptions={numeros}
						data={proyectosData ? proyectosData : ''}
						paginationPerPage={5}
						direction="auto"
						pagination
						noDataComponent={
							<DataTable
								columns={emptyColumn}
								className="dataTableContainer"
								data={dataTest ? dataTest : ''}
								direction="auto"
								fixedHeaderScrollHeight="300px"
								responsive
								subHeaderAlign="right"
								subHeaderWrap
							/>
						}
						fixedHeaderScrollHeight="300px"
						responsive
						subHeaderAlign="right"
						subHeaderWrap
					/>
				</div>
				<DeleteNotificationMui
					isOpen={isOpenNotification}
					title={isTItle}
					content={isContent}
					icon="delete"
				>
					<Stack
						className="botonesNoti"
						direction="row"
						spacing={1}
						style={{
							display: 'flex',
							justifyContent: 'end',
						}}
					>
						<Chip
							label="Continuar"
							type="submit"
							clickable
							onClick={deleteProyectoData}
							style={{
								background: '#003087',
								height: '43px',
								width: '170px',
								fontSize: '22px',
								color: '#FFF',
								borderRadius: '20px',
							}}
						/>

						<Chip
							clickable
							label="Cancelar"
							variant="outlined"
							onClick={closeNotification}
							style={{
								height: '43px',
								width: '170px',
								fontSize: '22px',
								borderRadius: '20px',
								marginRight: '7px',
							}}
						/>
					</Stack>
				</DeleteNotificationMui>
				<ModalCustomConfirmacion
					openModalConfirm={isOpenNotificationSuccessCreateProyecto} //
					onClose={closeNotificationSuccessCreateProyecto}
					configs={configuracion}
					icon={
						<CheckCircleIcon
							className="confirmIcon"
							style={{ color: '#53D258' }}
						/>
					}
					content={
						<FormControl>
							<RadioGroup
								aria-labelledby="demo-radio-buttons-group-label"
								defaultValue="navigateToCargarDatos"
								name="radio-buttons-group"
								onChange={handleSuccessCreateProyectoAfter}
								style={{
									textAlign: 'left',
									marginTop: '18px',
									fontFamily: 'Roboto',
									fontStyle: 'normal',
									fontWeight: '400',
									fontSize: '14px',
									lineHeight: '16px',
									color: '#2D2926',
								}}
							>
								<FormControlLabel
									value="dontNavigateToCargarDatos"
									control={
										<Radio
											icon={
												<RadioButtonUncheckedIcon
													style={{ fontSize: '18px' }}
												/>
											}
											checkedIcon={
												<CheckCircleIcon style={{ fontSize: '18px' }} />
											}
											sx={{
												color: '#6683B7',
												'&.Mui-checked': {
													color: '#6683B7',
												},
											}}
										/>
									}
									label="Sólo crear y regresar a la lista"
								/>
								<FormControlLabel
									value="navigateToCargarDatos"
									control={
										<Radio
											icon={
												<RadioButtonUncheckedIcon
													style={{ fontSize: '18px' }}
												/>
											}
											checkedIcon={
												<CheckCircleIcon style={{ fontSize: '18px' }} />
											}
											sx={{
												color: '#6683B7',
												'&.Mui-checked': {
													color: '#6683B7',
												},
											}}
										/>
									}
									label="Comenzar a cargar datos"
								/>
							</RadioGroup>
						</FormControl>
					}
					contentButton={
						<Stack
							Stack
							className="botonesNoti"
							direction="row"
							spacing={1}
							style={{
								display: 'flex',
								justifyContent: 'end',
								marginTop: '8px',
							}}
						>
							<Chip
								label="Continuar"
								type="submit"
								clickable
								onClick={handleNavigateToCargarDatos}
								style={{
									background: '#003087',
									height: '33px',
									width: '128px',
									fontSize: '14px',
									color: '#FFF',
									borderRadius: '20px',
								}}
							/>

							<Chip
								clickable
								label="Cancelar"
								variant="outlined"
								onClick={closeNotificationSuccessCreateProyecto}
								style={{
									height: '33px',
									width: '128px',
									fontSize: '14px',
									borderRadius: '20px',
								}}
							/>
						</Stack>
					}
				/>
			</Container>
		);
	}

	return (
		<>
			<LoginAuth></LoginAuth>
		</>
	);

	async function getProyectos() {
		try {
			handleOpenLoading();
			let proyectos = await getProyectosApi();
			setProyectosData(proyectos);
			handleCloseLoading();
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function deleteProyectoData(id) {
		try {
			let proyecto = await deleteProyectoApi(id);
			setOpenModalConfirm(false);
			getProyectos();
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}
}
