import {
	base_url,
} from '../config';
import { secureFetch } from '../../utils/http';
import {
	getAuthUser,
  } from "../../utils/auth";

const endpoints_materia = {
	GET: 'asignatura',
};

export function getQueryParams() {
	return {
		pagination: {
			value: false,
			items: 2,
			page: 1,
		},
		filters: {
			properties: [],
		},
		filtersDefault: {
			properties: {
				codMat:"codMat",
				nombMate:"nombMate",
				nombMatA:"nombMatA",
				uniId:"uniId",
			} 
		},
	};
}

export async function getMateriaApi(queryParams=null) {
	const url = `${base_url}/api/${endpoints_materia.GET}`;
	if(queryParams)
		return await secureFetch(url, 'GET', queryParams);
	return await secureFetch(url, 'GET', null);
}