import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import './accordionWhite.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {
	Card,
	CardActions,
	Collapse,
	CardContent,
	CardHeader,
	Menu,
	MenuItem,
} from '@mui/material';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { IconButton } from '@mui/material';

export default function AccordionMod(props) {
	const {
		descripcion,
		detalles,
		content,
		detalleMaterials,
		showModalCrearActividad,
		moduloId,
		fnEdit,
		handleDeleteModulo,
		panel,
		handleChange,
	} = props;

	const [anchorEl, setAnchorEl] = React.useState(null);
	const openA = Boolean(anchorEl);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [expanded, setExpanded] = useState(false);

	const ExpandMore = styled(props => {
		const { expand, ...other } = props;
		return <IconButton {...other} />;
	})(({ theme, expand }) => ({
		transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
		margin: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	}));

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<div>
			<Card className="Accordion" style={{ borderRadius: '12px' }}>
				<CardActions
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
					className="accordionSummaryModulo"
				>
					<ExpandMore
						expand={expanded}
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more"
					>
						<ExpandMoreIcon style={{ flexDirection: 'row-reverse' }} />
					</ExpandMore>
					<CardHeader
						className="cardTitulo"
						title={
							<p className="cardDescriModulo">
								{descripcion?.length < 25
									? `${descripcion}`
									: `${descripcion?.substring(0, 24)}...`}
							</p>
						}
					/>

					<div className="col-auto">
						<Stack direction="row" spacing={1}>
							{detalleMaterials.map((item, index) => (
								<React.Fragment key={generateKeyRandom() + index}>
									<div className="chipLabelListado">
										<Chip
											key={generateKeyRandom() + index}
											className="chipListadoMain"
											style={{
												background: 'none',
												color: '#2D2926',
											}}
											icon={
												<span
													className="material-icons"
													style={{ color: '#33599F' }}
												>
													{item.iconoMaterial != null
														? item.iconoMaterial
														: 'public'}
												</span>
											}
											label={
												<span key={generateKeyRandom() + item.material}>
													{item.tiempoMaterial}
												</span>
											}
										/>
										<label
											className="labelChipListado"
											key={generateKeyRandom() + index}
										>
											{item.material}
										</label>
									</div>
								</React.Fragment>
							))}
							{detalles != '' ? (
								<Chip
									key={generateKeyRandom()}
									style={{
										backgroundColor: '#000',
										fontFamily: 'Montserrat',
										fontStyle: 'normal',
										color: '#FEFCFC',
										background: '#33599F',
										marginTop: '3px',
									}}
									icon={<AccessTimeIcon style={{ color: '#FEFCFC' }} />}
									label={detalles[0] ? detalles : '0 min'}
								/>
							) : (
								<Chip
									key={generateKeyRandom()}
									style={{
										backgroundColor: '#000',
										fontFamily: 'Montserrat',
										fontStyle: 'normal',
										fontWeight: '500',
										fontSize: '12px',
										lineHeight: '15px',
										color: '#FEFCFC',
										background: '#A9A9A9',
									}}
									icon={<AccessTimeIcon style={{ color: '#FEFCFC' }} />}
									label={'0 min'}
								/>
							)}
						</Stack>
					</div>

					<div className="col-auto">
						<IconButton
							id="basic-button"
							aria-controls={openA ? 'basic-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={openA ? 'true' : undefined}
							onClick={handleClick}
						>
							<MoreVertIcon />
						</IconButton>
						<Menu
							id="basic-menu"
							anchorEl={anchorEl}
							open={openA}
							style={{ zIndex: '0' }}
							anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
							transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'basic-button',
							}}
						>
							<MenuItem
								data-modulo_id={moduloId}
								onClick={() => {
									showModalCrearActividad(null, moduloId);
								}}
							>
								Crear actividad
							</MenuItem>
							<MenuItem
								className="ActDropdownMenuItem"
								data-modulo_id={moduloId}
								data-nombre_modulo={descripcion}
								onClick={handleDeleteModulo}
							>
								Eliminar
							</MenuItem>
						</Menu>
					</div>
				</CardActions>
				<Collapse in={expanded} timeout="auto" unmountOnExit>
					<CardContent>{content}</CardContent>
				</Collapse>
			</Card>
		</div>
	);
	function generateKeyRandom() {
		return (Math.random() + 1).toString(36).substring(7);
	}
}
