import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { getAccessToken } from '../../utils/auth';
import { LoginAuth } from '../../components/login/LoginAuth';
import {
	Form,
	Button,
	Row,
	Container,
	Input,
	FormGroup,
	Label,
	Table,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import {
	initialProyectoModuloData,
	initialProyectoModulosData,
} from '../../api/Proyecto/proyectoModel';
import {
    getProyectoModulosApi,
    getProyectoModuloByIdApi,
    createProyectoModuloApi,
    editProyectoModuloApi,
    deleteProyectoModuloApi
} from '../../api/Proyecto/proyectoModulo';

export default function ProyectoActividad() {
	const [id, setId] = useState('');

	const [idMaterial, setIdMaterial] = useState('');
	const [idUnidad, setUnidad] = useState('');

	const [idDeleteProyectoModulo, setDeleteProyectoModulo] =
		useState(false);

	const [isCreate, setIsCreate] = useState(true);

	const [proyectoModuloData, setProyectoModuloData] = useState(
		initialProyectoModuloData
	);

	const [proyectoModulosData, setProyectoModulos] = useState(
		initialProyectoModulosData
	);

	useEffect(() => {
		if (idDeleteProyectoModulo) {
			getProyectoModulos();
		}
	}, [idDeleteProyectoModulo]);

	const handleChangeProyectoModulo = event => {
		setProyectoModuloData({
			...proyectoModuloData,
			[event.target.name]: event.target.value,
		});
	};

	const handleEditProyectoModulo = event => {
		let id = window.event.target.dataset.id;
		let proyecto = proyectoModulosData.filter(el => el.id == id);
		if (proyecto.length) {
			setProyectoModuloData(proyecto[0]);
			setIsCreate(false);
		}
	};

	const handleDeleteProyectoModulo = event => {
		let id = window.event.target.dataset.id;
		setDeleteProyectoModulo(false);
		deleteProyectoModulo(id);
	};

	const handleSubmitProyecto = event => {
		event.preventDefault();
		isCreate ? createProyectoModulo() : editProyectoModulo();
	};

	if (getAccessToken()) {
		return (
			<>
				<h1>ABM proyecto modulo</h1>
				<ToastContainer></ToastContainer>
				<Container>
					<Row>
						<Button color="primary" onClick={getProyectoModulos}>
							get proyecto modulo
						</Button>
					</Row>
					<Table bordered>
						<thead>
							<tr>
								<th>#</th>
								<td>descripcion</td>
								<td>observacion</td>
								<td>fecha</td>
								<td>ordenVisual</td>
								<td>proyecto</td>
							</tr>
						</thead>
						<tbody>
							{proyectoModulosData.map((proyecto, i) => {
								return (
									<tr key={proyecto.id}>
										<td>{proyecto.id}</td>
										<td>{proyecto.descripcion}</td>
										<td>{proyecto.observacion}</td>
										<td>{proyecto.fecha}</td>
										<td>{proyecto.ordenVisual}</td>
										<td>{proyecto.proyecto}</td>
										<td>
											<Button
												color="primary"
												size="sm"
												data-id={proyecto.id}
												onClick={handleEditProyectoModulo}
											>
												edit
											</Button>
											<Button
												color="warning"
												size="sm"
												data-id={proyecto.id}
												onClick={handleDeleteProyectoModulo}
											>
												delete
											</Button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Container>

				{/* <Container>
					<Row>
						<div>
							<Input
								id="id"
								name="id"
								onChange={handleChangeMaterial}
								value={id}
							/>
						</div>
						<Button color="primary" onClick={getUnidadById}>
							get unidad by id
						</Button>
					</Row>
				</Container>
                        */}
				<Container>
					Create
					<Row>
						<Form onSubmit={handleSubmitProyecto}>
							<FormGroup>
								<Input
									id="id"
									name="id"
									type="hidden"
									onChange={handleChangeProyectoModulo}
									value={proyectoModuloData?.id || ''}
								/>
								<Label for="descripcion">descripcion</Label>
								<Input
									id="descripcion"
									name="descripcion"
									onChange={handleChangeProyectoModulo}
									value={proyectoModuloData?.descripcion || ''}
								/>
								<Label for="observacion">observacion</Label>
								<Input
									id="observacion"
									name="observacion"
									onChange={handleChangeProyectoModulo}
									value={proyectoModuloData?.observacion || ''}
								/>
								<Label for="fecha">fecha</Label>
								<Input
									id="fecha"
									name="fecha"
									onChange={handleChangeProyectoModulo}
									value={
										proyectoModuloData?.fecha ||
										moment(new Date()).format('DD/MM/YYYY')
									}
								/>
								<Label for="ordenVisual">ordenVisual</Label>
								<Input
									id="ordenVisual"
									name="ordenVisual"
									onChange={handleChangeProyectoModulo}
									value={proyectoModuloData?.ordenVisual || ''}
								/>
								<Label for="proyecto">proyecto</Label>
								<Input
									id="proyecto"
									name="proyecto"
									onChange={handleChangeProyectoModulo}
									value={proyectoModuloData?.proyecto || ''}
								/>
							</FormGroup>
							<Button color="primary" type="submit">
								{isCreate ? 'create' : 'edit'}
							</Button>
						</Form>
					</Row>
				</Container>
			</>
		);
	}

	async function getProyectoModulos() {
		try {
			let proyectos = await getProyectoModulosApi();
			setProyectoModulos(proyectos);
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function createProyectoModulo() {
		try {
            let  {descripcion,observacion,proyecto} = proyectoModuloData;
			let result = await createProyectoModuloApi({descripcion,observacion,proyecto});
			setProyectoModulos([...proyectoModulosData, result]);
		} catch (error) {
			console.log(await error.json());
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);
		}
	}

	async function deleteProyectoModulo(id) {
		try {
			await deleteProyectoModuloApi(id);
			setDeleteProyectoModulo(true);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);
			console.log(await error.json());
		}
	}

	async function editProyectoModulo() {
		try {
			let id = proyectoModuloData.id;
			await editProyectoModuloApi(id, proyectoModuloData);
			setIsCreate(true);
			setProyectoModuloData(initialProyectoModuloData);
			getProyectoModulos();
		} catch (error) {
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);
			console.log(await error?.json());
		}
	}

	return (
		<>
			<LoginAuth></LoginAuth>
		</>
	);
}
