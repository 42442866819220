import { ACCESS_TOKEN, FULL_NAME, REFRESH_TOKEN,ROLES } from '../api/constants';
import jwtDecode from 'jwt-decode';

export function getAccessToken() {
	const accessToken = localStorage.getItem(ACCESS_TOKEN);
	if (
		!accessToken ||
		accessToken === 'null' ||
		typeof accessToken == 'undefined'
	) {
		return null;
	}

	return expireToken(accessToken) ? null : accessToken;
}

export function getAuthUser() {
	const accessToken = localStorage.getItem(ACCESS_TOKEN);
	if (!accessToken || accessToken === 'null' || accessToken == 'undefined') {
		return null;
	}

	return jwtDecode(accessToken);
}

export function getRefreshToken() {
	const refreshToken = localStorage.getItem(REFRESH_TOKEN);
	return refreshToken;
}

export function logout() {
	localStorage.removeItem(ACCESS_TOKEN);
	localStorage.removeItem(REFRESH_TOKEN);
	localStorage.removeItem(FULL_NAME);
	localStorage.removeItem(ROLES);
}

export function setToken(id_token, refresh_token) {
	localStorage.setItem(ACCESS_TOKEN, id_token);
	localStorage.setItem(REFRESH_TOKEN, refresh_token);
}

export function getItemLocalStorage(item) {
	return localStorage.getItem(item)
}

export function getRolesFromLocalStorage() {
	 return localStorage.getItem(ROLES)?.split(',') ?? [];
	// return ["ROLE_PERSONA"];
	// return ['ROLE_CONTE_PLAN'];
	// return ['ROLE_ADMIN_PLAN'];
}

const expireToken = token => {
	try {
		const seconds = 3600;
		const payload = jwtDecode(token);
		const { exp } = payload;
		const now = (Date.now() + seconds) / 1000;
		return now > exp;
	} catch (error) {
		window.location.href = '/';
	}
};
