import { base_url } from '../config';
import { secureFetch } from '../../utils/http';
import { getAuthUser } from '../../utils/auth';

const endpoints_proyecto_detalle = {
	GET: 'planif/proyecto/detalles',
	GETBYID: 'planif/proyecto/detalles',
	CREATE: 'planif/proyecto/detalles',
	EDIT: 'planif/proyecto/detalles',
	DELETE: 'planif/proyecto/detalles',
};

export async function getProyectoDetallesApi() {
	const url = `${base_url}/api/${endpoints_proyecto_detalle.GET}`;
	return await secureFetch(url, 'GET', null);
}

export async function getProyectoDetalleByIdApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_detalle.GETBYID}/${id}`;
	return await secureFetch(url, 'GET', null);
}

export async function createProyectoDetalleApi(data) {
	const url = `${base_url}/api/${endpoints_proyecto_detalle.CREATE}`;
	// let dataCreate = {
	// 	...data,
	// 	planifUniId: +data.planifUniId,
	// 	cantidad: +data.cantidad,
	// };
	
	return await secureFetch(url, 'POST', data);

}

export async function editProyectoDetalleApi(id, data) {
	let dataEdit = {
		...data
	};

	const url = `${base_url}/api/${endpoints_proyecto_detalle.EDIT}/${id}`;
	return await secureFetch(url, 'PUT', dataEdit);
}

export async function deleteProyectoDetalleApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_detalle.DELETE}/${id}`;
	return await secureFetch(url, 'DELETE',null);
}
