import React from 'react';
import { getUserApi } from '../../api/user';
import './ProfileInfo.css';
import {
	Button,
	Input,
	Badge,
	Label,
} from 'reactstrap';
import { useState } from 'react';


export function ProfileInfo() {

	const [nombre, setNombre] = useState('');
	const [dni, setDni] = useState('');
	const [codigo, setCodigo] = useState('');

    async function getUser() {
        try {
            let val = document.getElementById('codigo').value;
            const u = await getUserApi(val);
            setNombre(u.fullName);
            setDni(u.identification);
            setCodigo(u.username);
        } catch (error) {
            console.log("🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error", error)
        }
    }
	return (
		<>
			<div className="perfilContainer">
				<div className="perfilCodigo">
					<Label className='inputLabel'>Código de usuario</Label>
					<Input className="inputCodigo" type="text" id="codigo" placeholder='Ingrese el código...'/>
					<Button color="primary" onClick={getUser}>Buscar</Button>
				</div>
				<div className="perfilInfo">
					<h2 className='perfilNombre'>{nombre}</h2>
					<Badge className='perfilBadge'>{dni}</Badge>
					<Badge className='perfilBadge'>{codigo}</Badge>
				</div>
			</div>
		</>
	);

}
