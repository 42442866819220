import React, { useEffect, useState } from 'react';
import './cardProyectoTablero.scss';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CardActiTablero from '../cardActiTablero/CardActiTablero';
import Chip from '@mui/material/Chip';
import Accordion from '@mui/material/Accordion';
import Stack from '@mui/material/Stack';
import { Tooltip } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EnfasisTablero from '../enfasisTablero/EnfasisTablero';
import {
	Menu,
	MenuItem,
	Card,
	CardContent,
	CardHeader,
	Collapse,
	CardActions,
	IconButton,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { ThreePointsMenu } from '../threePointsMenu/ThreePointsMenu';

export default function CardProyectoTablero(props) {
	const {
		contentModulos,
		enfasis,
		showModalCrearActividad,
		actividades,
		actividadesDetalleMaterials,
		actividadesDetalleEnfasis,
		showModalConfirmDelete,
		actividadesDetalle,
		handleOnClickEditActividad,
		handleDeleteActividad,
		panel,
		expanded,
		handleChange,
	} = props;

	const [tiempoActividades, setTiempoActividades] = useState('0 min');

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		if (actividadesDetalle.length) {
			setTiempoActividades(actividadesDetalle[0].tiempoModulos);
		}
	}, [actividadesDetalle]);

	const AccordionSummary = styled(props => (
		<MuiAccordionSummary
			expandicon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
			{...props}
		/>
	))(({ theme }) => ({
		flexDirection: 'row-reverse',
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(180deg)',
		},
	}));

	const headerStyleFill = 'summaryHeader';
	const headerStyleEmpty = 'summaryHeaderEmpt';

	const open = Boolean(anchorEl);

	return (
		<>
			<Accordion
				className="Accordion"
				expanded={expanded === panel}
				onChange={handleChange(panel)}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
					className={actividades != '' ? headerStyleFill : headerStyleEmpty}
				>
					<div className="cardTitulo">
						<p className="cardDescri">Recursos Compartidos</p>
						<div className="cardOpciones" style={{ marginRight: '-10px' }}>
							<Stack direction="row" spacing={1}>
								<Chip
									className="chipCardProyec"
									icon={<AccessTimeIcon style={{ color: '#FFF' }} />}
									label={tiempoActividades}
									style={{
										marginRight: '10px',
										backgroundColor: '#33599F',
										color: '#FFF',
									}}
								/>
							</Stack>
							<div className='cardSeccion'>
								<ThreePointsMenu>
									<MenuItem
										// selected={false}
										data-actividad_id={null}
										data-enfasis_id={null}
										data-modulo_id={null}
										onClick={event => {
											event.stopPropagation();
											event.preventDefault();
											showModalCrearActividad();
										}}
									>
										Crear Actividad
									</MenuItem>
								</ThreePointsMenu>
							</div>
						</div>
					</div>
				</AccordionSummary>
				<AccordionDetails>
					<div
						style={{
							display: 'flex',
							justifyContent: 'right',
							marginBottom: '-15px',
						}}
					>
						{actividadesDetalleMaterials?.map((item, index) => (
							<Stack
								direction="row"
								spacing={1}
								style={{
									display: 'flex',
									justifyContent: 'right',
									marginBottom: '35px',
								}}
								key={generateKeyRandom() + index}
							>
								<Tooltip title={item.material} arrow>
									<Chip
										style={{
											background: 'none',
											fontFamily: 'Roboto',
											fontStyle: 'normal',
											fontWeight: '400',
											fontSize: '14px',
											lineHeight: '16px',
											color: '#003087',
										}}
										icon={
											<span
												className="material-icons"
												style={{ color: '#33599F', fontSize: '20px' }}
											>
												{item.iconoMaterial != null
													? item.iconoMaterial
													: 'public'}
											</span>
										}
										label={
											<span key={generateKeyRandom() + item.material}>
												{item.tiempoMaterial}
											</span>
										}
									/>
								</Tooltip>
							</Stack>
						))}
					</div>
					<div className="tableroGeneral">
						{enfasis.map((item, index) => (
							<EnfasisTablero
								key={index}
								nombreEnfasis={item.descripcion}
								actividades={actividades}
								detallesEnfasis={actividadesDetalleEnfasis}
								showModalCrearActividad={showModalCrearActividad}
								enfasisId={item.id}
								hr={item.color != null ? item.color : '#a0a0a0'}
								keyRandom={generateKeyRandom}
								CardActiTablero={handleOnClickEditActividad}
								contentActi={actividades?.map((el, index) => (
									<React.Fragment key={index}>
										{el.planifEnfId == item.id && (
											<CardActiTablero
												enfasisId={el.planifEnfId}
												id={el.id}
												handleOnClickEditActividad={handleOnClickEditActividad}
												handleDeleteActividad={handleDeleteActividad}
												descriDocente={el.descriDocente}
												showModalConfirmDelete={showModalConfirmDelete}
												proposito={el.proposito}
												nombreActi={el.nombreActividad}
												dificultad={el.dificultad}
												descriEnfasis={el.descriEnfasis}
												descriTipo={el.descriTipo}
												tema={el.tema}
												detalles={el.detalles}
												material={el.material}
												iconoProposito={el.iconoProposito}
												iconoDificultad={el.iconoDificultad}
												iconoMaterial={el.iconoMaterial}
												iconoTipo={el.iconoTipo}
												iconoEnfasis={el.iconoEnfasis}
												tiempoActividad={el.tiempoActividad}
											/>
										)}
									</React.Fragment>
								))}
							/>
						))}
					</div>
				</AccordionDetails>
			</Accordion>
			<div id="modulos">{contentModulos}</div>
		</>
	);

	function generateKeyRandom() {
		return (Math.random() + 1).toString(36).substring(7);
	}
}
