import { base_url } from '../config';
import { secureFetch } from '../../utils/http';
import { getAuthUser } from '../../utils/auth';

const endpoints_proyecto_competencia = {
	GET: 'planif/competencias',
	GETBYID: 'planif/competencias',
	CREATE: 'planif/competencias',
	EDIT: 'planif/competencias',
	DELETE: 'planif/competencias',
};

export async function getCompetenciaApi() {
	const url = `${base_url}/api/${endpoints_proyecto_competencia.GET}`;
	return await secureFetch(url, 'GET', null);
}

export async function getCompetenciaByIdApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_competencia.GETBYID}/${id}`;
	return await secureFetch(url, 'GET', null);
}

export async function createCompetenciaApi(data) {
    // {
    //     "descripcion": null,
    //     "icono": null,
    //     "color": null,
    //     "anulado": "N",
    //     "anuladoFec": null,
    //     "codUsuAnula": null
    // }
	const url = `${base_url}/api/${endpoints_proyecto_competencia.CREATE}`;
	let dataCreate = {
        ...data,
	};

	
	return await secureFetch(url, 'POST', dataCreate);

}

export async function editCompetenciaApi(id, data) {
    let user = getAuthUser();
	let dataEdit = {
		...data,
        descripcion: data.descripcion,
        anulado: data.anulado || '',
        color: data.color || '',
        icono: data.icono || '',
	};
	
	if(dataEdit.anulado == 'S'){
		
		dataEdit.codUsuAnula= user.username;
	}
	
	console.log(dataEdit);

	const url = `${base_url}/api/${endpoints_proyecto_competencia.EDIT}/${id}`;
	return await secureFetch(url, 'PUT', dataEdit);
}

export async function deleteCompetenciaApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_competencia.DELETE}/${id}`;
	return await secureFetch(url, 'DELETE',null);
}
