import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import MuiAccordionSummary, {
} from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import BtnCrear from '../button/BtnCrear';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import './accordionEnfasis.scss';

export default function AccordionEnf(props) {
	const [tiempoEnfasis, setTiempoEnfasis] = useState('0 min');

	const AccordionSummary = styled(props => (
		<MuiAccordionSummary
			expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
			{...props}
		/>
	))(({ theme }) => ({
		backgroundColor: '#E9E9E9',
		flexDirection: 'row-reverse',
		padding: '24px 16px',
		gap: '16px',
		background: 'rgba(233, 233, 233, 0.6)',
		borderRadius: '16px',
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: '700',
		fontSize: '16px',
		lineHeight: '16px',
		color: '#2D2926',
		height: '94px',
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(180deg)',
		},
		'& .MuiAccordionSummary-content': {
			marginLeft: theme.spacing(1),
		},
	}));

	const {
		descriTipo,
		tiempoActividad,
		detalleEnfasis,
		content,
		descriEnfasis,
		showModalCrearActividad,
		color,
		enfasiId,
		moduloId,
		handleDeleteActividad,
		panel,
		expanded,
		handleChange,
	} = props;

	useEffect(() => {
		setTiempoEnfasis(
			detalleEnfasis.filter(el => el.planifEnfId == enfasiId).length > 0
				? detalleEnfasis.filter(el => el.planifEnfId == enfasiId)[0]?.tiempo
				: '0 min'
		);
	});

	function generateKeyRandom() {
		return (Math.random() + 1).toString(36).substring(7);
	}

	return (
		<div>
			<Accordion className="my-3">
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<div className="row w-100 justify-content-between align-items-center">
						<div className="col-auto p-0">
							<div
								style={{
									fontFamily: 'Roboto',
									fontStyle: 'normal',
									fontWeight: '700',
									fontSize: '16px',
									lineHeight: '16px',
									color: '#2D2926',
								}}
							>
								{descriEnfasis}
							</div>
						</div>

						<span className="col-auto p-0">
							<Stack direction="row" spacing={1}>
								<Chip
									key={generateKeyRandom()}
									style={{
										background: '#FEFCFC',
										opacity: '0.76',
										fontFamily: 'Montserrat',
										fontStyle: 'normal',
										fontWeight: '500',
										fontSize: '12px',
										color: '#44464E',
									}}
									icon={<AccessTimeIcon style={{ color: '#33599F' }} />}
									label={tiempoEnfasis}
								/>
							</Stack>
						</span>

						<div
							className="hr-ens"
							style={{
								backgroundColor: color,
							}}
						></div>
					</div>
				</AccordionSummary>
				<AccordionDetails className="EnfAccordionDetails">
					{content}
					<BtnCrear
						texto="Crear Actividad"
						moduloId={moduloId}
						enfasisId={enfasiId}
						fn={showModalCrearActividad}
					/>
				</AccordionDetails>
			</Accordion>
		</div>
	);
}
