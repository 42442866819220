import React from 'react';
import { base_url } from '../config';
import { secureFetch } from '../../utils/http';
import { getAuthUser } from '../../utils/auth';

const endpoints_material_unidad = {
	GET: 'planif/materials/unidads',
	GETBYID: 'planif/materials/unidads',
	CREATE: 'planif/materials/unidads',
	DELETE: 'planif/materials/:id_material/unidads/:id_unidad',
	EDIT: 'planif/materials/unidads',
	GETCHK: 'planif/materials/check/unidads/:id_material'
};

export function getQueryParams() {
	return {
		pagination: {
			value: false,
			items: 2,
			page: 1,
		},
		filters: {
			properties: [],
		},
		filtersDefault: {
			properties: {
				anulado: 'anulado',
			},
		},
	};
}

export async function getMaterialsUnidadApi(queryParams = null) {
	const url = `${base_url}/api/${endpoints_material_unidad.GET}`;
	if (queryParams) return await secureFetch(url, 'GET', queryParams);
	return await secureFetch(url, 'GET', null);
}

export async function getMaterialsUnidadByIdApi(id, queryParams = null) {
	const url = `${base_url}/api/${endpoints_material_unidad.GETBYID}`;
	return await secureFetch(url, 'GET', queryParams);
}

export async function createMaterialsUnidadApi(data) {
	const url = `${base_url}/api/${endpoints_material_unidad.CREATE}`;
	return await secureFetch(url, 'POST', data);
}

export async function editMaterialsUnidadApi(id, data) {
	let dataEdit = {
		...data,
	};
	const url = `${base_url}/api/${endpoints_material_unidad.EDIT}/${id}`;
	return await secureFetch(url, 'PUT', dataEdit);
}

export async function deleteMaterialsUnidadApi(id_material, id_unidad) {
	const url = `${base_url}/api/${endpoints_material_unidad.DELETE}`;

	let urlA = url.replace(':id_material', id_material);
	let urlB = urlA.replace(':id_unidad', id_unidad);

	return await secureFetch(urlB, 'DELETE', null);
}

export async function getCheckActividadApi(id_material) {
	let url = `${base_url}/api/${endpoints_material_unidad.GETCHK}`;

	let urlA = url.replace(':id_material', +id_material);

	return await secureFetch(urlA, 'GET', null);
}
