import { base_url } from '../config';
import { secureFetch } from '../../utils/http';
import { getAuthUser } from '../../utils/auth';

const endpoints_proyecto_equivalencia = {
	GET: 'planif/equivalencias',
	GETBYID: 'planif/equivalencias',
	CREATE: 'planif/equivalencias',
	EDIT: 'planif/equivalencias',
	DELETE: 'planif/equivalencias',
};

export function getQueryParams() {
	return {
		pagination: {
			value: false,
			items: 2,
			page: 1,
		},
		filters: {
			properties: [],
		},
		filtersDefault: {
			properties: {
				material: 'material',
				unidad: 'unidad',
				dificultad: 'dificultad',
				proposito: 'proposito',
				anulado: 'anulado',
			},
		},
	};
}

export async function getEquivalenciaApi(queryParams = null) {
	const url = `${base_url}/api/${endpoints_proyecto_equivalencia.GET}`;
	if (queryParams) return await secureFetch(url, 'GET', queryParams);
	return await secureFetch(url, 'GET', null);
}

export async function getenequivalenciaByIdApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_equivalencia.GETBYID}/${id}`;
	return await secureFetch(url, 'GET', null);
}

export async function createEquivalenciaApi(data) {
	// {
	//     "material": 2,
	//     "proposito": 12,
	//     "dificultad": 15,
	//     "unidad": 4
	//		"factor": 1.4,
	//      "funcion": M || R
	//   }
	const url = `${base_url}/api/${endpoints_proyecto_equivalencia.CREATE}`;
	let dataCreate = {
		...data,
	};

	return await secureFetch(url, 'POST', dataCreate);
}

export async function editEquivalenciaApi(id, data) {
	// {
	//     "factor": 10.989
	//   }
	let dataEdit = {
		...data,
		factor: data.factor,
	};

	const url = `${base_url}/api/${endpoints_proyecto_equivalencia.EDIT}/${id}`;
	return await secureFetch(url, 'PUT', dataEdit);
}

export async function deleteEquivalenciaApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_equivalencia.DELETE}/${id}`;
	return await secureFetch(url, 'DELETE', null);
}
