import React, { useEffect, useState } from 'react';
import {
	getUnidsApi,
	getQueryParams as getQueryParamsUnidad,
} from '../../api/Unid/unid';
import {
	getCarrsApi,
	getQueryParams as getQueryParamsCarrera,
} from '../../api/Carrera/carr';
import {
	getMateriaApi,
	getQueryParams as getQueryParamsMateria,
} from '../../api/Materia/materia';

import {
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	TextField,
} from '@mui/material';

import {
	getExpressionCkeckIfValueIsNumAndPoint,
	getExpressionCkeckIfValueIsHour,
} from '../../utils/helpers';
import ReferenciaInput from '../referenciaInput/ReferenciaInput';

export default function UnidSelect(props) {
	const [unids, setUnids] = useState([]);
	const [carreras, setCarreras] = useState([]);
	const [codigoMateriaSearch, setCodigoMateriaSearch] = useState('');
	const [asignatura, setAsignatura] = useState('');
	const [valid, setValid] = useState(true);

	const {
		duracion,
		setDuracion,
		duracionRegex,
		setDuracionRegex,
		unidSelected,
		carrSelected,
		setUnidSelected,
		setCarrSelected,
		codigoMateria,
		setCodigoMateria,
		mateId,
		setMateId,
	} = props;

	useEffect(() => {
		getUnidsData();

		if (carrSelected != '' && carrSelected != null) {
			setCodigoMateria('');
			setAsignatura('');
			setMateId('');
		}
	}, []);

	useEffect(() => {
		/* setCodigoMateriaSearch(codigoMateria); */
		if (codigoMateriaSearch) {
			getMateriaData();
		}
	}, []);

	useEffect(() => {
		loadSelectCarrs();
	}, [unidSelected]);

	useEffect(() => {
		/* setCodigoMateriaSearch(codigoMateria); */
		if (codigoMateriaSearch) {
			getMateriaData();
		}
	}, [codigoMateriaSearch]);

	const handleOnChangeUnidadAcademica = event => {
		let uni_id = event.target.value;
		setUnidSelected(uni_id);
		setCodigoMateria('');
		setAsignatura('');
		setMateId('');
	};

	const loadSelectCarrs = () => {
		getCarrsData();
	};

	const handleOnChangeCarrera = event => {
		let carr_id = event.target.value;
		setCarrSelected(carr_id);
		setCodigoMateria('');
		setAsignatura('');
		setMateId('');
	};

	const handleOnBlurCodigoMateria = event => {
		let codigo = event.target.value;
		if (codigo?.length == 7) {
			setCodigoMateriaSearch(codigo);
			if (codigoMateriaSearch) {
				getMateriaData();
			}
		} else {
			setCodigoMateria('');
			setMateId('');
			setAsignatura('');
		}
	};

	const handleOnFocusCodigoMateria = event => {
		setCodigoMateria('');
		setAsignatura('');
		setCarrSelected('');
		setMateId('');
	};

	const handleOnChangeCodigoMateria = event => {
		let codigo_materia_value = event.target.value;
		setCodigoMateria(codigo_materia_value);
		setCarrSelected('');
	};

	const handleOnChangeDuracion = event => {
		let d = '';
		const regexInput = getExpressionCkeckIfValueIsHour();
		const regexStructure = getExpressionCkeckIfValueIsNumAndPoint();

		setDuracionRegex(regexStructure.test(event.target.value));

		setValid(regexStructure.test(event.target.value));

		if (regexInput.test(event.target.value)) {
			d = event.target.value;
		} else {
			d = d != '' ? duracion : d;
		}

		setDuracion(d);
	};

	const [show, setShow] = useState(true);

	function handleOnFocusDuracion() {
		setShow(true);
	}

	return (
		<div>
			<div className="seccionSelectProyecto">
				{/* {String(carrSelected) +  " - " + String(unidSelected)} */}
				<FormControl fullWidth className="inputUnidProyecto">
					<InputLabel id="select-unidad-academica-label">
						Unidad académica
					</InputLabel>
					<Select
						value={unidSelected || ''}
						id="select-unidad-academica"
						labelId="select-unidad-academica-label"
						label="Unidad académica"
						onChange={handleOnChangeUnidadAcademica}
					>
						<MenuItem key={'SeleccionUnid'} value={carrSelected}>
							<em>Seleccione</em>
						</MenuItem>
						{unids?.map((el, index) => {
							return (
								<MenuItem id={el.id} key={index} value={el.uniId}>
									{el.nombUniAl}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
				<br></br>
				<br></br>
				<FormControl fullWidth className="inputUnidProyecto">
					<InputLabel id="select-unidad-carrera-label">Carrera</InputLabel>
					<Select
						id="select-unidad-carrera"
						labelId="select-unidad-carrera-label"
						value={carrSelected ?? ''}
						label="Carrera"
						type="select"
						onChange={handleOnChangeCarrera}
					>
						<MenuItem key={'SeleccionCarr'} value={carrSelected}>
							<em>Seleccione</em>
						</MenuItem>
						{carreras?.map((el, index) => {
							return (
								<MenuItem
									onClick={handleOnChangeCarrera}
									id={el.id}
									key={index}
									value={el.codCarr}
								>
									{el.nombCarr}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
				<br></br>
				<br></br>
				<FormControl
					variant="standard"
					style={{ marginRight: '6px' }}
					className="inputCodProyecto"
				>
					<TextField
						id="codigo-carrera"
						label="Código"
						// variant="outlined"
						value={codigoMateria ?? ''}
						onBlur={handleOnBlurCodigoMateria}
						//onFocus={handleOnFocusCodigoMateria}
						onChange={handleOnChangeCodigoMateria}
						helperText="Debe tener 7 dígitos"
						inputProps={{ maxLength: 7 }}
					/>
				</FormControl>
				<br></br>
				<br></br>
				<FormControl disabled className="inputUnidProyecto">
					<TextField
						disabled
						id="outlined-disabled"
						label="Asignatura"
						value={asignatura ?? ''}
					/>
				</FormControl>
				<br></br>
				<br></br>
				<FormControl variant="filled" className="inputDuraProyecto">
					<TextField
						inputProps={{ maxLength: 5 }}
						id="duracion"
						error={!valid}
						label={
							<>
								Duración hrs<b style={{ color: 'red' }}> *</b>
							</>
						}
						value={duracion ?? ''}
						onChange={handleOnChangeDuracion}
						onFocus={handleOnFocusDuracion}
					/>
				</FormControl>
				<hr />
			</div>
			<ReferenciaInput
				color={'#00BCD4'}
				mensaje={
					'La duración es un porcentaje expresado en horas. Por ejemplo 30 minutos equivale a 0.5.'
				}
				show={show}
			/>
		</div>
	);

	async function getUnidsData() {
		let queryParamsDefault = getQueryParamsUnidad();
		let { isUnidAcad } = queryParamsDefault.filtersDefault.properties;
		const query = { [isUnidAcad]: 'S' };
		queryParamsDefault.filters.properties.push(query);

		let unids = await getUnidsApi(queryParamsDefault);
		setUnids(unids);
	}

	async function getCarrsData() {
		let queryParamsDefault = getQueryParamsCarrera();
		let { unid } = queryParamsDefault.filtersDefault.properties;
		const query = { [unid]: unidSelected };
		queryParamsDefault.filters.properties.push(query);
		let carrs = await getCarrsApi(queryParamsDefault);
		setCarreras(carrs);
	}

	async function getMateriaData() {
		let queryParamsDefault = getQueryParamsMateria();
		let { codMat } = queryParamsDefault.filtersDefault.properties;

		const query = { [codMat]: codigoMateriaSearch };
		queryParamsDefault.filters.properties.push(query);
		let response_materia = await getMateriaApi(queryParamsDefault);

		if (response_materia.length > 0 && codigoMateria != '') {
			let name_asignatura = response_materia[0].nombMate;
			let unidad_academica = response_materia[0].uniId;
			let id_materia = response_materia[0].mateId;
			setAsignatura(name_asignatura);
			setMateId(id_materia);
			setUnidSelected(unidad_academica);
		} else {
			//mensaje de materia no encontrada
			setCodigoMateriaSearch('');
			setMateId('');
		}
	}
}
