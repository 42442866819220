import react, { useState, useEffect } from 'react';
import './ContenidoEquivalencia.scss';
import {
	Table,
	TableContainer,
	TableRow,
	TableCell,
	TableHead,
	TableBody,
} from '@mui/material';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import {
	getExpressionCkeckIfValueIsHour,
	getExpressionCkeckIfValueIsNumAndPoint,
} from '../../utils/helpers';
import ReferenciaInput from '../referenciaInput/ReferenciaInput';

export default function ContenidoEquivalencia(props) {
	const {
		idMaterial,
		materialData,
		dificultad,
		proposito,
		equivalencia,
		editEquivalencia,
		createEquivalencia,
		setShow,
		show,
	} = props;

	const [mutateMaterialData, setMutateMaterialData] = useState([]);

	useEffect(() => {
		let mutate = materialData?.unidads?.map(element => {
			return {
				...element,
				isError: false,
			};
		});

		setMutateMaterialData(mutate);
	}, [materialData]);

	const handleEditFactor = event => {
		console.log(event, 'handleEditFactor');
		let fact = event.target.textContent;
		let factAntiguo = event.currentTarget.dataset.factor_antiguo;

		const regex = /^[+-]?\d+(\.\d+)?$/;

		if (!regex.test(fact) && fact !== '') {
			event.target.classList.add('redprueba');
			let index = event.target.dataset.index;
			mutateMaterialData[index].isError = true;

			let copy = structuredClone(mutateMaterialData);
			setMutateMaterialData(copy);
		} else {
			event.target.classList.remove('redprueba');
			let index = event.target.dataset.index;
			let unidad_id = event.target.dataset.unidad_id;
			let errors = document.querySelectorAll(
				'#table_' + unidad_id + ' > tbody > tr > th > p.redprueba'
			).length;

			if (errors === 0) {
				mutateMaterialData[index].isError = false;
				let copy = structuredClone(mutateMaterialData);
				setMutateMaterialData(copy);
			}
			if (fact !== factAntiguo) {
				if (fact !== '') {
					let id = event.currentTarget.dataset.id;
					let matId = event.currentTarget.dataset.material_id;
					let propoId = event.currentTarget.dataset.proposito_id;
					let dificId = event.currentTarget.dataset.dificultad_id;
					let nombreMat = event.currentTarget.dataset.funcion;
					let uniId = event.currentTarget.dataset.unidad_id;
					if (id !== undefined) {
						editEquivalencia(fact, id);
					} else {
						createEquivalencia(matId, propoId, dificId, uniId, fact, nombreMat);
					}
				}
			}
		}
	};

	const validateNumber = event => {
		const keyCode = event.keyCode || event.which;
		const string = String.fromCharCode(keyCode);
		const regex = /[0-9]|\./;
		if (!regex.test(string)) {
			event.returnValue = false;
			if (event.preventDefault) event.preventDefault();
		}

		if (event.target.innerHTML.length >= 6) {
			event.preventDefault();
			return false;
		}

		if (document.querySelector('.redprueba') === undefined) {
			let index = event.target.dataset.index;
			let unidad_id = event.target.dataset.unidad_id;
			let errors = document.querySelectorAll(
				'#table_' + unidad_id + ' > tbody > tr > th > p.redprueba'
			).length;

			if (errors === 0) {
				mutateMaterialData[index].isError = false;
				let copy = structuredClone(mutateMaterialData);
				setMutateMaterialData(copy);
			}
		}
	};

	useEffect(() => {
		celdaEquivFilter();
	});

	function celdaEquivFilter(
		idUnidad,
		idPropo,
		idDificultad,
		idMaterial,
		nombreMat,
		index,
		indexParent
	) {
		let equiv = equivalencia?.filter(
			el =>
				el.material.id == idMaterial &&
				el.proposito.id == idPropo &&
				el.dificultad.id == idDificultad &&
				el.unidad.id == idUnidad
		)[0];

		return (
			<TableCell component="th" scope="row" align="center" key={index}>
				<p
					data-index={indexParent}
					data-id={equiv?.id}
					data-material_id={idMaterial}
					data-unidad_id={idUnidad}
					data-dificultad_id={idDificultad}
					data-proposito_id={idPropo}
					data-factor={equiv?.factor}
					data-factor_antiguo={equiv?.factor}
					data-funcion={nombreMat}
					data-complete={equiv?.factor != '' ? true : false}
					contentEditable="true"
					suppressContentEditableWarning={true}
					onBlur={handleEditFactor}
					className="editarFactorCampo"
					onKeyPress={validateNumber}
					onPaste={e => {
						e.preventDefault();
						return false;
					}}
					onCopy={e => {
						e.preventDefault();
						return false;
					}}
				>
					{equiv?.factor}
				</p>
			</TableCell>
		);
	}

	return (
		<>
			{mutateMaterialData &&
				mutateMaterialData?.map((item, indexParent) => (
					<div className="contenidoEquivCont" key={indexParent}>
						<div className="contenidoEquivalenciaHeader">
							<span
								style={{
									color: '#003087',
									marginRight: '6px',
									fontSize: '20px',
								}}
								className="material-symbols-rounded"
							>
								{item.unidad?.icono != null ? item.unidad.icono : 'public'}
							</span>
							<h2>{item.unidad.descripcion}</h2>
							<p>Describir el formato de carga de contenido</p>
						</div>
						<TableContainer className="dataTableContainerEquiv">
							<Table
								sx={{ minWidth: 650 }}
								aria-label="simple table"
								id={'table_' + item.unidad.id}
							>
								<TableHead style={{ background: '#E9E9E9' }}>
									<TableRow>
										<TableCell
											style={{ fontWeight: 'bold', fontFamily: 'Roboto' }}
										>
											Dificultad/Propósitos
										</TableCell>
										{proposito.map((item, index) => (
											<TableCell
												style={{
													fontWeight: 'bold',
													fontFamily: 'Roboto',
												}}
												data-proposito-id={item.id}
												align="center"
												key={'tbl_' + index}
											>
												{item.descripcion}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{dificultad.map((row, index) => (
										<TableRow
											style={{ borderTop: '1px solid #ccc' }}
											key={index}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell
												data-id-dificultad={row.id}
												component="th"
												scope="row"
												align="left"
												style={{ width: '264px', background: '#FAF9F9' }}
											>
												<span
													className="material-icons"
													style={{
														fontSize: '22px',
														paddingLeft: '3px',
														marginTop: '-2px',
														paddingRight: '3px',
														textAlign: 'center',
														position: 'absolute',
													}}
												>
													{row.icono ?? 'public'}
												</span>
												<span style={{ marginLeft: '30px' }}>
													{row.descripcion}
												</span>
											</TableCell>
											{proposito.map((el, index) =>
												celdaEquivFilter(
													item.unidad.id,
													el.id,
													row.id,
													idMaterial,
													item.unidad.descripcion,
													index,
													indexParent
												)
											)}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						
						<div style={{ marginTop: '-25px' }}>
							<ReferenciaInput
								id={item.unidad.id}
								show={item.isError}
								mensaje={<p>Por favor vuelva a ingresar los datos con el formato correcto, <strong>ej. 0.2 ó 2</strong></p>}
								color={'#DB3124'}
							/>
						</div>
					</div>
				))}
		</>
	);
}
