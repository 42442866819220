import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import './notiStyle.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';

export default function NotiSuccess(props) {

    const {
        open,
        setState,
        config,
    } = props;

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setState(false);
    };

    const renderSwitch = (param) => {
        switch (param) {
            case ('notiSuccessFilled'):
                return <CheckCircleIcon style={{ color: '#FEFCFC' }} />
            case ('notiDangerFilled'):
                return <ErrorIcon style={{ color: '#FEFCFC' }} />
            case ('notiWarningFilled'):
                return <WarningIcon style={{ color: '#FEFCFC' }} />
            case ('notiInfoFilled'):
                return <InfoIcon style={{ color: '#FEFCFC' }} />
            case ('notiSuccessOutline'):
                return <CheckCircleIcon style={{ color: '#358638' }} />
            case ('notiDangerOutline'):
                return <ErrorIcon style={{ color: '#B1281D' }} />
            case ('notiWarningOutline'):
                return <WarningIcon style={{ color: '#CE9516' }} />
            case ('notiInfoOutline'):
                return <InfoIcon style={{ color: '#0098AC' }} />
            default:
                return <CheckCircleIcon style={{ color: '#FEFCFC' }} />
        }
    }

    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={config.tiempo}
                onClose={handleClose}
                anchorOrigin={{ vertical: config.vertical, horizontal: config.horizontal }}
                className={config.estilo}
            >
                <Alert onClose={() => { handleClose() }} icon={ renderSwitch(config.estilo) }>
                    <AlertTitle style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '16px',
                        lineHeight: '20px',
                    }}>{config.titulo}</AlertTitle>
                    {config.mensaje}
                </Alert>
            </Snackbar>

        </div>
    );
}
