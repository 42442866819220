import React, { useState, useEffect } from 'react';
import { getAccessToken } from '../../utils/auth';
import { LoginAuth } from '../../components/login/LoginAuth';
import DataTable from 'react-data-table-component';
import { Form, Button, Input } from 'reactstrap';
import {
	initialInteraccionData,
	initialArrayInteraccionData,
} from '../../api/Interaccion/interaccionModel';

import {
	getInteraccionApi,
	createInteraccionApi,
	editInteraccionApi,
} from '../../api/Interaccion/interaccion';
import { InputCheckbox } from '../../components/Inputs/InputCheckbox';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import Notificacion from '../../components/snackbar/Notificacion';
import { 
	IconButton,
	Box, 
	FormControl,
	TextField,
	Grid
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import Loading from '../../components/loading/Loading';
import SaveIcon from '@mui/icons-material/Save';
import '../../scss/abmEstilos.scss';

export default function Interaccion() {
	const [isCreate, setIsCreate] = useState(true);
	const [isAnulado, setIsAnulado] = useState(true);
	const [isDisable, setIsDisable] = useState('');

	const [interaccionData, setInteraccionData] = useState(initialInteraccionData);

	const [arrayInteraccionData, setArrayInteraccionData] = useState(
		initialArrayInteraccionData
	);

	const handleChangeInteraccion = event => {
		let texto = event.target.value;
		setIsDisable(texto);
		setInteraccionData({
			...interaccionData,
			[event.target.name]: event.target.value,
		});
	};

	const handleEditInteraccion = event => {
		let id = event.target.dataset.id;
		let interaccion = arrayInteraccionData.filter(el => el.id == id);
		setIsDisable(interaccion);
		if (interaccion.length) {
			setInteraccionData(interaccion[0]);
			setIsCreate(false);

			if (interaccion[0].anulado == 'N') {
				setIsAnulado(true);
			} else {
				setIsAnulado(false);
			}
		}
	};

	const handleSubmitInteraccion = event => {
		event.preventDefault();
		isCreate ? createInteraccion() : editInteraccion();
	};

	useEffect(() => {
		getArrayInteraccion();
	}, []);

	const columns = [
		{
			name: 'Interacciones',
			selector: row => row.descripcion ? ( row.descripcion) : (''),
			cell: row =>
				row.descripcion ? (
					<div className="dataTableText">
						{row.descripcion}
					</div>
				) : (
					''
				),
			sortable: true,
			maxWidth: 200,
			minWidth: 140,
			width: 200,
			wrap: true,
		},
		{
			name: 'Estado',
			selector: row => row.anulado,
			sortable: true,
			cell: obj => {
				return (
					<div className="botonesTabla">
						{obj.anulado == 'N' ? (
							<div className="abmCampoVisible">
								<span className="material-symbols-rounded abmIcoVisi">
									visibility
								</span>
								Habilitado
							</div>
						) : (
							<div className="abmCampoDeshabilitar">
								<span className="material-symbols-rounded  abmIcoVisiOff">
									visibility_off
								</span>
								Deshabilitado
							</div>
						)}
					</div>
				);
			},
			maxWidth: 200,
			minWidth: 140,
			width: 200,
			wrap: true,
		},
		{
			cell: obj => {
				return (
					<div className="botonesTabla">
						<Tooltip title="Editar" placement="top" arrow>
							<IconButton
								data-id={obj.id ? obj.id : ''}
								className="material-symbols-rounded icoAbmEditar"
								onClick={handleEditInteraccion}
							>
								mode_edit
							</IconButton>
						</Tooltip>
					</div>
				);
			},
			right: true,
			ignoreRowClick: true,
			allowOverflow: true,
		},
	];

	const handleChangeAnulado = event => {
		let anulado = event.target.checked;
		setIsAnulado(anulado);
	};

	const handleClickCancelar = () => {
		setIsDisable('');
		clear();
	};

	const clear = () => {
		setIsDisable('');
		setIsCreate(true);
		setIsAnulado(true);
		setInteraccionData(initialInteraccionData);
	};

	const [OpenNoti, setOpenNoti] = React.useState(false);

	const notiConfigIni = {
		titulo: '',
		mensaje: '',
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const [notiConfig,setNotiConfig] = useState(notiConfigIni);

	const _fieldObligatorio = () => {
		return <b style={{ color: 'red' }}>*</b>;
	};

	const emptyColumn = [
		{
			name: 'Interacciones',
			cell: obj => {
				return <span>Sin interacciones creadas.</span>;
			},
			left: true,
			ignoreRowClick: true,
			allowOverflow: true,
		},
	];

	const [dataTest, setDataTest] = useState([
		{
			descripcion: 'Sin interacciones creadas.',
		},
	]);

	const [openLoad, setOpenLoad] = React.useState(false);

	const handleCloseLoad = () => {
		setOpenLoad(false);
	};

	const handleOpenLoad = () => {
		setOpenLoad(true);
	};

	if (getAccessToken()) {
		return (
			<Container className="contenedor">
				<div className="textoContenido">
					<h1 className="tituloContenido">Interacciones</h1>
					<p className="parrafoContenido">
						Creá y configurá las interacciones que formarán parte del contenido.
					</p>
				</div>

				<div className="textoContenido">
					<h2 className="subtituloContenido">Crear interacción</h2>

					<Box sx={{ flexGrow: 1 }}>
						<Grid container spacing={2}>
							<Grid item xs={8}>
								<FormControl className="descripAbm">
									<TextField
										id="descripcion"
										name="descripcion"
										label={
											<span style={{ fontFamily: 'Roboto' }}>
												Nombre de la interacción {_fieldObligatorio()}
											</span>
										}
										variant="outlined"
										inputProps={{ maxLength: 255 }}
										type="text"
										onChange={handleChangeInteraccion}
										value={interaccionData.descripcion || ''}
									/>
								</FormControl>
							</Grid>
							<Grid className='' item xs={4}>
								<div className="seccionInput">
									<InputCheckbox
										isAnulado={isAnulado}
										handleChangeAnulado={handleChangeAnulado}
										isDisable={!isDisable}
									></InputCheckbox>
								</div>
								<div className="btnContentAbm">
									<Button
										className="btnCrearAbm"
										disabled={!isDisable}
										onClick={handleSubmitInteraccion}
									>
										{isCreate ?
										<AddIcon  className='btnIcoAbm'/>
										:
										<SaveIcon className='btnIcoAbm'/>
										}

										{isCreate ? 'Crear' : 'Guardar'}
									</Button>
									<Button
										className="btnAbmCancel"
										onClick={handleClickCancelar}
										disabled={!isDisable}
									>
										<CancelIcon  className='btnIcoAbm'/>
										Cancelar
									</Button>
								</div>
							</Grid>
						</Grid>
					</Box>
				</div>

				<h2 className="subtituloContenido listado">Listado de interacciones</h2>

				<DataTable
					columns={columns}
					className="dataTableContainer"
					data={arrayInteraccionData ? arrayInteraccionData : ''}
					direction="auto"
					fixedHeaderScrollHeight="300px"
					responsive
					subHeaderAlign="right"
					subHeaderWrap
					noDataComponent={
						<DataTable
							columns={emptyColumn}
							className="dataTableContainer"
							data={dataTest ? dataTest : ''}
							direction="auto"
							fixedHeaderScrollHeight="300px"
							responsive
							subHeaderAlign="right"
							subHeaderWrap
						/>
					}
					paginationPerPage={5}
					pagination
					paginationComponentOptions={{
						rowsPerPageText: 'Registros por página:',
						rangeSeparatorText: 'de',
					}}
					paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
				/>

				<Notificacion open={OpenNoti} setState={setOpenNoti} config={notiConfig} />

				<Loading
					bg="bgLoadingWhite"
					open={openLoad}
				/>
			</Container>
		);
	}

	async function getArrayInteraccion() {
		try {
			handleOpenLoad();
			let interacciones = await getInteraccionApi();
			setArrayInteraccionData(interacciones);
			handleCloseLoad();
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function createInteraccion() {
		try {
			delete interaccionData.id;
			// { descripcion } = interaccionData;
			let descripcion = interaccionData.descripcion.trim();
			let anulado = isAnulado ? 'N' : 'S';

			let interacciones = await createInteraccionApi({ descripcion, anulado });
			setArrayInteraccionData([...arrayInteraccionData, interacciones]);
			clear();
			notiConfig.estilo = 'notiSuccessFilled';
			notiConfig.mensaje = "Datos creados con éxito";
			setOpenNoti(true);
		} catch (error) {
			console.log(error);

			let msj = await error.json();

			if (error.status == 422) {
				notiConfig.estilo = 'notiDangerFilled';
				notiConfig.mensaje = msj.violations[0].message;
				setOpenNoti(true);
			}
		}
	}

	async function editInteraccion() {
		try {
			console.log('🚀 ~ file: Proyecto.js:237 ~ editMaterial ~ editMaterial');
			let id = interaccionData.id;
			interaccionData.anulado = isAnulado ? 'N' : 'S';
			interaccionData.descripcion = interaccionData.descripcion.trim();
			await editInteraccionApi(id, interaccionData);
			clear();
			setIsCreate(true);
			setInteraccionData(initialInteraccionData);
			getArrayInteraccion();
			notiConfig.estilo = 'notiSuccessFilled';
			notiConfig.mensaje = "Datos modificados con éxito";
			setOpenNoti(true);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);
			
			let msj = await error.json();

			if (error.status == 422) {
				notiConfig.estilo = 'notiDangerFilled';
				notiConfig.mensaje = msj.violations[0].message;
				setOpenNoti(true);
			}
		}
	}

	return (
		<>
			<LoginAuth></LoginAuth>
		</>
	);
}
