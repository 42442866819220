import { base_url } from '../config';
import { secureFetch } from '../../utils/http';
import { getAuthUser } from '../../utils/auth';

const endpoints_proyecto_tipo = {
	GET: 'planif/tipos',
	GETBYID: 'planif/tipos',
	CREATE: 'planif/tipos',
	EDIT: 'planif/tipos',
	DELETE: 'planif/tipos',
};

export function getQueryParams() {
	return {
		pagination: {
			value: false,
			items: 2,
			page: 1,
		},
		filters: {
			properties: [],
		},
		filtersDefault: {
			properties: {
				anulado: 'anulado',
				enfasi: 'enfasi',
			},
		},
	};
}

export async function getTiposApi(queryParams) {
	const url = `${base_url}/api/${endpoints_proyecto_tipo.GET}`;
	if (queryParams) return await secureFetch(url, 'GET', queryParams);
	return await secureFetch(url, 'GET', null);
}

export async function getTipoByIdApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_tipo.GETBYID}/${id}`;
	return await secureFetch(url, 'GET', null);
}

export async function createTipoApi(data) {
	const url = `${base_url}/api/${endpoints_proyecto_tipo.CREATE}`;
	let dataCreate = {
		...data,
		descripcion: data.descripcion || '',
		icono: data.icono || '',
		color: data.color || '',
	};

	console.log(dataCreate);

	return await secureFetch(url, 'POST', dataCreate);
}

export async function editTipoApi(id, data) {
	let user = getAuthUser();
	console.log('🚀 ~ file: tipo.js:59 ~ editTipoApi ~ user', user);

	let dataEdit = {
		...data,
		descripcion: data.descripcion || '',
		icono: data.icono || '',
		color: data.color || '',
	};

	if (dataEdit.anulado == 'S') {
		dataEdit.codUsuAnula = user.username;
		delete dataEdit.anuladoFec;
	} else {
		delete dataEdit.codUsuAnula;
	}

	delete dataEdit.enfasiId;
	delete dataEdit.enfasi;
	delete dataEdit.id;

	console.log('dataEdit');
	console.log(dataEdit);

	// return false;

	const url = `${base_url}/api/${endpoints_proyecto_tipo.EDIT}/${id}`;
	return await secureFetch(url, 'PUT', dataEdit);
}

export async function deleteTipoApi(id, data) {
	let user = getAuthUser();
	let dataEdit = {
		...data,
		codUsuAnula: user.username,
		anulado: 'S',
	};
	const url = `${base_url}/api/${endpoints_proyecto_tipo.DELETE}/${id}`;
	return await secureFetch(url, 'DELETE', dataEdit);
}
