const initialEquivalenciaData = {
	descripcion:'',
	icono:'',
	color:'',
	anulado:'',
	anuladoFec:'',
	codUsuAnula:''
};

const initialArrayEquivalenciaData = [];

export { initialEquivalenciaData, initialArrayEquivalenciaData };
