import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import {
	Modal,
	Box,
	Container,
	IconButton,
	TextField,
	Chip,
} from '@mui/material';

import Typography from '@mui/material/Typography';

import '../modalEditarActividad/modalEditarActividad.scss';

import UnidSelect from '../../unidSelect/UnidSelect';
import {
	getProyectoByIdApi,
	copyProyectoApi,
} from '../../../api/Proyecto/proyecto';
import { generateKeyRandom } from '../../../utils/helpers';
import { initialProyectoCopyData } from '../../../api/Proyecto/proyectoModel';
import Notificacion from '../../../components/snackbar/Notificacion';
import '../../../scss/modal.scss';

export default function ModalHacerCopia(props) {
	const [duracion, setDuracion] = useState('');
	const [unidSelected, setUnidSelected] = useState(0);
	const [carrSelected, setCarrSelected] = useState(0);
	const [mateId, setMateId] = useState(null);
	const [codigoMateria, setCodigoMateria] = useState('');
	const [valid, setValid] = useState(true);

	const { open, setOpen, proyectoId, setProyectoId } = props;

	const handleClose = () => {
		setOpen(false);
		clear();
	};

	const [duracionRegex,setDuracionRegex] = useState(true);

	const [proyectoCopyData, setProyectoCopyData] = useState(
		initialProyectoCopyData
	);

	const handleChangeProyectoCopyData = event => {
		setValid(event.target.value != '');
		setProyectoCopyData({
			...proyectoCopyData,
			[event.target.name]: event.target.value,
		});
	};

	const handleChangeProyectoCopyDataDescri = event => {
		setProyectoCopyData({
			...proyectoCopyData,
			[event.target.name]: event.target.value,
		});
	};

	const navigate = useNavigate();

	function clear() {
		setProyectoCopyData({
			descripcion: '',
			observacion: '',
			persona: '',
			copyProyectoId: '',
		});
		setDuracion('');
		setUnidSelected('');
		setCarrSelected('');
		setMateId('');
		setCodigoMateria('');
	}

	useEffect(() => {
		if (open) {
			setDuracion('');
			setUnidSelected(0);
			setCarrSelected(0);
			setMateId(0);
			getProyectosById();
			console.log('UNIDAD: ' + unidSelected);
		}
	}, [open]);

	const notiConfigIni = {
		titulo: '',
		mensaje: '',
		estilo: 'notiSuccessOutline',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const [notiConfig, setNotiConfig] = useState(notiConfigIni);

	const [openNoti, setOpenNoti] = React.useState(false);

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box className="modalActividadContainer modalShadow">
					<Container className="modalActividadHeader">
						<h1>Hacer una copia</h1>
						<IconButton onClick={handleClose} style={{ margin: 'auto 0px' }}>
							<CloseIcon />
						</IconButton>
					</Container>
					<Container>
						<Typography
							sx={{ mb: 3 }}
							style={{
								fontFamily: 'Roboto',
								fontStyle: 'normal',
								fontWeight: '400',
								fontSize: '16px',
								lineHeight: '19px',
								color: '#000000',
							}}
						>
							Configurá los datos que deseas modificar para la nueva copia del
							proyecto.
						</Typography>
					</Container>
					<Container>
						<TextField
							className="ActiInputModal"
							id="outlined-basic"
							name="descripcion"
							error={!valid}
							onChange={handleChangeProyectoCopyData}
							inputProps={{ maxLength: 225 }}
							value={proyectoCopyData?.descripcion || ''}
							label={
								<>
									Título<b style={{ color: 'red' }}>*</b>
								</>
							}
							variant="outlined"
						/>
					</Container>
					<Container>
						<TextField
							className="DescriInputModal"
							id="outlined-multiline-static"
							label="Descripción"
							inputProps={{ maxLength: 1000 }}
							multiline
							onChange={handleChangeProyectoCopyDataDescri}
							name="observacion"
							value={proyectoCopyData?.observacion || ''}
							rows={3}
						/>
					</Container>
					<Container className="w-auto">
						<UnidSelect
							duracion={duracion}
							unidSelected={unidSelected}
							carrSelected={carrSelected}
							setDuracion={setDuracion}
							setUnidSelected={setUnidSelected}
							setCarrSelected={setCarrSelected}
							setMateId={setMateId}
							codigoMateria={codigoMateria}
							setCodigoMateria={setCodigoMateria}
							duracionRegex={duracionRegex}
							setDuracionRegex={setDuracionRegex}
						></UnidSelect>
					</Container>
					<Container className="modalSeccionBotones">
						<Chip
							className="chipBotonCrearCopia"
							icon={<SaveIcon style={{ fontSize: '18px', color: '#003087' }} />}
							label="Guardar copia"
							clickable
							disabled={
								!duracionRegex || duracion == '' || proyectoCopyData?.descripcion == ''
									? true
									: false
							}
							onClick={copyProyecto}
						/>
						<Chip
							className="chipBotonCancelar"
							icon={
								<CancelIcon style={{ fontSize: '18px', color: '#003087' }} />
							}
							label="Cancelar"
							variant="outlined"
							clickable
							onClick={handleClose}
						/>
					</Container>
				</Box>
			</Modal>

			<Notificacion open={openNoti} setState={setOpenNoti} config={notiConfig} />
		</div>
	);

	async function getProyectosById() {
		try {
			let proyecto = await getProyectoByIdApi(proyectoId);

			setProyectoCopyData({
				descripcion: 'Copia de ' + proyecto.descripcion,
				observacion: proyecto.observacion,
				persona: proyecto.username,
				copyProyectoId: proyectoId,
			});
			setDuracion(proyecto.hsCurTotal);
			setUnidSelected(proyecto.unidad ? proyecto.unidad.uniId : '');
			setCodigoMateria(proyecto.asignatura ? proyecto.asignatura.codMat : '');
			setCarrSelected(proyecto.codCarr ? proyecto.codCarr : '');
			setMateId(proyecto.codMate);
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function copyProyecto() {
		try {
			if (carrSelected != '') {
				setMateId('');
				setCodigoMateria('');
			}

			proyectoCopyData.codUni = unidSelected != 0 ? unidSelected : null;
			proyectoCopyData.codCarr = carrSelected != 0 ? carrSelected : null;
			proyectoCopyData.codMate = mateId != '' ? mateId : null;

			if (duracion != '') {
				proyectoCopyData.hrsCurTotal = duracion;
			}

			let copia = await copyProyectoApi(proyectoCopyData);

			clear();
			handleClose();

			if (copia.status == 'true') {				
				notiConfig.estilo = 'notiSuccessFilled';
				notiConfig.mensaje = copia.message;
				
				setTimeout(function () {
					setOpenNoti(true);
				}, 5000);

				if (setProyectoId != undefined) {
					setProyectoId(copia.proyectoId);
				}

				setTimeout(function () {
					navigate(`../temasProyecto`, {
						state:{id:copia.proyectoId},
						replace: true,
					});
				}, 2000);
			} else {
				notiConfig.estilo = 'notiDangerFilled';
				notiConfig.mensaje = copia.message;

				setOpenNoti(true);
			}
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}
}
