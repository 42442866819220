import React from 'react';
import { useSearchParams, Link, Navigate } from 'react-router-dom';
import { getAccessToken } from '../utils/auth';
import { LoginAuth } from '../components/login/LoginAuth';
import { getRolesFromLocalStorage } from '../utils/auth';
import './home.scss';
import BannerUCC from '../img/banner.png';
import { Button } from '@mui/material';
import { getTokenRefresh } from '../api/user';

export default function Home() {
	if (getAccessToken()) {
		let role = getRolesFromLocalStorage();
		{
			if (
				role.includes('ROLE_CONTE_PLAN') ||
				role.includes('ROLE_ADMIN_PLAN')
			) {
				return (
					<>
						<div className="bannerContent">
							<div className="banner">

								<h1 className="bannerTitle">Planificador UCC</h1>

								<div className='bannerImage'>

									<img src={BannerUCC} alt="Banner Image" />

									<div className="bannerImageText">
										<h2>COMENZÁ A PLANIFICAR</h2>
										<p>
											Maximiza la creación y gestión del contenido. Crea y edita
											módulos y actividades fácilmente, con una simplificación del
											contenido para una mejor comprensión.
										</p>
										<Button
											style={{
												border: '1px solid #FFF',
												color: '#FFF',
												padding: '7px 21px',
												borderRadius: '60px',
											}}
											variant="outlined"
										>
											<Link
												style={{
													textDecoration: 'none',
													color: '#FFF',
													fontSize: '14px',
													fontWeight: '700',
												}}
												to="/proyectos"
											>
												MIS PROYECTOS
											</Link>
										</Button>
									</div>
								</div>
							</div>
						</div>
					</>
				);
			} else {
				return (
					<>
						<div className="bannerContent">
							<div className="banner">

								<h1 className="bannerTitle">Planificador UCC</h1>

								<div className='bannerImage'>
									<img src={BannerUCC} alt="Banner Image" />
								</div>
							</div>
						</div>
					</>
				);
			}
		}
	}
}
