import React, { useState, useEffect } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import { getTokenAccess, getUserApi } from '../../api/user';
import { ACCESS_TOKEN, REFRESH_TOKEN, FULL_NAME, ROLES } from '../../api/constants';
import { Container, Row, Col, Button } from 'reactstrap';
import Notification from '../../components/notification/Notificaction';
import useNotification from '../../hooks/useNotificacion';
import { getAccessToken, getAuthUser } from '../../utils/auth';
import '../../scss/index.scss';
import Loading from '../../components/loading/Loading';

export default function Auth() {

	const [searchParams, setSearchParams] = useSearchParams();
	const code = searchParams.get('code');

	const [openLoad, setOpenLoad] = useState(false);

	const handleCloseLoad = () => {
		setOpenLoad(false);
	};
	
	const handleOpenLoad = () => {
		setOpenLoad(true);
	};

	const checkCode = async code => {
		const result_token = await getTokenAccess(code);

		if (result_token?.id_token) {
			handleOpenLoad();
			const { id_token, refresh_token } = result_token;
			localStorage.setItem(ACCESS_TOKEN, id_token);
			localStorage.setItem(REFRESH_TOKEN, refresh_token);
			const { username } = getAuthUser();

			const user = await getUserApi(username)
			localStorage.setItem(FULL_NAME, user.fullName);
			localStorage.setItem(ROLES, user.roles);
			handleCloseLoad();
			window.location.href = '/';
		} else {			
			window.location.href = '/login';
		}
	};

	useEffect(() => {
		checkCode(code);
	}, []);

	return (
		<>
			<div className="d-flex align-items-center justify-content-center custom-height">
				<div className="p-2">
					<Loading
						bg="bgLoadingWhiteTrans"
						open={openLoad}
					/>
				</div>
			</div>
		</>
	);
}
