import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';

export function NotificationMui({
	isOpen,
	title,
	content,
	children = null,
	icon,
}) {
	return (
		<div>
			<Dialog
				open={isOpen}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className=""
			>
				<div className="notiTitle">
					<p className="iconNoti">
						{icon == 'delete' && (
							<WarningIcon style={{ color: '#626262', fontSize: '39px' }} />
						)}
						{icon == 'success' && (
							<CheckCircleIcon style={{ fontSize: '40px', color: '#53d258' }} />
						)}
						{icon == '' && <HelpIcon />}
					</p>
					<h1>{title}</h1>
				</div>
				<DialogContent>
					<DialogContentText
						id="alert-dialog-description"
						className="notiContent"
					>
						{content != '' ? content : ''}
					</DialogContentText>
				</DialogContent>
				<DialogActions className="notiChildren">{children}</DialogActions>
			</Dialog>
		</div>
	);
}
