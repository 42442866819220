import { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import {
	Modal,
	Button,
	Box,
	Container,
	IconButton,
	TextField,
	Chip,
} from '@mui/material';

import Typography from '@mui/material/Typography';
import '../../unidad/crearProyecto/CrearProyecto.scss';
import '../modalEditarActividad/modalEditarActividad.scss';

import UnidSelect from '../../unidSelect/UnidSelect';
import { useNavigate, useParams } from 'react-router';
import {
	getProyectoByIdApi,
	editProyectoApi,
} from '../../../api/Proyecto/proyecto';
import Notificacion from '../../../components/snackbar/Notificacion';
import '../../../scss/modal.scss';

export default function ModalEditarProyecto(props) {
	const navigate = useNavigate();
	const params = useParams();
	const { id } =   Object.keys(params).length === 0 && props;

	const [titulo, setTitulo] = useState('');
	const [descripcion, setDescripcion] = useState('');
	const [horas, setHoras] = useState();
	const [unidadId, setUnidadId] = useState();
	const [carreraId, setCarreraId] = useState();
	const [asignatura, setAsignatura] = useState();
	const [codigoId, setCodigoId] = useState();
	const [mateId, setMateId] = useState();
	const [codigoMateria, setCodigoMateria] = useState();

	const [isEditDescripcion, setIsEditDescripcion] = useState(false);

	const [duracion, setDuracion] = useState('');
	const [unidSelected, setUnidSelected] = useState(-1);
	const [carrSelected, setCarrSelected] = useState(-1);
	const [proyectoData, setProyectoData] = useState([]);

	const { open, setOpen, getProyectosById } = props;

	const [notiGuardarOpen, notiGuardarSetOpen] = useState(false);

	const [duracionRegex, setDuracionRegex] = useState(true);
	const [valid, setValid] = useState(true);

	const notiConfig = {
		titulo: '',
		mensaje: 'Datos modificados con éxito',
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	useEffect(() => {
		if (open) {
			getProyectosByIdA();
			setDuracion('');
			setUnidSelected(-1);
			setCarrSelected(-1);
			setMateId('');
		}
	}, [open]);

	//TODO why?? 
	useEffect(() => {
		if(id){
			getProyectosByIdA();
		}
	}, [id]);

	const handleClose = () => {
		setOpen(false);
		clear();
	};

	const handleOnChangeTitulo = event => {
		let val = event.target.value;

		setValid(event.target.value != '');
		if (val != ' ') {
			setTitulo(val);
		}
	};

	const handleOnChangeDescri = event => {
		let val = event.target.value;
		setDescripcion(val);
	};

	const handleOnChangeDuracion = event => {
		let duracion = event.target.value;
		setHoras(duracion);
	};

	const notiGuardarClick = () => {
		notiGuardarSetOpen(true);
	};

	function clear() {
		setTitulo('');
		setDescripcion('');
		setAsignatura('');
		setCarreraId('');
		setCodigoId('');
		setHoras('');
		setUnidadId('');
		setCodigoId('');
	}

	const handleEditProyecto = () => {
		setIsEditDescripcion(true);
		editProyectoData();
	};

	return (
		<div>
			<Notificacion
				open={notiGuardarOpen}
				setState={notiGuardarSetOpen}
				config={notiConfig}
			/>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box className="modalActividadContainer modalShadow">
					<Container className="modalActividadHeader">
						<h1>Editar proyecto</h1>
						<IconButton onClick={handleClose} style={{ margin: 'auto 0px' }}>
							<CloseIcon />
						</IconButton>
					</Container>
					<Container className="w-auto">
						<Typography
							sx={{ mb: 3 }}
							style={{
								fontFamily: 'Roboto',
								fontStyle: 'normal',
								fontWeight: '400',
								fontSize: '16px',
								lineHeight: '19px',
								color: '#000000',
							}}
						>
							Configurá los datos que deseas modificar para tu proyecto.
						</Typography>
					</Container>
					<Container>
						<TextField
							className="inputCrearProy"
							error={!valid}
							id="titulo"
							label={
								<span style={{ fontFamily: 'Roboto' }}>
									Título<b style={{ color: 'red' }}> *</b>
								</span>
							}
							variant="outlined"
							value={titulo ?? ''}
							onChange={handleOnChangeTitulo}
						/>
					</Container>
					<Container className="w-auto">
						<TextField
							className="textAreaCrearProy"
							id="descripcion"
							label="Descripción"
							multiline
							onChange={handleOnChangeDescri}
							value={descripcion ?? ''}
							rows={3}
						/>
					</Container>
					<Container className="w-auto">
						<UnidSelect
							duracion={horas}
							unidSelected={unidadId}
							carrSelected={carreraId}
							setDuracion={setHoras}
							setUnidSelected={setUnidadId}
							setCarrSelected={setCarreraId}
							codigoMateria={codigoId}
							setCodigoMateria={setCodigoId}
							setMateId={setMateId}
							mateId={mateId}
							duracionRegex={duracionRegex}
							setDuracionRegex={setDuracionRegex}
						></UnidSelect>
					</Container>
					<Container className="modalSeccionBotones">
						<Chip
							className="chipBotonCrear"
							icon={<SaveIcon style={{ fontSize: '18px', color: '#003087' }} />}
							label="Guardar"
							disabled={
								!duracionRegex || horas == '' || titulo == '' ? true : false
							}
							clickable
							onClick={handleEditProyecto}
						/>
						<Chip
							className="chipBotonCancelar"
							icon={
								<CancelIcon style={{ fontSize: '18px', color: '#003087' }} />
							}
							label="Cancelar"
							variant="outlined"
							clickable
							onClick={handleClose}
						/>
					</Container>
				</Box>
			</Modal>
		</div>
	);

	async function getProyectosByIdA() {
		try {
			let proyecto = await getProyectoByIdApi(id);

			setTitulo(proyecto.descripcion);
			setUnidSelected(proyecto.uniId);
			setDescripcion(proyecto.observacion);
			setHoras(proyecto.hsCurTotal);
			setCodigoMateria(proyecto.mateId);
			setCarreraId(proyecto.codCarr);
			setCarrSelected(proyecto.codCarr);
			setUnidadId(proyecto.uniId);
			setAsignatura(proyecto.asignatura);
			setMateId(proyecto.mateId);
			setCodigoId(proyecto.asignatura ? proyecto.asignatura.codMat : '');
			setDuracion(proyecto.hsCurTotal);
			setUnidSelected(proyecto.unidad ? proyecto.unidad.uniId : '');
			setCodigoMateria(proyecto.asignatura ? proyecto.asignatura.codMat : '');
			setCarrSelected(proyecto.codCarr ? proyecto.codCarr : '');
			setMateId(proyecto.mateId);
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function editProyectoData() {
		try {
			proyectoData['descripcion'] = titulo;
			proyectoData['observacion'] = descripcion != '' ? descripcion : '';
			proyectoData['hsCurTotal'] = horas;

			if (unidadId != null && unidadId != '') {
				proyectoData['unidad'] = unidadId;
			}

			if (carreraId != null) {
				proyectoData['codCarr'] = carreraId;
			}

			proyectoData['asignatura'] = mateId ? mateId : null;

			let edit = await editProyectoApi(id, proyectoData);
			getProyectosById();
			setIsEditDescripcion(false);
			handleClose();
			notiGuardarClick();
		} catch (error) {
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);
		}
	}
}
