import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { getAccessToken } from '../../utils/auth';
import { LoginAuth } from '../../components/login/LoginAuth';
import {
	Form,
	Button,
	Row,
	Container,
	Input,
	FormGroup,
	Label,
	Table,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import {
	initialProyectoActividadData,
	initialProyectosActividadData,
} from '../../api/Proyecto/proyectoModel';
import {
	getProyectosActividadApi,
	editProyectoActividadApi,
	createProyectoActividadApi,
	deleteProyectoActividadApi,
} from '../../api/Proyecto/proyectoActividad';

export default function ProyectoActividad() {
	const [id, setId] = useState('');

	const [idMaterial, setIdMaterial] = useState('');
	const [idUnidad, setUnidad] = useState('');

	const [idDeleteProyectoActividad, setDeleteProyectoActividad] =
		useState(false);

	const [isCreate, setIsCreate] = useState(true);

	const [proyectoActividadData, setProyectoActividadData] = useState(
		initialProyectoActividadData
	);

	const [proyectosActividadData, setProyectosActividadData] = useState(
		initialProyectosActividadData
	);

	useEffect(() => {
		if (idDeleteProyectoActividad) {
			getProyectosActividad();
		}
	}, [idDeleteProyectoActividad]);

	const handleChangeProyectoActividad = event => {
		setProyectoActividadData({
			...proyectoActividadData,
			[event.target.name]: event.target.value,
		});
	};

	const handleEditProyectoActividad = event => {
		let id = window.event.target.dataset.id;
		let proyecto = proyectosActividadData.filter(el => el.id == id);
		if (proyecto.length) {
			setProyectoActividadData(proyecto[0]);
			setIsCreate(false);
		}
	};

	const handleDeleteProyectoActividad = event => {
		let id = window.event.target.dataset.id;
		setDeleteProyectoActividad(false);
		deleteProyectoActividad(id);
	};

	const handleSubmitProyecto = event => {
		event.preventDefault();
		isCreate ? createProyectoActividad() : editProyectoActividad();
	};

	if (getAccessToken()) {
		return (
			<>
				<h1>ABM proyecto actividads</h1>
				<ToastContainer></ToastContainer>
				<Container>
					<Row>
						<Button color="primary" onClick={getProyectosActividad}>
							get proyecto actividads
						</Button>
					</Row>
					<Table bordered>
						<thead>
							<tr>
								<th>#</th>
								<td>planifPyId</td>
								<td>planifModId</td>
								<td>planifActId</td>
								<td>planifProId</td>
								<td>planifDifId</td>
								<td>planifConId</td>
								<td>fecha</td>
								<td>ordenVisual</td>
							</tr>
						</thead>
						<tbody>
							{proyectosActividadData.map((proyecto, i) => {
								return (
									<tr key={proyecto.id}>
										<td>{proyecto.planifPyId}</td>
										<td>{proyecto.planifModId}</td>
										<td>{proyecto.planifActId}</td>
										<td>{proyecto.planifProId}</td>
										<td>{proyecto.planifDifId}</td>
										<td>{proyecto.planifConId}</td>
										<td>{proyecto.fecha}</td>
										<td>{proyecto.ordenVisual}</td>
										<td>
											<Button
												color="primary"
												size="sm"
												data-id={proyecto.id}
												onClick={handleEditProyectoActividad}
											>
												edit
											</Button>
											<Button
												color="warning"
												size="sm"
												data-id={proyecto.id}
												onClick={handleDeleteProyectoActividad}
											>
												delete
											</Button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Container>

				{/* <Container>
					<Row>
						<div>
							<Input
								id="id"
								name="id"
								onChange={handleChangeMaterial}
								value={id}
							/>
						</div>
						<Button color="primary" onClick={getUnidadById}>
							get unidad by id
						</Button>
					</Row>
				</Container>
                        */}
				<Container>
					Create
					<Row>
						<Form onSubmit={handleSubmitProyecto}>
							<FormGroup>
								<Input
									id="id"
									name="id"
									type="hidden"
									onChange={handleChangeProyectoActividad}
									value={proyectoActividadData?.id || ''}
								/>
								<Label for="planifPyId">planifPyId</Label>
								<Input
									id="planifPyId"
									name="planifPyId"
									onChange={handleChangeProyectoActividad}
									value={proyectoActividadData?.planifPyId || ''}
								/>
								<Label for="planifModId">planifModId</Label>
								<Input
									id="planifModId"
									name="planifModId"
									onChange={handleChangeProyectoActividad}
									value={proyectoActividadData?.planifModId || ''}
								/>
								<Label for="planifActId">planifActId</Label>
								<Input
									id="planifActId"
									name="planifActId"
									onChange={handleChangeProyectoActividad}
									value={proyectoActividadData?.planifActId || ''}
								/>
								<Label for="planifProId">planifProId</Label>
								<Input
									id="planifProId"
									name="planifProId"
									onChange={handleChangeProyectoActividad}
									value={proyectoActividadData?.planifProId || ''}
								/>
								<Label for="planifDifId">planifDifId</Label>
								<Input
									id="planifDifId"
									name="planifDifId"
									onChange={handleChangeProyectoActividad}
									value={proyectoActividadData?.planifDifId || ''}
								/>
								<Label for="planifConId">planifConId</Label>
								<Input
									id="planifConId"
									name="planifConId"
									onChange={handleChangeProyectoActividad}
									value={proyectoActividadData?.planifConId || ''}
								/>
								<Label for="fecha">fecha</Label>
								<Input
									id="fecha"
									name="fecha"
									onChange={handleChangeProyectoActividad}
									value={proyectoActividadData?.fecha || moment(new Date()).format("DD/MM/YYYY")}
								/>
								<Label for="ordenVisual">orderVisual</Label>
								<Input
									id="ordenVisual"
									name="ordenVisual"
									onChange={handleChangeProyectoActividad}
									value={proyectoActividadData?.ordenVisual || ''}
								/>
							</FormGroup>
							<Button color="primary" type="submit">
								{isCreate ? 'create' : 'edit'}
							</Button>
						</Form>
					</Row>
				</Container>
			</>
		);
	}

	async function getProyectosActividad() {
		try {
			let proyectos = await getProyectosActividadApi();
			setProyectosActividadData(proyectos);
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function createProyectoActividad() {
		try {
			proyectoActividadData.fecha = proyectoActividadData.fecha || moment(new Date()).format("DD/MM/YYYY")
			let proyecto = await createProyectoActividadApi(proyectoActividadData);
			setProyectosActividadData([...proyectosActividadData, proyecto]);
		} catch (error) {
			console.log(await error.json());
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);
		}
	}

	async function deleteProyectoActividad(id) {
		try {
			await deleteProyectoActividadApi(id);
			setDeleteProyectoActividad(true);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);
			console.log(await error.json());
		}
	}

	async function editProyectoActividad() {
		try {
			let id = proyectoActividadData.id;
			await editProyectoActividadApi(id, proyectoActividadData);
			setIsCreate(true);
			setProyectoActividadData(initialProyectoActividadData);
			getProyectosActividad();
		} catch (error) {
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);
			console.log(await error?.json());
		}
	}

	return (
		<>
			<LoginAuth></LoginAuth>
		</>
	);
}
