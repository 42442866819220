import React, { useState, useEffect } from 'react';

import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';

import CrearModuloAuto from '../../components/crearModuloAuto/CrearModuloAuto';
import ModalCrearActividad from '../../components/newModalActividad/ModalCrearActividad';
import ModalEditarActividad from '../../components/modal/modalEditarActividad/ModalEditarActividad';
import { deleteProyectoApi } from '../../api/Proyecto/proyecto';
import {
	deleteProyectoModuloApi,
	editProyectoModuloApi,
} from '../../api/Proyecto/proyectoModulo';
import {
	getProyectoByIdApi,
	editProyectoApi,
} from '../../api/Proyecto/proyecto';
import ModalCrearModulo from '../../components/modal/modalCrearModulo/ModalCrearModulo';
import {
	createProyectoModuloApi,
	editOrdenModulo,
} from '../../api/Proyecto/proyectoModulo';
import ModalHacerCopia from '../../components/modal/modalHacerCopia/ModalHacerCopia';

import {
	Container,
	Chip,
	Stack,
	IconButton,
	Tooltip,
	Menu,
	Button,
	MenuItem,
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ListIcon from '@mui/icons-material/List';
import AddIcon from '@mui/icons-material/Add';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Notificacion from '../../components/snackbar/Notificacion';

import './TemasProyecto.scss';
import { SpaceBarRounded } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import InfoProyectoDeta from '../../components/InfoProyectoDeta/InfoProyectoDeta';
import CardTemaProyecto from '../../components/cardTemasProyecto/CardTemaProyecto';
import ModTemaProyecto from '../../components/modTemaProyecto/ModTemaProyecto';
import {
	deleteProyectoActividadApi,
	editOrdenActividad,
	editOrdenTema,
} from '../../api/Proyecto/proyectoActividad';
import { useNotificationMui } from '../../hooks/useNotificacionMui';
import { NotificationMui as DeleteNotificationMui } from '../../components/notification/NotificactionMui';
import ModalCustomConfirmacion from '../../components/modal/modalCustomConfirmacion/modalCustomConfirmacion';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ModalEditarModulo from '../../components/modal/modalEditarModulo/ModalEditarModulo';
import Loading from '../../components/loading/Loading';
import ModalEditarProyecto from '../../components/modal/modalEditarProyecto/ModalEditarProyecto';
import { getCarrByIdApi } from '../../api/Carrera/carr';

export default function TemasProyecto(props) {
	const navigate = useNavigate();
	const params = useParams();
	const location = useLocation();
	const { id } = Object.keys(params).length === 0 && location.state;

	const [observacion, setObservacion] = useState('');
	const [hsCurTotal, setHsCurTotal] = useState('');
	const [isMessageNotification, setIsMessageNotification] = useState(false);
	const [asignatura, setAsignatura] = useState(null);
	const [unidad, setUnidad] = useState(null);
	const [carrera, setCarrera] = useState(null);
	const [carreraId, setCarreraId] = useState(null);
	const [OpenNotiDelete, setOpenNotiDelete] = useState(false);

	const [proyectoId, setProyectoId] = useState(id);

	const [actividades, setActividades] = useState([]);
	const [detalles, setDetalles] = useState([]);
	const [detalleMaterials, setDetalleMaterials] = useState([]);
	const [actividadesDetalleMaterials, setActividadesDetalleMaterials] =
		useState([]);
	const [actividadesDetalleEnfasis, setActividadesDetalleEnfasis] = useState(
		[]
	);
	const [actividadesDetalle, setActividadesDetalle] = useState([]);
	const [modulos, setModulos] = useState([]);
	const [proyectoData, setProyectoData] = useState({});
	const [descripcion, setDescripcion] = useState('');
	const [tiempoEstado, setTiempoEstado] = useState('G');
	const [tiempoProyecto, setTiempoProyecto] = useState('0 min');
	const [enfasisId, setEnfasisId] = useState(false);
	const [moduloId, setModuloId] = useState(false);
	const [isOpenModalCrearActividad, setIsOpenModalCrearActividad] =
		useState(false);
	const [openModalEditarActividad, setOpenModalEditarActividad] =
		useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const [
		isOpenNotification,
		openNotificacion,
		closeNotification,
		isTItle,
		setTitleNotification,
		isContent,
		setContentNotification,
	] = useNotificationMui(false);

	const openA = Boolean(anchorEl);

	useEffect(() => {
		if (typeof id != 'undefined') {
			getProyectosById();
			getCarrById();
		}
	}, [id]);

	useEffect(() => {
		setTiempoProyecto(
			detalles.length > 0 ? detalles[0].tiempoProyecto : '0 min'
		);
		setTiempoEstado(detalles.length > 0 ? detalles[0].estadoTiempo : 'G');
	});

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const showModalCrearActividad = (enfasis_id = null, modulo_id = null) => {
		if (typeof modulo_id == 'number') {
			setModuloId(modulo_id);
		}

		if (typeof enfasis_id == 'number') {
			setEnfasisId(enfasis_id);
		}

		setIsOpenModalCrearActividad(!isOpenModalCrearActividad);
		handleClose();
	};

	const [editActividad, setEditActividad] = useState();

	const handleChangeEditActividad = actividad_id => {
		setEditActividad(actividad_id);
	};

	const handleChangeEnfasisId = enfasisid => {
		setEnfasisId(enfasisid);
	};

	const handleChangeModuloIdParent = moduloid => {
		setModuloId(moduloid);
	};

	const handleOnClickEditActividad = event => {
		let actividad_id = event.target.dataset.actividad_id;
		let enfasis_id = event.target.dataset.enfasis_id;
		let modulo_id = event.target.dataset.modulo_id;
		handleChangeEditActividad(actividad_id);
		handleChangeEnfasisId(enfasis_id);
		handleChangeModuloIdParent(modulo_id);
		setOpenModalEditarActividad(!isOpenModalCrearActividad);
	};

	const [openModalConfirmActividad, setOpenModalConfirmActividad] =
		useState(false);
	const [confirmacion, setConfirmacion] = useState();
	const [idDeleteActividad, setIdDeleteActividad] = useState(-1);
	const [mensajeEliminacion, setMensajeEliminacion] = useState('');
	const [idDeleteModulo, setIdDeleteModulo] = useState(-1);
	const [idDeleteProyecto, setIdDeleteProyecto] = useState(-1);

	const showModalConfirmDelete = event => {
		setOpenModalConfirmActividad(true);
		let id = event.target.getAttribute('data-actividad_id');
		let actiNomb = event.target.getAttribute('data-descri_doc');

		let idMod = event.target.getAttribute('data-modulo_id');
		let moduNomb = event.target.getAttribute('data-nombre_modulo');
		let idProy = event.target.getAttribute('data-proyecto_id');

		if (id != null) {
			setIdDeleteActividad(id);
			setMensajeEliminacion(
				'Si eliminas "' + actiNomb + '" se perderan todos los datos cargados.'
			);
		} else {
			setIdDeleteActividad('');
		}

		if (idMod != null) {
			setIdDeleteModulo(idMod);
			setMensajeEliminacion(
				'Si eliminas "' + moduNomb + '" se perderan todos los datos cargados.'
			);
		} else {
			setIdDeleteModulo('');
		}

		if (idProy != null) {
			setIdDeleteProyecto(idProy);
			setMensajeEliminacion(
				'Si eliminas "' +
					descripcion +
					'" se perderan todos los datos cargados.'
			);
		} else {
			setIdDeleteProyecto('');
		}
	};

	const closeModalConfirmActividad = val => {
		setOpenModalConfirmActividad(false);
		setConfirmacion(val);
	};

	const configuracionActividad = {
		titulo: 'Eliminar',
		descripcion: mensajeEliminacion,
	};

	const iniNotiErrorMod = {
		titulo: '',
		mensaje: 'Warning',
		estilo: 'notiDangerFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const [OpenNotiCrear, setOpenNotiCrear] = useState(false);

	const [tituloModulo, setTituloModulo] = useState('');
	const [observacionModulo, setObservacionModulo] = useState('');
	const [modal, setModal] = useState(false);
	const [moduDescri, setModuDescri] = useState('');
	const [moduObservacion, setModuObservacion] = useState('');
	const [idModSelect, setIdModSelect] = useState('');
	const [valid, setValid] = useState(true);

	const [OpenNotiEditMod, setOpenNotiEditMod] = useState(false);
	const [notiConfigNotiErrorMod, setNotiConfigNotiErrorMod] =
		useState(iniNotiErrorMod);
	const [OpenNotiErrorMod, setOpenNotiErrorMod] = useState(false);

	const showModalModuloEdit = event => {
		setModal(!modal);
		let descrip = event.target.getAttribute('data-nombre_modulo');
		let observacion = event.currentTarget.dataset.observacion;
		let id = event.currentTarget.dataset.modulo_id;
		setIdModSelect(id);
		setModuDescri(descrip);
		setModuObservacion(observacion);
	};

	function clearModulo() {
		setObservacionModulo('');
		setIdModSelect('');
		setModuDescri('');
		setModuObservacion('');
	}

	const onChangeTitulo = event => {
		let titulo = event.target.value;
		setValid(titulo != '' ? true : false);
		if (titulo != '') {
			setTituloModulo(titulo);
		} else {
			setTituloModulo('');
		}
	};

	const onChangeObservacion = event => {
		let obseModu = event.target.value;
		setObservacionModulo(obseModu);
	};

	const notiConfigNotiEditMod = {
		titulo: '',
		mensaje: 'Datos modificados con éxito',
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};
	const notiConfigNotiCrear = {
		titulo: '',
		mensaje: 'Datos creados con éxito',
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const [openLoading, setOpenLoading] = React.useState(false);

	const handleOpenLoading = () => {
		setOpenLoading(true);
	};

	const handleCloseLoading = () => {
		setOpenLoading(false);
	};
	/* MMMMMM */

	const [modalCrearModulo, setModalCrearModulo] = useState(false);
	const [modCreateTitulo, setModCreateTitulo] = useState('');
	const [modCreateObserv, setModCreateObserv] = useState('');

	const showModalModuloCrear = event => {
		setModalCrearModulo(!modalCrearModulo);
		let descrip = event.target.getAttribute('data-nombre_modulo');
		let observacion = event.currentTarget.dataset.observacion;
		setModCreateTitulo(descrip);
		setModCreateObserv(observacion);
	};

	const [openModalHacerCopia, setOpenModalHacerCopia] = useState(false);

	const handleOpenModalHacerCopia = () => {
		setOpenModalHacerCopia(true);
	};

	const [openModalEditarProyecto, setOpenModalEditarProyecto] = useState(false);

	const handleOpenModalEditarProyecto = () => {
		setOpenModalEditarProyecto(true);
	};

	const [isEditDescripcion, setIsEditDescripcion] = useState(false);

	const handleChangeProyectoDescripcion = e => {
		let text = e.currentTarget.textContent.trim();
		e.currentTarget.style.textOverflow = 'ellipsis';

		if (text.length <= 0) {
			text = descripcion;
			e.currentTarget.textContent = text;
		}

		setIsEditDescripcion(true);
		setDescripcion(text);
		setProyectoData({
			...proyectoData,
			['descripcion']: descripcion,
		});

		e.currentTarget.scrollLeft = 0;
	};

	useEffect(() => {
		editProyectoData();
	}, [descripcion]);

	useEffect(() => {
		getCarrById();
	}, [carreraId]);

	const [permitirMover, setPermitirMover] = useState(true);

	function cambiarOrdenModulo(event) {
		event.preventDefault();
		let tipo = event.currentTarget.dataset.tipo;
		let idModulo = event.currentTarget.dataset.modulo_id;
		editarOrdenModulo(idModulo, tipo);
	}

	function cambiarOrdenActividad(event) {
		let tipo = event.currentTarget.dataset.tipo;
		let idActi = event.currentTarget.dataset.actividad_id;

		editarOrdenActividad(idActi, tipo);
	}
	function cambiarOrdenTema(event) {
		let tipo = event.currentTarget.dataset.tipo;
		let tema_name = event.currentTarget.dataset.tema_completo;
		let idModu = event.currentTarget.dataset.modulo_id;

		editarOrdenTema(tema_name, tipo, idModu);
	}

	function moverHTMLtema(event) {
		let tipo = event.currentTarget.dataset.tipo;
		let tema_name = event.currentTarget.dataset.tema_sin_espacio;
		let archivo = event.currentTarget.dataset.archivo;

		// Tomo el header del tema
		let tema = document.querySelector('#' + tema_name);

		// Tomo el contenido del tema, donde están las actividades asignadas al tema
		let temaCollapse = document.querySelector('#' + tema_name + 'Abajo');

		let arriba = tema.previousElementSibling.previousElementSibling;
		let abajo = tema.nextElementSibling.nextElementSibling.nextElementSibling;

		if (permitirMover) {
			// Valida que de TRUE el llamado al endpoint
			if (
				tipo == 'S' &&
				arriba != null &&
				tema.previousElementSibling.className !=
					'MuiTableRow-root css-1q1u3t4-MuiTableRow-root'
			) {
				// Valido si el elemento Sube (S) o Baja (B) y si el elemento esta en su tope

				let arriba = tema.previousElementSibling.previousElementSibling;
				let arribaCollapse = temaCollapse.previousElementSibling;
				let arribaCollapse2 = temaCollapse.previousElementSibling;
				// Consulto los elementos que hay por encima del seleccionado.

				arriba.insertAdjacentElement('beforebegin', tema);
				arribaCollapse.insertAdjacentElement('beforebegin', temaCollapse);
				arribaCollapse2.insertAdjacentElement('afterend', temaCollapse);
				// Los muevo hacia arriba
			} else if (tipo == 'B' && abajo != null) {
				// Valido si el elemento Sube (S) o Baja (B)
				if (archivo == 'modulo') {
					// Valido donde se realiza el movimiento hacia abajo en este caso dentro de un módulo

					let abajo =
						tema.nextElementSibling.nextElementSibling.nextElementSibling;
					let abajoCollapse =
						temaCollapse.nextElementSibling.nextElementSibling
							.nextElementSibling;
					let abajoCollapse2 =
						temaCollapse.nextElementSibling.nextElementSibling
							.nextElementSibling;
					// Consulto los elementos que hay por debajo

					abajo.insertAdjacentElement('afterend', tema);
					abajoCollapse.insertAdjacentElement('afterend', temaCollapse);
					abajoCollapse2.insertAdjacentElement('beforebegin', temaCollapse);
					// Los muevo hacia abajo
				} else if (archivo == 'card') {
					// Valido donde se realiza el movimiento hacia abajo en este caso dentro de Recursos Compartidos
					let abajo =
						tema.nextElementSibling.nextElementSibling.nextElementSibling;
					let abajoCollapse =
						temaCollapse.nextElementSibling.nextElementSibling
							.nextElementSibling;
					let abajoCollapse2 =
						temaCollapse.nextElementSibling.nextElementSibling
							.nextElementSibling;
					// Consulto los elementos que hay por debajo

					abajo.insertAdjacentElement('afterend', tema);
					abajoCollapse.insertAdjacentElement('afterend', temaCollapse);
					abajoCollapse2.insertAdjacentElement('beforebegin', temaCollapse);
					// Los muevo hacia abajo
				}
			}

			// Resalto que elemento se movió
			document.querySelector('#' + tema_name).style.transition =
				'background .5s';
			document.querySelector('#' + tema_name).style.background = '#d7d7d7';
			setTimeout(function () {
				document.querySelector('#' + tema_name).style.background = '#FFF';
			}, 200);
		}
	}

	return (
		<Container className="contenedor">
			<ModalEditarProyecto
				open={openModalEditarProyecto}
				setOpen={setOpenModalEditarProyecto}
				getProyectosById={getProyectosById}
				id={id}
			/>

			<ModalHacerCopia
				open={openModalHacerCopia}
				setOpen={setOpenModalHacerCopia}
				proyectoId={proyectoId}
				setProyectoId={setProyectoId}
			/>
			<Loading
				bg="bgLoadingWhiteTrans"
				open={openLoading}
				tiempoMensaje={3000}
			/>
			<Notificacion
				open={OpenNotiEditMod}
				setState={setOpenNotiEditMod}
				config={notiConfigNotiEditMod}
			/>
			<Notificacion
				open={OpenNotiCrear}
				setState={setOpenNotiCrear}
				config={notiConfigNotiCrear}
			/>
			<ModalCrearModulo
				modal={modalCrearModulo}
				showModalModulo={showModalModuloCrear}
				createModuloData={createCustomModulo}
				descripcion={moduDescri}
				observacion={moduObservacion}
				onChangeTitulo={onChangeTitulo}
				onChangeObservacion={onChangeObservacion}
				valid={valid}
			/>
			<ModalEditarModulo
				modal={modal}
				showModalModulo={showModalModuloEdit}
				descripcion={moduDescri}
				observacion={moduObservacion}
				onChangeTitulo={onChangeTitulo}
				onChangeObservacion={onChangeObservacion}
				editModuloData={editModuloData}
				valid={valid}
			/>
			<ModalCustomConfirmacion
				openModalConfirm={openModalConfirmActividad}
				onClose={closeModalConfirmActividad}
				configs={configuracionActividad}
				icon={
					<WarningIcon
						className="confirmIcon"
						style={{ fontSize: '20px', color: '#626262' }}
					/>
				}
				content=""
				contentButton={
					<div style={{ marginTop: '-40px' }}>
						<Button
							className="buttonEliminarModal"
							onClick={
								idDeleteModulo > 0
									? deleteModuloData
									: idDeleteActividad > 0
									? deleteActividadData
									: deleteProyectoData
							}
						>
							Continuar
						</Button>
						<Button
							outlined="true"
							className="buttonEliminarModalOutline"
							onClick={closeModalConfirmActividad}
						>
							Cancelar
						</Button>
					</div>
				}
			/>
			<DeleteNotificationMui
				isOpen={isOpenNotification}
				title={isTItle}
				content={isContent}
			>
				{!isMessageNotification && (
					<>
						<Button onClick={closeNotification}>Cancelar</Button>
						<Button
							onClick={
								idDeleteModulo > 0
									? deleteModuloData
									: idDeleteActividad > 0
									? deleteActividadData
									: deleteProyectoData
							}
							autoFocus
						>
							Eliminar
						</Button>
					</>
				)}
			</DeleteNotificationMui>
			<ModalCrearActividad
				isOpen={isOpenModalCrearActividad}
				proyecto={proyectoData}
				moduloParent={moduloId}
				enfasisParent={enfasisId}
				getProyectosById={getProyectosById}
				showModalCrearActividad={showModalCrearActividad}
				setIsOpenModalCrearActividad={setIsOpenModalCrearActividad}
				handleChangeEnfasisId={handleChangeEnfasisId}
				handleChangeModuloIdParent={handleChangeModuloIdParent}
			/>

			<ModalEditarActividad
				actividades={actividades}
				proyecto={proyectoData}
				enfasisId={enfasisId}
				modulos={modulos}
				openModalEditarActividad={openModalEditarActividad}
				idEditActividad={editActividad}
				getProyectosById={getProyectosById}
				handleOpenModalEditarActividad={handleOnClickEditActividad}
				setOpenModalEditarActividad={setOpenModalEditarActividad}
				handleChangeEditActividad={handleChangeEditActividad}
			/>

			<div className="textoContenidoTablero">
				<div className="seccionVolver">
					<IconButton href="/proyectos">
						<ArrowBackIcon />
					</IconButton>
					<h1 className="tituloContenido">Detalle proyecto</h1>
				</div>
				<div className="tituloBotones">
					<Link to={`../temasProyecto`} state={{ id: proyectoData.id }}>
						<IconButton>
							<AccountTreeIcon style={{ color: '#003087' }} />
						</IconButton>
					</Link>
					<Link to={`../tableroProyectos`} state={{ id: proyectoData.id }}>
						<IconButton style={{ marginLeft: '4px' }}>
							<ViewColumnIcon />
						</IconButton>
					</Link>
				</div>
			</div>

			<div className="proyectoHeader">
				<div className="tituloEdit">
					<h2
						contentEditable={true}
						style={{
							fontSize: '16px',
							textOverflow: 'ellipsis',
							maxWidth: '900px',
							width: '100%',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textAlign: 'left',
						}}
						suppressContentEditableWarning={true}
						onBlur={handleChangeProyectoDescripcion}
						onClick={event => {
							event.currentTarget.style.textOverflow = 'clip';
						}}
					>
						{descripcion}
					</h2>
					<IconButton>
						<EditIcon style={{ color: '#FFF', fontSize: '19px' }} />
					</IconButton>
				</div>
				<div className="proyectoTimer">
					<Stack direction="row" spacing={1} mt={2}>
						{tiempoEstado == 'V' && (
							<div>
								<Chip
									className="chipVerde"
									style={{ color: '#003087', marginRight: '6px' }}
									icon={<AccessTimeIcon style={{ color: '#003087' }} />}
									label={<span>{tiempoProyecto}</span>}
								/>
								<Tooltip
									placement="top"
									arrow
									title="No supera la duración base."
								>
									<CheckCircleIcon style={{ color: '#53D258' }} />
								</Tooltip>
							</div>
						)}
						{tiempoEstado == 'A' && (
							<div>
								<Chip
									className="chipAmarillo"
									style={{ color: '#003087', marginRight: '6px' }}
									icon={<AccessTimeIcon className="iconAmarillo" />}
									label={<span>{tiempoProyecto}</span>}
								/>
								<Tooltip
									placement="top"
									arrow
									title="Alcanzando la duración base."
								>
									<WarningIcon className="iconAmarillo" />
								</Tooltip>
							</div>
						)}
						{tiempoEstado == 'R' && (
							<div>
								<Chip
									className="chipRojo"
									style={{ color: '#003087', marginRight: '6px' }}
									icon={<AccessTimeIcon className="iconRojo" />}
									label={<span>{tiempoProyecto}</span>}
								/>
								<Tooltip placement="top" arrow title="Supera la duración base.">
									<ErrorIcon className="iconRojo" />
								</Tooltip>
							</div>
						)}
						{tiempoEstado == 'G' && (
							<div>
								<Chip
									className="chipGeneral"
									style={{
										background: '#FFF',
										color: '#003087',
										marginRight: '8px',
									}}
									icon={<AccessTimeIcon style={{ color: '#003087' }} />}
									label={<span>{tiempoProyecto}</span>}
								/>
							</div>
						)}
					</Stack>
					<IconButton
						id="basic-button"
						aria-controls={openA ? 'basic-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={openA ? 'true' : undefined}
						onClick={handleClick}
					>
						<MoreVertIcon style={{ color: '#FFF' }} />
					</IconButton>
					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={openA}
						style={{ zIndex: '0' }}
						onClose={handleClose}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}
					>
						<MenuItem
							onClick={() => {
								handleOpenModalEditarProyecto();
								handleClose();
							}}
						>
							Editar
						</MenuItem>
						<MenuItem onClick={showModalModuloCrear}>Crear módulo</MenuItem>
						<MenuItem onClick={showModalCrearActividad}>
							Crear actividad
						</MenuItem>
						<MenuItem
							onClick={() => {
								handleOpenModalHacerCopia();
								handleClose();
							}}
						>
							Hacer una copia
						</MenuItem>
						<MenuItem
							onClick={showModalConfirmDelete}
							data-proyecto_id={proyectoData.id}
						>
							Eliminar
						</MenuItem>
					</Menu>
				</div>
			</div>

			<div
				style={{
					marginTop: '3px',
					display: 'flex',
					justifyContent: 'right',
					background: '#003087',
					borderRadius: '12px',
					padding: '0px 10px',
				}}
			>
				{detalleMaterials.map((item, index) => (
					<div key={index}>
						<Tooltip key={index} title={item.material} arrow>
							<Chip
								style={{
									backgroundColor: '#003087',
									color: '#fff',
									margin: '0px 6px',
									fontSize: '12px',
								}}
								icon={
									<span
										className="material-icons"
										style={{ color: '#fff', fontSize: '18px' }}
									>
										{item.iconoMaterial != null ? item.iconoMaterial : 'public'}
									</span>
								}
								label={<span key={item.material}>{item.tiempoMaterial}</span>}
							/>
						</Tooltip>
					</div>
				))}
			</div>

			<InfoProyectoDeta
				observacion={observacion}
				hsCurTotal={hsCurTotal}
				asignatura={asignatura}
				detalles={detalles}
				unidad={unidad}
				carrera={carrera}
			/>

			<div style={{ fontFamily: 'Roboto', fontSize: '15px' }}></div>

			<div>
				<CardTemaProyecto
					actividades={actividades}
					actividadesDetalleMaterials={actividadesDetalleMaterials}
					temasDetalleMaterials={proyectoData.temasDetalleMaterials}
					proyecto={proyectoData}
					actividadesDetalle={actividadesDetalle}
					showModalConfirmDelete={showModalConfirmDelete}
					handleOnClickEditActividad={handleOnClickEditActividad}
					showModalCrearActividad={showModalCrearActividad}
					permitirMover={permitirMover}
					cambiarOrdenActividad={cambiarOrdenActividad}
					cambiarOrdenTema={cambiarOrdenTema}
					moverHTMLtema={moverHTMLtema}
				/>
				<div id="modulos">
					{modulos?.map((item, index) => (
						<div key={index} id={'card_mod_' + item.id}>
							<ModTemaProyecto
								key={item.id + index}
								detalleMaterials={item.detalleMaterials}
								idModulo={item.id}
								cambiarOrden={cambiarOrdenModulo}
								showModalModulo={showModalModuloEdit}
								showModalConfirmDelete={showModalConfirmDelete}
								modulos={modulos}
								handleOnClickEditActividad={handleOnClickEditActividad}
								temasDetalleMaterials={proyectoData.temasDetalleMaterials}
								tema={item.actividades.tema}
								panel={item.id}
								observacion={item.observacion}
								proyecto={proyectoData}
								cambiarOrdenActividad={cambiarOrdenActividad}
								showModalCrearActividad={showModalCrearActividad}
								descripcion={item.descripcion}
								moverHTMLtema={moverHTMLtema}
								permitirMover={permitirMover}
								cambiarOrdenTema={cambiarOrdenTema}
								actividades={item.actividades}
								detalles={item.detalles?.map((item, index) => (
									<span key={index}>{item.tiempoModulos}</span>
								))}
							/>
						</div>
					))}
				</div>
			</div>
			<Grid container rowSpacing={1} spacing={2}>
				<Grid item>
					<CrearModuloAuto
						getProyectosById={getProyectosById}
						proyecto={proyectoData}
						typeButton="chip"
					/>
				</Grid>
				<Grid item>
					<Chip
						className="crearModuloBtn"
						style={{ backgroundColor: '#CCD6E7', color: '#003087' }}
						onClick={showModalCrearActividad}
						icon={<AddIcon style={{ color: '#003087' }} />}
						label="Crear actividad"
						clickeable="true"
					/>
				</Grid>
			</Grid>
		</Container>
	);

	async function getProyectosById() {
		try {
			handleOpenLoading();
			let proyecto = await getProyectoByIdApi(proyectoId);
			setActividades(proyecto.actividades);
			setModulos(proyecto.modulos);
			setDetalles(proyecto.detalles);
			setDetalleMaterials(proyecto.detalleMaterials);
			setActividadesDetalleMaterials(proyecto.actividadesDetalleMaterials);
			setActividadesDetalleEnfasis(proyecto.actividadesDetalleEnfasis);
			setActividadesDetalle(proyecto.actividadesDetalle);
			setDescripcion(proyecto.descripcion);
			setObservacion(proyecto.observacion);
			setHsCurTotal(proyecto.hsCurTotal);
			setAsignatura(proyecto.asignatura);
			setUnidad(proyecto.unidad);
			setCarreraId(proyecto.carreraId);
			setProyectoData(proyecto);
			setTimeout(function () {
				handleCloseLoading();
			}, 1000);
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function deleteActividadData() {
		try {
			let deleteActividad = await deleteProyectoActividadApi(idDeleteActividad);

			if (deleteActividad?.status || deleteActividad) {
				getProyectosById();
				closeNotification();
				setOpenModalConfirmActividad(false);
				setOpenNotiDelete(true);
			} else {
				setIsMessageNotification(true);
				setTitleNotification('Informacion');
				setContentNotification(deleteActividad.message);
				openNotificacion({ delay: 3000 });
			}

			setIdDeleteActividad(-1);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);
			console.log(await error?.json());
		}
	}

	async function deleteProyectoData() {
		try {
			let deleteProyecto = await deleteProyectoApi(idDeleteProyecto);

			if (deleteProyecto.status) {
				setOpenModalConfirmActividad(false);
				setOpenNotiDelete(true);
				setTimeout(() => {
					navigate('/proyectos', { replace: true });
				}, 2000);
			} else {
				setIsMessageNotification(true);
				setTitleNotification('Informacion');
				setContentNotification(deleteProyecto.message);
				openNotificacion({ delay: 3000 });
			}
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function deleteModuloData() {
		try {
			let deleteModulo = await deleteProyectoModuloApi(idDeleteModulo);

			if (deleteModulo.status) {
				getProyectosById();
				closeNotification();
				setOpenModalConfirmActividad(false);
				setOpenNotiDelete(true);
			} else {
				setIsMessageNotification(true);
				setTitleNotification('Informacion');
				setContentNotification(deleteModulo.message);
				openNotificacion({ delay: 3000 });
			}

			setIdDeleteModulo(-1);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);
			console.log(await error?.json());
		}
	}

	async function editModuloData() {
		try {
			let edit = await editProyectoModuloApi(idModSelect, {
				descripcion: tituloModulo == '' ? moduDescri : tituloModulo,
				observacion: observacionModulo,
			});
			setOpenNotiEditMod(true);
			getProyectosById();
			showModalModuloEdit(!modal);
			setOpenNotiEditMod(true);
			clearModulo();
		} catch (error) {
			if (error.status == 422) {
				console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);
				let msj = await error.json();
				notiConfigNotiErrorMod.mensaje = msj.violations[0].message;
				setOpenNotiErrorMod(true);
			}
		}
	}

	async function createCustomModulo() {
		try {
			let result = await createProyectoModuloApi({
				descripcion: tituloModulo,
				observacion: observacionModulo,
				proyecto: proyectoData.id,
			});
			await getProyectosById();
			setModalCrearModulo(!modalCrearModulo);
			setTimeout(function () {
				setOpenNotiCrear(true);
			}, 1300);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);
		}
	}

	async function editProyectoData() {
		try {
			if (isEditDescripcion) {
				let edit = await editProyectoApi(id, { descripcion });
				setOpenNotiEditMod(true);
			}
		} catch (error) {
			let msj = await error.json();
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);

			if (error.status == 422) {
				notiConfigNotiErrorMod.mensaje = msj.violations[0].message;
				setOpenNotiErrorMod(true);
			}
		}
	}

	async function getCarrById() {
		try {
			if (carreraId != null) {
				let carrById = await getCarrByIdApi(carreraId);
				setCarrera(carrById);
			} else {
				setCarrera(null);
			}
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 177 ~ getUser ~ error', error);
		}
	}

	async function editarOrdenModulo(idModu, tipoMod) {
		try {
			let edit = await editOrdenModulo({
				planifPyId: +id,
				planifModId: +idModu,
				tipo: tipoMod,
				dato: null,
			});
			setPermitirMover(edit.status);
		} catch (error) {
			console.log(error);
		}
	}

	async function editarOrdenActividad(idActi, tipoActi) {
		try {
			let edit = await editOrdenActividad({
				planifPyId: +id,
				planifPyActId: +idActi,
				tipo: tipoActi,
				dato: null,
			});
			setPermitirMover(edit.status);
		} catch (err) {
			console.log(err);
		}
	}
	async function editarOrdenTema(tema, tipoTema, idModu) {
		try {
			let edit = await editOrdenTema({
				planifPyId: +id,
				planifModId: +idModu,
				tema: tema,
				tipo: tipoTema,
				dato: null,
			});
			setPermitirMover(edit.status);
		} catch (error) {
			console.log(error);
		}
	}
}
