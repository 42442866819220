import { getAccessToken } from './auth';
import { getTokenRefresh } from '../api/user';
import { toast } from 'react-toastify';
export function secureFetch(url, method, data) {
	let accessToken = getAccessToken();

	let headers = {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	};

	if (accessToken) {
		headers = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: `Bearer ${accessToken}`,
		};
	}

	let body = ['POST', 'DELETE', 'PUT'].includes(method)
		? JSON.stringify(data)
		: null;

	if (method == 'GET') {
		if (data?.pagination?.value) {
			//json+ld TODO
			headers = {
				Authorization: `Bearer ${accessToken}`,
			};

			url =
				url +
				'?' +
				new URLSearchParams({
					pagination: data.pagination.value,
					items: data.pagination.items,
				});
		} else {
			url = url.concat('?');

			if (data?.filters?.properties?.length > 0) {
				let raw = false;
				data?.filters?.properties.forEach(el => {

					if (el.hasOwnProperty('raw')) {
						raw = el.raw;
						delete el['raw'];
					}

					url =
						url +
						new URLSearchParams({
							...{ pagination: false },
							...el,
						});
				});

				if (raw) {
					url = `${url}&${raw}`;
				}
			} else {
				url =
					url +
					new URLSearchParams({
						pagination: false,
					});
			}
		}
	}

	// console.log(url);

	const CloseButton = ({ closeToast }) => (
		<span className="material-symbols-rounded" style={{alignSelf: 'center'}}>
			close
		</span>
	); 
	
	return new Promise((resolve, reject) => {
		fetch(url, {
			method: method || 'GET',
			headers,
			body,
		})
			.then(async response => {
				if (response.ok) {
					const isJson = response.headers
						.get('content-type')
						?.includes('application/json');
					isJson ? resolve(response.json()) : resolve(true);
				} else {
					switch (response.status) {
						case 401:
							let responseTokenRefresh = await getTokenRefresh();

							if (!responseTokenRefresh) {
								return window.location.href = '/login';
							}
							
							break;
						case 403:
							toast.error('Ha ocurrido un error, sin acceso, contacte a SIUCC', {
								position: toast.POSITION.TOP_RIGHT,
								progress: undefined,
								hideProgressBar: true,
								style: { width: '390px', marginLeft: '-100px'},
								theme: "colored",
								closeButton: CloseButton,
							});
							break;
						case 404:
							toast.error('Ha ocurrido un error, recurso no encontrado, contacte a SIUCC', {
								position: toast.POSITION.TOP_RIGHT,
								progress: undefined,
								hideProgressBar: true,
								style: { width: '390px', marginLeft: '-100px'},
								theme: "colored",
								closeButton: CloseButton,
							});
							break;
						case 500:
							toast.error('Ha ocurrido un error, intente nuevamente, contacte a SIUCC', {
								position: toast.POSITION.TOP_RIGHT,
								progress: undefined,
								hideProgressBar: true,
								style: { width: '390px', marginLeft: '-100px'},
								theme: "colored",
								closeButton: CloseButton,
							});
							break;
						default:
							console.log('Ha ocurrido un error, intente nuevamente, contacte a SIUCC');
							break;
					}

					reject(response);
				}
			})
			.catch(error => {
				toast.error('Ha ocurrido un error, intente nuevamente', {
					position: toast.POSITION.TOP_RIGHT,
				});
				reject(error);
			});
	});
}
