import React from 'react';

import {
	Chip,
	Stack,
	IconButton,
	Tooltip,
	Menu,
	MenuItem,
	Table,
	TableRow,
	TableCell,
	Collapse,
	TableBody,
} from '@mui/material';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ThreePointsMenu } from '../threePointsMenu/ThreePointsMenu';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import './cardTemaActividad.scss';

export default function CardTemaActividad(props) {
	const {
		tema,
		idModulo,
		actividades,
		proyecto,
		temasDetalleMaterials,
		handleOnClickEditActividad,
		showModalConfirmDelete,
		showModalCrearActividad,
		tiempoTema,
		permitirMover,
		cambiarOrdenActividad,
		detalleMaterials,
		moverHTMLactividad,
		moverHTMLtema,
		cambiarOrdenTema,
		archivo,
	} = props;

	const [open, setOpen] = React.useState(false);

	let temaSpace = tema.replace(/ /g, '_');

	return (
		<>
			<TableRow className={'tableRowTema ' + temaSpace} id={temaSpace}>
				<TableCell>
					<IconButton size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell align="left">{tema}</TableCell>
				<TableCell align="right" colSpan={4}></TableCell>

				<TableCell align="right">
					<Tooltip title="Tiempo total" arrow>
						<Chip
							className="chipCardProyec"
							icon={<AccessTimeIcon style={{ color: '#FFF' }} />}
							label={tiempoTema}
							style={{ backgroundColor: '#33599F', color: '#FFF' }}
						/>
					</Tooltip>
				</TableCell>
				<TableCell align="right" colSpan={4}>
					<div className='btnBoxUpDownTemaTema'>
						<Tooltip title="Subir Tema" placement="top">
							<IconButton className='btnUpDown'
								sx={{
									"&.MuiButtonBase-root:hover": { bgcolor: "transparent" }
								}}

								data-tema_sin_espacio={temaSpace}
								data-tipo="S"
								data-tema_completo={tema}
								data-modulo_id={idModulo}
								data-archivo={archivo}
								onClick={e => {
									moverHTMLtema(e);
									cambiarOrdenTema(e);
								}}
							>
								<ArrowCircleUpIcon className='btnUpDownIco' />
							</IconButton>
						</Tooltip>
						<Tooltip title="Bajar Tema" placement="bottom">
							<IconButton className='btnUpDown'
								sx={{
									"&.MuiButtonBase-root:hover": { bgcolor: "transparent" }
								}}
								data-tema_sin_espacio={temaSpace}
								data-tema_completo={tema}
								data-archivo={archivo}
								data-modulo_id={idModulo}
								data-tipo="B"
								onClick={e => {
									moverHTMLtema(e);
									cambiarOrdenTema(e);
								}}
							>
								<ArrowCircleDownIcon className='btnUpDownIco' />
							</IconButton>
						</Tooltip>

					</div>
				</TableCell>
				<TableCell align="right" style={{ padding: '5px' }} colSpan={12}>
					<ThreePointsMenu>
						<MenuItem onClick={showModalCrearActividad}>
							Crear actividad
						</MenuItem>
					</ThreePointsMenu>
				</TableCell>
			</TableRow>

			<TableRow className={temaSpace} id={temaSpace + 'Abajo'}>
				<TableCell style={{ padding: '0px' }} colSpan={12}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<div
							style={{
								fontFamily: 'Roboto',
								fontSize: '15px',
							}}
						>
							<Stack
								direction="row"
								spacing={1}
								style={{
									display: 'flex',
									justifyContent: 'right',
									margin: '10px 10px',
								}}
							>
								{temasDetalleMaterials?.map(
									(item, index) =>
										tema == item.tema &&
										item.planifModId === idModulo && (
											<React.Fragment key={index}>
												<div key={index}>
													<Tooltip title={item.material} arrow>
														<Chip
															key={index}
															style={{
																backgroundColor: '#FFF',
																color: '#003087',
																fontSize: '12px',
															}}
															icon={
																<span
																	className="material-icons"
																	style={{ color: '#003087', fontSize: '18px' }}
																>
																	{item.iconoMaterial != null
																		? item.iconoMaterial
																		: 'public'}
																</span>
															}
															label={
																<span key={item.material}>
																	{item.tiempoMaterial}
																</span>
															}
														/>
													</Tooltip>
												</div>
											</React.Fragment>
										)
								)}
							</Stack>
						</div>
						<Table size="small">
							<TableBody style={{ borderTop: '1px solid #ccc' }}>
								{actividades?.map(
									(item, index) =>
										item.tema == tema && (
											<TableRow
												id={'card_acti' + item.id}
												key={index}
												style={{ borderBottom: '1px solid #CCC' }}
											>
												<TableCell
													align="left"
													component="th"
													style={{ border: 'none', background: 'none' }}
													scope="row"
												>
													{item.descriDocente}
												</TableCell>
												<TableCell
													align="left"
													component="th"
													style={{ border: 'none', background: 'none' }}
												>
													{item.descriTipo}
												</TableCell>
												<TableCell align="left" style={{ border: 'none' }}>
													<Chip
														label={item.descriEnfasis}
														sx={{ backgroundColor: item.colorEnfasis }}
													/>
												</TableCell>
												<TableCell align="left" style={{ border: 'none' }}>
													{item.material}
												</TableCell>
												<TableCell
													align="right"
													style={{
														border: 'none',
														borderBottom: '1px solid #ccc',
													}}
												>
													<Stack direction="row" spacing={1}>
														<Tooltip title={item.dificultad} arrow>
															<span
																className="material-icons"
																style={{ color: '#33599F' }}
															>
																{item.iconoDificultad != null
																	? item.iconoDificultad
																	: 'public'}
															</span>
														</Tooltip>
														<Tooltip title={item.proposito} arrow>
															<span
																className="material-icons"
																style={{ color: '#33599F' }}
															>
																{item.iconoProposito != null
																	? item.iconoProposito
																	: 'public'}
															</span>
														</Tooltip>
													</Stack>
												</TableCell>
												<TableCell
													align="right"
													style={{
														border: 'none',
														borderBottom: '1px solid #ccc',
													}}
													colSpan={2}
												>
													<Tooltip title="Tiempo total" arrow>
														<Chip
															style={{
																background: 'none',
																fontFamily: 'Roboto',
																fontStyle: 'normal',
																fontWeight: '400',
																fontSize: '14px',
																lineHeight: '16px',
																color: '#2D2926',
															}}
															icon={
																<span
																	className="material-icons"
																	style={{ color: '#33599F' }}
																>
																	schedule
																</span>
															}
															label={
																<span key={index}>{item.tiempoActividad}</span>
															}
														/>
													</Tooltip>
												</TableCell>
												<TableCell align="right" colSpan={4}>
													<div className='btnBoxUpDownTemaTema'>
														<Tooltip title="Subir Actividad" placement="top">
															<IconButton className='btnUpDown'
																sx={{
																	"&.MuiButtonBase-root:hover": { bgcolor: "transparent" }
																}}

																data-actividad_id={item.id}
																data-tipo="S"
																data-tema="S"
																onClick={e => {
																	moverHTMLactividad(e);
																	cambiarOrdenActividad(e);
																}}
															>
																<ArrowCircleUpIcon className='btnUpDownIco' />
															</IconButton>
														</Tooltip>
														<Tooltip title="Bajar Actividad" placement="bottom">
															<IconButton className='btnUpDown'
																sx={{
																	"&.MuiButtonBase-root:hover": { bgcolor: "transparent" }
																}}

																data-actividad_id={item.id}
																data-tipo="B"
																data-tema="S"
																onClick={e => {
																	moverHTMLactividad(e);
																	cambiarOrdenActividad(e);
																}}
															>
																<ArrowCircleDownIcon className='btnUpDownIco' />
															</IconButton>
														</Tooltip>

													</div>
												</TableCell>
												<TableCell
													align="right"
													style={{ paddingRight: '6px', border: 'none' }}
													colSpan={2}
												>
													<ThreePointsMenu>
														<MenuItem
															data-actividad_id={item.id}
															data-enfasis_id={null}
															data-modulo_id={null}
															onClick={handleOnClickEditActividad}
														>
															Editar
														</MenuItem>
														<MenuItem
															onClick={showModalConfirmDelete}
															data-actividad_id={item.id}
															data-descri_doc={item.descriDocente}
														>
															Eliminar
														</MenuItem>
													</ThreePointsMenu>
												</TableCell>
											</TableRow>
										)
								)}
							</TableBody>
						</Table>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}
