import React, { useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import {
	initialProyectoModuloData,
} from '../../api/Proyecto/proyectoModel';
import { createProyectoModuloApi } from '../../api/Proyecto/proyectoModulo';
import Notificacion from '../../components/snackbar/Notificacion';
import Loading from '../../components/loading/Loading';

export default function CrearModuloAuto(props) {
	const { getProyectosById, proyecto, typeButton, handleClose } = props;

	const [proyectoModuloData, setProyectoModuloData] = useState(
		initialProyectoModuloData
	);

	const handleCreateProyectoModulo = event => {
		createProyectoModulo();
	};

	const [OpenNotiCrear, setOpenNotiCrear] = useState(false);

	const notiConfigNotiCrear = {
		titulo: '',
		mensaje: 'Datos creados con éxito', 
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: "bottom",
		horizontal: "center",
	};

	const [openLoad, setOpenLoad] = React.useState(false);
    const handleCloseLoad = () => {
        setOpenLoad(false);
    };
    const handleOpenLoad = () => {
        setOpenLoad(true);
    };

	return (
		<>
			<Loading
				bg="bgLoadingWhiteTrans"
				open={openLoad}
			/>

			<div>
				{typeButton == 'chip' && (
					<Chip
						className="crearModuloBtn"
						style={{ backgroundColor: '#CCD6E7', color: '#003087' }}
						onClick={handleCreateProyectoModulo}
						icon={<AddIcon style={{ color: '#003087' }} />}
						label="Crear módulo"
						clickeable="true"
					/>
				)}
				{typeButton == 'menu' && (
					<MenuItem onClick={handleCreateProyectoModulo}>Crear módulo</MenuItem>
				)}
				{typeButton == 'large' && (
					<Stack spacing={2} direction="row">
						<Button
							style={{
								background: '#FFFFFF',
								fontFamily: 'Roboto',
								fontStyle: 'normal',
								fontWeight: '400',
								fontSize: '14px',
								lineHeight: '16px',
								color: '#2D2926',
								padding: '16px',
								width: '285px',
								height: '49px',
								border: '1px solid #E9E9E9',
								borderRadius: '12px',
								textTransform: 'capitalize',
								marginTop: '0.5rem',
								justifyContent: 'flex-start',
							}}
							startIcon={<AddIcon />}
							onClick={handleCreateProyectoModulo}
						>
							Crear módulo
						</Button>
					</Stack>
				)}
			</div>
			<TextField
				style={{ display: 'none' }}
				fullWidth
				type={'hidden'}
				id="descripcion"
				name="descripcion"
				label="Título"
				value={'Módulo'}
			/>

			<Notificacion
				open = {OpenNotiCrear}
				setState = {setOpenNotiCrear}
				config = {notiConfigNotiCrear}
			/>
		</>
	);

	async function createProyectoModulo() {
		handleOpenLoad();
		try {
			let { descripcion } = proyectoModuloData;
			let result = await createProyectoModuloApi({
				descripcion: 'Módulo ' + (proyecto.modulos.length + 1),
				proyecto: proyecto.id,
			});
			handleCloseLoad();
			await getProyectosById();
			setTimeout(function () {
				setOpenNotiCrear(true);
			}, 1300);
			
		} catch (error) {
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);
			handleCloseLoad();
		}
	}
}
