import { base_url } from '../config';
import { secureFetch } from '../../utils/http';
import { getAuthUser } from '../../utils/auth';

const endpoints_proyecto_dificultads = {
	GET: 'planif/dificultads',
	GETBYID: 'planif/dificultads',
	CREATE: 'planif/dificultads',
	EDIT: 'planif/dificultads',
	DELETE: 'planif/dificultads',
};

export function getQueryParams() {
	return {
		pagination: {
			value: false,
			items: 2,
			page: 1,
		},
		filters: {
			properties: [],
		},
		filtersDefault: {
			properties: {
				anulado:"anulado",
			} 
		},
	};
}

export async function getDificultadApi(queryParams=null) {
	const url = `${base_url}/api/${endpoints_proyecto_dificultads.GET}`;
	if(queryParams)
		return await secureFetch(url, 'GET', queryParams);
	return await secureFetch(url, 'GET', null);
}

export async function getDificultadByIdApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_dificultads.GETBYID}/${id}`;
	return await secureFetch(url, 'GET', null);
}

export async function createDificultadApi(data) {
	const url = `${base_url}/api/${endpoints_proyecto_dificultads.CREATE}`;
	let dataCreate = {
        ...data,
	};

	
	return await secureFetch(url, 'POST', dataCreate);

}

export async function editDificultadApi(id, data) {
	let user = getAuthUser();
	let dataEdit = {
		...data,
        descripcion: data.descripcion,
        anulado: data.anulado || '',
        color: data.color || '',
        icono: data.icono || ''
	};

	if(dataEdit.anulado == 'S'){
		
		dataEdit.codUsuAnula= user.username;
	}

	const url = `${base_url}/api/${endpoints_proyecto_dificultads.EDIT}/${id}`;
	return await secureFetch(url, 'PUT', dataEdit);
}

export async function deleteDificultadApi(id) {
	const url = `${base_url}/api/${endpoints_proyecto_dificultads.DELETE}/${id}`;
	return await secureFetch(url, 'DELETE',null);
}
