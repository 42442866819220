import './TableroProyectos.scss';
import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import {
	getProyectoByIdApi,
	editProyectoApi,
} from '../../api/Proyecto/proyecto';
import {
	getEnfasisApi,
	getQueryParams as getQueryParamsEnfasis,
} from '../../api/Enfasis/enfasis';
import { generateKeyRandom } from '../../utils/helpers';
import { deleteProyectoActividadApi } from '../../api/Proyecto/proyectoActividad';
import { deleteProyectoApi } from '../../api/Proyecto/proyecto';
import { useNotificationMui } from '../../hooks/useNotificacionMui';
import { NotificationMui as DeleteNotificationMui } from '../../components/notification/NotificactionMui';
import {
	editProyectoModuloApi,
	deleteProyectoModuloApi,
	editOrdenModulo,
} from '../../api/Proyecto/proyectoModulo';
import CardActiTablero from '../../components/cardActiTablero/CardActiTablero';
import CardProyectoTablero from '../../components/cardProyectoTablero/CardProyectoTablero';
import CardModuloTablero from '../../components/cardModuloTablero/CardModuloTablero';
import CrearModuloAuto from '../../components/crearModuloAuto/CrearModuloAuto';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WarningIcon from '@mui/icons-material/Warning';
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import Button from '@mui/material/Button';
import ModalCrearActividad from '../../components/newModalActividad/ModalCrearActividad';
import ModalEditarActividad from '../../components/modal/modalEditarActividad/ModalEditarActividad';
import ModalHacerCopia from '../../components/modal/modalHacerCopia/ModalHacerCopia';
import ModalEditarModulo from '../../components/modal/modalEditarModulo/ModalEditarModulo';
import InfoProyectoDeta from '../../components/InfoProyectoDeta/InfoProyectoDeta';
import ModalEditarProyecto from '../../components/modal/modalEditarProyecto/ModalEditarProyecto';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getCarrByIdApi } from '../../api/Carrera/carr';
import Notificacion from '../../components/snackbar/Notificacion';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Tooltip, Container } from '@mui/material';
import { initialProyectoModuloData } from '../../api/Proyecto/proyectoModel';
import { createProyectoModuloApi } from '../../api/Proyecto/proyectoModulo';
import ModalCustomConfirmacion from '../../components/modal/modalCustomConfirmacion/modalCustomConfirmacion';
import Loading from '../../components/loading/Loading';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ModalCrearModulo from '../../components/modal/modalCrearModulo/ModalCrearModulo';
import { ConstructionOutlined } from '@mui/icons-material';

export default function TableroProyectos() {
	const navigate = useNavigate();
	const params = useParams();
	const location = useLocation();

	// const { id } = params;
	const { id } = Object.keys(params).length === 0 && location.state;

	const [proyectoId, setProyectoId] = useState(id);

	const [actividades, setActividades] = useState([]);
	const [detalles, setDetalles] = useState([]);
	const [detalleMaterials, setDetalleMaterials] = useState([]);
	const [actividadesDetalleMaterials, setActividadesDetalleMaterials] =
		useState([]);
	const [actividadesDetalleEnfasis, setActividadesDetalleEnfasis] = useState(
		[]
	);
	const [actividadesDetalle, setActividadesDetalle] = useState([]);
	const [modulos, setModulos] = useState([]);
	const [enfasis, setEnfasis] = useState([]);
	const [modulo, setModulo] = useState({});
	const [isEditModulo, setIsEditModulo] = useState(false);
	const [proyectoData, setProyectoData] = useState({});
	const [descripcion, setDescripcion] = useState('');
	const [isEditDescripcion, setIsEditDescripcion] = useState(false);
	const [tiempoProyecto, setTiempoProyecto] = useState('0 min');

	const [idDeleteModulo, setIdDeleteModulo] = useState(-1);
	const [idDeleteActividad, setIdDeleteActividad] = useState(-1);
	const [isMessageNotification, setIsMessageNotification] = useState(false);

	const [observacion, setObservacion] = useState('');
	const [hsCurTotal, setHsCurTotal] = useState('');
	const [asignatura, setAsignatura] = useState(null);
	const [unidad, setUnidad] = useState(null);
	const [carrera, setCarrera] = useState(null);
	const [carreraId, setCarreraId] = useState(null);

	const [
		isOpenNotification,
		openNotificacion,
		closeNotification,
		isTItle,
		setTitleNotification,
		isContent,
		setContentNotification,
	] = useNotificationMui(false);

	const [editActividad, setEditActividad] = useState(0);
	const [tiempoEstado, setTiempoEstado] = useState('G');
	const [isOpenModalCrearActividad, setIsOpenModalCrearActividad] =
		useState(false);

	const [enfasisId, setEnfasisId] = useState(false);
	const [moduloId, setModuloId] = useState(false);

	const [idDeleteProyecto, setIdDeleteProyecto] = useState(-1);

	const [anchorEl, setAnchorEl] = React.useState(null);

	const open = Boolean(anchorEl);

	const showModalCrearActividad = (enfasis_id = null, modulo_id = null) => {
		if (typeof modulo_id == 'number') {
			setModuloId(modulo_id);
		}

		if (typeof enfasis_id == 'number') {
			setEnfasisId(enfasis_id);
		}

		setIsOpenModalCrearActividad(!isOpenModalCrearActividad);
		//  handleClose();
	};

	const handleChangeEditActividad = actividad_id => {
		setEditActividad(actividad_id);
	};

	const handleChangeEnfasisId = enfasisid => {
		setEnfasisId(enfasisid);
	};

	const handleChangeModuloIdParent = moduloid => {
		setModuloId(moduloid);
	};

	const handleOnClickEditActividad = event => {
		let actividad_id = event.target.dataset.actividad_id;
		let enfasis_id = event.target.dataset.enfasis_id;
		let modulo_id = event.target.dataset.moudlo_id;
		handleChangeEditActividad(actividad_id);
		handleChangeEnfasisId(enfasis_id);
		handleChangeModuloIdParent(modulo_id);
		setOpenModalEditarActividad(!isOpenModalCrearActividad);
	};

	const handleChangeProyectoDescripcion = e => {
		let text = e.currentTarget.textContent.trim();
		e.currentTarget.style.textOverflow = 'ellipsis';

		if (text.length <= 0) {
			text = descripcion;
			e.currentTarget.textContent = text;
		}

		setIsEditDescripcion(true);
		setDescripcion(text);
		setProyectoData({
			...proyectoData,
			['descripcion']: descripcion,
		});

		e.currentTarget.scrollLeft = 0;
	};

	const handleChangeModuloDescripcion = e => {
		let text = e.currentTarget.textContent;
		e.currentTarget.style.textOverflow = 'ellipsis';

		if (text != modulo.descripcion) {
			let modulo = modulos.filter(el => {
				return e.currentTarget.id == el.id;
			})[0];

			modulo = { ...modulo, ['descripcion']: text };

			setModulo(modulo);
		}

		e.currentTarget.scrollLeft = 0;
	};

	const [OpenNotiEditMod, setOpenNotiEditMod] = useState(false);

	const notiConfigNotiEditMod = {
		titulo: '',
		mensaje: 'Datos modificados con éxito',
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const iniNotiErrorMod = {
		titulo: '',
		mensaje: 'Warning',
		estilo: 'notiDangerFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const [notiConfigNotiErrorMod, setNotiConfigNotiErrorMod] =
		useState(iniNotiErrorMod);
	const [OpenNotiErrorMod, setOpenNotiErrorMod] = useState(false);

	const [proyectoModuloData, setProyectoModuloData] = useState(
		initialProyectoModuloData
	);

	const [OpenNotiCrear, setOpenNotiCrear] = useState(false);
	const [OpenNotiDelete, setOpenNotiDelete] = useState(false);

	const notiConfigNotiCrear = {
		titulo: '',
		mensaje: 'Datos creados con éxito',
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	const notiConfirmDelete = {
		titulo: 'Eliminación exitosa',
		mensaje: 'Se guardaron los cambios realizados',
		estilo: 'notiSuccessFilled',
		tiempo: 4000,
		vertical: 'bottom',
		horizontal: 'center',
	};

	useEffect(() => {
		getProyectosById();
		getCarrById();
	}, [proyectoId]);

	useEffect(() => {
		getEnfasisData();
		getProyectosById();
	}, []);

	useEffect(() => {
		getCarrById();
	}, [carreraId]);

	useEffect(() => {
		editProyectoData();
	}, [descripcion]);

	useEffect(() => {
		setTiempoProyecto(
			detalles.length > 0 ? detalles[0].tiempoProyecto : '0 min'
		);
		setTiempoEstado(detalles.length > 0 ? detalles[0].estadoTiempo : 'G');
	});

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDeleteModulo = event => {
		let id = event.target.getAttribute('data-modulo_id');
		setIsMessageNotification(false);
		setTitleNotification('Eliminación modulo');
		setContentNotification('¿ Esta seguro que desea eliminar el módulo ?');
		openNotificacion();
		setIdDeleteModulo(id);
	};

	const handleDeleteActividad = event => {
		let id = event.target.getAttribute('data-actividad_id');
		setIsMessageNotification(false);
		setTitleNotification('Eliminación actividad');
		setContentNotification('¿ Esta seguro que desea eliminar el actividad ?');
		openNotificacion();
		setIdDeleteActividad(id);
	};

	const [openModalHacerCopia, setOpenModalHacerCopia] = useState(false);

	const handleOpenModalHacerCopia = () => {
		setOpenModalHacerCopia(true);
	};

	const [openModalEditarProyecto, setOpenModalEditarProyecto] = useState(false);
	const [openModalEditarActividad, setOpenModalEditarActividad] =
		useState(false);

	const handleOpenModalEditarProyecto = () => {
		setOpenModalEditarProyecto(true);
	};

	async function handleDeleteProyecto(event) {
		try {
			let id = event.target.getAttribute('data-proyecto_id');
			setTitleNotification('Eliminación proyecto');
			setContentNotification('¿Esta seguro que desea eliminar el proyecto ?');
			openNotificacion();
			setIdDeleteProyecto(id);
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	/* PRUEBA DE MODAL CONFIRMACION */

	const [openModalConfirmActividad, setOpenModalConfirmActividad] =
		useState(false);
	const [confirmacion, setConfirmacion] = useState();

	const showModalConfirmDelete = event => {
		setOpenModalConfirmActividad(true);
		let id = event.target.getAttribute('data-actividad_id');
		let actiNomb = event.target.getAttribute('data-descri_doc');

		let idMod = event.target.getAttribute('data-modulo_id');
		let moduNomb = event.target.getAttribute('data-nombre_modulo');
		let idProy = event.target.getAttribute('data-proyecto_id');

		if (id != null) {
			setIdDeleteActividad(id);
			setMensajeEliminacion(
				'Si eliminas "' + actiNomb + '" se perderan todos los datos cargados.'
			);
		} else {
			setIdDeleteActividad('');
		}

		if (idMod != null) {
			setIdDeleteModulo(idMod);
			setMensajeEliminacion(
				'Si eliminas "' + moduNomb + '" se perderan todos los datos cargados.'
			);
		} else {
			setIdDeleteModulo('');
		}

		if (idProy != null) {
			setIdDeleteProyecto(idProy);
			setMensajeEliminacion(
				'Si eliminas "' +
					descripcion +
					'" se perderan todos los datos cargados.'
			);
		} else {
			setIdDeleteProyecto('');
		}
	};

	const closeModalConfirmActividad = val => {
		setOpenModalConfirmActividad(false);
		setConfirmacion(val);
	};

	const [mensajeEliminacion, setMensajeEliminacion] = useState('');

	const configuracionActividad = {
		titulo: 'Eliminar',
		descripcion: mensajeEliminacion,
	};

	const [expandedMod, setExpandedMod] = React.useState(false);

	const handleChangeExpandedMod = panel => (event, isExpanded) => {
		setExpandedMod(isExpanded ? panel : false);
	};

	const [openLoading, setOpenLoading] = React.useState(false);

	const handleCloseLoading = () => {
		setOpenLoading(false);
	};

	const handleOpenLoading = () => {
		setOpenLoading(true);
	};

	const [tituloModulo, setTituloModulo] = useState('');
	const [observacionModulo, setObservacionModulo] = useState('');
	const [modal, setModal] = useState(false);
	const [moduDescri, setModuDescri] = useState('');
	const [moduObservacion, setModuObservacion] = useState('');
	const [idModSelect, setIdModSelect] = useState('');

	const [valid, setValid] = useState(true);

	const onChangeTitulo = event => {
		let titulo = event.target.value;
		setValid(titulo != '' ? true : false);
		setTituloModulo(titulo);
	};

	const onChangeObservacion = event => {
		let obseModu = event.target.value;
		setObservacionModulo(obseModu);
	};

	const showModalModuloEdit = event => {
		setModal(!modal);
		let descrip = event.target.getAttribute('data-nombre_modulo');
		let observacion = event.currentTarget.dataset.observacion;
		let id = event.currentTarget.dataset.modulo_id;
		setIdModSelect(id);
		setModuDescri(descrip);
		setModuObservacion(observacion);
	};

	function clearModulo() {
		console.log('Limpiado !');
		setTituloModulo('');
		setObservacionModulo('');
		setIdModSelect('');
		setModuDescri('');
		setModuObservacion('');
	}

	const [modalCrearModulo, setModalCrearModulo] = useState(false);
	const [modCreateTitulo, setModCreateTitulo] = useState('');
	const [modCreateObserv, setModCreateObserv] = useState('');

	const [permitirMover, setPermitirMover] = useState(true);

	const showModalModuloCrear = event => {
		setModalCrearModulo(!modalCrearModulo);
		let descrip = event.target.getAttribute('data-nombre_modulo');
		let observacion = event.currentTarget.dataset.observacion;
		setModCreateTitulo(descrip);
		setModCreateObserv(observacion);
	};

	function cambiarOrden(event) {
		event.preventDefault();
		let tipo = event.currentTarget.dataset.tipo;
		let idModulo = event.currentTarget.dataset.modulo_id;
		editarOrdenModulo(idModulo, tipo);
	}

	/* PRUEBA DE MODAL CONFIRMACION */
	return (
		<>
			<ModalCrearModulo
				modal={modalCrearModulo}
				showModalModulo={showModalModuloCrear}
				descripcion={moduDescri}
				observacion={moduObservacion}
				onChangeTitulo={onChangeTitulo}
				onChangeObservacion={onChangeObservacion}
				createModuloData={createCustomModulo}
				valid={valid}
			/>
			<ModalEditarModulo
				modal={modal}
				showModalModulo={showModalModuloEdit}
				descripcion={moduDescri}
				observacion={moduObservacion}
				onChangeTitulo={onChangeTitulo}
				onChangeObservacion={onChangeObservacion}
				editModuloData={editModuloData}
				valid={valid}
			/>

			<ModalCustomConfirmacion
				openModalConfirm={openModalConfirmActividad}
				onClose={closeModalConfirmActividad}
				configs={configuracionActividad}
				icon={
					<WarningIcon
						className="confirmIcon"
						style={{ fontSize: '20px', color: '#626262' }}
					/>
				}
				content=""
				contentButton={
					<div style={{ marginTop: '-40px' }}>
						<Button
							className="buttonEliminarModal"
							onClick={
								idDeleteModulo > 0
									? deleteModuloData
									: idDeleteActividad > 0
									? deleteActividadData
									: deleteProyectoData
							}
						>
							Continuar
						</Button>
						<Button
							outlined="true"
							className="buttonEliminarModalOutline"
							onClick={closeModalConfirmActividad}
						>
							Cancelar
						</Button>
					</div>
				}
			/>

			<DeleteNotificationMui
				isOpen={isOpenNotification}
				title={isTItle}
				content={isContent}
			>
				{!isMessageNotification && (
					<>
						<Button onClick={closeNotification}>Cancelar</Button>
						<Button
							onClick={
								idDeleteModulo > 0
									? deleteModuloData
									: idDeleteActividad > 0
									? deleteActividadData
									: deleteProyectoData
							}
							autoFocus
						>
							Eliminar
						</Button>
					</>
				)}
			</DeleteNotificationMui>

			<ModalHacerCopia
				open={openModalHacerCopia}
				setOpen={setOpenModalHacerCopia}
				proyectoId={proyectoId}
				setProyectoId={setProyectoId}
			/>

			<ModalEditarProyecto
				open={openModalEditarProyecto}
				setOpen={setOpenModalEditarProyecto}
				getProyectosById={getProyectosById}
				id={id}
			/>

			<ModalCrearActividad
				isOpen={isOpenModalCrearActividad}
				proyecto={proyectoData}
				moduloParent={moduloId}
				enfasisParent={enfasisId}
				getProyectosById={getProyectosById}
				showModalCrearActividad={showModalCrearActividad}
				setIsOpenModalCrearActividad={setIsOpenModalCrearActividad}
				handleChangeEnfasisId={handleChangeEnfasisId}
				handleChangeModuloIdParent={handleChangeModuloIdParent}
			/>

			<ModalEditarActividad
				actividades={actividades}
				proyecto={proyectoData}
				enfasisId={enfasisId}
				modulos={modulos}
				openModalEditarActividad={openModalEditarActividad}
				idEditActividad={editActividad}
				getProyectosById={getProyectosById}
				handleOpenModalEditarActividad={handleOnClickEditActividad}
				setOpenModalEditarActividad={setOpenModalEditarActividad}
				handleChangeEditActividad={handleChangeEditActividad}
			/>
			<Loading
				bg="bgLoadingWhiteTrans"
				open={openLoading}
				tiempoMensaje={3000}
			/>

			<Container className="contenedor">
				<div className="textoContenidoTablero">
					<div className="seccionVolver">
						<IconButton href="/proyectos">
							<ArrowBackIcon />
						</IconButton>
						<h1 className="tituloContenido">Detalle proyecto</h1>
					</div>
					<div className="tituloBotones">
						<Link to={`../temasProyecto`} state={{ id: proyectoData.id }}>
							<IconButton>
								<AccountTreeIcon />
							</IconButton>
						</Link>
						<Link to={`../tableroProyectos`} state={{ id: proyectoData.id }}>
							<IconButton style={{ marginLeft: '4px' }}>
								<ViewColumnIcon style={{ color: '#003087' }} />
							</IconButton>
						</Link>
					</div>
				</div>

				<div className="proyectoHeader">
					<div className="tituloEdit">
						<h2
							contentEditable={true}
							onBlur={handleChangeProyectoDescripcion}
							style={{
								fontSize: '16px',
								maxWidth: '900px',
								width: '100%',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								textAlign: 'left',
							}}
							suppressContentEditableWarning={true}
							onClick={event => {
								event.currentTarget.style.textOverflow = 'clip';
							}}
						>
							{descripcion}
						</h2>

						<IconButton>
							<EditIcon style={{ color: '#FFF', fontSize: '19px' }} />
						</IconButton>
					</div>
					<div className="proyectoTimer">
						<Stack direction="row" spacing={1} mt={2}>
							{tiempoEstado == 'V' && (
								<div>
									<Chip
										key={generateKeyRandom()}
										className="chipVerde"
										style={{ color: '#003087', marginRight: '6px' }}
										icon={<AccessTimeIcon style={{ color: '#003087' }} />}
										label={
											<span key={generateKeyRandom()}>{tiempoProyecto}</span>
										}
									/>
									<Tooltip
										placement="top"
										arrow
										title="No supera la duración base."
									>
										<CheckCircleIcon style={{ color: '#53D258' }} />
									</Tooltip>
								</div>
							)}

							{tiempoEstado == 'A' && (
								<div>
									<Chip
										key={generateKeyRandom()}
										className="chipAmarillo"
										style={{ color: '#003087', marginRight: '6px' }}
										icon={<AccessTimeIcon className="iconAmarillo" />}
										label={
											<span key={generateKeyRandom()}>{tiempoProyecto}</span>
										}
									/>
									<Tooltip
										placement="top"
										arrow
										title="Alcanzando la duración base."
									>
										<WarningIcon className="iconAmarillo" />
									</Tooltip>
								</div>
							)}

							{tiempoEstado == 'R' && (
								<div>
									<Chip
										key={generateKeyRandom()}
										className="chipRojo"
										style={{ color: '#003087', marginRight: '6px' }}
										icon={<AccessTimeIcon className="iconRojo" />}
										label={
											<span key={generateKeyRandom()}>{tiempoProyecto}</span>
										}
									/>
									<Tooltip
										placement="top"
										arrow
										title="Supera la duración base."
									>
										<ErrorIcon className="iconRojo" />
									</Tooltip>
								</div>
							)}
							{tiempoEstado == 'G' && (
								<div>
									<Chip
										key={generateKeyRandom()}
										className="chipGeneral"
										style={{
											background: '#FFF',
											color: '#003087',
											marginRight: '8px',
										}}
										icon={<AccessTimeIcon style={{ color: '#003087' }} />}
										label={
											<span key={generateKeyRandom()}>{tiempoProyecto}</span>
										}
									/>
								</div>
							)}
						</Stack>

						<IconButton
							id="basic-button"
							aria-controls={open ? 'basic-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open ? 'true' : undefined}
							onClick={handleClick}
						>
							<MoreVertIcon style={{ color: '#FFF' }} />
						</IconButton>
						<Menu
							id="basic-menu"
							style={{ zIndex: '0' }}
							anchorEl={anchorEl}
							open={open}
							anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
							transformOrigin={{ vertical: 'top', horizontal: 'center' }}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'basic-button',
							}}
						>
							<MenuItem
								onClick={() => {
									handleOpenModalEditarProyecto();
									handleClose();
								}}
							>
								Editar
							</MenuItem>

							<MenuItem
								onClick={() => {
									handleOpenModalHacerCopia();
									handleClose();
								}}
							>
								Hacer una copia
							</MenuItem>

							<MenuItem onClick={showModalModuloCrear}>Crear módulo</MenuItem>

							<MenuItem onClick={showModalCrearActividad}>
								Crear actividad
							</MenuItem>
							<MenuItem
								onClick={showModalConfirmDelete}
								data-proyecto_id={proyectoData.id}
							>
								Eliminar
							</MenuItem>
						</Menu>
					</div>
				</div>

				<div
					style={{
						marginTop: '3px',
						display: 'flex',
						justifyContent: 'right',
						background: '#003087',
						borderRadius: '12px',
						padding: '0px 10px',
					}}
				>
					{detalleMaterials.map((item, index) => (
						<div key={index}>
							<Tooltip title={item.material} arrow>
								<Chip
									style={{
										backgroundColor: '#003087',
										color: '#fff',
										margin: '0px 6px',
										fontSize: '12px',
									}}
									icon={
										<span
											className="material-icons"
											style={{ color: '#fff', fontSize: '18px' }}
										>
											{item.iconoMaterial != null
												? item.iconoMaterial
												: 'public'}
										</span>
									}
									label={<span key={item.material}>{item.tiempoMaterial}</span>}
								/>
							</Tooltip>
						</div>
					))}
				</div>
				<InfoProyectoDeta
					observacion={observacion}
					asignatura={asignatura}
					detalles={detalles}
					unidad={unidad}
					carrera={carrera}
				/>

				<div className="my-4">
					<CardProyectoTablero
						enfasis={enfasis}
						showModalCrearActividad={showModalCrearActividad}
						onBlur={handleChangeProyectoDescripcion}
						handleOnClickEditActividad={handleOnClickEditActividad}
						handleDeleteActividad={handleDeleteActividad}
						showModalConfirmDelete={showModalConfirmDelete}
						actividades={actividades}
						actividadesDetalleMaterials={actividadesDetalleMaterials}
						actividadesDetalleEnfasis={actividadesDetalleEnfasis}
						actividadesDetalle={actividadesDetalle}
						panel={128}
						expanded={expandedMod}
						handleChange={handleChangeExpandedMod}
						contentModulos={modulos
							?.sort((a, b) =>
								a.ordenModulo > b.ordenModulo
									? a.ordenModulo - b.ordenModulo
									: b.ordenModulo - a.ordenModulo
							)
							.map((item, index) => (
								<React.Fragment key={index}>
									<div key={index} id={'card_mod_' + item.id}>
										<CardModuloTablero
											enfasis={enfasis}
											getProyectosById={getProyectosById}
											showModalCrearActividad={showModalCrearActividad}
											handleOnClickEditActividad={handleOnClickEditActividad}
											showModalModulo={showModalModuloEdit}
											fnEdit={handleChangeModuloDescripcion}
											permitirMover={permitirMover}
											handleDeleteModulo={handleDeleteModulo}
											handleDeleteActividad={handleDeleteActividad}
											showModalConfirmDelete={showModalConfirmDelete}
											descripcion={item.descripcion}
											cambiarOrden={cambiarOrden}
											observacion={item.observacion}
											detallesEnfasis={item.detalleEnfasis}
											actividades={actividades}
											idModulo={item.id}
											modulos={item.actividades}
											detalleMaterials={item.detalleMaterials}
											detalleEnfasis={item.detalleEnfasis}
											detalles={item.detalles?.map((item, index) => (
												<span key={index}>{item.tiempoModulos}</span>
											))}
											panel={item.id}
											expanded={expandedMod}
											handleChange={handleChangeExpandedMod}
											content={item.actividades?.map((item, index) => (
												<React.Fragment key={index}>
													<div id={`${index}`} key={index}>
														<CardActiTablero
															id={item.id}
															handleOnClickEditActividad={
																handleOnClickEditActividad
															}
															handleDeleteActividad={handleDeleteActividad}
															descriDocente={item.descriDocente}
															proposito={item.proposito}
															showModalConfirmDelete={showModalConfirmDelete}
															dificultad={item.dificultad}
															descriEnfasis={item.descriEnfasis}
															descriTipo={item.descriTipo}
															detalles={item.detalles}
															material={item.material}
															tema={item.tema}
															iconoProposito={item.iconoProposito}
															iconoDificultad={item.iconoDificultad}
															iconoMaterial={item.iconoMaterial}
															iconoTipo={item.iconoTipo}
															iconoEnfasis={item.iconoEnfasis}
															tiempoActividad={item.tiempoActividad}
														/>
													</div>
												</React.Fragment>
											))}
										/>
									</div>
								</React.Fragment>
							))}
					/>
					<Grid container rowSpacing={1} spacing={2}>
						<Grid item>
							<CrearModuloAuto
								getProyectosById={getProyectosById}
								proyecto={proyectoData}
								typeButton="chip"
							/>
						</Grid>
						<Grid item>
							<Chip
								className="crearModuloBtn"
								style={{ backgroundColor: '#CCD6E7', color: '#003087' }}
								onClick={showModalCrearActividad}
								icon={<AddIcon style={{ color: '#003087' }} />}
								label="Crear actividad"
								clickeable="true"
							/>
						</Grid>
					</Grid>
				</div>
			</Container>

			<Notificacion
				open={OpenNotiEditMod}
				setState={setOpenNotiEditMod}
				config={notiConfigNotiEditMod}
			/>

			<Notificacion
				open={OpenNotiErrorMod}
				setState={setOpenNotiErrorMod}
				config={notiConfigNotiErrorMod}
			/>

			<Notificacion
				open={OpenNotiCrear}
				setState={setOpenNotiCrear}
				config={notiConfigNotiCrear}
			/>

			<Notificacion
				open={OpenNotiDelete}
				setState={setOpenNotiDelete}
				config={notiConfirmDelete}
			/>
		</>
	);

	async function editarOrdenModulo(idModu, tipoMod) {
		try {
			let edit = await editOrdenModulo({
				planifPyId: +id,
				planifModId: +idModu,
				tipo: tipoMod,
				dato: null,
			});
			setPermitirMover(edit.status);
		} catch (error) {
			console.log(error);
		}
	}

	async function getProyectosById() {
		try {
			handleOpenLoading();
			let proyecto = await getProyectoByIdApi(proyectoId);
			setActividades(proyecto.actividades);
			setModulos(proyecto.modulos);
			setDetalles(proyecto.detalles);
			setDetalleMaterials(proyecto.detalleMaterials);
			setActividadesDetalleMaterials(proyecto.actividadesDetalleMaterials);
			setActividadesDetalleEnfasis(proyecto.actividadesDetalleEnfasis);
			setActividadesDetalle(proyecto.actividadesDetalle);
			setDescripcion(proyecto.descripcion);
			setObservacion(proyecto.observacion);
			setHsCurTotal(proyecto.hsCurTotal);
			setAsignatura(proyecto.asignatura);
			setUnidad(proyecto.unidad);
			setCarreraId(proyecto.carreraId);
			setProyectoData(proyecto);
			setTimeout(function () {
				handleCloseLoading();
			}, 1000);
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function getEnfasisData() {
		let queryParamsDefault = getQueryParamsEnfasis();

		let { anulado } = queryParamsDefault.filtersDefault.properties;
		let value = 'N';

		const query = { [anulado]: value };
		queryParamsDefault.filters.properties.push(query);

		try {
			const resp = await getEnfasisApi(queryParamsDefault);
			setEnfasis(resp);
		} catch (error) {
			console.log(await error.json());
		}
	}

	async function editProyectoData() {
		try {
			if (isEditDescripcion) {
				let edit = await editProyectoApi(id, { descripcion });
				setOpenNotiEditMod(true);
			}
		} catch (error) {
			let msj = await error.json();
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);

			if (error.status == 422) {
				notiConfigNotiErrorMod.mensaje = msj.violations[0].message;
				setOpenNotiErrorMod(true);
			}
		}
	}

	async function editModuloData() {
		try {
			let edit = await editProyectoModuloApi(idModSelect, {
				descripcion: tituloModulo,
				observacion: observacionModulo,
			});
			setOpenNotiEditMod(true);
			getProyectosById();
			showModalModuloEdit(!modal);
			setIsEditModulo(false);
			clearModulo();
		} catch (error) {
			let msj = await error.json();
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);

			if (error.status == 422) {
				notiConfigNotiErrorMod.mensaje = msj.violations[0].message;
				setOpenNotiErrorMod(true);
			}
		}
	}

	async function deleteModuloData() {
		try {
			let deleteModulo = await deleteProyectoModuloApi(idDeleteModulo);

			if (deleteModulo.status) {
				getProyectosById();
				closeNotification();
				setOpenModalConfirmActividad(false);
				setOpenNotiDelete(true);
			} else {
				setIsMessageNotification(true);
				setTitleNotification('Informacion');
				setContentNotification(deleteModulo.message);
				openNotificacion({ delay: 3000 });
			}

			setIdDeleteModulo(-1);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);
			console.log(await error?.json());
		}
	}

	async function deleteActividadData() {
		try {
			let deleteActividad = await deleteProyectoActividadApi(idDeleteActividad);

			if (deleteActividad?.status || deleteActividad) {
				getProyectosById();
				closeNotification();
				setOpenModalConfirmActividad(false);
				setOpenNotiDelete(true);
			} else {
				setIsMessageNotification(true);
				setTitleNotification('Informacion');
				setContentNotification(deleteActividad.message);
				openNotificacion({ delay: 3000 });
			}

			setIdDeleteActividad(-1);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:187 ~ editMaterial ~ error', error);
			console.log(await error?.json());
		}
	}

	async function deleteProyectoData() {
		try {
			let deleteProyecto = await deleteProyectoApi(idDeleteProyecto);

			if (deleteProyecto.status) {
				setOpenModalConfirmActividad(false);
				setOpenNotiDelete(true);
				setTimeout(() => {
					navigate('/proyectos', { replace: true });
				}, 2000);
			} else {
				setIsMessageNotification(true);
				setTitleNotification('Informacion');
				setContentNotification(deleteProyecto.message);
				openNotificacion({ delay: 3000 });
			}
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 55 ~ getUser ~ error', error);
		}
	}

	async function getCarrById() {
		try {
			if (carreraId != null) {
				let carrById = await getCarrByIdApi(carreraId);
				setCarrera(carrById);
			} else {
				setCarrera(null);
			}
		} catch (error) {
			console.log('🚀 ~ file: Example.js ~ line 177 ~ getUser ~ error', error);
		}
	}

	async function createCustomModulo() {
		try {
			let result = await createProyectoModuloApi({
				descripcion: tituloModulo,
				observacion: observacionModulo,
				proyecto: proyectoData.id,
			});

			await getProyectosById();
			setModalCrearModulo(!modalCrearModulo);

			setTimeout(function () {
				setOpenNotiCrear(true);
			}, 1300);
		} catch (error) {
			console.log('🚀 ~ file: Material.js:186 ~ createMaterial ~ error', error);
		}
	}
}
