import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, Box } from '@mui/material';
import AccessibilityIcon from '@mui/icons-material/Accessibility';

import './ModalCustomConfirmacion.scss';

export default function ModalCustomConfirmacion(props) {
	const {
		closeModalConfirm,
		openModalConfirm,
		confirmacion,
		configs,
		icon,
		content,
		contentButton,
	} = props;

	const [modalConfirmacion, setModalConfirmacion] = useState(true);

	const handleCloseModal = () => {
		setModalConfirmacion(confirmacion);
	};

	return (
		<>
			<Dialog className="q" onClose={handleCloseModal} open={openModalConfirm}>
				<Box className="modalConfirmacionContainer">
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						{icon}
					</div>
					<div className="modalConfirmacionHeader">
						<DialogTitle className="confirmTitle">{configs.titulo}</DialogTitle>
						<p>{configs.descripcion}</p>
					</div>

					<div
						className="modalConfirmacionContenido"
						style={{ display: content != '' ? '' : 'none' }}
					>
						{content}
					</div>
					<div className="modalConfirmacionBotones">{contentButton}</div>
				</Box>
			</Dialog>
		</>
	);
}
