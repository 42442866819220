const initialProyectoData = {
	codigoId: '',
	codigoTipo: '',
	descripcion: '',
	observacion: '',
};

const initialProyectosData = [];


const initialProyectoCopyData = {
	descripcion: '',
	observacion: '',
    codUni: '',
    codCarr: '',
    codMate: '',
    hrsCurTotal: '',
    persona: '',
    copyProyectoId: '',

};

/*ProyectoActividad*/

const initialProyectoActividadData = {
	planifPyId: '',
	planifModId: '',
	planifActId: '',
	planifProId: '',
	planifDifId: '',
	planifConId: '',
	fecha: '',
	ordenVisual: '',
};

const initialProyectosActividadData = [];

/*ProyectoDetalle*/

const initialProyectoDetalleData = {
	id: '',
	planifUniId: '',
	cantidad: '',
};

const initialProyectoDetallesData = [];

/*ProyectoModulo*/

const initialProyectoModuloData = {
	id: '',
	descripcion: '',
	observacion: '',
	fecha: '',
	ordenVisual: '',
	proyecto: ''
};

const initialProyectoModulosData = [];

export {
	initialProyectoData,
	initialProyectosData,
	initialProyectoActividadData,
	initialProyectosActividadData,
	initialProyectoDetalleData,
	initialProyectoDetallesData,
	initialProyectoModuloData,
	initialProyectoModulosData,
	initialProyectoCopyData
};
